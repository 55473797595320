import React from "react";
// import { Link } from "react-router-dom";

import { switchClasses } from "./../../languages/CssClasses";
import { FooterTexts as texts } from "./../../languages/FooterTexts";

import Icon from "@mdi/react"
// import { mdiPhone, mdiEmailOutline, mdiMapMarker, mdiFacebook, mdiTwitter, mdiYoutube, mdiLinkedin, mdiInstagram } from "@mdi/js";
import { mdiEmailOutline, mdiMapMarker, mdiFacebook, mdiTwitter, mdiYoutube, mdiLinkedin } from "@mdi/js";
// import { getEmail, getMobile, getSocialMediaLink } from "./../../configs/general-config";
import { getEmail, getSocialMediaLink } from "./../../configs/general-config";

// Our SCSS
import "./../../resources/styles/sass/footer.scss";

export default class Footer extends React.Component {
	state = {};

	render() {
		const hideFooterCssClass = this.props.hide === true ? " x-d-n" : "";

		return (
			<footer className={"x-ov-h " + hideFooterCssClass}>
				<div className="container-fluid">
					<div className="row __ff-droidarabickufi">
						{/* TODO: enable the mobile phone number when needed */}
						{/*
						<div className="col-md-3 col-sm-6 x-pt-10 x-pb-10">
							<div className="contact-item">
								<div className="contact-item-icon">
									<Icon
										path={mdiPhone}
										size="1.4em"
										className="x-pos-r x-b-0 x-c-fff"
									/>
								</div>
								<h4 className="contact-item-title">{texts["phone"]}</h4>
								<div className="contact-item-info x-dr-ltr __ff-bootstrap">{getMobile("info")}</div>
							</div>
						</div>
						*/}

						<div className="col-md-3 col-sm-6 x-pt-10 x-pb-10">
							<div className="contact-item">
								<div className="contact-item-icon">
									<Icon
										path={mdiEmailOutline}
										size="1.4em"
										className="x-pos-r x-b-0 x-c-fff"
									/>
								</div>
								<h4 className="contact-item-title">{texts["email"]}</h4>
								<div className="contact-item-info x-dr-ltr __ff-bootstrap">{getEmail("info")}</div>
							</div>
						</div>

						<div className="col-md-3 col-sm-6 x-pt-10 x-pb-10">
							<div className="contact-item">
								<div className="contact-item-icon">
									<Icon
										path={mdiMapMarker}
										size="1.4em"
										className="x-pos-r x-b-0 x-c-fff"
									/>
								</div>
								<h4 className="contact-item-title">{texts["address"]}</h4>
								<div className="contact-item-info x-fz-075rem">{texts["address-value"]}</div>
							</div>
						</div>

						{/* TODO: When you enable the above mobile phone number, delete this div */}
						<div className="col-md-3 col-sm-6 x-pt-10 x-pb-10">&nbsp;</div>

						<div className="col-md-3 col-sm-6 x-pt-10 x-pb-10">
							<div className="row x-pt-8">
								<div className="col-md-4"></div>
								<div className="col-md-8">
									<a href={getSocialMediaLink("facebook")} className="facebook social-icon" target="_blank" rel="noopener noreferrer">
										<Icon
											path={mdiFacebook}
											size="1.4em"
											className="x-pos-r x-b-0 x-c-fff"
										/>
									</a>
									<a href={getSocialMediaLink("twitter")} className={"twitter social-icon " + switchClasses(["x-r-50"])} target="_blank" rel="noopener noreferrer">
										<Icon
											path={mdiTwitter}
											size="1.4em"
											className="x-pos-r x-b-0 x-c-fff"
										/>
									</a>
									<a href={getSocialMediaLink("youtube")} className={"youtube social-icon " + switchClasses(["x-r-95"])} target="_blank" rel="noopener noreferrer">
										<Icon
											path={mdiYoutube}
											size="1.4em"
											className="x-pos-r x-b-0 x-c-fff"
										/>
									</a>
									<a href={getSocialMediaLink("linkedin")} className={"linkedin social-icon " + switchClasses(["x-r-140"])} target="_blank" rel="noopener noreferrer">
										<Icon
											path={mdiLinkedin}
											size="1.4em"
											className="x-pos-r x-b-0 x-c-fff"
										/>
									</a >
									{/* <a href={getSocialMediaLink("instagram")} className={"instagram social-icon " + switchClasses(["x-r-140"])} target="_blank" rel="noopener noreferrer">
										<Icon
											path={mdiInstagram}
											size="1.4em"
											className="x-pos-r x-b-0 x-c-fff"
										/>
									</a > */}
								</div >
							</div >
						</div >
					</div >
				</div >
			</footer >
		);
	}
}
