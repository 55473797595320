// Our Services
import http from "./http-service";

// Our Configs
import { getApiEndpoint } from "./../configs/api-config";

// -----------------------------------------------

export const create = (data) => {
    return http.post(
        getApiEndpoint("companyCreate").path,
        data
    );
}

// -----------------------------------------------

export const update = (id, data) => {
    return http.put(
        getApiEndpoint("companyUpdate").path + "/" + id,
        data
    );
}
