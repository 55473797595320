import React from "react";
import DatePicker from "react-datepicker";
import _isEmpty from "lodash/isEmpty";


import "react-datepicker/dist/react-datepicker.css";
import "./sass/DateTimePickerBox.scss";

export default function YearPickerBox(props) {
    // Possible "props":
    //------------------
    // props.autoComplete
    // props.className
    // props.dateFormat
    // props.disabled
    // props.id
    // props.isClearable
    // props.label
    // props.name
    // props.onBlur
    // props.onChange
    // props.onFocus
    // props.required
    // props.validationError
    // props.value
    
    // -------------------------

    const dateValue = String(props.value);
    const selectedDate = !_isEmpty(dateValue) ? new Date(dateValue) : null;

    // TEST
    console.log("YearPickerBox - selectedDate: ", selectedDate);
    
    // -------------------------

    return (
        <div className="form-group x-pt-15">
            <label htmlFor={props.name}>
                {props.label}
                {
                    typeof props.required === "undefined" || props.required === true
                        ? <span className="x-c-red"> *</span>
                        : ""
                }
            </label>

            {/* This is a full list of the DatePicker properties: t.ly/gOgP */}
            <DatePicker
                autoComplete={props.autoComplete || "off"} 
                className={props.className || "form-control x-bdrs-2 x-pt-21 x-pb-21 x-dr-ltr x-ta-l " + (props.extraCssClasses || "")}
                dateFormat={props.dateFormat || "yyyy"}
                disabled={props.disabled || false}
                id={props.id || props.name}
                isClearable={props.isClearable || false}
                name={props.name}
                onBlur={props.onBlur || (() => {})}
                onChange={props.onChange || (() => {})}
                onFocus={props.onFocus || (() => {})}
                selected={selectedDate} // "selected" opens the calendar on the selected date
                showYearPicker={true}
            />

            <small className="form-text text-danger">{props.validationError || null}</small>
        </div>
    );
}