import React from "react";
import { Link } from "react-router-dom";

import { preferredLanguage } from "./../../../configs//ui-language-config";
import { switchClasses } from "./../../../languages/CssClasses";
import { AdminLoanAdvancedTexts as texts } from "./../../../languages/AdminLoanAdvancedTexts";
import { GeneralTexts } from "./../../../languages/GeneralTexts";

// Third-Party Modules
// import moment from "moment";
import Joi from "@hapi/joi";
// import _toLower from "lodash/toLower";
import Icon from "@mdi/react"
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";

// Our Modules
import MessageModal, { toggleMessageModal, getErrorMessage } from "./../../shared/MessageModal";
import FormHandler from "./../../shared/FormHandler";
// import TextBox from "./../../shared/TextBox";
import SelectBox from "./../../shared/SelectBox";
import TextareaBox from "./../../shared/TextareaBox";
import CustomButton from "./../../shared/CustomButton";
import PageLoader from "./../../shared/PageLoader";
import ButtonLoader from "./../../shared/ButtonLoader";
import RequiredDataHint from "./../../shared/RequiredDataHint";
import AdminLoanSubMenu from "./AdminLoanSubMenu";
import DateTimePickerBox from "./../../shared/DateTimePickerBox";
import PageContent from "./../../shared/PageContent";

// Our Services
import documentTitleService from "./../../../services/document-title-service";
import logService from "./../../../services/log-service";
import * as loanService from "./../../../services/loan-service";

// Our Configs
import { getRoutePath } from "./../../../configs/route-config";
import { getLoanStatuses, getPercentagesOfExpectedReturn } from "./../../../configs/loan-config";

export default class AdminLoanAdvanced extends FormHandler {
    /**
     *
     */
    initialState = {
        ...this.initialStateElements,
        allowedUserType: "admin",

        formInputs: {
            id                             : "",
            transaction_public_description : "",
            current_status                 : "",
            rejection_reason               : "",
            crowdfunding_campaign_starts_at: "",
            crowdfunding_campaign_ends_at  : "",
            transaction_starts_at          : "",
            transaction_ends_at            : "",
            expected_return                : "",
            borrower_return                : "",
            lender_return                  : "",
        },
        validationErrors : {},
        messageModal     : {},
        showPageLoader   : true,
        showButtonLoader : false,
        loan             : {},
        user             : {},
        borrower         : {},
        company          : {},
        urlParmas        : {},
        rejectionReasionFieldExtraClass: " x-d-n",
    };

    /**
     * Clone a separate copy of the "initialState" and assign it to the "state".
     * Note that the spread operator "..." is important to get a separate copy.
     */
    state = { ...this.initialState };

    /**
     *
     */
    validationRules = {
        id: Joi.number()
            .optional()
            .allow(null, "")
            .integer()
            .positive(),

        transaction_public_description: Joi.string()
            .required()
            .label("Public Description of The Transaction"),

        current_status: Joi.string()
            .required()
            .label("Current Status of The Loan"),

        rejection_reason: Joi.string()
            .optional()
            .allow(null, "")
            .label("Rejection Reason"),

        crowdfunding_campaign_starts_at: Joi.date()
            .optional()
            .allow(null, "")
            // requires the date value to be in valid ISO 8601 format (i.e. 2020-08-18)
            // .iso()
            // More info about custom messages: t.ly/gY6z and t.ly/F5n2
            // And this is a full list of the errors: t.ly/LUUy
            // .messages({
            //     "date.format": `{{#label}} should match this format: 2020-12-31`,
            // })
            .label("Crowdfunding Campaign Starts On"),

        crowdfunding_campaign_ends_at: Joi.date()
            .optional()
            .allow(null, "")
            // requires the date value to be in valid ISO 8601 format (i.e. 2020-08-18)
            // .iso()
            // More info about custom messages: t.ly/gY6z and t.ly/F5n2
            // And this is a full list of the errors: t.ly/LUUy
            // .messages({
            //     "date.format": `{{#label}} should match this format: 2020-12-31`,
            // })
            .label("Crowdfunding Campaign Ends On"),

        transaction_starts_at: Joi.date()
            .optional()
            .allow(null, "")
            // requires the date value to be in valid ISO 8601 format (i.e. 2020-08-18)
            // .iso()
            // More info about custom messages: t.ly/gY6z and t.ly/F5n2
            // And this is a full list of the errors: t.ly/LUUy
            // .messages({
            //     "date.format": `{{#label}} should match this format: 2020-12-31`,
            // })
            .label("Transaction Starts On"),

        transaction_ends_at: Joi.date()
            .optional()
            .allow(null, "")
            // requires the date value to be in valid ISO 8601 format (i.e. 2020-08-18)
            // .iso()
            // More info about custom messages: t.ly/gY6z and t.ly/F5n2
            // And this is a full list of the errors: t.ly/LUUy
            // .messages({
            //     "date.format": `{{#label}} should match this format: 2020-12-31`,
            // })
            .label("Transaction Ends On"),

        expected_return: Joi.string()
            .optional()
            .allow(null, "")
            .label("Expected Return"),

        borrower_return: Joi.string()
            .optional()
            .allow(null, "")
            .label("Borrower Expected Return"),

        lender_return: Joi.string()
            .optional()
            .allow(null, "")
            .label("Investor Expected Return"),
    };

    /**
     *
     */
    componentDidMount() {
        // More info: t.ly/ZVsd
        window.scrollTo(0, 0);

        // Properly set the browser's document title
        documentTitleService.setTitle(GeneralTexts["admin-dashboard"] + " | " + texts["admin-loan-advanced"]);

        this.setState({ urlParmas: this.props.match.params });

        this.setAuthenticatedUser();
        this.getLoan();
    }

    /**
     * Get loan data that the admin tries to update
     */
    getLoan = async () => {
        this.setState({ showPageLoader: true });

        const response = await loanService.getItem(this.props.match.params.loanUuid, "?include=borrower.user|company");

        const loan = response.data.data.loan;

        // TESTING
        console.log("loan: ", loan);

        this.setState({
            formInputs: {
                id                  : loan.id || "",
                current_status      : loan.current_status || "",
                rejection_reason    : loan.rejection_reason || "",
                crowdfunding_campaign_starts_at: loan.crowdfunding_campaign_starts_at || "",
                crowdfunding_campaign_ends_at  : loan.crowdfunding_campaign_ends_at || "",
                transaction_starts_at: loan.transaction_starts_at || "",
                transaction_ends_at  : loan.transaction_ends_at || "",
                expected_return     : loan.expected_return || "",
                borrower_return     : loan.borrower_return || "",
                lender_return       : loan.lender_return || "",
                transaction_public_description : loan.transaction_public_description || "",
            },
            showPageLoader: false,
            loan          : loan,
            borrower      : loan.borrower,
            user          : loan.borrower.user,
            company       : loan.company,
            rejectionReasionFieldExtraClass: loan.current_status === "rejected" ? "" : " x-d-n",
        });
    }

    /**
     *
     */
    doSubmit = async (e) => {
        // Display a loader icon
        this.setState({ showButtonLoader: true });

        try {
            const response = await loanService.updateAdvancedData(this.props.match.params.loanUuid, {...this.state.formInputs, borrower_id: this.state.borrower.id});

            this.setState({
                messageModal: {
                    ...toggleMessageModal("success"),
                    body: response.data.meta.message,
                    footerButtons: [
                        {
                            variant: "primary",
                            title: "Back to Loans",
                            onClick: () => this.props.history.push(getRoutePath("adminLoanList")),
                        },
                        {
                            variant: "primary",
                            title: "Close",
                            onClick: () => this.setState({ messageModal: { ...this.initialState.messageModal } }),
                        }
                    ],
                    // onHide: () => this.props.history.push(getRoutePath("adminLoanList")),
                }
            });

        } catch (error) {
            /**
             * If there is an unexpected error like: network down, server down, db down, bug, ..
             *  - Log the error.
             *  - Display a generic and friendly error message.
             *
             * Note that "error.response" determines if the error is an expected error or not.
             */

            const isExpectedError =
                error.response
                && error.response.status >= 400
                && error.response.status < 500;

            // if (!error.response) {
            if (!isExpectedError) {
                // TEST
                // console.log("Logging the error", error);

                // Log the error
                logService.log(error);
            }

            // Display an appropriate error message
            this.showErrorMessage(error);
        }

        // Hide the loader
        this.setState({ showButtonLoader: false });
    };

    /**
     *
     */
    showErrorMessage = (error, props = this.props) => {
        this.setState({
            messageModal: {
                ...toggleMessageModal("error"),
                body: getErrorMessage(error, props).body || "",
                footerButtons: getErrorMessage(error, props).footerButtons || [],
            }
        });
    }

    /**
     *
     */
    handleCurrentStatusChange = (event) => {
        const input = event.currentTarget;

        // Clone the 'state.formInputs' object
        const formInputs = { ...this.state.formInputs };

        // Overwrite the value of the input field that has been changed
        formInputs[input.name] = input.value;

        // Update the 'state.formInputs' object with the new value
        this.setState({ formInputs: formInputs });

        // If the user choose the "rejected" option, show the "rejection_reason"
        // textarea so the user can write why he rejected the loan request
        if (input.value === "rejected") {
            this.setState({rejectionReasionFieldExtraClass: ""});
        } else {
            this.setState({ rejectionReasionFieldExtraClass: " x-d-n" });
        }
    }

    /**
     *
     */
	render() {
        return (
            <PageContent
                pageContent={this.getPageContent()}
                authenticatedUser={this.getAuthenticatedUser()}
                showPageContent={this.showPageContent()}
                windowLocation={window.location}
            />
        );
	}

    /**
     *
     */
    getPageContent = () => {
        return (
            <div>
                {this.getPageTitle()}

                <div className="x-mt-15">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-3 col-lg-2 x-pr-8 x-pl-8">
                                <AdminLoanSubMenu loan={this.state.loan} />
                            </div>

                            <div className="col-md-9 col-lg-10 x-pr-8 x-pl-8">
                                <div className="shadow-sm x-m-0 x-mt-15 x-mb-70 x-p-20 x-pt-16 x-pb-100 x-mih-500 x-bgc-fff x-bdc-e3e3e3 x-bdrs-4">
                                    <PageLoader show={this.state.showPageLoader} />

                                    <div className={"row" + (this.state.showPageLoader ? " x-d-n" : "")}>
                                        <div className="col">
                                            {this.getFormContent()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.getMessageModal()}
            </div>
        );
    }

    /**
     *
     */
    getPageTitle = () => {
        return (
            <>
                <div className={"x-m-25 x-mb-15 " + switchClasses(["x-mr-15"])}>
                    <div>
                        <Link to={getRoutePath("adminLoanList")} className="x-fz-085rem">
                            <Icon
                                path={preferredLanguage.direction === "rtl" ? mdiChevronRight : mdiChevronLeft}
                                size="1.4em"
                                className="x-pos-r x-b-2"
                            />
                            <span className={switchClasses(["__ff-droidarabickufi"])}>
                                {texts["admin-loan-list"]}
                            </span>
                        </Link>
                    </div>

                    <h5 className={"x-pt-10 head-color "}>{texts["admin-loan-advanced"]}</h5>
                </div>
            </>
        );
    }

    /**
     *
     */
    getFormContent = () => {
        return (
            <>
                <form className={"__ff-droidarabickufi " + switchClasses(["x-fz-085rem"])} onSubmit={(e) => e.preventDefault()}>
                    <div className="row">
                        <div className="col-md-3">
                            <SelectBox
                                name="current_status"
                                value={this.state.formInputs.current_status || ""}
                                label={texts["current-status-of-the-loan"]}
                                onBlur={this.handleBlur}
                                onChange={this.handleCurrentStatusChange}
                                onFocus={this.handleFocus}
                                validationError={this.state.validationErrors.current_status || ""}
                                selectBoxOptions={getLoanStatuses().map(status => {
                                    return (
                                        <option
                                            key={Math.random()}
                                            value={status.value}
                                        >
                                            {status.label}
                                        </option>
                                    )
                                })}
                            />
                        </div>
                    </div>

                    <div className={"row " + this.state.rejectionReasionFieldExtraClass}>
                        <div className="col-md-6">
                            <TextareaBox
                                name="rejection_reason"
                                value={this.state.formInputs.rejection_reason || ""}
                                label={texts["rejection-reason"]}
                                onBlur={this.handleBlur}
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                validationError={this.state.validationErrors.rejection_reason || ""}
                                placeholder={texts["rejection-reason-placeholder"]}
                                required={false}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            <DateTimePickerBox
                                endYear={(new Date()).getFullYear() + 1} // The next year (current year + 1). Works with renderCustomHeader
                                isClearable={true}
                                label={texts["crowdfunding-campaign-starts-on"]}
                                name="crowdfunding_campaign_starts_at"
                                onBlur={this.handleBlur}
                                onChange={(date) => {this.handleDateTimePickerChange(date, "crowdfunding_campaign_starts_at")}}
                                onFocus={this.handleFocus}
                                required={false}
                                showCustomHeader={true}
                                showTimeInput={true}
                                startYear={(new Date()).getFullYear()} // The current year. Works with renderCustomHeader
                                validationError={this.state.validationErrors.crowdfunding_campaign_starts_at || ""}
                                value={this.state.formInputs.crowdfunding_campaign_starts_at || ""}
                            />
                        </div>

                        <div className="col-md-3">
                            <DateTimePickerBox
                                endYear={(new Date()).getFullYear() + 1} // The next year (current year + 1). Works with renderCustomHeader
                                isClearable={true}
                                label={texts["crowdfunding-campaign-ends-on"]}
                                name="crowdfunding_campaign_ends_at"
                                onBlur={this.handleBlur}
                                onChange={(date) => {this.handleDateTimePickerChange(date, "crowdfunding_campaign_ends_at")}}
                                onFocus={this.handleFocus}
                                required={false}
                                showCustomHeader={true}
                                showTimeInput={true}
                                startYear={(new Date()).getFullYear()} // The current year. Works with renderCustomHeader
                                validationError={this.state.validationErrors.crowdfunding_campaign_ends_at || ""}
                                value={this.state.formInputs.crowdfunding_campaign_ends_at || ""}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            <DateTimePickerBox
                                endYear={(new Date()).getFullYear() + 1} // The next year (current year + 1). Works with renderCustomHeader
                                isClearable={true}
                                label={texts["transaction-starts-on"]}
                                name="transaction_starts_at"
                                onBlur={this.handleBlur}
                                onChange={(date) => { this.handleDateTimePickerChange(date, "transaction_starts_at") }}
                                onFocus={this.handleFocus}
                                required={false}
                                showCustomHeader={true}
                                showTimeInput={true}
                                startYear={(new Date()).getFullYear()} // The current year. Works with renderCustomHeader
                                validationError={this.state.validationErrors.transaction_starts_at || ""}
                                value={this.state.formInputs.transaction_starts_at || ""}
                            />
                        </div>

                        <div className="col-md-3">
                            <DateTimePickerBox
                                endYear={(new Date()).getFullYear() + 1} // The next year (current year + 1). Works with renderCustomHeader
                                isClearable={true}
                                label={texts["transaction-ends-on"]}
                                name="transaction_ends_at"
                                onBlur={this.handleBlur}
                                onChange={(date) => { this.handleDateTimePickerChange(date, "transaction_ends_at") }}
                                onFocus={this.handleFocus}
                                required={false}
                                showCustomHeader={true}
                                showTimeInput={true}
                                startYear={(new Date()).getFullYear()} // The current year. Works with renderCustomHeader
                                validationError={this.state.validationErrors.transaction_ends_at || ""}
                                value={this.state.formInputs.transaction_ends_at || ""}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <TextareaBox
                                name="transaction_public_description"
                                value={this.state.formInputs.transaction_public_description || ""}
                                label={texts["public-description-of-the-transaction"]}
                                onBlur={this.handleBlur}
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                validationError={this.state.validationErrors.transaction_public_description || ""}
                                placeholder={texts["public-description-of-the-transaction-hint"]}
                                required={true}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            <SelectBox
                                name="expected_return"
                                value={this.state.formInputs.expected_return || ""}
                                label={texts["expected-return"]}
                                required={false}
                                onBlur={this.handleBlur}
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                validationError={this.state.validationErrors.expected_return || ""}
                                selectBoxOptions={getPercentagesOfExpectedReturn().map(percentage => {
                                    return (
                                        <option
                                            key={Math.random()}
                                            value={percentage.value}
                                        >
                                            {percentage.label}
                                        </option>
                                    )
                                })}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            <SelectBox
                                name="borrower_return"
                                value={this.state.formInputs.borrower_return || ""}
                                label={texts["borrower-expected-return"]}
                                required={false}
                                onBlur={this.handleBlur}
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                validationError={this.state.validationErrors.borrower_return || ""}
                                selectBoxOptions={getPercentagesOfExpectedReturn().map(percentage => {
                                    return (
                                        <option
                                            key={Math.random()}
                                            value={percentage.value}
                                        >
                                            {percentage.label}
                                        </option>
                                    )
                                })}
                            />
                        </div>
                        <div className="col-md-3">
                            <SelectBox
                                name="lender_return"
                                value={this.state.formInputs.lender_return || ""}
                                label={texts["investor-expected-return"]}
                                required={false}
                                onBlur={this.handleBlur}
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                validationError={this.state.validationErrors.lender_return || ""}
                                selectBoxOptions={getPercentagesOfExpectedReturn().map(percentage => {
                                    return (
                                        <option
                                            key={Math.random()}
                                            value={percentage.value}
                                        >
                                            {percentage.label}
                                        </option>
                                    )
                                })}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            <div className="x-mt-20">
                                <CustomButton
                                    type="button"
                                    label={this.state.showButtonLoader ? <ButtonLoader /> : GeneralTexts["save"]}
                                    disabled={this.validateFormInputs() ? true : false}
                                    onClick={this.handleSubmit}
                                />
                            </div>
                        </div>
                    </div>
                </form>

                <RequiredDataHint className={this.state.showPageLoader ? "x-d-n" : ""} />
            </>
        );
    }

    /**
     *
     */
    getMessageModal = () => {
        return (
            <MessageModal
                show={this.state.messageModal.show || false}
                title={this.state.messageModal.title || null}
                body={this.state.messageModal.body || null}
                alertClassName={this.state.messageModal.alertClassName || null}
                size={this.state.messageModal.size || null}
                centered={this.state.messageModal.centered || false}
                footerButtons={this.state.messageModal.footerButtons || []}
                onHide={this.state.messageModal.onHide || (() => this.setState({ messageModal: { ...this.initialState.messageModal } }))}
            />
        );
    }
}
