import { preferredLanguage } from "./../configs/ui-language-config";

const texts = {
    ar: {
        "home"       : "الرئيسية",
        "borrow"     : "اقترض",
        "invest"     : "استثمر",
        "contact-us" : "اتصل بنا",
        "signup"     : "تسجيل",
        "login"      : "دخول",
        "test"       : "(test)",
        "development": "(dev)",
    },

    en: {
        "home"       : "Home",
        "borrow"     : "Borrow",
        "invest"     : "Invest",
        "contact-us" : "Contact Us",
        "signup"     : "Signup",
        "login"      : "Login",
        "test"       : "(test)",
        "development": "(dev)",
    }
}

export const HeaderNavbarTexts = texts[preferredLanguage.alpha2code];
