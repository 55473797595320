import { preferredLanguage } from "./../configs/ui-language-config";
import { GeneralTexts } from "./GeneralTexts";
import { RouteConfigTexts } from "./RouteConfigTexts";

const texts = {
    ar: {
        "admin-overview-general"     : RouteConfigTexts["admin-overview-general"],
        "admin-overview-users"       : RouteConfigTexts["admin-overview-users"],
        "all-time"                   : GeneralTexts["all-time"],
        "today"                      : GeneralTexts["today"],
        "yesterday"                  : GeneralTexts["yesterday"],
        "last-7-days"                : GeneralTexts["last-7-days"],
        "last-30-days"               : GeneralTexts["last-30-days"],
        "borrowers"                  : "المقترضين",
        "all-borrowers"              : "المقترضين",
        "users-of-type-borrower"     : "المستخدمين من نوع المقترض",
        "applied-for-loans"          : "طلبوا قروض",
        "applied-for-1-or-more-loans": "طلبوا قرض أو أكثر",
        "got-loans"                  : "حصلوا على قروض",
        "already-got-loans"          : "حصلوا على قرض أو اكثر",
        "inactive"                   : "لم يطلبوا قروض",
        "did-not-apply-for-loans"    : "لم يطلبوا قروض من قبل",
    },

    en: {
        "admin-overview-general"     : RouteConfigTexts["admin-overview-general"],
        "admin-overview-users"       : RouteConfigTexts["admin-overview-users"],
        "all-time"                   : GeneralTexts["all-time"],
        "today"                      : GeneralTexts["today"],
        "yesterday"                  : GeneralTexts["yesterday"],
        "last-7-days"                : GeneralTexts["last-7-days"],
        "last-30-days"               : GeneralTexts["last-30-days"],
        "borrowers"                  : "Borrowers",
        "all-borrowers"              : "All Borrowers",
        "users-of-type-borrower"     : "Users of Type Borrower",
        "applied-for-loans"          : "Applied For Loans",
        "applied-for-1-or-more-loans": "Applied For 1 or More Loans",
        "got-loans"                  : "Got Loans",
        "already-got-loans"          : "Already Got Loans",
        "inactive"                   : "Inactive",
        "did-not-apply-for-loans"    : "Didn't Apply For Loan(s)",
    }
}

export const AdminOverviewBorrowersTexts = texts[preferredLanguage.alpha2code];
