import React from "react";

import { switchClasses } from "./../../../languages/CssClasses";
import { AdminLoanListTexts as texts } from "./../../../languages/AdminLoanListTexts";
import { GeneralTexts } from "./../../../languages/GeneralTexts";

// Third-Party Modules
import moment from "moment";
import numeral from "numeral"
import _isEmpty from "lodash/isEmpty";
import _startCase from "lodash/startCase";
import _toLower from "lodash/toLower";

// Our Services
import documentTitleService from "./../../../services/document-title-service";
import * as loanService from "./../../../services/loan-service";

// Our Modules
import PageLoader from "./../../shared/PageLoader";
import Pagination2 from "./../../shared/Pagination2";
import PageContent from "./../../shared/PageContent";
import PageHandler from "./../../shared/PageHandler";

// Our Configs
import { getRoutePath } from "./../../../configs/route-config";
import { getPagination } from "./../../../configs/general-config";

// Our Helpers
import * as paginateHelper from "./../../../helpers/paginate-helper";

export default class AdminLoanList extends PageHandler {

    initialState = {
        ...this.initialStateElements,
        allowedUserType: "admin",

        loans         : [],
        showPageLoader: true,
        pagination    : getPagination(),
    };

    // -----------------------------------------------

    // Clone a separate copy of the "initialState" and assign it to the "state".
    // Note that the spread operator "..." is important to get a separate copy.
    state = { ...this.initialState };

    // -----------------------------------------------

    componentDidMount() {
        // More info: t.ly/ZVsd
        window.scrollTo(0, 0);

        // ----------------------

        // Properly set the browser's document title
        // documentTitleService.setTitle("Admin Dashboard | Loans");
        documentTitleService.setTitle(GeneralTexts["admin-dashboard"] + " | " + texts["admin-loan-list"]);

        // ----------------------

        this.setAuthenticatedUser();
        this.getTheLoans();
    }

    // -----------------------------------------------

    getTheLoans = async () => {
        const response = await loanService.getList("?include=borrower,company&page=" + this.state.pagination.currentPage);
        const loans = response.data.data.loans;

        // TEST
        // console.log("loanListResponse: ", response);

        // Sort ascending according to the "id"
        // loans.sort((a, b) => a.id - b.id);

        // Sort descending according to the "id"
        loans.sort((a, b) => b.id - a.id);

        this.setState({
            loans: loans || [],
            showPageLoader: false,
        });
    }

    // -----------------------------------------------

    getLoansOfCurrentPage = () => {
        return paginateHelper.getCurrentPageItems(
            this.state.loans,
            this.state.pagination.currentPage,
            this.state.pagination.numberOfItemsPerPage
        );
    }

    // -----------------------------------------------

    handlePaginationChange = (pageNumber) => {
        this.setState({ pagination: { ...this.state.pagination, currentPage: pageNumber } });
    }

    // -----------------------------------------------

    render() {
        const showHint = (
            <div className={"x-ta-c x-c-crimson x-pt-50 x-lh-34 " + switchClasses(["__ff-droidarabickufi", "x-fz-085rem"])}>
                {texts["no-loans-message"]}
            </div>
        );

        // ----------------------

        const showLoans = (
            <React.Fragment>
                <div className="table-responsive x-pt-30">
                    <table className="table table-lg table-hover x-fz-08rem __ff-droidarabickufi">
                        <tbody>
                            {this.getLoansOfCurrentPage().map(loan => {
                                return (
                                    <tr key={loan.id} className="__ff-rubik">
                                        <td>
                                            <p className={"x-m-0 x-fz-07rem x-c-bbb __ff-droidarabickufi"}>{texts["date"]}</p>
                                            {moment(loan.created_at).format("DD-MM-YYYY")}
                                        </td>
                                        <td>
                                            <p className={"x-m-0 x-fz-07rem x-c-bbb __ff-droidarabickufi"}>{texts["company"]}</p>
                                            {_startCase(_toLower(!_isEmpty(loan.company) ? loan.company.official_name : ""))}
                                        </td>
                                        <td>
                                            <p className={"x-m-0 x-fz-07rem x-c-bbb __ff-droidarabickufi"}>{texts["required"]}</p>
                                            <span className="x-c-bbb">{loan.currency}</span> {numeral(loan.required_amount).format("0,0") || 0}
                                        </td>
                                        <td>
                                            <p className={"x-m-0 x-fz-07rem x-c-bbb __ff-droidarabickufi"}>{texts["pledged"]}</p>
                                            <span className="x-c-bbb">{loan.currency}</span> {numeral(loan.pledged_amount).format("0,0") || 0}
                                        </td>
                                        <td>
                                            <p className={"x-m-0 x-fz-07rem x-c-bbb __ff-droidarabickufi"}>{texts["duration"]}</p>
                                            {loan.loan_duration_in_months} <span className="x-c-bbb x-fz-07rem">{texts["month"]}</span>
                                        </td>
                                        <td>
                                            <p className={"x-m-0 x-fz-07rem x-c-bbb __ff-droidarabickufi"}>{texts["crowdfunding-campaign"]}</p>
                                            <span className={"x-m-0 x-fz-07rem x-c-bbb"}>{GeneralTexts["from"]}</span>&nbsp;
                                            <p className="x-m-0 x-d-ib">
                                                {loan.crowdfunding_campaign_starts_at ? moment(loan.crowdfunding_campaign_starts_at).format("DD-MM-YYYY") : ""}&nbsp;&nbsp;&nbsp;&nbsp;
                                            </p>
                                            {/* {loan.crowdfunding_campaign_starts_at ? <span className="x-c-bbb">&nbsp;/&nbsp;</span> : ""} */}
                                            <span className={"x-m-0 x-fz-07rem x-c-bbb"}>{GeneralTexts["to"]}</span>&nbsp;
                                            <p className="x-m-0 x-d-ib">
                                                {loan.crowdfunding_campaign_ends_at ? moment(loan.crowdfunding_campaign_ends_at).format("DD-MM-YYYY") : ""}
                                            </p>
                                        </td>
                                        <td>
                                            <p className={"x-m-0 x-fz-07rem x-c-bbb __ff-droidarabickufi"}>{texts["loan-status"]}</p>
                                            {_startCase(_toLower(loan.current_status))}
                                        </td>
                                        <td className={switchClasses(["x-ta-l"])}>
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-sm shadow-sm x-fz-075rem"
                                                onClick={() => this.props.history.push(getRoutePath("adminLoanBasic") + "/" + loan.uuid)}
                                            >
                                                {texts["details"]}
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>

                <div className="x-pt-30">
                    <Pagination2
                        numberOfTotalItems={this.state.loans.length}
                        numberOfItemsPerPage={this.state.pagination.numberOfItemsPerPage}
                        currentPage={this.state.pagination.currentPage}
                        onPaginationChange={this.handlePaginationChange}
                    />
                </div>
            </React.Fragment>
        );

        // ----------------------

        const pageContent = (
            <React.Fragment>
                <div className="x-m-25 x-mt-50">
                    <h5 className={"head-color " + switchClasses(["__ff-droidarabickufi"])}>{texts["admin-loan-list"]}</h5>
                </div>

                <div className="_border _border-light shadow-sm x-m-25 x-mt-0 x-mb-70 x-p-10 x-pb-100 x-mih-500 x-bgc-fff x-bdc-e3e3e3 x-bdrs-4">
                    <div className="container-fluid">
                        <PageLoader show={this.state.showPageLoader} />

                        <div className={"row x-pt-20" + (this.state.showPageLoader ? " x-d-n" : "")}>
                            <div className="col">
                                {
                                    this.state.loans.length === 0
                                        ? showHint
                                        : showLoans
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );

        return (
            <PageContent
                pageContent={pageContent}
                authenticatedUser={this.getAuthenticatedUser()}
                showPageContent={this.showPageContent()}
                windowLocation={window.location}
            />
        );
    }
}
