// Our Services
import http from "./http-service";

// Our Configs
import { getApiEndpoint } from "./../configs/api-config";

// -----------------------------------------------

export const create = (data) => {
    return http.post(
        getApiEndpoint("documentCreate").path,
        data
    );
}

export const getItem = (uuid, urlQueryString = "") => {
    return http.get(
        getApiEndpoint("documentShow").path + "/" + uuid + urlQueryString
    );
}

