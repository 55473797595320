// React modules
import React from "react";
import ReactDOM from "react-dom";

import * as Sentry from "@sentry/react";

import { preferredLanguage, setDefaultUILanguage } from "./configs/ui-language-config";

import App from "./App";

import logService from "./services/log-service";
import * as serviceWorker from "./serviceWorker";

import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "x-framework/dist/css/x-framework.min.css";
import "./resources/styles/sass/shared.scss";
import "./resources/styles/sass/custom.scss";

// -----------------------------------------------

setDefaultUILanguage();

// -----------------------------------------------

/**
 * We use sentry.io as an error logging and tracking service.
 * for more info, check services/log-service.js file.
 *
 * Note that initiating the logService here (in index.jsx file) will automatically
 * cause catching the unexpected errors and logging them to our account on sentry.io.
 * That's beside the errors that we are logging manually through the code.
 */
logService.init();

// -----------------------------------------------

ReactDOM.render(
    <React.StrictMode>
        <Sentry.ErrorBoundary>
            <div className={preferredLanguage.direction === "rtl" ? "x-dr-rtl x-ta-r" : "x-dr-ltr x-ta-l"}>
                <App />
            </div>
        </Sentry.ErrorBoundary>
    </React.StrictMode>,
    document.getElementById("root")
);

// -----------------------------------------------

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// -----------------------------------------------

// The following code helps switching between RTL languages like
// Arabic and LTR languages like English. More info: t.ly/ZSvA
const htmlTag = document.getElementsByTagName("html")[0];
htmlTag.setAttribute("lang", preferredLanguage.alpha2code)
htmlTag.setAttribute("dir", preferredLanguage.direction);

