// More info about custom environment variable: t.ly/eDkJ
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

// -----------------------------------------------

const apiEndpoints = {
    preSignup: {
        path: apiBaseUrl + "/auth/pre-signup",
    },

    signup: {
        path: apiBaseUrl + "/auth/signup",
    },

    login: {
        path: apiBaseUrl + "/auth/login",
    },

    logout: {
        path: apiBaseUrl + "/auth/logout",
    },

    passwordForgot: {
        path: apiBaseUrl + "/auth/password/forgot",
    },

    passwordReset: {
        path: apiBaseUrl + "/auth/password/reset",
    },

    me: {
        path: apiBaseUrl + "/auth/me",
    },

    myProfileUpdate: {
        path: apiBaseUrl + "/my-profile",
    },

    passwordChange: {
        path: apiBaseUrl + "/password/change",
    },

    userList: {
        path: apiBaseUrl + "/admin/users",
    },

    borrowerUpdate: {
        path: apiBaseUrl + "/borrowers",
    },

    documentCreate: {
        path: apiBaseUrl + "/documents",
    },

    documentShow: {
        path: apiBaseUrl + "/documents",
    },

    companyCreate: {
        path: apiBaseUrl + "/companies",
    },

    companyUpdate: {
        path: apiBaseUrl + "/companies",
    },

    loanList: {
        path: apiBaseUrl + "/loans",
    },

    approvedLoanList: {
        path: apiBaseUrl + "/loans/approved",
    },

    loanDetails: {
        path: apiBaseUrl + "/loans",
    },

    loanPublicDetails: {
        path: apiBaseUrl + "/public/loans",
    },

    loanCreate: {
        path: apiBaseUrl + "/loans",
    },

    loanCoverImageUpdate: {
        path: apiBaseUrl + "/loans",
    },

    loanUpdateBasicData: {
        path: apiBaseUrl + "/loans/basic",
    },

    loanUpdateAdvabcedData: {
        path: apiBaseUrl + "/loans/advanced",
    },

    loanIndicatorUpdate: {
        path: apiBaseUrl + "/loan-indicators",
    },

    lenderDetails: {
        path: apiBaseUrl + "/lenders",
    },

    lenderUpdate: {
        path: apiBaseUrl + "/lenders",
    },

    lenderLoanCreate: {
        path: apiBaseUrl + "/lenders/loan-create",
    },

    linkCreate: {
        path: apiBaseUrl + "/links",
    },

    linkDelete: {
        path: apiBaseUrl + "/links",
    },
};

// -----------------------------------------------

export const getApiEndpoint = (apiEndpointName) => {
    return apiEndpoints[apiEndpointName] || {};
}

// -----------------------------------------------

export const getApiEndpointPath = (apiEndpointName) => {
    // If the given "apiEndpointName" is one of the "apiEndpoints" elements..
    if (Object.keys(apiEndpoints).indexOf(apiEndpointName) !== -1) {
        return apiEndpoints[apiEndpointName].path || "";
    }

    return "";
}

// -----------------------------------------------
