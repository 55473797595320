import { preferredLanguage } from "./../configs/ui-language-config";

const texts = {
    ar: {
        "create-new-account"  : "إنشاء حساب جديد",
        "first-name"          : "الاسم الأول",
        "last-name"           : "الاسم الأخير",
        "country"             : "الدولة",
        "mobile-number"       : "رقم الموبايل",
        "email-address"       : "البريد الإلكتروني",
        "password"            : "كلمة السر",
        "user-type"           : "نوع الحساب",
        "gender"              : "الجنس",
        "read-and-agree-hint" : "قرأت وأوافق على",
        "terms-and-conditions": "الشروط والقواعد المنظمة",
        "already-have-account": "إذا كان لديك حساب بالفعل،",
        "login-from-here"     : "ادخل من هنا",
    },

    en: {
        "create-new-account"  : "Create New Account",
        "first-name"          : "First Name",
        "last-name"           : "Last Name",
        "country"             : "Country",
        "mobile-number"       : "Mobile Number",
        "email-address"       : "Email Address",
        "password"            : "Password",
        "user-type"           : "Account Type",
        "gender"              : "Gender",
        "read-and-agree-hint" : "I have read and agree to the",
        "terms-and-conditions": "Terms & Conditions",
        "already-have-account": "If you already have an account,",
        "login-from-here"     : "Login from here",
    }}

export const SignupTexts = texts[preferredLanguage.alpha2code];
