import React from "react";
import { Link } from "react-router-dom";

import { preferredLanguage } from "./../configs/ui-language-config";
// import { GeneralTexts } from "./GeneralTexts";
import { RouteConfigTexts } from "./RouteConfigTexts";

import { getRoutePath } from "./../configs/route-config";

const texts = {
    ar: {
        "lender-loan-list"     : RouteConfigTexts["lender-loan-list"],
        "required"             : "المطلوب",
        "pledged"              : "المُتعهد به",
        "my-commitment"        : "قيمة استثماري",
        "duration"             : "المدة",
        "crowdfunding-campaign": "حملة التمويل",
        "status"               : "الحالة",
        "month"                : "شهر",
        "details"              : "التفاصيل",

        "no-investments-message": (
            <span>
                ليس لديك استثمارات حتى الآن<br />
                لتعرف فرص الاستثمار المتاحة وتشارك فيها، اذهب لصفحة&nbsp;
                <strong><Link to={getRoutePath("loans")} className="x-td-n">{RouteConfigTexts["loans"]}</Link></strong>
            </span>
        ),
    },

    en: {
        "lender-loan-list"     : RouteConfigTexts["lender-loan-list"],
        "required"             : "Required",
        "pledged"              : "Pledged",
        "my-commitment"        : "My Commitment",
        "duration"             : "Duration",
        "crowdfunding-campaign": "Crowdfunding Campaign",
        "status"               : "Status",
        "month"                : "months",
        "details"              : "Details",

        "no-investments-message": (
            <span>
                You didn't invest in any loan yet<br />
                To check the open investment opportunities, go to page&nbsp;
                <strong><Link to={getRoutePath("loans")} className="x-td-n">{RouteConfigTexts["loans"]}</Link></strong>
            </span>
        ),
    }
}

export const LenderLoanListTexts = texts[preferredLanguage.alpha2code];
