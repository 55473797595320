import React from "react";

import { switchClasses } from "./../languages/CssClasses";
// import { InvestPrivacyPolicyTexts as texts } from "./../languages/InvestPrivacyPolicyTexts";
import { RouteConfigTexts } from "./../languages/RouteConfigTexts";

// Our Modules
import InvestSubMenu from "./shared/InvestSubMenu";
import PageLoader from "./shared/PageLoader";

// Our Services
import documentTitleService from "./../services/document-title-service";
import PageHandler from "./shared/PageHandler";
import PageContent from "./shared/PageContent";

export default class InvestPrivacyPolicy extends PageHandler {
	initialState = {
		...this.initialStateElements,
		isThisPagePublic: true,
		showPageLoader: false,
	};

	// -----------------------------------------------

	// Clone a separate copy of the "initialState" and assign it to the "state".
	// Note that the spread operator "..." is important to get a separate copy.
	state = { ...this.initialState };

	// -----------------------------------------------

	componentDidMount() {
		// More info: t.ly/ZVsd
		window.scrollTo(0, 0);

		// ----------------------

		// Properly set the browser's document title
		documentTitleService.setTitle(RouteConfigTexts["invest-privacy-policy"]);

		// ----------------------

		this.setAuthenticatedUser();
	}

	// -----------------------------------------------

	render() {
		const pageContent = (
			<React.Fragment>
				<div>
					<div className={"x-m-25 x-mb-15 " + switchClasses(["x-mr-15"])}>
						<h5 className={"x-pt-20 head-color "}>
							{RouteConfigTexts["invest-privacy-policy"]}
						</h5>
					</div>

					<div className="x-mt-15">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-3 col-lg-2 x-pr-8 x-pl-8">
									<InvestSubMenu />
								</div>

								<div className="col-md-9 col-lg-10 x-pr-8 x-pl-8">
									<div className="shadow-sm x-m-0 x-mt-15 x-mb-70 x-p-20 x-pt-16 x-pb-100 x-mih-500 x-bgc-fff x-bdc-e3e3e3 x-bdrs-4">
										<PageLoader show={this.state.showPageLoader} />

										<div
											className={
												"row" + (this.state.showPageLoader ? " x-d-n" : "")
											}
										>
											<div className="col">
												{/* <p className="x-pt-50 x-dr-ltr x-ta-c">Coming soon..</p> */}

												<h1 className="x-pt-30 x-ta-c x-fz-2rem">
													{RouteConfigTexts["invest-privacy-policy"]}
												</h1>

												<div className="x-p-50 x-lh-28">
													<div className="">
														{/* <h3 className="border-bottom x-pb-10">...</h3> */}

														<div className="">
															<p className="x-m-0 x-pt-25">
																www.amwal.com.eg (Site) and the AMWAL online
																platform (AMWAL) are owned and operated by AMWAL
																Financial Advisors LLC Limited (we, us, or our).
															</p>
															<p className="x-m-0 x-pt-25">
																This privacy policy (Policy), together with the
																terms of use (Terms), set out the basis on which
																we collect and use any personal data we collect
																from customers, visitors and users of the Site
																and the Platform (you or your), or that you
																provide to us, will be processed by us.
															</p>
															<p className="x-m-0 x-pt-25">
																Please read this Policy carefully. By accessing
																and continuing to use this Site, you confirm
																that you have read, understood, and agree to be
																bound by this Policy in its entirety and that
																you give us your consent to collect and process
																your personal information as set out in this
																Policy. If you do not accept the terms of this
																Policy, then you must stop using or accessing
																this Site. We respect your right to privacy and
																are committed to responsible
																information-handling practices.{" "}
															</p>
														</div>
													</div>

													<div className="x-pt-50">
														<h3 className="border-bottom x-pb-10">
															1. Collecting your personal information
														</h3>

														<div className="">
															<p className="x-m-0 x-pt-25">
																We will collect personal information about you
																via some different means including:
															</p>
															<ol>
																<li className="x-pt-15">
																	When you submit it to us (including via the
																	Site).
																</li>
																<li className="x-pt-15">
																	When you visit and/or use the Site.
																</li>
																<li className="x-pt-15">
																	When you carry out transactions via the Site
																	or in connection with the services provided to
																	you or requested by you via the Site.
																</li>
																<li className="x-pt-15">
																	When you contact us (whether in writing, by
																	email, phone or any other means).
																</li>
																<li className="x-pt-15">
																	From your digital devices.
																</li>
																<li className="x-pt-15">
																	When you interact with us, including
																	information you may voluntarily share with us.
																</li>
																<li className="x-pt-15">
																	Credit reference agencies and other third
																	party agencies (who may check the information
																	against other databases – public and private –
																	to which they have access), fraud prevention
																	agencies and/or other publicly available
																	sources. For example, we may collect
																	information about you from credit reference
																	agencies who we appoint to carry out
																	identification services.
																</li>
															</ol>
														</div>
													</div>

													<div className="x-pt-50">
														<h3 className="border-bottom x-pb-10">
															2. Personal information we collect
														</h3>

														<div className="">
															<ol>
																<li className="x-pt-15">
																	As well as your personal details (such as your
																	name, job, passport details, contact
																	information, and address details), we will
																	collect details of the transactions you
																	complete on AMWAL and your bank account
																	details used to make transfers on AMWAL. In
																	some instances, we may also ask you for your
																	income details and other such personal
																	information.
																</li>
																<li className="x-pt-15">
																	Technical information relating to your use of
																	the Site, including (a) IP address used to
																	connect your computer to the internet, your
																	login information, browser type and version,
																	time zone setting, browser plug-in types and
																	versions, operating system and platform; and
																	(b) information about your use of the Site,
																	including the foll Uniform Resource Locators
																	(URL), clickstream to, through and from the
																	Site (including date and time), services you
																	viewed or searched for, page response times,
																	download errors, length of visits to certain
																	pages, page interaction information (such as
																	scrolling, clicks, and mouse-overs), methods
																	used to browse away from the page.
																</li>
																<li className="x-pt-15">
																	Where you wish to become a business on AMWAL
																	we may seek additional information about you
																	from credit reference agencies who may provide
																	further information on your credit behavior
																	with other organizations. Such information may
																	relate to you and your business partners’
																	personal credit behavior and personal credit
																	accounts (if you are proprietors of a small
																	business and are directors of that business),
																	your business accounts, the identities of the
																	ultimate beneficial owners of the business and
																	both you and your personal financial partner’s
																	accounts (if they are a director). We may also
																	carry out searches through fraud prevention
																	agencies to obtain information on your
																	business, you, your personal financial partner
																	(if they are a director), your business
																	partners, beneficial owners, and your
																	addresses. By submitting an application to
																	become a business or providing us with
																	information about another person, you confirm
																	that you have informed that person of the
																	purposes (as set out above) for which their
																	information will be used and they have
																	consented to the use of their information for
																	these purposes.
																</li>
																<li className="x-pt-15">
																	We may also collect anonymized details about
																	visitors to the Site for the purpose of
																	collating aggregate statistics for reporting
																	purposes and to calculate referral fees.
																	However, no single individual will be
																	identifiable from the anonymized details we
																	collect or disclose by us for these purposes.
																</li>
																<li className="x-pt-15">
																	By providing us with any personal information,
																	you are consenting to the use of your personal
																	information as contemplated in this Policy. If
																	you do not want us to use your personal
																	information for any of these purposes, please
																	do not provide us with your personal
																	information.
																</li>
															</ol>
														</div>
													</div>

													<div className="x-pt-50">
														<h3 className="border-bottom x-pb-10">
															3. Using your information
														</h3>

														<div className="">
															<ol className="x-m-0 x-pt-25">
																<li className="x-pt-15">
																	<p className="x-fw-b">
																		Where you become a member of AMWAL we may
																		use the personal information we obtain about
																		you for the following purposes:
																	</p>
																	<ol>
																		<li className="x-pt-15">
																			To verify your identity and any other
																			information you have provided us.
																		</li>
																		<li className="x-pt-15">
																			In order to prevent and detect crime,
																			including fraud, and money laundering,
																			including by using scoring methods.
																		</li>
																		<li className="x-pt-15">
																			To administer and operate your AMWAL
																			investor or business account.
																		</li>
																		<li className="x-pt-15">
																			To allow for binding legal obligations to
																			be created between investors and
																			businesses pursuant to an agreement
																			governing the terms on which an investor
																			makes financing to a business (Finance
																			Contract or Murabaha Contract).
																		</li>
																		<li className="x-pt-15">
																			To update the records we hold about you
																			from time to time.
																		</li>
																		<li className="x-pt-15">
																			To carry out statistical analysis and
																			market research.
																		</li>
																		<li className="x-pt-15">
																			To comply with our regulatory obligations
																			including “know your customer” obligations
																			and to carry out regulatory checks.
																		</li>
																		<li className="x-pt-15">
																			To manage and improve the Site and to
																			develop and improve AMWAL and our services
																			and products.
																		</li>
																		<li className="x-pt-15">
																			Analyze, assess, and improve our services
																			to customers, and for training and
																			monitoring purposes.
																		</li>
																		<li className="x-pt-15">
																			To establish, exercise, or defend legal
																			claims.
																		</li>
																		<li className="x-pt-15">
																			To personalize our services to you
																			including by identifying products and
																			services in which you may be interested
																			in.
																		</li>
																		<li className="x-pt-15">
																			We will contact you (including by SMS and
																			e-mail) with products and services that
																			may interest you. However, if at any time
																			you wish to be removed from our mailing
																			lists, you can send us an email to
																			info@amwal.com.eg, and we will use
																			reasonable efforts to, within a reasonable
																			time frame, cease to use your personal
																			information for these purposes and update
																			our mailing lists. There may be some
																			messages we must send you by law or
																			regulation, even if you ask us not to.
																		</li>
																	</ol>
																</li>

																<li className="x-pt-25">
																	<p className="x-fw-b">
																		Where you become a business on AMWAL, we
																		will also use the personal information you
																		provide or which we obtain about you for the
																		following purposes:
																	</p>
																	<ol>
																		<li className="x-pt-15">
																			To assess your application for credit,
																			including by using scoring methods.
																		</li>
																		<li className="x-pt-15">
																			To check details on applications for
																			credit and credit related and other
																			facilities.
																		</li>
																		<li className="x-pt-15">
																			To display beneficial owner information on
																			our platform as required by regulation.
																		</li>
																		<li className="x-pt-15">
																			To display certain financial ratios on our
																			platform as required by regulation.
																		</li>
																		<li className="x-pt-15">
																			If you do not repay money you have
																			borrowed, to trace your whereabouts and
																			recover debts or enforce agreements you
																			have entered into via AMWAL.
																		</li>
																	</ol>
																</li>

																<li className="x-pt-25">
																	<p className="x-fw-b">
																		AMWAL has the right to publish loan and
																		investment information, with investor and
																		business identities anonymized, on a public
																		blockchain.
																	</p>
																</li>
															</ol>
														</div>
													</div>

													<div className="x-pt-50">
														<h3 className="border-bottom x-pb-10">
															4. Disclosure and transfer of information
														</h3>

														<div className="">
															<ol className="x-m-0 x-pt-25">
																<li className="x-pt-15">
																	<p className="x-fw-b">
																		We rely on doly licensed third parties'
																		support to provide aspects of our services.
																		In the course of providing our services, we
																		may need to disclose your personal
																		information to those third parties, as well
																		as other companies within the AMWAL
																		corporate group as follows:
																	</p>
																	<ol>
																		<li className="x-pt-15">
																			To other members of the AMWAL Group for
																			the purposes of providing and
																			administering the AMWAL platform and
																			services.
																		</li>
																		<li className="x-pt-15">
																			To specialist third-party service
																			providers who support the provision of our
																			services.
																		</li>
																		<li className="x-pt-15">
																			To carefully selected third parties who
																			wish to contact you in relation to
																			products and services that may be of
																			interest to you where you have provided
																			your express consent.
																		</li>
																		<li className="x-pt-15">
																			To companies which assist us in supplying
																			our services, including IT support and
																			professional services, and to companies
																			who perform certain functions on our
																			behalf, including to verify the
																			information you provide.
																		</li>
																		<li className="x-pt-15">
																			To credit reference agencies who will
																			record information about your application,
																			your business, you, and your business
																			partners.
																		</li>
																		<li className="x-pt-15">
																			To a third party if it acquires all (or
																			substantially all) of our business and/or
																			assets in connection with the acquisition.
																		</li>
																		<li className="x-pt-15">
																			Where we are required to do so by
																			applicable law or by a governmental body,
																			regulatory authority, or law enforcement
																			agency with competent jurisdiction.
																		</li>
																		<li className="x-pt-15">
																			Where we consider that such disclosure is
																			necessary to protect, defend, or enforce
																			our rights under the Terms or any other
																			agreements we have entered into with you.
																			Some of these third parties, such as
																			collection agencies and/or lawyers may be
																			located overseas and so we may be required
																			to transfer your information outside of
																			the country where it was collected.
																		</li>
																	</ol>
																</li>

																<li className="x-pt-25">
																	<p className="x-fw-b">
																		We will not disclose, sell, or rent your
																		personal information to any third party
																		other than as set out in this Policy unless
																		you have consented to this. If you give us
																		your consent for processing your personal
																		information in a certain way but later
																		change your mind, you may contact us by
																		email at compliance@AMWAL.ae and we will,
																		within a reasonable period of time, cease
																		any such processing activity in the future,
																		but we have no obligation to take any action
																		in relation to any disclosure, transfer or
																		other processing of your personal
																		information which occurred prior to such
																		period of time.
																	</p>
																</li>
															</ol>
														</div>
													</div>

													<div className="x-pt-50">
														<h3 className="border-bottom x-pb-10">
															5. Disclosure of business personal information
														</h3>

														<div className="">
															<ol className="x-m-0 x-pt-25">
																<li className="x-pt-15">
																	<p className="x-fw-b">
																		Where you wish to become a business on
																		AMWAL, in addition to the uses set out in
																		section 4 above, we may disclose the
																		personal information we obtain about you to:
																	</p>
																	<ol>
																		<li className="x-pt-15">
																			Credit reference agencies who will record
																			information about your application, your
																			business, you and your business partners
																			and how you manage your AMWAL financings
																			(for the avoidance of doubt we will not
																			disclose any management account
																			information you supply to us to credit
																			reference agencies).
																		</li>
																		<li className="x-pt-15">
																			Fraud prevention agencies, where it will
																			be recorded by them, and supplied to other
																			organizations to perform similar checks,
																			trace your whereabouts, and recover debts
																			you owe.
																		</li>
																		<li className="x-pt-15">
																			Investors who have financed you funds
																			pursuant to a Finance Contract or Murabaha
																			Contract, in order for binding legal
																			obligations to be created between you and
																			those investors.
																		</li>
																		<li className="x-pt-15">
																			Other investors on AMWAL in connection
																			with other finance outstanding with AMWAL
																			investors.
																		</li>
																		<li className="x-pt-15">
																			Other members of AMWAL if required to
																			enforce the terms of any agreement you
																			have entered into.
																		</li>
																		<li className="x-pt-15">
																			Any organization which provides a
																			centralized matching service that it
																			collects from mortgage and/or credit
																			applications, for the purpose of
																			preventing and detecting fraud.
																		</li>
																		<li className="x-pt-15">
																			If you expressly consent, a reputable
																			licensed credit broker in the event that
																			you apply to borrow money using AMWAL and
																			your application is declined or the
																			financing monies are otherwise unavailable
																			and we reasonably believe that the credit
																			broker may be able to help you obtain
																			financing.
																		</li>
																	</ol>
																</li>

																<li className="x-pt-25">
																	<p className="x-fw-b">
																		To secure AMWAL financing you will provide
																		business and personal information to
																		prospective investors on your financing
																		request page. Please be aware that once made
																		available on your profile this information
																		shall be available online to other users of
																		AMWAL. We have no control or responsibility
																		for the ways that other members of AMWAL may
																		use that information. Both businesses and
																		investors on AMWAL acknowledge and agree
																		that in order to enter into a specific
																		Finance Contract or Murabaha Contract it
																		will be necessary for certain information to
																		be shared with the other party. If you are a
																		business, you consent to us disclosing to
																		your investors, your company name and
																		registration details and contact details for
																		the service of notices, and in the case of
																		an investor, you consent to us disclosing to
																		the relevant business, your name and
																		passport number (if you are investing in
																		your personal capacity) or your company name
																		and registration details.
																	</p>
																</li>

																<li className="x-pt-25">
																	<p className="x-fw-b">
																		It may sometimes be necessary for us to
																		share your personal information with other
																		AMWAL companies, or AMWAL offices to share
																		your information with AMWAL in the DIFC. It
																		may also be necessary for us to share your
																		personal information with trusted third
																		parties, who provide services to us or on
																		our behalf. This may entail a transfer of
																		personal information from within the DIFC to
																		recipients outside the DIFC, and vice versa.
																	</p>
																</li>

																<li className="x-pt-25">
																	<p className="x-fw-b">
																		The level of personal information protection
																		in locations outside DIFC may be less than
																		that offered within the DIFC, but we have
																		procedures in place to ensure the protection
																		of personal information. These procedures
																		include putting steps in place to ensure
																		that all such entities keep your personal
																		information confidential and secure and only
																		use it for the purposes that we have
																		specified and communicated to you. In
																		relation to any other third parties we will
																		only disclose your information where you
																		have given your consent or where we are
																		required to do so by law or regolation, or
																		where it is necessary for the purpose of, or
																		in connection with legal proceedings or in
																		order to exercise or defend legal rights.
																	</p>
																</li>
															</ol>
														</div>
													</div>

													<div className="x-pt-50">
														<h3 className="border-bottom x-pb-10">
															6. Disclosure of SME data and information to AMWAL
															users
														</h3>

														<div className="">
															<ol className="x-m-0 x-pt-25">
																<li>
																	Apart from the data shown on the AMWAL
																	platform, or as set out above, we will not
																	share other SME financial and non-financial
																	documents with investors, unless we have first
																	received the SME’s consent.
																</li>
																<li>
																	An investor has the right to see the
																	supporting legal and contractual documentation
																	if they choose to opt out of AMWAL’s actions
																	against a defaulted SME.
																</li>
															</ol>
														</div>
													</div>

													<div className="x-pt-50">
														<h3 className="border-bottom x-pb-10">
															7. Disclosure of transaction information to AMWAL
															users
														</h3>

														<div className="">
															<p className="x-m-0 x-pt-25">
																We will share transactional information with
																other users of AMWAL as and where it is relevant
																but this information will not include your
																postal address or payment details. Please note
																that where you become an investor on AMWAL your
																full name will be disclosed to the relevant
																business in the Finance Contract or Murabaha
																Contract you enter into. We are not responsible
																for any misuse of this transactional information
																by AMWAL members. If you are concerned about the
																use of your personal information by any other
																member of AMWAL please let us know.
															</p>
														</div>
													</div>

													<div className="x-pt-50">
														<h3 className="border-bottom x-pb-10">
															8. Legal compliance
														</h3>

														<div className="">
															<ol className="x-m-0 x-pt-25">
																<li>
																	<p className="x-fw-b">
																		We may need to share your personal
																		information with third parties in order to
																		comply with our legal obligations including
																		for fraud prevention and anti-money
																		laundering purposes, and also when we:
																	</p>

																	<ol>
																		<li>
																			Check details on applications for
																			financings or other facilities.
																		</li>
																		<li>
																			Administer financings and financing
																			accounts or facilities (indirectly via our
																			licensed third parties)
																		</li>
																		<li>Recover debts.</li>
																	</ol>
																</li>
																<li>
																	If you give us false or inaccurate information
																	or we suspect you are attempting some form of
																	identity fraud, we will record this and may be
																	obliged to disclose the details to fraud
																	prevention agencies and/or the local
																	authorities. We and other organizations may
																	access and use from other countries the
																	information recorded by fraud prevention
																	agencies.
																</li>
																<li>
																	We shall only retain your personal information
																	for as long as is reasonably necessary for the
																	purposes for which it was collected – or as
																	otherwise required by applicable law.
																</li>
															</ol>
														</div>
													</div>

													<div className="x-pt-50">
														<h3 className="border-bottom x-pb-10">
															9. Your rights
														</h3>

														<div className="">
															<ol className="x-m-0 x-pt-25">
																<li className="x-pt-15">
																	<h6 className="x-fw-b">
																		Right to be provided with certain
																		information
																	</h6>
																	<p>
																		We must provide you with certain information
																		at the time we collect your personal
																		information, including how we use it and the
																		legal basis for doing so, who we share it
																		with, how long we intend to keep it, where
																		we got the information from and your rights
																		in relation to it. You find this information
																		in this Privacy Policy.
																	</p>
																</li>

																<li className="x-pt-15">
																	<h6 className="x-fw-b">
																		Right to access your information
																	</h6>
																	<p>
																		We must provide you with a copy of your
																		information unless providing some or all of
																		it would adversely affect the rights and
																		freedoms of others, or the law allows us not
																		to comply with your request. You can access
																		your personal information by emailing or
																		calling us. We aim to provide you with your
																		information within 30 days.
																	</p>
																</li>

																<li className="x-pt-15">
																	<h6 className="x-fw-b">
																		Right to correction
																	</h6>
																	<p>
																		You can ask us to correct any information
																		which you believe is inaccurate. We will
																		update your information as soon as we can.
																		You may need to provide supporting evidence
																		before we can deal with your request; e.g. a
																		copy of your marriage certificate if you
																		want to update your name.
																	</p>
																</li>

																<li className="x-pt-15">
																	<h6 className="x-fw-b">
																		Right to erasure (right to be forgotten)
																	</h6>
																	<p>
																		You can ask us to erase your personal
																		information:
																	</p>
																	<ol>
																		<li>
																			If you think it is no longer necessary for
																			us to hold your information
																		</li>
																		<li>
																			If you do not think we have any legitimate
																			grounds for processing it
																		</li>
																		<li>
																			If you think we are using your personal
																			information unlawfully
																		</li>
																		<li>
																			If you think we should delete your
																			personal information because the law
																			requires us to do so.
																		</li>
																	</ol>
																	<p></p>
																</li>

																<li className="x-pt-15">
																	<h6 className="x-fw-b">
																		Right to restrict processing
																	</h6>
																	<p>
																		You can ask us to stop using your personal
																		information:
																	</p>
																	<ol>
																		<li>If you think it is inaccurate</li>
																		<li>
																			If you think it is illegal for us to use
																			it
																		</li>
																		<li>
																			If you do not want us to destroy it
																			because you need it for legal proceedings
																		</li>
																		<li>
																			If you have told we do not have a
																			legitimate reason for using it and we are
																			considering your request.
																		</li>
																	</ol>
																</li>

																<li className="x-pt-15">
																	<h6 className="x-fw-b">
																		Notification obligation
																	</h6>
																	<p>
																		You can ask us to notify everyone to whom we
																		have given your personal information
																		whenever we amend, delete or agree to
																		restrict our use of it.
																	</p>
																</li>

																<li className="x-pt-15">
																	<h6 className="x-fw-b">
																		Right to data portability
																	</h6>
																	<p>
																		If we are using your information on the
																		basis of your consent or because we need it
																		to carry out our contractual obligations to
																		you, you can ask us to give you your
																		personal information in a structured,
																		commonly used and machine-readable format or
																		have it transmitted to another data
																		controller.
																	</p>
																</li>

																<li className="x-pt-15">
																	<h6 className="x-fw-b">Right to object</h6>
																	<p>
																		We may use your personal information to
																		further our legitimate interests; e.g., we
																		may use information about you to improve our
																		marketing messages because we have a
																		legitimate interest in making sure our
																		customers know about new products which
																		could be of benefit to them. We will always
																		give you a right to object whenever we
																		intend to use your personal information for
																		our legitimate interests.
																	</p>
																</li>

																<li className="x-pt-15">
																	<h6 className="x-fw-b">
																		Right to withdraw consent
																	</h6>
																	<p>You have the right to withdraw consent:</p>
																	<ol>
																		<li>
																			If your data has been processed without a
																			lawful basis
																		</li>
																		<li>
																			If you have not given consent to process
																			your information and/or special categories
																			of data for specific purposes
																		</li>
																		<li>
																			If you have not been informed of any
																			method displaying to exercise your rights
																			about using your information outside AMWAL
																			Financial Advisors or a Third Country.
																		</li>
																	</ol>
																</li>

																<li className="x-pt-15">
																	<h6 className="x-fw-b">
																		Right to human intervention
																	</h6>
																	<ol>
																		<li>
																			When you apply for a loan we will use an
																			automated process to decide whether to
																			lend to you. If we decline your
																			application, you can ask us to review our
																			decision.{" "}
																		</li>
																		<li>
																			There may be occasions when you wish to
																			exercise one of your rights and we are
																			unable to agree to your request, e.g.
																			because we have compelling legitimate
																			grounds for using your information, or
																			because we need to keep your information
																			to comply with a legal and/or regulatory
																			obligation.
																		</li>
																		<li>
																			You can send us your request by emailing
																			or calling us{" "}
																		</li>
																	</ol>
																</li>
															</ol>
														</div>
													</div>

													<div className="x-pt-50">
														<h3 className="border-bottom x-pb-10">
															10. Cookies
														</h3>

														<div className="">
															<ol className="x-m-0 x-pt-25">
																<li className="x-pt-15">
																	<p className="x-fw-b">
																		This Site uses cookies to collect
																		information about you. Cookies are small
																		data files which are placed on your computer
																		by this Site and which collect certain
																		personal information about you. Cookies
																		enable us to tailor our service offering
																		(including the Site) and to provide you with
																		a better service. We use the following
																		cookies:
																	</p>
																	<ol>
																		<li className="x-pt-15">
																			Strictly necessary cookies. These are
																			cookies that are required for the
																			operation of our Site. They include, for
																			example, cookies that enable you to log
																			into secure areas of our Site. They do not
																			gather information about you that could be
																			used for marketing purposes or remembering
																			where you have been on the Internet.
																		</li>
																		<li className="x-pt-15">
																			Analytical/performance cookies. They allow
																			us to collect information about how you
																			use our Site, such as, how you move around
																			our Site and if you experience any errors.
																			These cookies do not collect any
																			information that could identify you; all
																			of the information collected is anonymous
																			and is only used to help us improve the
																			way our Site works, understand what
																			interests our users and measure how
																			effective our advertising is.
																		</li>
																		<li className="x-pt-15">
																			Functionality cookies. These are used to
																			recognize you when you return to our Site.
																			This enables us to personalize our content
																			for you, greet you by name remember your
																			preferences, and improve your visit.
																		</li>
																		<li className="x-pt-15">
																			Targeting cookies. These cookies record
																			your visit to our Site, the pages you have
																			visited, and the links you have followed.
																			They are linked to services provided by
																			third parties, such as “Like” and “Share”
																			buttons. The third parties provide these
																			services in return for recognizing that
																			you have visited our Site.
																		</li>
																	</ol>
																</li>

																<li className="x-pt-25">
																	<p className="x-fw-b">
																		Generally, cookies that are strictly
																		necessary for the operation of the Site will
																		expire when you leave the Site and close
																		your web browser session. Other cookies may
																		be more permanent or may not expire unless
																		you actively delete them. You can find out
																		more about how to manage cookies and remove
																		them from your computer at the attached link
																		http://www.aboutcookies.org
																	</p>
																</li>

																<li className="x-pt-25">
																	<p className="x-fw-b">
																		If you want to reject any of the cookies
																		that we use, then you will need to change
																		your website browser settings. Please note
																		that doing so may impact your ability to
																		access certain parts of the Site and may
																		impact your user experience.
																	</p>
																</li>
															</ol>
														</div>
													</div>

													<div className="x-pt-50">
														<h3 className="border-bottom x-pb-10">
															11. Security
														</h3>

														<div className="">
															<p className="x-m-0 x-pt-25">
																Whilst we take appropriate technical and
																organizational measures to safeguard the
																personal information that you provide to us, we
																accept no liability if communications or other
																personal information are intercepted by third
																parties, delayed or incorrectly delivered, or
																not delivered.
															</p>
														</div>
													</div>

													<div className="x-pt-50">
														<h3 className="border-bottom x-pb-10">
															12. Third-party sites
														</h3>

														<div className="">
															<p className="x-m-0 x-pt-25">
																This Site contains links to other sites operated
																by third parties. Please note that this Policy
																applies only to the personal information that we
																collect through this Site and we cannot be
																responsible for personal information that third
																parties, including sites linked to this Site,
																may collect, store, and use through other
																websites. You should always read the privacy
																policy of each website you visit carefully.
															</p>
														</div>
													</div>

													<div className="x-pt-50">
														<h3 className="border-bottom x-pb-10">
															13. Changes to our policy
														</h3>

														<div className="">
															<p className="x-m-0 x-pt-25">
																We reserve the right to modify or amend this
																Policy from time to time. You are advised to
																check this Policy periodically to ensure that
																you are aware of and are complying with the
																current version of the Policy. Changes are
																binding on users of the Site and will take
																effect immediately on posting of the revised
																policy on the Site. You agree to be bound by
																such variation and your access and continued use
																of this Site shall constitute your acceptance of
																such variation and your consent to us to
																collect, use, store and otherwise process your
																personal information as may be set out in the
																then current version of the Policy. We will
																advise you on the Site that the Policy has
																changed. You accept that by doing so, we will
																have provided you with sufficient notice of such
																change.
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);

		return (
			<PageContent
				pageContent={pageContent}
				authenticatedUser={this.getAuthenticatedUser()}
				showPageContent={this.showPageContent()}
				windowLocation={window.location}
			/>
		);
	}
}
