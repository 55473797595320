import { preferredLanguage } from "./../configs/ui-language-config";
// import { RouteConfigTexts } from "./RouteConfigTexts";

const texts = {
    ar: {
        "my-advanced-profile"               : "بياناتي المتقدمة",
        "country-of-nationality"            : "بلد الجنسية",
        "country-of-residence"              : "بلد الإقامة",
        "city-of-residence"                 : "مدينة الإقامة",
        "address-of-residence"              : "عنوان الإقامة",
        "occupation"                        : "المهنة",
        "job-position"                      : "المستوى الوظيفي",
        "work-sector"                       : "قطاع العمل",
        "preferred-sectors-to-invest-in"    : "القطاعات المفضلة للاستثمار",
        "preferred-duration-of-investment"  : "المدة المفضلة للاستثمار",
        "preferred-amount-to-invest"        : "المبلغ المفضل للاستثمار",
        "are-investments-from-legal-sources": "الاستثمارات من مصادر مشروعة؟",
        "national-id-number"                : "الرقم القومي",
        "passport-number"                   : "رقم جواز السفر",
        "birth-date"                        : "تاريخ الميلاد",
        "invested-before"                   : "استثمرت من قبل؟ (خارج أموال)",
        "has-bank-account"                  : "لديك حساب بنكي؟",
    },

    en: {
        "my-advanced-profile"               : "My Advanced Profile",
        "country-of-nationality"            : "Country of Nationality",
        "country-of-residence"              : "Country of Residence",
        "city-of-residence"                 : "City of Residence",
        "address-of-residence"              : "Address of Residence",
        "occupation"                        : "Occupation",
        "job-position"                      : "Job Position",
        "work-sector"                       : "Work Sector",
        "preferred-sectors-to-invest-in"    : "Preferred Sectors to Invest in",
        "preferred-duration-of-investment"  : "Preferred Duration of Investment",
        "preferred-amount-to-invest"        : "Preferred Amount to Invest",
        "are-investments-from-legal-sources": "Investments Are From Legal Sources?",
        "national-id-number"                : "National ID Number",
        "passport-number"                   : "Passport Number",
        "birth-date"                        : "Birth Date",
        "invested-before"                   : "Invested Before? (outside Amwal)",
        "has-bank-account"                  : "Has Bank Account?",
    }
}

export const LenderAdvancedProfileTexts = texts[preferredLanguage.alpha2code];
