import React from "react";
import { NavLink } from "react-router-dom";

import { switchClasses } from "./../../../languages/CssClasses";
import { AdminProfileSubMenuTexts as texts } from "./../../../languages/AdminProfileSubMenuTexts";

// Our Configs
import { getRoutePath } from "./../../../configs/route-config";

export default class AdminProfileSubMenu extends React.Component {
    state = {};

    render() {
        return (
            <div className={"list-group shadow-sm x-mt-15 x-mb-30 __ff-droidarabickufi " + switchClasses(["x-fz-08rem"])}>
                <NavLink to={getRoutePath("adminBasicProfile")} className="list-group-item list-group-item-action _active">{texts["my-basic-profile"]}</NavLink>
                {/* <NavLink to={getRoutePath("adminAdvancedProfile")} className="list-group-item list-group-item-action">My Advanced Profile</NavLink> */}
                <NavLink to={getRoutePath("adminChangePassword")} className="list-group-item list-group-item-action">{texts["change-my-password"]}</NavLink>
            </div>
        );
    }
}
