import React from "react";
// import { NavLink } from "react-router-dom";
// import Icon from "@mdi/react"
import { switchClasses } from "./../../../languages/CssClasses";
import { BorrowerFirstLoanApplicationWizardSubMenuTexts as texts } from "./../../../languages/BorrowerFirstLoanApplicationWizardSubMenuTexts";
import { getRoutePath } from "./../../../configs/route-config";

const BorrowerFirstLoanApplicationWizardSubMenu = (props) => {
    const makeItemActiveIfProper = (path) => {
        if (String(path).toLocaleLowerCase() === String(props.activePagePath).toLocaleLowerCase()) {
            return "active";
        }
    }

    return (
        <div className={"list-group shadow-sm x-mt-15 x-mb-30 __ff-droidarabickufi " + switchClasses(["x-fz-085rem"])}>
            <li className={"list-group-item list-group-item-action " + makeItemActiveIfProper(getRoutePath("borrowerAdvancedProfile"))}><span className="__ff-rubik x-fz-17">1.</span>&nbsp;&nbsp;{texts["my-data"]}</li>
            <li className={"list-group-item list-group-item-action " + makeItemActiveIfProper(getRoutePath("borrowerCompanyCreate"))}><span className="__ff-rubik x-fz-17">2.</span>&nbsp;&nbsp;{texts["company-data"]}</li>
            <li className={"list-group-item list-group-item-action " + makeItemActiveIfProper(getRoutePath("borrowerDocumentList"))}><span className="__ff-rubik x-fz-17">3.</span>&nbsp;&nbsp;{texts["required-documents"]}</li>
            <li className={"list-group-item list-group-item-action " + makeItemActiveIfProper(getRoutePath("borrowerLoanCreate"))}><span className="__ff-rubik x-fz-17">4.</span>&nbsp;&nbsp;{texts["loan-data"]}</li>
        </div>
    );
};

export default BorrowerFirstLoanApplicationWizardSubMenu;
