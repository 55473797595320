import { preferredLanguage } from "./../configs/ui-language-config";

const texts = {
    ar: {
        "my-basic-profile": "بياناتي الأساسية",
        "my-advanced-profile": "بياناتي المتقدمة",
        "change-my-password": "تغيير كلمة السر",
    },

    en: {
        "my-basic-profile": "My Basic Profile",
        "my-advanced-profile": "My Advanced Profile",
        "change-my-password": "Change My Password",
    }
}

export const BorrowerProfileSubMenuTexts = texts[preferredLanguage.alpha2code];
