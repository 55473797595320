import { preferredLanguage } from "./../configs/ui-language-config";
// import { GeneralTexts } from "./GeneralTexts";
import { RouteConfigTexts } from "./RouteConfigTexts";

const texts = {
    ar: {
        "admin-overview-general": RouteConfigTexts["admin-overview-general"],
        "general"               : "احصائيات عامة",
        "all-time"              : "كل الأوقات",
        "today"                 : "اليوم",
        "yesterday"             : "أمس",
        "last-7-days"           : "7 أيام الماضية",
        "last-30-days"          : "30 يوم الماضية",
        "revenue"               : "الإيرادات",
        "amwal-revenue"         : "إيرادات أموال",
        "loans"                 : "القروض",
        "loans-of-all-statuses" : "القروض من جميع الحالات",
        "users"                 : "المستخدمين",
        "users-of-all-types"    : "المستخدمين من جميع الأنواع",
        "borrowers"             : "المقترضين",
        "users-of-type-borrower": "المستخدمين من نوع المقترض",
        "investors"             : "المستثمرين",
        "users-of-type-investor": "المستخدمين من نوع المستثمر",
        "admins"                : "مديري الموقع",
        "users-of-type-admin"   : "المستخدمين من نوع الأدمن",
    },

    en: {
        "admin-overview-general": RouteConfigTexts["admin-overview-general"],
        "general"               : "General",
        "all-time"              : "All Time",
        "today"                 : "Today",
        "yesterday"             : "Yesterday",
        "last-7-days"           : "Last 7 Days",
        "last-30-days"          : "Last 30 Days",
        "revenue"               : "Revenue",
        "amwal-revenue"         : "Amwal's Revenue",
        "loans"                 : "Loans",
        "loans-of-all-statuses" : "Loans of All Statuses",
        "users"                 : "Users",
        "users-of-all-types"    : "Users of All Types",
        "borrowers"             : "Borrowers",
        "users-of-type-borrower": "Users of Type Borrower",
        "investors"             : "Investors",
        "users-of-type-investor": "Users of Type Investor",
        "admins"                : "Admins",
        "users-of-type-admin"   : "Users of Type Admin",
    }
}

export const AdminOverviewGeneralTexts = texts[preferredLanguage.alpha2code];
