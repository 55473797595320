// import _isEmpty from "lodash/isEmpty";

// Our Services
import http from "./http-service";
import * as accessTokenService from "./access-token-service";

// Our Configs
import { getApiEndpoint } from "./../configs/api-config";

// -----------------------------------------------

/*
export const getCurrentUser = async () => {
    try {
        // If the access token doesn't exist
        if (_isEmpty(accessTokenService.getAccessToken())) {
            return null;
        }

        // TEST
        // console.log("accessToken: ", accessTokenService.getAccessToken());

        // Get data of the current user
        const response = await http.get(
            getApiEndpoint("me").path
        );

        // TEST
        // console.log("auth-service me response: ", response);

        const user = response.data.data.user || null;

        return ! _isEmpty(user) ? user : null;

    } catch (error) {
        return null;
    }
}
*/

// -----------------------------------------------

export const preSignup = (data) => {
    return http.post(
        getApiEndpoint("preSignup").path,
        data
    );
}

// -----------------------------------------------

export const signup = (data) => {
    return http.post(
        getApiEndpoint("signup").path,
        data
    );
}

// -----------------------------------------------

// export const login = (data) => {
//     return http.post(
//         getApiEndpoint("login").path,
//         data
//     );
// }

export const login = async (data) => {
    const response = await http.post(
        getApiEndpoint("login").path,
        data
    );

    // Get the returned access token
    const accessToken = response.data.meta.access_token;
    // Store tha access token in the browser's local storage
    accessTokenService.setAccessToken(accessToken);

    // Get the returned access token expiration date
    const accessTokenExpirationDate = response.data.meta.access_token_expires_at;
    // Store tha access token expiration date in the browser's local storage
    accessTokenService.setAccessTokenExpirationDate(accessTokenExpirationDate);

    return response;
}

// -----------------------------------------------

// export const logout = () => {
//     return http.post(
//         getApiEndpoint("logout").path
//     );
// }

export const logout = async () => {
    // Remove the stored access token
    accessTokenService.removeAccessToken();

    const response = await http.post(
        getApiEndpoint("logout").path
    );

    // const loggedOut = response.data?.meta?.success;
    // if (loggedOut) {
    //     // Remove the stored access token
    //     accessTokenService.removeAccessToken();
    // }

    return response;
}

// -----------------------------------------------

export const passwordForgot = (data) => {
    return http.post(
        getApiEndpoint("passwordForgot").path,
        data
    );
}

// -----------------------------------------------

export const passwordReset = (data) => {
    return http.post(
        getApiEndpoint("passwordReset").path,
        data
    );
}

// -----------------------------------------------
// export default {
//     preSignup,
//     signup,
//     login,
//     passwordForgot,
//     passwordReset
// }
