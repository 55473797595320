import React from "react";

// Our Services
import documentTitleService from "./../services/document-title-service";
import PageHandler from "./shared/PageHandler";
import PageContent from "./shared/PageContent";

export default class ContactUs extends PageHandler {

    initialState = {
        ...this.initialStateElements,
        isThisPagePublic: true,
    };

    // -----------------------------------------------

    // Clone a separate copy of the "initialState" and assign it to the "state".
    // Note that the spread operator "..." is important to get a separate copy.
    state = { ...this.initialState };

    // -----------------------------------------------

    componentDidMount() {
        // More info: t.ly/ZVsd
        window.scrollTo(0, 0);

        // ----------------------

        // Properly set the browser's document title
        documentTitleService.setTitle("Contact Us");

        // ----------------------

        this.setAuthenticatedUser();
    }

    // -----------------------------------------------

	render() {
		const pageContent = (
            <React.Fragment>
                <h5 className="x-ta-c x-pt-50 x-c-crimson">
                    To Be Added Soon
                </h5>
            </React.Fragment>
        );

        return (
            <PageContent
                pageContent={pageContent}
                authenticatedUser={this.getAuthenticatedUser()}
                showPageContent={this.showPageContent()}
                windowLocation={window.location}
            />
        );
	}
}
