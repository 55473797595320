import React from "react";
import ReactApexChart from "react-apexcharts"

import { switchClasses } from "./../../../languages/CssClasses";
import { AdminOverviewLoansTexts as texts } from "./../../../languages/AdminOverviewLoansTexts";
import { GeneralTexts } from "./../../../languages/GeneralTexts";

// Our Modules
import StatsCard from "./../../shared/StatsCard";
import AdminOverviewSubMenu from "./AdminOverviewSubMenu";
import PageLoader from "./../../shared/PageLoader";
import PageContent from "./../../shared/PageContent";
import PageHandler from "./../../shared/PageHandler";

// Our Services
import documentTitleService from "./../../../services/document-title-service";

export default class AdminOverviewLoans extends PageHandler {

    initialState = {
        ...this.initialStateElements,
        allowedUserType: "admin",

        showPageLoader: true,
        currentLoansChart: "loansBarChart",

        loansBarChart: {
            series: [{
                name: "",
                // data: [1000, 25, 75, 800, 100, 750, 200, 50]
                data: [1000, 25, 75, 800, 100, 50]
            }],
            options: {
                chart: { type: "bar" },
                xaxis: {
                    type: "category",
                    // categories: ["All Loans", "Pending (New)", "Under Review", "Approved", "Rejected", "Fully Succeeded", "Partially Succeeded", "Failed"],
                    categories: [texts["all-loans"], texts["pending"], texts["under-review"], texts["approved"], texts["rejected"], texts["failed"]],
                },
                grid: {
                    borderColor: "rgba(0,0,0,0.2)",
                    strokeDashArray: 2,
                },
                tooltip: { theme: "dark" },
                legend: { show: true },
                colors: ['#2179fe', '#E91E63', '#546E7A', '#80b918', '#FF9800', '#A52A2A', '#D2691E', '#FF7F50', '#008B8B', '#9932CC', '#483D8B'],
            },
        },

        loansPieChart: {
            // series: [25, 75, 800, 100, 750, 200, 50],
            series: [25, 75, 800, 100, 50],
            options: {
                chart: { type: "donut" },
                // labels: ["Pending (New)", "Under Review", "Approved", "Rejected", "Fully Succeeded", "Partially Succeeded", "Failed"],
                labels: [texts["pending"], texts["under-review"], texts["approved"], texts["rejected"], texts["failed"]],
                colors: ['#2179fe', '#E91E63', '#546E7A', '#80b918', '#FF9800', '#A52A2A', '#D2691E', '#FF7F50', '#008B8B', '#9932CC', '#483D8B'],
            },
        },
    };

    // -----------------------------------------------

    // Clone a separate copy of the "initialState" and assign it to the "state".
    // Note that the spread operator "..." is important to get a separate copy.
    state = { ...this.initialState };

    // -----------------------------------------------

    componentDidMount() {
        // More info: t.ly/ZVsd
        window.scrollTo(0, 0);

        // ----------------------

        // Properly set the browser's document title
        documentTitleService.setTitle(GeneralTexts["admin-dashboard"] + " | " + texts["admin-overview-loans"]);

        // ----------------------

        this.setAuthenticatedUser();
        this.getStats();
    }

    // -----------------------------------------------

    // Get stats
    getStats = async () => {
        this.setState({ showPageLoader: true });

        // TODO: Fetch and handle data here..

        this.setState({ showPageLoader: false });
    }

    // -----------------------------------------------

    handleChangeLoansChart = (event) => {
        const input = event.currentTarget;

        this.setState({ currentLoansChart: input.value });

        // TEST
        console.log("input value: " + input.value);
    };

    // -----------------------------------------------

    render() {
        const pageContent = (
            <React.Fragment>
                <div>
                    <div className={"x-m-25 x-mb-15 " + switchClasses(["x-mr-15"])}>
                        <h5 className={"x-pt-10 head-color " + switchClasses(["__ff-droidarabickufi"])}>{texts["admin-overview-general"]}</h5>
                    </div>

                    <div className="x-mt-15">
                        <div className="container-fluid x-ov-h">
                            <div className="row">
                                <div className="col-md-3 col-lg-2">
                                    <AdminOverviewSubMenu />
                                </div>

                                <div className={"col-md-9 col-lg-10 x-pb-100 " + switchClasses(["__ff-droidarabickufi"])}>
                                    <PageLoader show={this.state.showPageLoader} />

                                    <div className={(this.state.showPageLoader ? "x-d-n" : "")}>
                                        <h5 className="x-pt-10 head-color clearfix">
                                            {texts["loans"]}

                                            <div className={"x-d-ib " + switchClasses(["x-fl-l"])}>
                                                <select className="custom_select">
                                                    <option value="all-time">{texts["all-time"]}</option>
                                                    <option value="today">{texts["today"]}</option>
                                                    <option value="yesterday">{texts["yesterday"]}</option>
                                                    <option value="last-7-days">{texts["last-7-days"]}</option>
                                                    <option value="last-30-days">{texts["last-30-days"]}</option>
                                                </select>
                                            </div>
                                        </h5>

                                        <div className="row">
                                            <div className="col-sm-6 col-md-4 x-pt-15 x-pb-15">
                                                <StatsCard
                                                    title={texts["all-loans"]}
                                                    hint={texts["loans-of-all-statuses-hint"]}
                                                    value="1000"
                                                    valuePercentage={(1000 / 1000) * 100}
                                                    cssColorClass="text-primary"
                                                    cssBackgroundColorClass="bg-primary"
                                                />
                                            </div>

                                            <div className="col-sm-6 col-md-4 x-pt-15 x-pb-15">
                                                <StatsCard
                                                    title={texts["pending"]}
                                                    hint={texts["pending-loan-requests-hint"]}
                                                    value="25"
                                                    valuePercentage={(25 / 1000) * 100}
                                                    cssColorClass="x-c-chocolate"
                                                    cssBackgroundColorClass="x-bgc-chocolate"
                                                />
                                            </div>

                                            <div className="col-sm-6 col-md-4 x-pt-15 x-pb-15">
                                                <StatsCard
                                                    title={texts["under-review"]}
                                                    hint={texts["under-review-loans-hint"]}
                                                    value="75"
                                                    valuePercentage={(75 / 1000) * 100}
                                                    cssColorClass="x-c-blueviolet"
                                                    cssBackgroundColorClass="x-bgc-blueviolet"
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-6 col-md-4 x-pt-15 x-pb-15">
                                                <StatsCard
                                                    title={texts["approved"]}
                                                    hint={texts["approved-loans-hint"]}
                                                    value="800"
                                                    valuePercentage={(800 / 1000) * 100}
                                                    cssColorClass="text-success"
                                                    cssBackgroundColorClass="bg-success"
                                                />
                                            </div>

                                            <div className="col-sm-6 col-md-4 x-pt-15 x-pb-15">
                                                <StatsCard
                                                    title={texts["rejected"]}
                                                    hint={texts["rejected-loans-hint"]}
                                                    value="100"
                                                    valuePercentage={(100 / 1000) * 100}
                                                    cssColorClass="text-danger"
                                                    cssBackgroundColorClass="bg-danger"
                                                />
                                            </div>

                                            <div className="col-sm-6 col-md-4 x-pt-15 x-pb-15">
                                                <StatsCard
                                                    title={texts["failed"]}
                                                    hint={texts["failed-loans-hint"]}
                                                    value="50"
                                                    valuePercentage={(50 / 1000) * 100}
                                                    cssColorClass="x-c-mediumorchid"
                                                    cssBackgroundColorClass="x-bgc-mediumorchid"
                                                />
                                            </div>
                                        </div>

                                        {/*
                                        <div className="row">
                                            <div className="col-sm-6 col-md-4 x-pt-15 x-pb-15">
                                                <StatsCard
                                                    title="Fully Succeeded"
                                                    value="750"
                                                    hint="Fully Succeeded Loans"
                                                    valuePercentage={(750 / 1000) * 100}
                                                    cssColorClass="x-c-lightseagreen"
                                                    cssBackgroundColorClass="x-bgc-lightseagreen"
                                                />
                                            </div>

                                            <div className="col-sm-6 col-md-4 x-pt-15 x-pb-15">
                                                <StatsCard
                                                    title="Partially Succeeded"
                                                    value="200"
                                                    hint="Partially Succeeded Loans"
                                                    valuePercentage={(200 / 1000) * 100}
                                                    cssColorClass="x-c-lightslategrey"
                                                    cssBackgroundColorClass="x-bgc-lightslategrey"
                                                />
                                            </div>
                                        </div>
                                        */}

                                        <div className="row x-pt-30">
                                            <div className="col x-pt-15 x-pb-15">
                                                <div className="x-bgc-fff x-p-20 x-bdrs-4 shadow-sm">
                                                    <h5 className={"x-pb-15 clearfix " + switchClasses(["__ff-droidarabickufi"])}>
                                                        <span>{texts["loans"]}</span>

                                                        <div className={"x-d-ib " + switchClasses(["x-fl-l"])}>
                                                            <select className="custom_select" onChange={this.handleChangeLoansChart}>
                                                                <option value="loansBarChart">Bar Chart</option>
                                                                <option value="loansPieChart">Pie Chart</option>
                                                            </select>
                                                        </div>
                                                    </h5>

                                                    <div className={this.state.currentLoansChart === "loansBarChart" ? "" : "x-d-n"}>
                                                        <ReactApexChart
                                                            options={this.state.loansBarChart.options}
                                                            series={this.state.loansBarChart.series}
                                                            type={this.state.loansBarChart.options.chart.type}
                                                            height="300px"
                                                        />
                                                    </div>

                                                    <div className={this.state.currentLoansChart === "loansPieChart" ? "" : "x-d-n"}>
                                                        <ReactApexChart
                                                            options={this.state.loansPieChart.options}
                                                            series={this.state.loansPieChart.series}
                                                            type={this.state.loansPieChart.options.chart.type}
                                                            height="300px"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <hr className="x-m-25 x-mt-50 x-mb-45" /> */}

            </React.Fragment>
        );

        return (
            <PageContent
                pageContent={pageContent}
                authenticatedUser={this.getAuthenticatedUser()}
                showPageContent={this.showPageContent()}
                windowLocation={window.location}
            />
        );
    }
}
