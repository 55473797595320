import React from "react";
import { Link } from "react-router-dom";

import { preferredLanguage } from "./../../../configs//ui-language-config";
import { switchClasses } from "./../../../languages/CssClasses";
import { BorrowerCompanyCreateTexts as texts } from "./../../../languages/BorrowerCompanyCreateTexts";
import { BorrowerFirstLoanApplicationWizardSubMenuTexts as wizardTexts } from "./../../../languages/BorrowerFirstLoanApplicationWizardSubMenuTexts";
import { GeneralTexts } from "./../../../languages/GeneralTexts";

// Third-Party Modules
// import _isEmpty from "lodash/isEmpty";
import Joi from "@hapi/joi";
import Icon from "@mdi/react";
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import queryString from "query-string";

// Our Modules
import MessageModal, {
	toggleMessageModal,
	getErrorMessage,
} from "./../../shared/MessageModal";
import FormHandler from "./../../shared/FormHandler";
import TextBox from "./../../shared/TextBox";
import SelectBox from "./../../shared/SelectBox";
import TextareaBox from "./../../shared/TextareaBox";
import CustomButton from "./../../shared/CustomButton";
import PageLoader from "./../../shared/PageLoader";
import ButtonLoader from "./../../shared/ButtonLoader";
import RequiredDataHint from "./../../shared/RequiredDataHint";
// import YearPickerBox from "./../../shared/YearPickerBox";
import PageContent from "./../../shared/PageContent";
import BorrowerFirstLoanApplicationWizardSubMenu from "./BorrowerFirstLoanApplicationWizardSubMenu";

// Our Services
import documentTitleService from "./../../../services/document-title-service";
import logService from "./../../../services/log-service";
import * as companyService from "./../../../services/company-service";

// Our Configs
import { getRoutePath } from "./../../../configs/route-config";
import {
	getCommonCountryList,
	getCountry,
} from "./../../../configs/country-config";
import { getCityList } from "./../../../configs/country-config";
import {
	getCompanyTypes,
	getActivitySectors,
	getActivitySubsectors,
} from "./../../../configs/company-config";

export default class BorrowerCompanyCreate extends FormHandler {
	// Get query string as an object
	urlQueryString = queryString.parse(this.props.location.search);

	// -----------------------------------------------

	initialState = {
		...this.initialStateElements,
		allowedUserType: "borrower",

		formInputs: {
			official_name: "",
			country: "",
			city: "",
			address: "",
			country_calling_code: "",
			mobile: "",
			landline: "",
			founded_in: "",
			company_type: "",
			activity_sector: "",
			activity_subsector: "",
			business_cycle: "",
			email: "",
			website: "",
			// commercial_register_image: "",
			// tax_card_image           : "",
		},
		validationErrors: {},
		messageModal: {},
		showPageLoader: true,
		showButtonLoader: false,
		closeInfoAlert: false,
	};

	// -----------------------------------------------

	// Clone a separate copy of the "initialState" and assign it to the "state".
	// Note that the spread operator "..." is important to get a separate copy.
	state = { ...this.initialState };

	// -----------------------------------------------

	validationRules = {
		official_name: Joi.string().required().max(100).label("Official Name"),

		company_type: Joi.string().required().label("Company Type"),

		founded_in: Joi.number()
			.required()
			.integer()
			.positive()
			// Can't be before the year 1900
			.min(1900)
			// Can't be after the current year
			.max(new Date().getFullYear())
			.label("Founded in"),

		activity_sector: Joi.string().required().label("Activity Sector"),

		activity_subsector: Joi.string()
			.optional()
			.allow(null, "")
			.label("Activity Subsector"),

		country: Joi.string().required().min(2).max(2).label("Country"),

		city: Joi.string().required().label("City"),

		address: Joi.string().required().label("Address"),

		mobile: Joi.string()
			.required()
			.min(8)
			.max(11)
			// Accept only digits. The same as: .pattern(new RegExp('/^\d+$/'))
			.regex(/^0\d+$/)
			// Tha mobile number must begins with a "0" and consists of 10, or 11 digits including the first "0"
			// .regex(/^0\d{9,10}$/)
			.label("Mobile Number")
			// More info about custom messages: t.ly/gY6z and t.ly/F5n2
			// And this is a full list of the errors: t.ly/LUUy
			.messages({
				"string.pattern.base": `"Mobile Number" should consists of digits only and begins with a zero '0'.`,
			}),

		landline: Joi.string()
			.optional()
			.allow(null, "")
			// .min(9)
			// .max(12)
			// The landline number must begins with a "0" then a number or two then a dash "-" then a number consists at least of 6 digits and at most 8 digits
			.regex(/^0\d{1,2}-\d{6,8}$/)
			.label("Landline Number")
			// More info about custom messages: t.ly/gY6z and t.ly/F5n2
			// And this is a full list of the errors: t.ly/LUUy
			.messages({
				"string.pattern.base": `"Landline Number" should consists of digits only and one dash '-' between the area code the landline number.`,
			}),

		email: Joi.string()
			.optional()
			.allow(null, "")
			.email({ tlds: false })
			.max(50)
			.label("Email Address"),

		website: Joi.string().optional().allow(null, "").label("Website"),

		business_cycle: Joi.string().required().label("Business Cycle"),

		country_calling_code: Joi.string()
			.optional()
			.allow(null, "")
			.max(5)
			.label("Country Calling Code"),
	};

	// -----------------------------------------------

	componentDidMount() {
		// More info: t.ly/ZVsd
		window.scrollTo(0, 0);

		// ----------------------

		// Properly set the browser's document title
		documentTitleService.setTitle(
			GeneralTexts["borrower-dashboard"] +
				" | " +
				texts["borrower-company-create"]
		);

		// ----------------------

		this.setAuthenticatedUser();
		this.setState({ showPageLoader: false });
	}

	// -----------------------------------------------

	componentWillUnmount() {
		// This code fixes the warning: "Can't perform a React state update on an unmounted component'
		// More info: t.ly/Kday
		this.setState = (state, callback) => {
			return;
		};
	}

	// -----------------------------------------------

	handleCountryChange = (event) => {
		const input = event.currentTarget;

		// Clone the 'state.formInputs' object
		const formInputs = { ...this.state.formInputs };

		// Overwrite the value of the input field that has been changed
		formInputs[input.name] = input.value;

		// Get the full data of the selected country
		const country = getCountry(input.value);

		// Overwrite the value of the country_calling_code attribute according to the selected country
		formInputs.country_calling_code = country.callingCodes[0];

		// Update the 'state.formInputs' object with the new value
		this.setState({ formInputs: formInputs });
	};

	// -----------------------------------------------

	doSubmit = async (e) => {
		this.setState({ showButtonLoader: true });

		try {
			const response = await companyService.create(this.state.formInputs);

			// const company = response.data.data.company;

			if (this.isFirstTimeLoan()) {
				const url = `${getRoutePath("borrowerDocumentList")}?first_time_loan=1`;
				this.props.history.push(url);
			}

			this.setState({
				messageModal: {
					...toggleMessageModal("success"),
					body: response.data.meta.message || "",
					onHide: () =>
						this.props.history.push(getRoutePath("borrowerCompanyList")),
				},
			});
		} catch (error) {
			/**
			 * If there is an unexpected error like: network down, server down, db down, bug, ..
			 *  - Log the error.
			 *  - Display a generic and friendly error message.
			 *
			 * Note that "error.response" determines if the error is an expected error or not.
			 */

			const isExpectedError =
				error.response &&
				error.response.status >= 400 &&
				error.response.status < 500;

			if (!isExpectedError) {
				logService.log(error);
			}

			this.showErrorMessage(error);
		}

		this.setState({ showButtonLoader: false });
	};

	// -----------------------------------------------

	showErrorMessage = (error, props = this.props) => {
		this.setState({
			messageModal: {
				...toggleMessageModal("error"),
				body: getErrorMessage(error, props).body || "",
				footerButtons: getErrorMessage(error, props).footerButtons || [],
			},
		});
	};

	// -----------------------------------------------

	closeInfoAlert = () => {
		this.setState({ closeInfoAlert: true });
	};

	// -----------------------------------------------

	isFirstTimeLoan = () => {
		return String(this.urlQueryString?.first_time_loan) === "1";
	};

	// -----------------------------------------------

	goToBorrowerAdvancedProfile = () => {
		const url = `${getRoutePath("borrowerAdvancedProfile")}?first_time_loan=1`;
		this.props.history.push(url);
	};

	// -----------------------------------------------

	render() {
		const pageContent = (
			<React.Fragment>
				<div className="x-m-25">
					<div className={this.isFirstTimeLoan() ? "x-d-n" : ""}>
						<Link
							to={getRoutePath("borrowerCompanyList")}
							className="x-fz-085rem"
						>
							<Icon
								path={
									preferredLanguage.direction === "rtl"
										? mdiChevronRight
										: mdiChevronLeft
								}
								size="1.4em"
								className="x-pos-r x-b-2"
							/>
							<span className={switchClasses(["__ff-droidarabickufi"])}>
								{texts["borrower-company-list"]}
							</span>
						</Link>
					</div>

					<h5
						className={
							"x-pt-10 head-color " + switchClasses(["__ff-droidarabickufi"])
						}
					>
						{this.isFirstTimeLoan()
							? wizardTexts["company-data"]
							: texts["borrower-company-create"]}
					</h5>
				</div>

				<div className="_border _border-light shadow-sm x-m-25 x-mt-0 x-mb-70 x-p-10 x-pb-100 x-mih-500 x-bgc-fff x-bdc-e3e3e3 x-bdrs-4">
					<div className="container-fluid">
						<PageLoader show={this.state.showPageLoader} />

						<div
							className={
								"row x-pt-20" + (this.state.showPageLoader ? " x-d-n" : "")
							}
						>
							{this.isFirstTimeLoan() ? (
								<div className="col-md-3">
									<BorrowerFirstLoanApplicationWizardSubMenu
										activePagePath={this.props.location.pathname}
									/>
								</div>
							) : (
								""
							)}

							<div className="col-md-9">
								<div className={this.isFirstTimeLoan() ? "" : "x-d-n"}>
									<div className={this.state.closeInfoAlert ? "x-d-n" : ""}>
										<div
											className={
												"alert alert-primary alert-dismissible x-lh-28 x-mt-15 __ff-droidarabickufi " +
												switchClasses(["x-fz-082rem"])
											}
											role="alert"
										>
											<div>
												{/*
                                                <p>خطوة <span className="__ff-rubik x-fz-18">2</span> من <span className="__ff-rubik x-fz-18">4</span> &nbsp;|&nbsp; <strong>{wizardTexts["company-data"]}</strong></p>
                                                <p>أكمل البيانات المطلوبة التالية ثم اضغط زر <strong>{GeneralTexts["next"]}</strong> لتنتقل للخطوة التالية، أو أضغط زر <strong>{GeneralTexts["previous"]}</strong> لترجع للخطوة السابقة.</p>
                                                */}
												{texts["step-2-hint"]}
											</div>
											<button
												type="button"
												className="close"
												onClick={this.closeInfoAlert}
											>
												<span>&times;</span>
											</button>
										</div>
									</div>
								</div>

								<form
									className={
										"__ff-droidarabickufi " + switchClasses(["x-fz-085rem"])
									}
									onSubmit={(e) => e.preventDefault()}
								>
									<div className="row">
										<div className="col-lg-8">
											<TextBox
												type="text"
												name="official_name"
												label={texts["company-official-name"]}
												value={this.state.formInputs.official_name || ""}
												onBlur={this.handleBlur}
												onChange={this.handleChange}
												onFocus={this.handleFocus}
												validationError={
													this.state.validationErrors.official_name || ""
												}
											/>
										</div>
									</div>

									<div className="row">
										<div className="col-lg-4">
											<SelectBox
												name="company_type"
												value={this.state.formInputs.company_type || ""}
												label={texts["company-type"]}
												onBlur={this.handleBlur}
												onChange={this.handleChange}
												onFocus={this.handleFocus}
												validationError={
													this.state.validationErrors.company_type || ""
												}
												selectBoxOptions={getCompanyTypes().map((type) => {
													return (
														<option key={Math.random()} value={type.value}>
															{type.label}
														</option>
													);
												})}
											/>
										</div>

										<div className="col-lg-4">
											{/*
                                            <YearPickerBox
                                                isClearable={false}
                                                label={texts["founded-in"]}
                                                name="founded_in"
                                                onBlur={this.handleBlur}
                                                onChange={(date) => { this.handleYearPickerChange(date, "founded_in") }}
                                                onFocus={this.handleFocus}
                                                required={true}
                                                validationError={this.state.validationErrors.founded_in || ""}
                                                value={this.state.formInputs.founded_in || ""}
                                            />
                                            */}
											<TextBox
												extraCssClasses="x-dr-ltr x-ta-l"
												label={texts["founded-in"]}
												name="founded_in"
												onBlur={this.handleBlur}
												onChange={this.handleChange}
												onFocus={this.handleFocus}
												placeholder="2005"
												required={true}
												type="text"
												validationError={
													this.state.validationErrors.founded_in || ""
												}
												value={this.state.formInputs.founded_in || ""}
											/>
										</div>
									</div>

									<div className="row">
										<div className="col-lg-4">
											<SelectBox
												name="activity_sector"
												value={this.state.formInputs.activity_sector || ""}
												label={texts["activity-sector"]}
												onBlur={this.handleBlur}
												onChange={this.handleChange}
												onFocus={this.handleFocus}
												validationError={
													this.state.validationErrors.activity_sector || ""
												}
												selectBoxOptions={getActivitySectors().map((sector) => {
													return (
														<option key={Math.random()} value={sector.value}>
															{sector.label}
														</option>
													);
												})}
											/>
										</div>

										<div className="col-lg-4">
											<SelectBox
												name="activity_subsector"
												value={this.state.formInputs.activity_subsector || ""}
												label={texts["activity-subsector"]}
												required={false}
												onBlur={this.handleBlur}
												onChange={this.handleChange}
												onFocus={this.handleFocus}
												validationError={
													this.state.validationErrors.activity_subsector || ""
												}
												selectBoxOptions={getActivitySubsectors(
													this.state.formInputs.activity_sector
												).map((subsector) => {
													return (
														<option key={Math.random()} value={subsector.value}>
															{subsector.label}
														</option>
													);
												})}
											/>
										</div>
									</div>

									<div className="row">
										<div className="col-lg-4">
											<SelectBox
												name="country"
												value={this.state.formInputs.country || ""}
												label={texts["country"]}
												onBlur={this.handleBlur}
												// For more info about using multiple functions with the onChange attribute check: t.ly/KRFg
												// onChange={e => {this.handleChange(e); this.setCountryCallingCode(e)}}
												onChange={this.handleCountryChange}
												onFocus={this.handleFocus}
												validationError={
													this.state.validationErrors.country || ""
												}
												selectBoxOptions={getCommonCountryList().map(
													(country) => {
														return (
															<option
																key={Math.random()}
																value={country.alpha2Code}
															>
																{country.name + " (" + country.alpha2Code + ")"}
															</option>
														);
													}
												)}
											/>
										</div>

										<div className="col-lg-4">
											<SelectBox
												name="city"
												value={this.state.formInputs.city || ""}
												label={texts["city"]}
												onBlur={this.handleBlur}
												onChange={this.handleChange}
												onFocus={this.handleFocus}
												validationError={this.state.validationErrors.city || ""}
												selectBoxOptions={getCityList(
													this.state.formInputs.country
												).map((city) => {
													return (
														<option key={Math.random()} value={city}>
															{city}
														</option>
													);
												})}
											/>
										</div>
									</div>

									<div className="row">
										<div className="col-lg-8">
											<TextBox
												name="address"
												label={texts["address"]}
												value={this.state.formInputs.address || ""}
												onBlur={this.handleBlur}
												onChange={this.handleChange}
												onFocus={this.handleFocus}
												validationError={
													this.state.validationErrors.address || ""
												}
											/>
										</div>
									</div>

									<div className="row">
										<div className="col-lg-4">
											<TextBox
												name="mobile"
												label={texts["mobile-number"]}
												value={this.state.formInputs.mobile || ""}
												onBlur={this.handleBlur}
												onChange={this.handleChange}
												onFocus={this.handleFocus}
												validationError={
													this.state.validationErrors.mobile || ""
												}
												placeholder="01012345678"
												extraCssClasses="x-dr-ltr x-ta-l"
											/>
										</div>

										<div className="col-lg-4">
											<TextBox
												name="landline"
												label={texts["landline-number"]}
												value={this.state.formInputs.landline || ""}
												required={false}
												onBlur={this.handleBlur}
												onChange={this.handleChange}
												onFocus={this.handleFocus}
												validationError={
													this.state.validationErrors.landline || ""
												}
												placeholder="02-12345678"
												extraCssClasses="x-dr-ltr x-ta-l"
											/>
										</div>
									</div>

									<div className="row">
										<div className="col-lg-4">
											<TextBox
												name="email"
												label={texts["email-address"]}
												value={this.state.formInputs.email || ""}
												required={false}
												onBlur={this.handleBlur}
												onChange={this.handleChange}
												onFocus={this.handleFocus}
												validationError={
													this.state.validationErrors.email || ""
												}
												placeholder="info@company.com"
												extraCssClasses="x-dr-ltr x-ta-l"
											/>
										</div>

										<div className="col-lg-4">
											<TextBox
												name="website"
												label={texts["website"]}
												value={this.state.formInputs.website || ""}
												required={false}
												onBlur={this.handleBlur}
												onChange={this.handleChange}
												onFocus={this.handleFocus}
												validationError={
													this.state.validationErrors.website || ""
												}
												placeholder="www.example.com"
												extraCssClasses="x-dr-ltr x-ta-l"
											/>
										</div>
									</div>

									<div className="row">
										<div className="col-lg-8">
											<TextareaBox
												name="business_cycle"
												value={this.state.formInputs.business_cycle || ""}
												label={texts["business-cycle"]}
												onBlur={this.handleBlur}
												onChange={this.handleChange}
												onFocus={this.handleFocus}
												validationError={
													this.state.validationErrors.business_cycle || ""
												}
												placeholder={texts["business-cycle-placeholder"]}
											/>
										</div>
									</div>

									<div className="row">
										<div className="col">
											<div className="x-mt-20">
												<CustomButton
													type="button"
													label={
														this.state.showButtonLoader ? (
															<ButtonLoader />
														) : this.isFirstTimeLoan() ? (
															GeneralTexts["next"]
														) : (
															GeneralTexts["save"]
														)
													}
													disabled={this.validateFormInputs() ? true : false}
													onClick={this.handleSubmit}
												/>
												&nbsp;&nbsp;&nbsp;
												{this.isFirstTimeLoan() ? (
													<CustomButton
														type="button"
														label={GeneralTexts["previous"]}
														disabled={false}
														// onClick={this.props.history.goBack}
														onClick={this.goToBorrowerAdvancedProfile}
													/>
												) : (
													""
												)}
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>

						<RequiredDataHint
							className={this.state.showPageLoader ? "x-d-n" : ""}
						/>
					</div>

					<MessageModal
						show={this.state.messageModal.show || false}
						title={this.state.messageModal.title || null}
						body={this.state.messageModal.body || null}
						alertClassName={this.state.messageModal.alertClassName || null}
						size={this.state.messageModal.size || null}
						centered={this.state.messageModal.centered || false}
						footerButtons={this.state.messageModal.footerButtons || []}
						onHide={
							this.state.messageModal.onHide ||
							(() =>
								this.setState({
									messageModal: { ...this.initialState.messageModal },
								}))
						}
					/>
				</div>
			</React.Fragment>
		);

		return (
			<PageContent
				pageContent={pageContent}
				authenticatedUser={this.getAuthenticatedUser()}
				showPageContent={this.showPageContent()}
				urlQueryString={this.urlQueryString || null}
				windowLocation={window.location}
			/>
		);
	}
}
