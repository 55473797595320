import React from "react";
import { getDefaultUILanguage } from "./../configs/ui-language-config";
import queryString from "query-string";
import documentTitleService from "./../services/document-title-service";
import loaderImage from "./../resources/images/loader.gif";

export default class SwitchLanguage extends React.Component {

    // Get the "query string" as an object
    urlQueryString = queryString.parse(this.props.location.search);

    // -----------------------------------------------

    componentDidMount() {
        // Properly set the browser's document title
        documentTitleService.setTitle("Switch Language");

        // ----------------------

        this.switchLanguage();
        this.redirectBack();
    }

    // -----------------------------------------------

    switchLanguage = () => {
        const preferredLanguage = this.urlQueryString.lang || getDefaultUILanguage().alpha2code;
        localStorage.setItem("preferredLanguage", preferredLanguage);
    }

    // -----------------------------------------------

    redirectBack = () => {
        let url = "";

        // url += this.urlQueryString.origin || ""; // No need for the origin since we are already on the same host
        url += this.urlQueryString.pathname || "";
        url += this.urlQueryString.search ? `?${this.urlQueryString.search}` : "";
        url += this.urlQueryString.hash ? `#${this.urlQueryString.hash}` : "";

        // "window.location" will cause a full reload of the application on the specified
        // page url. This is important so that the translated textes work properly.
        window.location = decodeURIComponent(url);
    }

    // -----------------------------------------------

    render() {
        return (
            <div className="x-pt-70 x-ta-c">
                <p className="__ff-bootstrap x-fz-1rem x-dr-ltr">Switching Language...</p>
                <img
                    alt=""
                    src={loaderImage}
                    className="x-pt-10"
                />
            </div>
        );
    }
}
