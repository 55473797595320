// import React from "react";

// Third-Party Modules
import moment from "moment";
import _toLower from "lodash/toLower";
import Joi from "@hapi/joi";

import PageHandler from "./PageHandler";

export default class FormHandler extends PageHandler {

    // state = {
    //     formInputs      : {},
    //     validationErrors: {},
    // };

    // -----------------------------------------------

    validationRules = {};

    // -----------------------------------------------

    // More info about validation options of Joi package: t.ly/BFAk
    validationOptions = {
        // 'abortEarly: false' means we want all validation errors to be
        // returned instead of stopping validation on the first error
        abortEarly: false,
    };

    // -----------------------------------------------

    handleSubmit = (event) => {
        // Prevent page reload when press the 'Submit' button
        event.preventDefault();

        // ----------------------

        // Validate the entered data
        const validationErrors = this.validateFormInputs();

        // Update the 'state.validationErrors' object with the validation errors
        this.setState({ validationErrors: validationErrors || {} });

        // TEST
        console.log("validationErrors: ", validationErrors);

        // If there is any errors, don't continue
        if (validationErrors) return;

        // ----------------------

        this.doSubmit(event);
    };

    // -----------------------------------------------

    doSubmit = (event) => { };

    // -----------------------------------------------

    handleChange = (event) => {
		/**
		 * Here we will update the state for each input field
		 * in the form when its value getting changed
		 */

        const input = event.currentTarget;

        // Clone the 'state.formInputs' object
        const formInputs = { ...this.state?.formInputs };

        // Overwrite the value of the input field that has been changed
        formInputs[input.name] = input.value;

        // Update the 'state.formInputs' object with the new value
        this.setState({ formInputs: formInputs });

        // TEST
        // console.log(input.name + ": " + input.value);
    };

    // -----------------------------------------------

    setFormData = (event) => {
        const input = event.currentTarget;

        // Using FormData() is important when uploading a file, otherwise an error will occur
        const formData = new FormData();
        formData.append(input.name, input.files[0]);

        return formData;

        // TEST
        // console.log("File Input: ", input.name);
        // console.log("Name: ", input.name.name);
        // console.log("Type: ", input.name.type);
        // console.log("Size: ", (input.name.size / 1024).toFixed(0), "KB");
    };

    // -----------------------------------------------

    handleDateTimePickerChange = (selectedDate, inputName) => {
        const formattedDate = moment(selectedDate).format("YYYY-MM-DD HH:mm:ss");
        const date = _toLower(formattedDate) === "invalid date" ? "" : formattedDate;

        // Clone the 'state.formInputs' object
        const newFormInputs = { ...this.state?.formInputs };

        // Overwrite the value of the input field that has been changed
        newFormInputs[inputName] = date;

        this.setState({ formInputs: newFormInputs });

        // TEST
        // console.log("selectedDate: ", selectedDate);
        // console.log("formattedDate: ", date);
    }

    // -----------------------------------------------

    handleYearPickerChange = (selectedDate, inputName) => {
        const formattedYear = moment(selectedDate).format("YYYY");
        const year = _toLower(formattedYear) === "invalid date" ? "" : formattedYear;

        // Clone the 'state.formInputs' object
        const newFormInputs = { ...this.state?.formInputs };

        // Overwrite the value of the input field that has been changed
        newFormInputs[inputName] = year;

        this.setState({ formInputs: newFormInputs });

        // TEST
        console.log("selectedYear: ", selectedDate);
        console.log("formattedYear: ", year);
    }

    // -----------------------------------------------

    handleBlur = (event) => {
        const input = event.currentTarget;

        // Validate the input value
        const inputValidationError = this.validateFormInput(input);

        const newValidationErrors = inputValidationError ? { ...this.state?.validationErrors, ...inputValidationError } : { ...this.state?.validationErrors };

        // Update the 'state.validationErrors' object with the validation errors
        this.setState({ validationErrors: newValidationErrors || {} });

        // TEST
        // console.log({ [input.name]: input.value });

        // If there is any errors, don't continue
        if (inputValidationError) return;
    };

    // -----------------------------------------------

    handleFocus = (event) => {
        const input = event.currentTarget;

        // Reset value of the error message of the current input field, so the the current
        // error message (if exists) disappear till the user leaves the input field again
        this.setState({
            validationErrors: { ...this.state?.validationErrors, [input.name]: "" },
        });
    };

    // -----------------------------------------------

    handleKeyPress = (event) => {
        // console.log("event: ", event);
        // console.log("event.key: ", event.key);

        if (String(event.key) === "Enter") {
            this.handleSubmit(event);
        }
    }

    // -----------------------------------------------

    validateFormInputs = () => {
		/**
		 * Validating a form using Joi package
		 * More info about Joi package, see: t.ly/RG6n and t.ly/0ElW
		 */

        const validationSchema = Joi.object().keys(this.validationRules);

        // validate the request data against the validation schema
        const validationResult = validationSchema.validate(
            this.state?.formInputs,
            this.validationOptions
        );

        // ----------------------

        // If the validationResult object doesn't has an element called "error",
        // then there is no validation errors and we should return null
        if (!validationResult.error) return null;

        // ----------------------

        const validationErrors = {};

        validationResult.error.details.map((error) => {
            // 'return' here is useless but we use it to prevent
            // a warning message from being displayed in the browser console
            return validationErrors[error.path[0]] = error.message;
        });

        return validationErrors;
    };

    // -----------------------------------------------

    validateFormInput = (input) => {
		/**
		 * Validating a form using Joi package
		 * More info about Joi package: More info: t.ly/RG6n
		 */

        // If there is no validation rules for the current input field, return null
        if (!this.validationRules[input.name]) return null;

        // ----------------------

        // Extract the validation rules of the current input field only
        const inputValidationRules = {
            [input.name]: this.validationRules[input.name],
        };

        // ----------------------

        // define the validation schema. More info, see: t.ly/99ZI
        const validationSchema = Joi.object().keys(inputValidationRules);

        // Overwrite the validation options if necessary
        const validationOptions = { ...this.validationOptions, abortEarly: true };

        // Set the data of the current input field that we want to validate
        const inputData = { [input.name]: input.value };

        const validationResult = validationSchema.validate(
            inputData,
            validationOptions
        );

        // ----------------------

        // If the validationResult object doesn't has an element called "error",
        // then there is no validation errors and we should return null
        if (!validationResult.error) return null;

        // ----------------------

        const validationError = {
            [input.name]: validationResult.error.details[0].message,
        };

        return validationError;
    };
}
