import { preferredLanguage } from "../configs/ui-language-config";
// import { RouteConfigTexts } from "./RouteConfigTexts";

const texts = {
    ar: {
        "borrower-document-show": "عرض مستند",
        "reload-file"           : "إعادة عرض الملف",
        "reload-file-hint"      : "إذا لم يظهر لك الملف، أو لم يبدأ في النزول على جهازك، فاضغط على الزر التالي..",
    },

    en: {
        "borrower-document-show": "Show a Document",
        "reload-file"           : "Reload File",
        "reload-file-hint"      : "If the file didn't show up or didn't begin to download to your computer, click the next button..",
    }
}

export const BorrowerDocumentShowTexts = texts[preferredLanguage.alpha2code];
