import { preferredLanguage } from "./../configs/ui-language-config";
// import { RouteConfigTexts } from "./RouteConfigTexts";

const texts = {
    ar: {
        "my-basic-profile": "بياناتي الأساسية",
        "first-name"      : "الاسم الأول",
        "last-name"       : "الاسم الأخير",
        "country"         : "الدولة",
        "mobile-number"   : "رقم الموبايل",
        "email-address"   : "البريد الإلكتروني",
        "gender"          : "الجنس",
    },

    en: {
        "my-basic-profile": "My Basic Profile",
        "first-name"      : "First Name",
        "last-name"       : "Last Name",
        "country"         : "Country",
        "mobile-number"   : "Mobile Number",
        "email-address"   : "Email Address",
        "gender"          : "Gender",
    }
}

export const BasicProfileTexts = texts[preferredLanguage.alpha2code];
