import React from "react";

import { switchClasses } from "./../../../languages/CssClasses";
import { ChangePasswordTexts as texts } from "./../../../languages/ChangePasswordTexts";
import { GeneralTexts } from "./../../../languages/GeneralTexts";

// Third-Party Modules
import Joi from "@hapi/joi";

// Our Modules
import MessageModal, { toggleMessageModal, getErrorMessage } from "./../../shared/MessageModal";
import FormHandler from "./../../shared/FormHandler";
import TextBox from "./../../shared/TextBox";
import CustomButton from "./../../shared/CustomButton";
// import PageLoader from "./../../shared/PageLoader";
import ButtonLoader from "./../../shared/ButtonLoader";
import UserProfileSubMenu from "./../../shared/UserProfileSubMenu";
import RequiredDataHint from "./../../shared/RequiredDataHint";
import PageContent from "./../../shared/PageContent";

// Our Services
import documentTitleService from "./../../../services/document-title-service";
import logService from "./../../../services/log-service";
import * as userService from "./../../../services/user-service";

// Our Configs
import { getRoutePath } from "./../../../configs/route-config";

export default class LenderChangePassword extends FormHandler {

    initialState = {
        ...this.initialStateElements,
        allowedUserType: "lender",

        formInputs: {
            current_password: "",
            new_password    : "",
        },
        validationErrors: {},
        messageModal    : {},
        showButtonLoader: false,
    };

    // -----------------------------------------------

    // Clone a separate copy of the "initialState" and assign it to the "state".
    // Note that the spread operator "..." is important to get a separate copy.
    state = { ...this.initialState };

    // -----------------------------------------------

    validationRules = {
        current_password: Joi.string()
            .required()
            .min(6)
            .label("Current Password"),

        new_password: Joi.string()
            .required()
            .min(6)
            .label("New Password"),
    };

    // -----------------------------------------------

    componentDidMount() {
        // More info: t.ly/ZVsd
        window.scrollTo(0, 0);

        // ----------------------

        // Properly set the browser's document title
        documentTitleService.setTitle(GeneralTexts["dashboard"] + " | " + texts["change-my-password"]);

        // ----------------------

        this.setAuthenticatedUser();
    }

    // -----------------------------------------------

    doSubmit = async (e) => {
        this.setState({ showButtonLoader: true });

        try {
            const response = await userService.changePassword(this.state.formInputs);

            this.setState({
                formInputs: {...this.initialState.formInputs},

                messageModal: {
                    ...toggleMessageModal("success"),
                    body: response.data.meta.message,
                    footerButtons: [
                        {
                            variant: "primary",
                            title: "Login",
                            // onClick: () => { this.props.history.replace(getRoutePath("login")) }
                            onClick: (() => window.location = getRoutePath("login"))
                        }
                    ],
                    onHide: (() => window.location = getRoutePath("login")),
                }
            });

        } catch (error) {
            /**
             * If there is an unexpected error like: network down, server down, db down, bug, ..
             *  - Log the error.
             *  - Display a generic and friendly error message.
             *
             * Note that "error.response" determines if the error is an expected error or not.
             */

            const isExpectedError =
                error.response
                && error.response.status >= 400
                && error.response.status < 500;

            if (!isExpectedError) {
                logService.log(error);
            }

            this.showErrorMessage(error);
        }

        this.setState({ showButtonLoader: false });
    };

    // -----------------------------------------------

    showErrorMessage = (error, props = this.props) => {
        this.setState({
            messageModal: {
                ...toggleMessageModal("error"),
                body: getErrorMessage(error, props).body || "",
                footerButtons: getErrorMessage(error, props).footerButtons || [],
            }
        });
    }

    // -----------------------------------------------

    render() {
        const pageContent = (
            <React.Fragment>
                <div className="x-m-25 x-mt-50">
                    <h5 className={"head-color " + switchClasses(["__ff-droidarabickufi"])}>{texts["change-my-password"]}</h5>
                </div>

                <div className="_border _border-light shadow-sm x-m-25 x-mt-0 x-mb-70 x-p-10 x-pb-100 x-mih-500 x-bgc-fff x-bdc-e3e3e3 x-bdrs-4">
                    <div className="container-fluid">
                        <div className="row x-pt-20">
                            <div className="col-md-3">
                                <UserProfileSubMenu userType={this.props.authenticatedUser?.user_type || null} />
                            </div>
                            <div className="col-md-9">
                                {/* <PageLoader show={this.state.showPageLoader} /> */}

                                <div className={(this.state.showPageLoader ? "x-d-n" : "")}>
                                    <form className={"__ff-droidarabickufi " + switchClasses(["x-fz-085rem"])} onSubmit={(e) => e.preventDefault()}>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <TextBox
                                                    type="password"
                                                    name="current_password"
                                                    label={texts["current-password"]}
                                                    value={this.state.formInputs?.current_password || ""}
                                                    onBlur={this.handleBlur}
                                                    onChange={this.handleChange}
                                                    onFocus={this.handleFocus}
                                                    validationError={this.state.validationErrors?.current_password || ""}
                                                    extraCssClasses="x-dr-ltr x-ta-l"
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-4">
                                                <TextBox
                                                    type="password"
                                                    name="new_password"
                                                    label={texts["new-password"]}
                                                    value={this.state.formInputs?.new_password || ""}
                                                    onBlur={this.handleBlur}
                                                    onChange={this.handleChange}
                                                    onFocus={this.handleFocus}
                                                    validationError={this.state.validationErrors?.new_password || ""}
                                                    extraCssClasses="x-dr-ltr x-ta-l"
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="x-mt-20">
                                                    <CustomButton
                                                        type="button"
                                                        label={this.state.showButtonLoader ? <ButtonLoader /> : GeneralTexts["save"]}
                                                        disabled={this.validateFormInputs() ? true : false}
                                                        onClick={this.handleSubmit}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <RequiredDataHint />
                    </div>

                    <MessageModal
                        show={this.state.messageModal?.show || false}
                        title={this.state.messageModal?.title || null}
                        body={this.state.messageModal?.body || null}
                        alertClassName={this.state.messageModal?.alertClassName || null}
                        size={this.state.messageModal?.size || null}
                        centered={this.state.messageModal?.centered || false}
                        footerButtons={this.state.messageModal?.footerButtons || []}
                        onHide={this.state.messageModal?.onHide || (() => this.setState({ messageModal: { ...this.initialState.messageModal } }))}
                    />
                </div>
            </React.Fragment>
        );

        return (
            <PageContent
                pageContent={pageContent}
                authenticatedUser={this.getAuthenticatedUser()}
                showPageContent={this.showPageContent()}
                windowLocation={window.location}
            />
        );
    }
}
