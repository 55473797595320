import { preferredLanguage } from "./../configs/ui-language-config";
// import { GeneralTexts } from "./GeneralTexts";
// import { RouteConfigTexts } from "./RouteConfigTexts";

const texts = {
    ar: {
        "general"  : "احصائيات عامة",
        "users"    : "المستخدمين",
        "loans"    : "القروض",
        "borrowers": "المقترضين",
        "investors": "المستثمرين",
    },

    en: {
        "general"  : "General",
        "users"    : "Users",
        "loans"    : "Loans",
        "borrowers": "Borrowers",
        "investors": "Investors",
    }
}

export const AdminOverviewSubMenuTexts = texts[preferredLanguage.alpha2code];
