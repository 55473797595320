import React from "react";

// Third-Party Modules
import _isEmpty from "lodash/isEmpty";

import { BorrowerDocumentShowTexts as texts } from "./../../../languages/BorrowerDocumentShowTexts";
import { GeneralTexts } from "./../../../languages/GeneralTexts";
import { switchClasses } from "./../../../languages/CssClasses";

// Our Services
import documentTitleService from "./../../../services/document-title-service";
import * as documentService from "./../../../services/document-service";
import PageContent from "../../shared/PageContent";
import PageHandler from "../../shared/PageHandler";

import CustomButton from "../../shared/CustomButton";

export default class BorrowerDocumentShow extends PageHandler {

    initialState = {
        ...this.initialStateElements,
        allowedUserType: "borrower",

        showFileReloadButton: false,
    };

    // -----------------------------------------------

    // Clone a separate copy of the "initialState" and assign it to the "state".
    // Note that the spread operator "..." is important to get a separate copy.
    state = { ...this.initialState };

    // -----------------------------------------------

    componentDidMount() {
        // More info: t.ly/ZVsd
        window.scrollTo(0, 0);

        // ----------------------

        // Properly set the browser's document title
        documentTitleService.setTitle(GeneralTexts["borrower-dashboard"] + " | " + texts["borrower-document-show"]);

        // ----------------------

        this.setAuthenticatedUser();
        this.getDocument();
    }

    // -----------------------------------------------

    getDocument = async () => {
        try {
            const response = await documentService.getItem(this.props.match.params.documentUuid);

            // TEST
            console.log("getDocument response: ", response.data);

            const documentFullUrl = response.data;

            if (_isEmpty(documentFullUrl)) {
                this.setState({ showFileReloadButton: true });
                return;
            }

            window.location = documentFullUrl;
        } catch (error) {
            this.setState({ showFileReloadButton: true });
        }
    }

    // -----------------------------------------------

    reloadThePage = () => {
        window.location.reload();
    }

    // -----------------------------------------------

	render() {
        const pageContent = (
            <React.Fragment>
                <div className={"__ff-droidarabickufi x-pt-100 x-ta-c " + (this.state.showFileReloadButton ? "" : " x-d-n")}>
                    <p className={"x-c-crimson x-pb-10 " + switchClasses(["x-fz-082rem"])}>
                        {texts["reload-file-hint"]}
                    </p>
                    <CustomButton
                        type="button"
                        label={texts["reload-file"]}
                        disabled={false}
                        onClick={this.reloadThePage}
                    />
                </div>
            </React.Fragment>
        );

		return (
            <PageContent
                pageContent={pageContent}
                authenticatedUser={this.getAuthenticatedUser()}
                showPageContent={this.showPageContent()}
                windowLocation={window.location}
                hideHeaderNavbar={true}
                hideFooter={true}
                hideWhatsAppIcon={true}
            />
        );
	}
}
