import React from "react";

import _toLower from "lodash/toLower";

import BorrowerProfileSubMenu from "./../dashboard/borrower/BorrowerProfileSubMenu";
import LenderProfileSubMenu from "./../dashboard/lender/LenderProfileSubMenu";
import AdminProfileSubMenu from "./../dashboard/admin/AdminProfileSubMenu";

export default class UserProfileSubMenu extends React.Component {

    getUserProfileSubMenu = (userType) => {
        switch (_toLower(userType)) {
            case "borrower":
                return <BorrowerProfileSubMenu />

            case "lender":
                return <LenderProfileSubMenu />

            case "admin":
                return <AdminProfileSubMenu />

            default:
                return null;
        }
    }

    render() {
        return this.getUserProfileSubMenu(this.props?.userType || null);
    }
}
