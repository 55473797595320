import { preferredLanguage } from "./../configs/ui-language-config";

const texts = {
    ar: {
        "enter-your-email"    : "أدخل إيميلك",
        "email-address"       : "الإيميل",
        "already-have-account": "إذا كان لديك حساب بالفعل،",
        "login-from-here"     : "ادخل من هنا",
    },
    
    en: {
        "enter-your-email"    : "Enter Your Email",
        "email-address"       : "Email Address",
        "already-have-account": "If you already have an account,",
        "login-from-here"     : "Login from here",
    }}

export const PreSignupTexts = texts[preferredLanguage.alpha2code];
