import { preferredLanguage } from "./../configs/ui-language-config";

const texts = {
    ar: {
        "password-reset-request": "طلب إعادة تعيين كلمة السر",
        "your-email-address"    : "بريدك الإلكتروني",
        "if-remember-password"  : "إذا كنت تعرف كلمة سر حسابك",
        "login-from-here"       : "ادخل من هنا",
    },

    en: {
        "password-reset-request": "Password Reset Request",
        "your-email-address"    : "Your Email",
        "if-remember-password"  : "If you remember your password,",
        "login-from-here"       : "Login from here",
    }}

export const PasswordForgotTexts = texts[preferredLanguage.alpha2code];
