import React from "react";

import { switchClasses } from "./../../../languages/CssClasses";
import { LenderLoanDetailsTexts as texts } from "./../../../languages/LenderLoanDetailsTexts";
import { GeneralTexts } from "./../../../languages/GeneralTexts";

// Third-Party Modules
import Joi from "@hapi/joi";
import numeral from "numeral"
import _startCase from "lodash/startCase"
import _toLower from "lodash/toLower"
import _toUpper from "lodash/toUpper"
import _isEmpty from "lodash/isEmpty"

// Our Modules
import MessageModal, { toggleMessageModal, getErrorMessage } from "./../../shared/MessageModal";
import FormHandler from "./../../shared/FormHandler";
import PageLoader from "./../../shared/PageLoader";
import ButtonLoader from "./../../shared/ButtonLoader";
import CustomButton from "./../../shared/CustomButton";
import TextBox from "./../../shared/TextBox";
import PageContent from "./../../shared/PageContent";

// Our Services
import documentTitleService from "./../../../services/document-title-service";
import logService from "./../../../services/log-service";
import * as loanService from "./../../../services/loan-service";
import * as lenderService from "./../../../services/lender-service";

// Our Configs
import { getRoutePath } from "./../../../configs/route-config";
import { getMaxAmountLenderCanLend, getMinAmountLenderCanLend } from "../../../configs/loan-config";
// import { getEmail, getWhatsapp } from "./../../../configs/general-config";
// import { getCountry } from "./../../../configs/country-config";

export default class LenderLoanDetails extends FormHandler {

    initialState = {
        ...this.initialStateElements,
        allowedUserType: "lender",

        formInputs: {
            amount: "",
        },
        validationErrors: {},
        messageModal    : {},
        showPageLoader  : true,
        showButtonLoader: false,
        loan            : {},
        loanIndicator   : {},
        company         : {},
        // user            : {},
        // borrower        : {},
        showInvestForm  : false,
    };

    // -----------------------------------------------

    // Clone a separate copy of the "initialState" and assign it to the "state".
    // Note that the spread operator "..." is important to get a separate copy.
    state = { ...this.initialState };

    // -----------------------------------------------

    validationRules = {
        // Note that we override this rules in the getLoan function
        // so we make the max() rule equals the required_amount
        amount: Joi.number()
            .required()
            .integer()
            .positive()
            .min(getMinAmountLenderCanLend())
            .max(getMaxAmountLenderCanLend()) // Note that this value is being replaced in the getLoan() method below
            .label("Amount to Invest"),
    };

    // -----------------------------------------------

    componentDidMount() {
        // More info: t.ly/ZVsd
        window.scrollTo(0, 0);

        // ----------------------

        // Properly set the browser's document title
        documentTitleService.setTitle(GeneralTexts["lender-dashboard"] + " | " + texts["lender-loan-details"]);

        // ----------------------

        this.setAuthenticatedUser();
        this.getLoan();
    }

    // -----------------------------------------------

    getLoan = async () => {
        this.setState({ showPageLoader: true });

        // TODO: Do we need data of the user, borrower, and company in this page?
        // const response = await loanService.getItem(this.props.match.params.loanUuid, "?include=borrower.user|company");
        const response = await loanService.getItem(this.props.match.params.loanUuid, "?include=company");

        const loan = response.data.data.loan || {};

        this.setState({
            formInputs    : {},
            showPageLoader: false,
            loan          : loan,
            loanIndicator : loan.indicator || {},
            company       : loan.company || {},

            // TODO: Do we need data of the user and borrower in this page?
            // user          : loan.borrower.user,
            // borrower      : loan.borrower,
        });

        this.validationRules = {
            ...this.validationRules,
            amount: Joi.number()
                .required()
                .integer()
                .positive()
                .min(getMinAmountLenderCanLend())
                .max(getMaxAmountLenderCanLend(loan.required_amount))
                .label("Amount to Invest"),
        };

        // this.validationRules.amount =
        //     Joi.number()
        //     .required()
        //     .integer()
        //     .positive()
        //     .min(5000)
        //     .max(loan.required_amount)
        //     .label("Amount to Invest");
    }

    // -----------------------------------------------

    doSubmit = async (e) => {
        this.setState({ showButtonLoader: true });

        try {
            const response = await lenderService.loanCreate({ ...this.state.formInputs, loan_uuid: this.state.loan.uuid });

            this.setState({
                messageModal: {
                    ...toggleMessageModal("success"),
                    body: response.data.meta.message,
                    footerButtons: [
                        {
                            variant: "primary",
                            title: "Loans",
                            onClick: () => this.props.history.push(getRoutePath("lenderLoanList")),
                        },
                        // {
                        //     variant: "primary",
                        //     title: "Close",
                        //     onClick: () => this.setState({ messageModal: { ...this.initialState.messageModal } }),
                        // }
                    ],
                    // onHide: () => this.props.history.push(getRoutePath("lenderLoanList")),
                }
            });

        } catch (error) {
            /**
             * If there is an unexpected error like: network down, server down, db down, bug, ..
             *  - Log the error.
             *  - Display a generic and friendly error message.
             *
             * Note that "error.response" determines if the error is an expected error or not.
             */

            const isExpectedError =
                error.response
                && error.response.status >= 400
                && error.response.status < 500;

            if (!isExpectedError) {
                logService.log(error);
            }

            this.showErrorMessage(error);
        }

        this.setState({ showButtonLoader: false });
    };

    // -----------------------------------------------

    showErrorMessage = (error, props = this.props) => {
        this.setState({
            messageModal: {
                ...toggleMessageModal("error"),
                body: getErrorMessage(error, props).body || "",
                footerButtons: getErrorMessage(error, props).footerButtons || [],
            }
        });
    }

    // -----------------------------------------------

    handleShowInvestForm = () => {
        this.setState({showInvestForm: true});
    }

    // -----------------------------------------------

    render() {
        const loanCurrency              = this.state.loan.currency;
        const requiredAmount            = this.state.loan.required_amount;
        const pledgedAmount             = this.state.loan.pledged_amount;
        const remainingAmount           = requiredAmount - pledgedAmount;
        const pledgedAmountPercentage   = ((pledgedAmount / requiredAmount) * 100).toFixed(0);
        const remainingAmountPercentage = (((requiredAmount - pledgedAmount) / requiredAmount) * 100).toFixed(0);

        const pageContent = (
            <React.Fragment>
                <div className="x-m-25 x-mt-50">
                    <h5 className={"x-ta-c head-color " + switchClasses(["__ff-droidarabickufi"])}>{texts["lender-loan-details"]}</h5>
                </div>

                <div className="container-fluid">
                    <div className="row x-mb-100">
                        <div className="col-md-2 col-lg-3"></div>
                        <div className={"col-md-8 col-lg-6 __ff-droidarabickufi " + switchClasses(["x-fz-085rem"])}>
                            <div className="x-p-30 x-mt-30 x-bgc-fff shadow-md x-mih-200">
                                <PageLoader show={this.state.showPageLoader} />

                                <ul className={"list-unstyled x-pr-0 x-pl-0" + (this.state.showPageLoader ? " x-d-n" : "")}>
                                    <li className={switchClasses(["__ff-droidarabickufi"])}>
                                        <div className="x-pt-10 x-pb-15">
                                            <p className={"text-center x-fw-n x-m-0 x-c-999 " + switchClasses(["x-fz-085rem"])}>{texts["required-amount"]}</p>
                                            <h3 className="x-ta-c x-fz-25rem x-m-0 x-dr-ltr __ff-rubik">{numeral(requiredAmount).format("0,0") || 0}<span className="x-fz-1rem">{this.state.loan.currency}</span></h3>
                                        </div>
                                        <hr />

                                        <div className="row x-pt-10">
                                            <div className={"col " + switchClasses(["x-ta-r"])}>
                                                <p className={"x-fw-n x-m-0 x-c-999 " + switchClasses(["x-fz-085rem"])}>{texts["pledged"]}</p>
                                                <p className="x-fz-1rem x-fw-n x-m-0 __ff-rubik">{pledgedAmountPercentage}%</p>
                                                <h3 className="x-fz-15rem x-m-0 x-dr-ltr text-success __ff-rubik">{numeral(pledgedAmount).format("0,0") || 0}<span className="x-fw-n x-fz-08rem x-c-999">{this.state.loan.currency}</span></h3>
                                            </div>
                                            <div className={"col " + switchClasses(["x-ta-l"])}>
                                                <p className={"x-fw-n x-m-0 x-c-999 " + switchClasses(["x-fz-085rem"])}>{texts["remaining"]}</p>
                                                <p className="x-fz-1rem x-fw-n x-m-0 __ff-rubik">{remainingAmountPercentage}%</p>
                                                <h3 className="x-fz-15rem x-m-0 x-dr-ltr text-danger __ff-rubik">{numeral(remainingAmount).format("0,0") || 0}<span className="x-fw-n x-fz-08rem x-c-999">{this.state.loan.currency}</span></h3>
                                            </div>
                                        </div>
                                        <div className="x-pt-5 x-pb-20">
                                            <div className="progress x-mt-5 x-bdrs-10" style={{ height: "1rem" }}>
                                                <div className="progress-bar" role="progressbar" style={{ width: pledgedAmountPercentage + "%", "backgroundColor": "#00CC6F" }} aria-valuenow={pledgedAmountPercentage} aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                        <hr />

                                        <div className="x-pt-15 x-ta-c">
                                            <p className={"x-fw-n x-m-0 x-c-999 " + switchClasses(["x-fz-085rem"])}>{texts["committed-investors"]}</p>
                                            <h3 className="x-fz-17rem x-m-0 x-dr-ltr x-pt-10 __ff-rubik">{this.state.loan.number_of_committed_lenders || 0}</h3>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div className="x-mt-60">
                                <h5 className={"head-color x-ta-c " + switchClasses(["__ff-droidarabickufi"])}>{texts["transaction-info"]}</h5>

                                <div className="x-p-30 x-mt-15 x-bgc-fff shadow-md x-mih-200">
                                    <PageLoader show={this.state.showPageLoader} />

                                    <ul className={"list-unstyled x-pr-0 x-pl-0" + (this.state.showPageLoader ? " x-d-n" : "")}>
                                        <li className="clearfix">
                                            <span className="x-c-999">{texts["transaction-type"]}</span>
                                            <span className={"_x-fw-b " + switchClasses(["x-fl-l"])}>{this.state.loan.loan_purpose}</span>
                                        </li>
                                        <hr />
                                        <li className="clearfix">
                                            <span className="x-c-999">{texts["transaction-duration"]}</span>
                                            <span className={"_x-fw-b" + switchClasses(["x-fl-l"])}>{this.state.loan.loan_duration_in_months}<span className="x-fw-n x-c-999"> {texts["month"]}</span></span>
                                        </li>
                                        <hr />
                                        <li className="clearfix">
                                            <span className="x-c-999">{texts["transaction-gross-profit"]}</span>
                                            <span className={"_x-fw-b x-dr-ltr " + switchClasses(["x-fl-l"])}>{this.state.loan.percentage_of_the_transaction_gross_profit}<span className="x-c-999 x-fw-n"> %</span></span>
                                        </li>
                                        <hr />
                                        <li className="clearfix">
                                            <span className="x-c-999">{texts["borrower-has-experience-in-the-transaction"]}</span>
                                            <span className={"_x-fw-b " + switchClasses(["x-fl-l"])}>{_startCase(_toLower(this.state.loan.has_previous_experience_in_the_transaction))}</span>
                                        </li>
                                        <hr />
                                        <li className="clearfix">
                                            <p className="x-c-999 x-mb-10">{texts["transaction-description"]}</p>
                                            <span className="">{this.state.loan.transaction_public_description}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className={"x-mt-60" + (_isEmpty(this.state.company) ? " x-d-n" : "")}>
                                <h5 className={"head-color x-ta-c " + switchClasses(["__ff-droidarabickufi"])}>{texts["company-info"]}</h5>

                                <div className="x-p-30 x-mt-15 x-bgc-fff shadow-md x-mih-200">
                                    <PageLoader show={this.state.showPageLoader} />

                                    <ul className={"list-unstyled x-pr-0 x-pl-0" + (this.state.showPageLoader ? " x-d-n" : "")}>
                                        <li className="clearfix">
                                            <span className="x-c-999">{texts["headquarter-city"]}</span>
                                            {/* <span className="x-fl-r _x-fw-b">{_startCase(_toLower(this.state.company.city))}, {_startCase((getCountry(this.state.company.country)).name)}</span> */}
                                            <span className={"_x-fw-b " + switchClasses(["x-fl-l"])}>{_startCase(_toLower(this.state.company.city))}, {_toUpper(this.state.company.country)}</span>
                                        </li>
                                        <hr />
                                        <li className="clearfix">
                                            <span className="x-c-999">{texts["company-type"]}</span>
                                            <span className={"_x-fw-b " + switchClasses(["x-fl-l"])}>{this.state.company.company_type}</span>
                                        </li>
                                        <hr />
                                        <li className="clearfix">
                                            <span className="x-c-999">{texts["activity-sector"]}</span>
                                            <span className={"_x-fw-b " + switchClasses(["x-fl-l"])}>{_startCase(_toLower(this.state.company.activity_sector))} {this.state.company.activity_subsector ? "- " + _startCase(_toLower(this.state.company.activity_subsector)) : ""}</span>
                                        </li>
                                        <hr />
                                        <li className="clearfix">
                                            <span className="x-c-999">{texts["founded-in"]}</span>
                                            <span className={"_x-fw-b x-dr-ltr " + switchClasses(["x-fl-l"])}>{this.state.company.founded_in}</span>
                                        </li>
                                        <hr />
                                        <li className="clearfix">
                                            <span className="x-c-999">{texts["operating-license-status"]}</span>
                                            <span className={"_x-fw-b " + switchClasses(["x-fl-l"])}>
                                                {_startCase(_toLower(this.state.loanIndicator.company_operating_license_status)) || <span className="x-fw-n x-c-999">---</span>}
                                            </span>
                                        </li>
                                        <hr />
                                        <li className="clearfix">
                                            <span className="x-c-999">{texts["number-of-employees"]}</span>
                                            <span className={"_x-fw-b " + switchClasses(["x-fl-l"])}>
                                                {this.state.loanIndicator.company_number_of_employees || ""}
                                                &nbsp;
                                                <span className="x-fw-n x-c-999">
                                                    {
                                                        this.state.loanIndicator.company_number_of_employees
                                                            ? texts["employee"]
                                                            : "---"
                                                    }
                                                </span>
                                            </span>
                                        </li>
                                        <hr />
                                        <li className="clearfix">
                                            <span className="x-c-999">{texts["capital-size"]}</span>
                                            <span className={"_x-fw-b " + switchClasses(["x-fl-l"])}>
                                                {
                                                    this.state.loanIndicator.company_capital_size
                                                        ? numeral(this.state.loanIndicator.company_capital_size).format("0,0")
                                                        : ""
                                                }
                                                &nbsp;
                                                <span className="x-fw-n x-c-999">
                                                    {
                                                        this.state.loanIndicator.company_capital_size
                                                            ? this.state.loanIndicator.currency
                                                            : "---"
                                                    }
                                                </span>
                                            </span>
                                        </li>
                                        <hr />
                                        <li className="clearfix">
                                            <span className="x-c-999">{texts["average-monthly-sales"]}</span>
                                            <span className={"_x-fw-b " + switchClasses(["x-fl-l"])}>
                                                {
                                                    this.state.loanIndicator.company_average_monthly_sales
                                                        ? numeral(this.state.loanIndicator.company_average_monthly_sales).format("0,0")
                                                        : ""
                                                }
                                                &nbsp;
                                                <span className="x-fw-n x-c-999">
                                                    {
                                                        this.state.loanIndicator.company_average_monthly_sales
                                                            ? this.state.loanIndicator.currency
                                                            : "---"
                                                    }
                                                </span>
                                            </span>
                                        </li>
                                        <hr />
                                        <li className="clearfix">
                                            <span className="x-c-999">{texts["last-year-revenue"]}</span>
                                            <span className={"_x-fw-b " + switchClasses(["x-fl-l"])}>
                                                {
                                                    this.state.loanIndicator.company_last_year_revenue
                                                        ? numeral(this.state.loanIndicator.company_last_year_revenue).format("0,0")
                                                        : ""
                                                }
                                                &nbsp;
                                                <span className="x-fw-n x-c-999">
                                                    {
                                                        this.state.loanIndicator.company_last_year_revenue
                                                            ? this.state.loanIndicator.currency
                                                            : "---"
                                                    }
                                                </span>
                                            </span>
                                        </li>
                                        <hr />
                                        <li className="clearfix">
                                            <span className="x-c-999">{texts["has-bank-account"]}</span>
                                            <span className={"_x-fw-b " + switchClasses(["x-fl-l"])}>
                                                {_startCase(_toLower(this.state.loanIndicator.company_has_bank_account)) || <span className="x-fw-n x-c-999">---</span>}
                                            </span>
                                        </li>
                                        <hr />
                                        <li className="clearfix">
                                            <span className="x-c-999">{texts["has-financial-statements"]}</span>
                                            <span className={"_x-fw-b " + switchClasses(["x-fl-l"])}>
                                                {_startCase(_toLower(this.state.loanIndicator.company_has_financial_statements)) || <span className="x-fw-n x-c-999">---</span>}
                                            </span>
                                        </li>
                                        <hr />
                                        <li className="clearfix">
                                            <span className="x-c-999">{texts["has-tax-related-issues"]}</span>
                                            <span className={"_x-fw-b " + switchClasses(["x-fl-l"])}>
                                                {_startCase(_toLower(this.state.loanIndicator.company_has_tax_related_issues)) || <span className="x-fw-n x-c-999">---</span>}
                                            </span>
                                        </li>
                                        <hr />
                                        <li className="clearfix">
                                            <span className="x-c-999">{texts["has-social-insurance-related-issues"]}</span>
                                            <span className={"_x-fw-b " + switchClasses(["x-fl-l"])}>
                                                {_startCase(_toLower(this.state.loanIndicator.company_has_social_insurance_related_issues)) || <span className="x-fw-n x-c-999">---</span>}
                                            </span>
                                        </li>
                                        <hr />
                                        <li className="clearfix">
                                            <span className="x-c-999">{texts["has-existing-bank-loans"]}</span>
                                            <span className={"_x-fw-b " + switchClasses(["x-fl-l"])}>
                                                {_startCase(_toLower(this.state.loanIndicator.company_has_existing_bank_loans)) || <span className="x-fw-n x-c-999">---</span>}
                                            </span>
                                        </li>
                                        <hr />
                                        <li className="clearfix">
                                            <span className="x-c-999">{texts["has-defaulted-loans"]}</span>
                                            <span className={"_x-fw-b " + switchClasses(["x-fl-l"])}>
                                                {_startCase(_toLower(this.state.loanIndicator.company_has_defaulted_loans)) || <span className="x-fw-n x-c-999">---</span>}
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="x-mt-60 x-d-n">
                                <h5 className={"head-color x-ta-c " + switchClasses(["__ff-droidarabickufi"])}>{texts["loan-risk-profiling"]}</h5>

                                <div className="x-p-30 x-mt-15 x-bgc-fff shadow-md" style={{ minHeight: "200px" }}>
                                    <PageLoader show={this.state.showPageLoader} />

                                    <ul className={"list-unstyled x-pr-0 x-pl-0" + (this.state.showPageLoader ? " x-d-n" : "")}>
                                        <li className="clearfix">
                                            <span className="x-c-999">xxxx xxxx</span>
                                            <span className={"_x-fw-b " + switchClasses(["x-fl-l"])}>xxxxx</span>
                                        </li>
                                        <hr />
                                        <li className="clearfix">
                                            <span className="x-c-999">xxxx xxxx</span>
                                            <span className={"_x-fw-b " + switchClasses(["x-fl-l"])}>xxxxx</span>
                                        </li>
                                        <hr />
                                        <li className="clearfix">
                                            <span className="x-c-999">xxxx xxxx</span>
                                            <span className={"_x-fw-b " + switchClasses(["x-fl-l"])}>xxxxx</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="x-mt-60">
                                <div className="x-p-30 x-mt-15 x-bgc-fff shadow-md">
                                    <PageLoader show={this.state.showPageLoader} />

                                    <ul className={"list-unstyled x-pr-0 x-pl-0" + (this.state.showPageLoader ? " x-d-n" : "")}>
                                        <li className="x-ta-c">
                                            <div className={"x-ta-c x-c-555 x-lh-30 " + switchClasses(["__ff-droidarabickufi", "x-fz-085rem"])}>
                                                {texts["want-to-invest"]}
                                            </div>

                                            <div className={"x-pt-20" + (this.state.showInvestForm ? " x-d-n" : "")}>
                                                <CustomButton
                                                    type="button"
                                                    label={texts["invest-in-this-loan"]}
                                                    onClick={this.handleShowInvestForm}
                                                    disabled={false}
                                                    extraCssClasses={switchClasses(["x-fz-09rem"])}
                                                />
                                            </div>

                                            <div className={"x-mt-50" + (this.state.showInvestForm ? "" : " x-d-n")}>
                                                <form onSubmit={(e) => e.preventDefault()}>
                                                    <div className="row">
                                                        <div className="col-md-3"></div>
                                                        <div className="col-md-6">
                                                            <TextBox
                                                                name="amount"
                                                                label={texts["amount-to-invest"]}
                                                                value={this.state.formInputs.amount || ""}
                                                                onBlur={this.handleBlur}
                                                                onChange={this.handleChange}
                                                                onFocus={this.handleFocus}
                                                                validationError={this.state.validationErrors.amount || ""}
                                                                // placeholder={"Example: " + getMinAmountLenderCanLend()}
                                                                placeholder={`Min: ${loanCurrency} ${getMinAmountLenderCanLend()} / Max: ${loanCurrency} ${getMaxAmountLenderCanLend(requiredAmount)}`}
                                                                extraCssClasses="x-dr-ltr x-ta-l x-fz-085rem"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-3"></div>
                                                        <div className="col-md-6">
                                                            <div className="x-mt-10 x-ta-c">
                                                                <CustomButton
                                                                    type="button"
                                                                    label={this.state.showButtonLoader ? <ButtonLoader /> : "Submit"}
                                                                    disabled={this.validateFormInputs() ? true : false}
                                                                    onClick={this.handleSubmit}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="x-mt-50">
                                <div className={"x-c-555 x-lh-28 " + switchClasses(["__ff-droidarabickufi", "x-fz-085rem"])}>
                                    {texts["further-inquiry-hint"]}
                                </div>
                            </div>
                        </div>
                    </div>

                    <MessageModal
                        show={this.state.messageModal.show || false}
                        title={this.state.messageModal.title || null}
                        body={this.state.messageModal.body || null}
                        alertClassName={this.state.messageModal.alertClassName || null}
                        size={this.state.messageModal.size || null}
                        centered={this.state.messageModal.centered || false}
                        footerButtons={this.state.messageModal.footerButtons || []}
                        onHide={this.state.messageModal.onHide || (() => this.setState({ messageModal: { ...this.initialState.messageModal } }))}
                    />
                </div>
            </React.Fragment>
        );

        return (
            <PageContent
                pageContent={pageContent}
                authenticatedUser={this.getAuthenticatedUser()}
                showPageContent={this.showPageContent()}
                windowLocation={window.location}
            />
        );
    }
}
