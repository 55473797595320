import React from "react";

import { switchClasses } from "./../../../languages/CssClasses";
import { BasicProfileTexts as texts } from "./../../../languages/BasicProfileTexts";
import { GeneralTexts } from "./../../../languages/GeneralTexts";

// Third-Party Modules
import Joi from "@hapi/joi";

// Our Modules
import MessageModal, { toggleMessageModal, getErrorMessage } from "./../../shared/MessageModal";
import FormHandler from "./../../shared/FormHandler";
import TextBox from "./../../shared/TextBox";
import SelectBox from "./../../shared/SelectBox";
import CustomButton from "./../../shared/CustomButton";
import PageLoader from "./../../shared/PageLoader";
import ButtonLoader from "./../../shared/ButtonLoader";
import RequiredDataHint from "./../../shared/RequiredDataHint";
import UserProfileSubMenu from "./../../shared/UserProfileSubMenu";
import PageContent from "./../../shared/PageContent";

// Our Services
import documentTitleService from "./../../../services/document-title-service";
import logService from "./../../../services/log-service";
import * as userService from "./../../../services/user-service";

// Our Configs
import { getCommonCountryList } from "./../../../configs/country-config";
import { getUserGenders } from "./../../../configs/user-config";

// Our helpers
// import { getPathOfBasicProfileRoute } from "./../../../helpers/route-helper";

export default class BorrowerBasicProfile extends FormHandler {

    initialState = {
        ...this.initialStateElements,
        allowedUserType: "borrower",

        formInputs: {
            first_name          : "",
            last_name           : "",
            country_calling_code: "",
            mobile              : "",
            email               : "",
            gender              : "",
        },
        validationErrors: {},
        messageModal    : {},
        showPageLoader  : true,
        showButtonLoader: false,
    };

    // -----------------------------------------------

    // Clone a separate copy of the "initialState" and assign it to the "state".
    // Note that the spread operator "..." is important to get a separate copy.
    state = { ...this.initialState };

    // -----------------------------------------------

    async componentDidMount() {
        // More info: t.ly/ZVsd
        window.scrollTo(0, 0);

        // ----------------------

        // Properly set the browser's document title
        documentTitleService.setTitle(GeneralTexts["dashboard"] + " | " + texts["my-basic-profile"]);

        // ----------------------

        await this.setAuthenticatedUser();
        this.setStateFormInputs();
    }

    // -----------------------------------------------

    // TODO: This should be refactored
    setStateFormInputs = async (authenticatedUser = null) => {
        // await this.setAuthenticatedUser();

        const user = authenticatedUser || this.getAuthenticatedUser();

        this.setState({
            formInputs: {
                first_name          : user?.first_name,
                last_name           : user?.last_name,
                country_calling_code: user?.country_calling_code,
                mobile              : user?.mobile,
                email               : user?.email,
                gender              : user?.gender,
            },
            showPageLoader: false,
        });
    }

    // -----------------------------------------------

    doSubmit = async (e) => {
        // TEST
        // console.log("formInputs", this.state.formInputs);

        // Display a loader icon
        this.setState({ showButtonLoader: true });

        try {
            const response = await userService.update(this.state.formInputs);

            const currentUser = response.data.data.user;

            this.setState({ authenticatedUser: currentUser });

            this.setStateFormInputs(currentUser);

            this.setState({
                messageModal: {
                    ...toggleMessageModal("success"),
                    body: response.data.meta.message,
                    // This page should be reloaded when updated
                    onHide: (() => window.location.reload()),
                }
            });
        } catch (error) {
            /**
             * If there is an unexpected error like: network down, server down, db down, bug, ..
             *  - Log the error.
             *  - Display a generic and friendly error message.
             *
             * Note that "error.response" determines if the error is an expected error or not.
             */

            const isExpectedError =
                error.response
                && error.response.status >= 400
                && error.response.status < 500;

            if (!isExpectedError) {
                logService.log(error);
            }

            this.showErrorMessage(error);
        }

        this.setState({ showButtonLoader: false });
    };

    // -----------------------------------------------

    showErrorMessage = (error, props = this.props) => {
        this.setState({
            messageModal: {
                ...toggleMessageModal("error"),
                body: getErrorMessage(error, props).body || "",
                footerButtons: getErrorMessage(error, props).footerButtons || [],
            }
        });
    }

    // -----------------------------------------------

    validationRules = {
        first_name: Joi.string()
            .required()
            .min(2)
            .max(20)
            .label("First Name"),

        last_name: Joi.string()
            .required()
            .min(2)
            .max(20)
            .label("Last Name"),

        country_calling_code: Joi.string()
            .required()
            .max(5)
            .label("Country"),

        mobile: Joi.string()
            .required()
            .min(8)
            .max(11)
            // Accept only digits. The same as: .pattern(new RegExp('/^\d+$/'))
            .regex(/^\d+$/)
            .label("Mobile Number")
            // More info about custom messages: t.ly/gY6z and t.ly/F5n2
            // And this is a full list of the errors: t.ly/LUUy
            .messages({
                "string.pattern.base": `"Mobile Number" should consist of numbers only.`,
            }),

        email: Joi.string()
            .required()
            .email({ tlds: false })
            .max(50)
            .label("Email Address"),

        gender: Joi.string()
            .required()
            // .equal(...getUserGenders().map((gender) => gender.value))
            // .insensitive()
            .label("Gender"),
    };

    // -----------------------------------------------

	render() {
        const authenticatedUser = this.getAuthenticatedUser();

        const pageContent = (
            <React.Fragment>
                <div className="x-m-25 x-mt-50">
                    <h5 className={"head-color " + switchClasses(["__ff-droidarabickufi"])}>{texts["my-basic-profile"]}</h5>
                </div>

                <div className="_border _border-light shadow-sm x-m-25 x-mt-0 x-mb-70 x-p-10 x-pb-100 x-mih-500 x-bgc-fff x-bdc-e3e3e3 x-bdrs-4">
                    <div className="container-fluid">
                        <div className="row x-pt-20">
                            <div className="col-md-3">
                                <UserProfileSubMenu userType={authenticatedUser?.user_type || null} />
                            </div>
                            <div className="col-md-9">
                                <PageLoader show={this.state.showPageLoader} />

                                <div className={(this.state.showPageLoader ? "x-d-n" : "")}>
                                    <form className={"__ff-droidarabickufi " + switchClasses(["x-fz-085rem"])} onSubmit={(e) => e.preventDefault()}>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <TextBox
                                                    name="first_name"
                                                    label={texts["first-name"]}
                                                    value={this.state.formInputs?.first_name || ""}
                                                    onBlur={this.handleBlur}
                                                    onChange={this.handleChange}
                                                    onFocus={this.handleFocus}
                                                    validationError={this.state.validationErrors?.first_name || ""}
                                                />
                                            </div>
                                            <div className="col-lg-4">
                                                <TextBox
                                                    name="last_name"
                                                    label={texts["last-name"]}
                                                    value={this.state.formInputs?.last_name || ""}
                                                    onBlur={this.handleBlur}
                                                    onChange={this.handleChange}
                                                    onFocus={this.handleFocus}
                                                    validationError={this.state.validationErrors?.last_name || ""}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-4">
                                                <SelectBox
                                                    name="country_calling_code"
                                                    value={this.state.formInputs?.country_calling_code || ""}
                                                    label={texts["country"]}
                                                    onBlur={this.handleBlur}
                                                    onChange={this.handleChange}
                                                    onFocus={this.handleFocus}
                                                    validationError={this.state.validationErrors?.country_calling_code || ""}
                                                    selectBoxOptions={getCommonCountryList().map(country => {
                                                        return (
                                                            <option
                                                                key={Math.random()}
                                                                value={country.callingCodes[0]}
                                                            >
                                                                {country.name + " (+" + country.callingCodes[0] + ")"}
                                                            </option>
                                                        )
                                                    })}
                                                />
                                            </div>
                                            <div className="col-lg-4">
                                                <TextBox
                                                    name="mobile"
                                                    label={texts["mobile-number"]}
                                                    value={this.state.formInputs?.mobile || ""}
                                                    onBlur={this.handleBlur}
                                                    onChange={this.handleChange}
                                                    onFocus={this.handleFocus}
                                                    validationError={this.state.validationErrors?.mobile || ""}
                                                    placeholder="01012345678"
                                                    extraCssClasses="x-dr-ltr x-ta-l"
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-4">
                                                <TextBox
                                                    name="email"
                                                    label={texts["email-address"]}
                                                    value={this.state.formInputs?.email || ""}
                                                    onBlur={this.handleBlur}
                                                    onChange={this.handleChange}
                                                    onFocus={this.handleFocus}
                                                    validationError={this.state.validationErrors?.email || ""}
                                                    extraCssClasses="x-dr-ltr x-ta-l"
                                                />
                                            </div>
                                            <div className="col-lg-4">
                                                <SelectBox
                                                    name="gender"
                                                    value={this.state.formInputs?.gender || ""}
                                                    label={texts["gender"]}
                                                    onBlur={this.handleBlur}
                                                    onChange={this.handleChange}
                                                    onFocus={this.handleFocus}
                                                    validationError={this.state.validationErrors?.gender || ""}
                                                    selectBoxOptions={getUserGenders().map(gender => {
                                                        return (
                                                            <option
                                                                key={Math.random()}
                                                                value={gender.value}
                                                            >
                                                                {gender.label}
                                                            </option>
                                                        )
                                                    })}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="x-mt-20">
                                                    <CustomButton
                                                        type="button"
                                                        label={this.state.showButtonLoader ? <ButtonLoader /> : GeneralTexts["save"]}
                                                        disabled={this.validateFormInputs() ? true : false}
                                                        onClick={this.handleSubmit}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <RequiredDataHint className={this.state.showPageLoader ? "x-d-n" : ""} />
                    </div>

                    <MessageModal
                        show={this.state.messageModal.show || false}
                        title={this.state.messageModal.title || null}
                        body={this.state.messageModal.body || null}
                        alertClassName={this.state.messageModal.alertClassName || null}
                        size={this.state.messageModal.size || null}
                        centered={this.state.messageModal.centered || false}
                        footerButtons={this.state.messageModal.footerButtons || []}
                        onHide={this.state.messageModal.onHide || (() => this.setState({ messageModal: { ...this.initialState.messageModal } }))}
                    />
                </div>
            </React.Fragment>
        );

		return (
            <PageContent
                pageContent={pageContent}
                authenticatedUser={this.getAuthenticatedUser()}
                showPageContent={this.showPageContent()}
                windowLocation={window.location}
            />
        );
    }
}
