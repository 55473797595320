import { preferredLanguage } from "./../configs/ui-language-config";

const setTitle = (title = null, language = preferredLanguage.alpha2code, replace = false) => {
    if (!title) {
        resetTitle(language);
        return;
    }

    if (replace) {
        document.title = title;
    } else {
        if (language === "ar") {
            document.title = process.env.REACT_APP_WEBSITE_NAME_AR + " | " + title;
        } else {
            document.title = process.env.REACT_APP_WEBSITE_NAME_EN + " | " + title;
        }
    }
}

// -----------------------------------------------

const resetTitle = (language = null) => {
    if (language === "ar") {
        document.title = process.env.REACT_APP_WEBSITE_NAME_AR;
    } else if (language === "en") {
        document.title = process.env.REACT_APP_WEBSITE_NAME_EN;
    } else {
        document.title = process.env.REACT_APP_DEFAULT_DOCUMENT_TITLE;
    }
}

// -----------------------------------------------

export default {
    setTitle,
    resetTitle
}
