import { preferredLanguage } from "./../configs/ui-language-config";
// import { GeneralTexts } from "./GeneralTexts";
import { RouteConfigTexts } from "./RouteConfigTexts";

const texts = {
    ar: {
        "borrower-company-update"   : RouteConfigTexts["borrower-company-update"],
        "borrower-company-list"     : RouteConfigTexts["borrower-company-list"],
        "company-official-name"     : "الاسم الرسمي للشركة",
        "company-type"              : "نوع الشركة",
        "founded-in"                : "سنة التأسيس",
        "activity-sector"           : "مجال النشاط",
        "activity-subsector"        : "المجال الفرعي للنشاط",
        "country"                   : "الدولة",
        "city"                      : "المدينة",
        "address"                   : "العنوان",
        "mobile-number"             : "رقم الموبايل",
        "landline-number"           : "رقم التليفون الأرضي",
        "email-address"             : "البريد الإلكتروني",
        "website"                   : "موقع الإنترنت",
        "business-cycle"            : "تفاصيل دورة النشاط",
        "business-cycle-placeholder": "اشرح هنا ما الذي تفعله شركتك..",
    },

    en: {
        "borrower-company-update"   : RouteConfigTexts["borrower-company-update"],
        "borrower-company-list"     : RouteConfigTexts["borrower-company-list"],
        "company-official-name"     : "Company Official Name",
        "company-type"              : "Company Type",
        "founded-in"                : "Founded in Year",
        "activity-sector"           : "Activity Sector",
        "activity-subsector"        : "Activity Subsector",
        "country"                   : "Country",
        "city"                      : "City",
        "address"                   : "Address",
        "mobile-number"             : "Mobile Number",
        "landline-number"           : "Landline Number",
        "email-address"             : "Email Address",
        "website"                   : "Website",
        "business-cycle"            : "Business Cycle Details",
        "business-cycle-placeholder": "Describe here what your company do..",
    }
}

export const BorrowerCompanyUpdateTexts = texts[preferredLanguage.alpha2code];
