import React from "react";

import { switchClasses } from "./../languages/CssClasses";
// import { BorrowOverviewTexts as texts } from "./../languages/BorrowOverviewTexts";
import { RouteConfigTexts } from "./../languages/RouteConfigTexts";

// Our Modules
import BorrowSubMenu from "./shared/BorrowSubMenu";
import PageLoader from "./shared/PageLoader";

// Our Services
import documentTitleService from "./../services/document-title-service";
import PageHandler from "./shared/PageHandler";
import PageContent from "./shared/PageContent";

export default class BorrowOverview extends PageHandler {

    initialState = {
        ...this.initialStateElements,
        isThisPagePublic: true,
        showPageLoader: false,
    };

    // -----------------------------------------------

    // Clone a separate copy of the "initialState" and assign it to the "state".
    // Note that the spread operator "..." is important to get a separate copy.
    state = { ...this.initialState };

    // -----------------------------------------------

    componentDidMount() {
        // More info: t.ly/ZVsd
        window.scrollTo(0, 0);

        // ----------------------

        // Properly set the browser's document title
        documentTitleService.setTitle(RouteConfigTexts["borrow-overview"]);

        // ----------------------

        this.setAuthenticatedUser();
    }

    // -----------------------------------------------

	render() {
        const pageContent = (
            <React.Fragment>
                <div>
                    <div className={"x-m-25 x-mb-15 " + switchClasses(["x-mr-15"])}>
                        <h5 className={"x-pt-20 head-color "}>
                            {RouteConfigTexts["borrow-overview"]}
                        </h5>
                    </div>

                    <div className="x-mt-15">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-3 col-lg-2 x-pr-8 x-pl-8">
                                    <BorrowSubMenu />
                                </div>

                                <div className="col-md-9 col-lg-10 x-pr-8 x-pl-8">
                                    <div className="shadow-sm x-m-0 x-mt-15 x-mb-70 x-p-20 x-pt-16 x-pb-100 x-mih-500 x-bgc-fff x-bdc-e3e3e3 x-bdrs-4">
                                        <PageLoader show={this.state.showPageLoader} />

                                        <div className={"row" + (this.state.showPageLoader ? " x-d-n" : "")}>
                                            <div className="col">
                                                <p className="x-pt-50 x-dr-ltr x-ta-c">Coming soon..</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );

        return (
            <PageContent
                pageContent={pageContent}
                authenticatedUser={this.getAuthenticatedUser()}
                showPageContent={this.showPageContent()}
                windowLocation={window.location}
            />
        );
	}
}
