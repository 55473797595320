import arIcon from "./../resources/images/flags/eg-flag.svg";
import enIcon from "./../resources/images/flags/us-flag.svg";

// More info about language alpha2code and alpha3code, check: t.ly/LqUx
const uiLanguages = [
    {
        nameAr    : "عربي",
        nameEn    : "Arabic",
        direction : "rtl", // Right to Left
        alpha2code: "ar",
        icon      : arIcon,
        isDefault : true,
    },
    
    {
        nameAr    : "إنجليزي",
        nameEn    : "English",
        direction : "ltr", // Left to Right
        alpha2code: "en",
        icon      : enIcon,
        isDefault : false,
    },
];

// ----------------------

export function getUILanguages() {
    return uiLanguages;
}

// ----------------------

// export function getUILanguage(languageAlpha2Code = getDefaultUILanguage().alpha2code) {
export function getUILanguage(languageAlpha2Code) {
    return uiLanguages.find(lang => lang.alpha2code === languageAlpha2Code.toLocaleLowerCase());
}

// ----------------------

export function getDefaultUILanguage() {
    return uiLanguages.find(lang => lang.isDefault);
}

// ----------------------

export function setDefaultUILanguage() {
    if (! localStorage.getItem("preferredLanguage")) {
        localStorage.setItem("preferredLanguage", getDefaultUILanguage().alpha2code);
    }
}

// ----------------------

function getPreferredUILanguage() {
    const preferredLanguageAlpha2Code = localStorage.getItem("preferredLanguage") || getDefaultUILanguage().alpha2code;

    return uiLanguages.find(lang => lang.alpha2code === preferredLanguageAlpha2Code.toLocaleLowerCase());
}

export const preferredLanguage = getPreferredUILanguage();

// ----------------------