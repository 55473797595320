import React from "react";
import _toLower from "lodash/toLower";

// import "./sass/PageLoader.scss";
import "./sass/PageLoaderWhite.scss";
import "./sass/PageLoaderBlack.scss";
import "./sass/PageLoaderBlue.scss";

const PageLoader = (props) => {
    // Set the default css class
    // let loaderCssClass = "page-loader-dot-typing-blue";
    let loaderCssClass;

    // if (typeof props.color !== 'undefined') {
        switch (_toLower(props?.color)) {
            case "white":
            case "ffffff":
            case "fff":
                loaderCssClass = "page-loader-dot-typing-white";
                break;

            case "black":
            case "000000":
            case "000":
                loaderCssClass = "page-loader-dot-typing-black";
                break;

            case "blue":
                loaderCssClass = "page-loader-dot-typing-blue";
                break;

            default:
                loaderCssClass = "page-loader-dot-typing-blue"
                break;
        }
    // }

    if (typeof props?.show === 'undefined' || props?.show === true) {
        return (
            <div className={props?.className || ""}>
                <div className="page-loader-wrapper">
                    <div className={loaderCssClass}></div>
                </div>
            </div>
        );
    }

    return "";
}

export default PageLoader;
