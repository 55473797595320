import React from "react";

import { switchClasses } from "./../languages/CssClasses";
// import { InvestTermsAndConditionsTexts as texts } from "./../languages/InvestTermsAndConditionsTexts";
import { RouteConfigTexts } from "./../languages/RouteConfigTexts";

// Our Modules
import InvestSubMenu from "./shared/InvestSubMenu";
import PageLoader from "./shared/PageLoader";

// Our Services
import documentTitleService from "./../services/document-title-service";
import PageHandler from "./shared/PageHandler";
import PageContent from "./shared/PageContent";

export default class InvestTermsAndConditions extends PageHandler {
	initialState = {
		...this.initialStateElements,
		isThisPagePublic: true,
		showPageLoader: false,
	};

	// -----------------------------------------------

	// Clone a separate copy of the "initialState" and assign it to the "state".
	// Note that the spread operator "..." is important to get a separate copy.
	state = { ...this.initialState };

	// -----------------------------------------------

	componentDidMount() {
		// More info: t.ly/ZVsd
		window.scrollTo(0, 0);

		// ----------------------

		// Properly set the browser's document title
		documentTitleService.setTitle(
			RouteConfigTexts["invest-terms-and-conditions"]
		);

		// ----------------------

		this.setAuthenticatedUser();
	}

	// -----------------------------------------------

	render() {
		const pageContent = (
			<React.Fragment>
				<div>
					<div className={"x-m-25 x-mb-15 " + switchClasses(["x-mr-15"])}>
						<h5 className={"x-pt-20 head-color "}>
							{RouteConfigTexts["invest-terms-and-conditions"]}
						</h5>
					</div>

					<div className="x-mt-15">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-3 col-lg-2 x-pr-8 x-pl-8">
									<InvestSubMenu />
								</div>

								<div className="col-md-9 col-lg-10 x-pr-8 x-pl-8">
									<div className="shadow-sm x-m-0 x-mt-15 x-mb-70 x-p-20 x-pt-16 x-pb-100 x-mih-500 x-bgc-fff x-bdc-e3e3e3 x-bdrs-4">
										<PageLoader show={this.state.showPageLoader} />

										<div
											className={
												"row" + (this.state.showPageLoader ? " x-d-n" : "")
											}
										>
											<div className="col">
												{/* <p className="x-pt-50 x-dr-ltr x-ta-c">Coming soon..</p> */}
												<h1 className="x-pt-30 x-ta-c x-fz-2rem">
													شروط وأحكام الممول
												</h1>

												<div className="x-p-50 x-lh-32">
													<div className="">
														<h3 className="border-bottom x-pb-10">التعريفات</h3>

														<div className="">
															<p className="x-m-0 x-pt-25">
																تسري التعريفات التالية على الشروط والأحكام
																المعروضة أدناه، علما بأن هذه الشروط والأحكام هي
																وسياسة الخصوصية إذ تعتبر وبموجب هذه المُحرر كل
																ما تم الاتفاق عليه والتفاهم بشأنه فيما بين منصة
																أموال و "الممول"، سواء كان الممول في صورة شخص أو
																كيان يقوم بالدخول على منصة أموال.
															</p>

															<p className="x-m-0 x-pt-25">
																يُـشير لفظ "<span className="h5">نحن</span>"، و"
																<span className="h5">لنا</span>"، و"
																<span className="h5">لدينا</span>"، و"
																<span className="h5">الشركة</span>" و"
																<span className="h5">منصة أموال</span>" إلى منصة
																أموال وعامليه والوكلاء المعتمدين لديه.
															</p>

															<p className="x-m-0 x-pt-25">
																<span className="h5">الخدمات</span>: ويقصد بها
																خدمات منصة أموال الوارد بيانها وفق الشروط
																والاحكام الماثلة.
															</p>

															<p className="x-m-0 x-pt-25">
																<span className="h5">القوة القاهرة</span>:
																ويُـقصد بها أي حدث قدري، ومن ذلك على وجه العموم
																لا الحصر الحريق والفيضانات والعواصف والاعاصير
																والزوابع والنشاطات البركانية والزلازل، وكذا أية
																تصرفات أو أوامر أو طلبات تصدر من جهة سيادية أو
																حكومة و/أو أية هيئة يُـفترض أنها تعمل لصالح تلك
																الجهة، وكذا الحرب، سواء المعلنة أو غير المعلنة،
																والتصرفات المثيلة للحرب، والثورات، وحالات الشغب،
																والعصيان المدني، والاضطراب المدني، وكذا عمليات
																الاضراب، والتوقف عن العمل، و/أو ما شابة ذلك من
																حالات أو تصرفات تندرج ضمن النزاعات العمالية، وأي
																سبب آخر مثيل ويخرج عن نطاق تحكم أو سيطرة أي طرف،
																هذا مالم يكن تنفيذه هو تنفيذ محل ضمان يسري بموجب
																تنازل صريح عن الاعفاء في حالة وقوع حدث القوة
																القاهرة.
															</p>
														</div>
													</div>

													<div className="x-pt-60">
														<h3 className="border-bottom x-pb-10">
															1. شروط استخدام منصة أموال
														</h3>

														<div className="">
															<p className="x-m-0 x-pt-25">
																1.1. يرد فيما يلي الشروط والأحكام التي تسري بشأن
																عملية استخدام موقع "
																<span className="h5">منصة أموال</span>"
																الإلكتروني ("
																<span className="h5">منصة أموال</span>") وكذا كل
																ما يرتبط بتلك منصة أموال من مواقع وخدمات، علما
																بأن استخدام منصة أموال إذ يعتبر بمثابة موافقة من
																جانبكم على الشروط والأحكام الماثلة. وإذا لم
																توافقوا على هذه الشروط والأحكام، فيكون عليكم عدم
																الدخول على الموقع أو استخدامه.
															</p>

															<p className="x-m-0 x-pt-25">
																1.2. تسري الشروط والأحكام التالي بيانها على جميع
																الممولين، وذلك فيما يتعلق بالخدمات التي تقدمها
																منصة أموال من خلال منصة أموال وتسري الشروط
																والاحكام الماثلة وتنفذ بمجرد قبولكم لأية خدمة
																تجري عبر منصة أموال من خلال أية وسيلة تواصل أخرى
																مع منصة أموال.
															</p>

															<p className="x-m-0 x-pt-25">
																1.3. يُـوافق المستخدم، بل ويقبل بأن يكون استخدام
																منصة أموال والخدمات التي تقدمها منصة أموال
																استخداما يسري على مسئوليته الخاصة، كما يُـقر
																أيضا بأن منصة أموال إذ تبرأ، بل وتعفى من أية
																إقراراتٍ أو ضماناتٍ من أي نوع، سواءً صدرت صراحة
																أو ضمنًا.
															</p>
														</div>
													</div>

													<div className="x-pt-60">
														<h3 className="border-bottom x-pb-10">
															2. الشروط الأخرى المعمول بها
														</h3>

														<div className="">
															<p className="x-m-0 x-pt-25">
																2.1. تشير هذه الشروط والأحكام إلى الشروط
																الإضافية، والتي تنطبق أيضا على استخدامك للمنصة
																والتي تتمثل في سياسة الخصوصية الخاصة بنا، والتي
																تحدد الشروط التي نعالج بموجبها أي بيانات شخصية
																نجمعها منك، أو التي تقدمها لنا. بناءا عليه، إذا
																قمت باستخدام منصة أموال، فإنك توافق على هذه
																المعالجة وتضمن أن جميع البيانات التي تقدمها
																دقيقة.
															</p>

															<p className="x-m-0 x-pt-25">
																2.2. إذا كنت تستخدم منصة أموال، أو توافق على هذه
																الشروط والأحكام نيابة عن أى كيان اعتباري منفصل
																آخر، فإنك تضمن أنك مُخَّول للتصرف نيابة عن ذلك
																الكيان.
															</p>
														</div>
													</div>

													<div className="x-pt-60">
														<h3 className="border-bottom x-pb-10">
															3. الحسابات والتسجيل
														</h3>

														<div className="">
															<p className="x-m-0 x-pt-25">
																3.1. تستلزم منكم عملية تسجيل الحساب كممول أن
																تتقدموا إلى منصة أموال بمعلوماتٍ معينة وفقاً
																لنموذج "اعرف عميلك" ومنها على وجه العموم لا
																الحصر؛ الاسم بالكامل والعنوان، والبريد
																الالكتروني، ورقم الهاتف. تمتلك منصة أموال الحق
																في رفض أي ممول لا يمتثل لمتطلبات نموذج "اعرف
																عميلك" وفقًا لمحض اختيار منصة أموال وحدها.
															</p>

															<p className="x-m-0 x-pt-25">
																3.2. ويكون عليكم الموافقة على أن يتم حفظ
																معلوماتٍ صحيحة ودقيقة وكاملة، بل ومُحدثة بشأنكم
																على الحساب. كما يكون عليكم أن تتحملوا المسئولية
																عن كل نشاطٍ يجري في إطار حسابكم، كما تلتزمون
																بالموافقة على الحفاظ وباستمرار سريان إجراءاتٍ
																امنية على اسم المستخدم وكلمة المرور الخاصة
																بحسابكم، هذا ما لم تصرح منصة أموال بغير ذلك
																كتابة.
															</p>

															<p className="x-m-0 x-pt-25">
																3.3. وإذا نما لدى منصة أموال (وحسب مطلق
																اختيارها) ثمة شكوك بأن أية معلومة من المعلومات
																التي تقدمتم بها هي معلومة غير صحيحة، أو غير
																دقيقة، أو غير كاملة، أو غير محدثة، فدونما إخلال
																بأية حقوق أخرى وتعويضاتٍ مكفولة ل منصة أموال وفق
																الشروط والاحكام الماثلة أو بمقتضى القوانين واجبة
																التطبيق، فيكون لدينا الحق في إيقاف عملية دخولكم
																على منصة أموال وخدماتها، أو تحديد هذا الدخول أو
																قصره.
															</p>

															<p className="x-m-0 x-pt-25">
																3.4. ويجوز ل منصة أموال القيام (وحسب مطلق
																اختيارها، بل وفي أي حين) بإبداء أية استفسارات
																تراها ضرورية (بشرط أن يتم ذلك بصورة مباشرة)،
																وكذا مطالبتكم بتقديم الرد وبمزيد من المعلومات أو
																المستندات، ومنها على وجه العموم لا الحصر
																معلوماتٍ ومستندات للتحقق من هويتكم أو صحة ما هو
																مكتوب بغرض النشر. ودونما تقييد لما سلف بيانه،
																فإذا كنتم بمثابة جهة عمل أو مسجلين بالنيابة عن
																جهة عمل، فيجوز أن تشمل هذه المعلومات أو
																المستندات الترخيص التجاري وغير ذلك من مستنداتٍ
																رسمية خاصة بالشركة و/أو مستنداتٍ تستعرض أية
																صلاحية مكفولة لأي شخص للعمل نيابة عنكم. ويكون
																عليكم الموافقة على توفير أية معلوماتٍ و/أو
																مستندات إلى منصة أموال فور طلبها إياها. كما يكون
																عليكم الإقرار والموافقة على أنه إذا تعذر عليكم
																القيام بذلك، فيجوز ل منصة أموال القيام ودونما
																أية مسئولية عليه بتقييد أو إيقاف أو سحب عملية
																دخولكم على منصة أموال. كما نحتفظ أيضا بالحق في
																إلغاء أية حساباتٍ لم يرد تأكيد بشأنها أو لم يتم
																التحقق منها أو أية حساباتٍ لم تكن مفعلة لمدة
																طويلة.
															</p>

															<p className="x-m-0 x-pt-25">
																3.5. وعند إتمام عملية التسجيل من جانبكم، يكون
																عليكم الإقرار بأنكم اطلعتم على الشروط والأحكام
																الماثلة، بل وتفهمتموها ووافقتم على الالتزام بها،
																وكذا سياسة الخصوصية، بل وكل ما يطرأ عليها من
																تعديل من حين لآخر، حيث تعتبر السياسة وتعديلاتها
																- وبموجب هذا المحرر- بمثابة جزء لا يتجزأ، بل
																وتندرج ضمن الشروط والاحكام الماثلة.
															</p>
														</div>
													</div>

													<div className="x-pt-60">
														<h3 className="border-bottom x-pb-10">
															4. العملية
														</h3>

														<div className="">
															<p className="x-m-0 x-pt-25">
																4.1. نظير الالتزام بالشروط والاحكام الماثلة،
																تمنح منصة أموال إلى المستخدم ترخيصًا محدودا وغير
																حصري ولا يجوز منحه من الباطن وغير قابل للإلغاء
																أو الإبطال، بل وغير قابل للحوالة أو النقل فيما
																يتعلق بما يلي:
															</p>
															<ul>
																<li>
																	الدخول على الخدمات واستخدامها فيما يتعلق
																	بعملية تمويل المشاريع المعروضة على منصة أموال.
																</li>
																<li>
																	والدخول على أي محتوى واستخدامه وكذا أية
																	معلومات – على سبيل المثال لا الحصر – أى بيانات
																	المقترضين أو دراسة الجدوى الخاصة بالمشروع الذي
																	ترغب في تمويله، وما يرتبط بها من مواد قد تتوفر
																	من خلال الخدمات.
																</li>
															</ul>

															<p className="x-m-0 x-pt-25">
																4.2. علما بأن أي حق لم يتم منحه على نحو صريح في
																هذه الشروط هو حق محفوظ ل منصة أموال، بل ويجوز
																الغائه أو ابطاله في أي حين، بل ودونما أي إخطارٍ
																بذلك إلى المستخدم.
															</p>

															<p className="x-m-0 x-pt-25">
																4.3. تحدد اموال طريقة إقراض الصفقات على منصة
																أموال، عن طريق تحديد سعر الفائدة على التمويل
																الخاص بالمشروع الذي تم دراسته من جانبنا وعرض
																نتائج تلك الدراسة على منصة أموال.
															</p>

															<p className="x-m-0 x-pt-25">
																4.4. ستتمكن فقط من استثمار ما يصل إلى ٢٥ ٪ من
																إجمالي حساب الممول الشخصي الخاص بك (أي المحفظة
																الإلكترونية – Wallet) مقابل كل قرض متاح.
															</p>

															<p className="x-m-0 x-pt-25">
																4.5. يتمثل دورنا الرئيسي في أداء وظائف تمهيدية
																نيابة عن المقترضين والممولين من أجل الجمع بين
																المقترضين والممولين المحتملين، لتوفير عملية
																مبسطة للدخول في القروض (بما في ذلك تطوير
																اتفاقيات القروض) وتسهيل دفع وتحصيل المبالغ
																المستحقة بموجب أو فيما يتعلق بهذه القروض (بما في
																ذلك اتخاذ إجراءات معينة نيابة عن الممولين عند
																تخلف المقترض عن السداد أو إذا أصبح المقترض معسر)
																على النحو المبين في هذه الشروط والأحكام. أنت
																تفوضنا بالدخول في أي اتفاقية قرض ذات صلة تفي
																بمعاييرك.
															</p>

															<p className="x-m-0 x-pt-25">
																4.6. نعطي كل مقترض يتقدم من خلال منصة أموال
																تصنيفا لنطاق المخاطر في وقت معالجة طلب المقترض،
																والذي يعتمد على المعلومات المضمنة في طلب المقترض
																ونتائج دراسات الجدوى و/أو الفحص النافي للجهالة
																الذي يتم إجراؤه من خلالنا. نحن لا نقوم بتعديل أو
																تغيير المعلومات المقدمة أو المحملة من قبل
																المقترض.
															</p>

															<p className="x-m-0 x-pt-25">
																4.7. بينما نضيف نتائج دراسات الجدوى كجزء من
																خدماتنا، فإن تصنيف المخاطر يهدف إلى أن يكون
																إعلاميا فقط ويجب على الممول تكوين رأيه الخاص
																فيما يتعلق بسعر الفائدة الذي يرغب في اختياره
																وإجراء أبحاثه وتحليلاته وتقييمه الخاص لكل سعر
																فائدة متاح لكل قرض، عند الاقتضاء، يتعين عليك طلب
																مشورة مالية مستقلة.
															</p>

															<p className="x-m-0 x-pt-25">
																{/* <span className="h5">4.8. نطاق المخاطر:</span> */}
																4.8. نطاق المخاطر:
															</p>

															<div className="x-mr-30 x-ml-30">
																<p className="x-m-0 x-pt-25">
																	4.8.1.{" "}
																	<span className="h5">خسارة رأس المال</span>.
																	هناك احتمال أن يتخلف المقترضين الذين تقرضهم عن
																	السداد وقد يؤدي ذلك إلى فقدان بعض أو كل رأس
																	المال المستثمر الخاص بك.
																</p>

																<p className="x-m-0 x-pt-25">
																	4.8.2. <span className="h5">الضريبة</span>.
																	أنت مسؤول عن شئونك الضريبية، والتي قد تشمل
																	المكاسب الرأسمالية و/أو ضريبة الدخل. في كل مرة
																	تقرض، يجب أن تكون متأكدا قبل تقديم أي التزام
																	انك تفهم وتقبل العواقب الضريبية. نحن لا نقدم
																	المشورة الضريبية ويجب عليك أن تبحث بشكل مستقل
																	قبل الإقراض إذا كنت غير متأكد من شؤونك
																	الضريبية. تقع على عاتقك مسؤولية التأكد من صحة
																	الإقرار الضريبي الخاص بك وتقديمه في الموعد
																	النهائي ودفع أي ضريبة مستحقة في الوقت المحدد.
																	إذا كنت غير متأكد من كيفية تأثير هذا الاستثمار
																	على وضعك الضريبي. يجب عليك طلب مشورة الخبراء
																	قبل الإقراض.
																</p>

																<p className="x-m-0 x-pt-25">
																	4.8.2.{" "}
																	<span className="h5">مخططات التعويض</span>.
																	[...]
																</p>
															</div>
														</div>
													</div>

													<div className="x-pt-60">
														<h3 className="border-bottom x-pb-10">5. الرسوم</h3>

														<div className="">
															<p className="x-m-0 x-pt-25">
																5.1. سيشمل سعر الفائدة (أى سعر الفائدة على
																القرض) جميع الرسوم، ويتم تحديده بالكامل بموجب
																اتفاقية القرض. قد يشمل سعر الفائدة على القرض ما
																يلي:
															</p>
															<div className="x-mr-30 x-ml-30">
																<p className="x-m-0 x-pt-25">
																	(أ) رسوم عائد الممولين؛ و
																</p>

																<p className="x-m-0 x-pt-0">
																	(ب) رسوم مخصصة لصناديق الممولين.
																</p>
															</div>

															<p className="x-m-0 x-pt-25"></p>
														</div>

														<p className="x-m-0 x-pt-25">
															5.2. يتم تضمين الرسوم والتكاليف المنصوص عليها في
															الفقرة 5.1 في كل قسط من السداد اعتمادا على المدة
															ودرجة المخاطرة المحددة من قبلنا.
														</p>

														<p className="x-m-0 x-pt-25">
															5.3. قد نختار التنازل عن الرسوم المذكورة أعلاه أو
															تخفيضها من وقت لآخر.
														</p>

														<p className="x-m-0 x-pt-25">
															5.4.{" "}
															<span className="h5">رسوم عائد الممولين</span>.
															يتم تضمين رسوم عائد الممولين في سعر الفائدة على
															القرض. سيشكل هذا جزءا من سعر الفائدة على القرض
															وسيتم استخدامه لدفع معدل عائد أساسي للممولين،
															ووفقا لتقديرنا الخاص، سعر فائدة إضافي (يدفع
															استحقاق كامل قيمة القرض). أي فائدة قد تتراكم على
															ال منصة أموال غير المقترضة التي نحتفظ بها نيابة عن
															الممول – في المحفظة الالكترونية الخاصة بالممول
															لدينا - لن تكون مستحقة الدفع للممول وسيتم الاحتفاظ
															بها لدينا.
														</p>

														<p className="x-m-0 x-pt-25">
															5.5.{" "}
															<span className="h5">
																رسوم مخصصة لصناديق الممولين
															</span>
															. يتم تضمين رسوم صندوق الممولين المخصص في سعر
															الفائدة على القرض. سيشكل هذا جزءا من سعر الفائدة
															على القرض وسيتم استخدامه لتشغيل حساب وصيانته
															وتشغيله لحالات الديون المعدومة.
														</p>
													</div>

													<div className="x-pt-60">
														<h3 className="border-bottom x-pb-10">
															6. حقوق الملكية الفكرية
														</h3>

														<p className="x-m-0 x-pt-25">
															6.1. بالنسبة لجميع المحتوى الوارد على منصة أموال،
															ومن ذلك على وجه العموم لا الحصر النص، وصور
															الجرافيك، وشعارات منصة أموال، والازرار، والصور،
															والمقاطع الصوتية، وعمليات التحميل الرقمي، وتجميع
															البيانات، والبرامج الالكترونية، فيعتبر ذلك المحتوى
															بمثابة ملكية حصرية، بل وعمل خاص ب منصة أموال أو
															بمرخصيه. كما يخضع هذا المحتوى للحماية بموجب حق
															المؤلف وحقوق العلامات التجارية وبراءات الاختراع
															وغير ذلك من حقوق ملكية فكرية وقوانين.
														</p>

														<p className="x-m-0 x-pt-25">
															6.2. وتعتبر علامة منصة أموال التجارية وما يرتبط
															بها من رموز هي علاماتٍ تجارية خاصة ب منصة أموال،
															علما بأنه لا يجوز استخدام العلامات التجارية ل منصة
															أموال فيما يتعلق بأي منتج أو أية خدمة ليست معروضة
															على منصة أموال، أو أي منتج أو خدمة من شأنها
															التقليل من شأن منصة أموال أو الإساءة لسمعتها.
														</p>

														<p className="x-m-0 x-pt-25">
															6.3. يحظر على المستخدم استخدام أية علامة تجارية أو
															أي حقوق ملكية فكرية تخص منصة أموال، وذلك من أي جزء
															من الخدمات، كما يحظر عليه إعادة إنتاج الخدمات أو
															تعديلها أو إجراء أية عملية اعداد أو نشر أو تنفيذ
															أو ارسال أو عرض أو بث أو غير ذلك من وسائل استغلال
															الخدمات، هذا ما لم يسمح منصة أموال بذلك أو يأذن
															صراحة وبصورة كتابية، كما لا يجوز إجراء اعمال تفكيك
															لنظام الخدمات أو أية هندسية عكسية أو إعادة تشكيل
															لها، أو القيام بربط أي جزء من الخدمات بأي نظام
															عاكس أو تأطيره أو وضع أية برامج أو نصوص لغرض إزالة
															أي جزء من الخدمات أو فهرستها أو مسحها أو إجراء غير
															ذلك من عمليات استخراج للبيانات منه، أو القيام
															بتحميل عملية التشغيل و/أو العمل الوظيفي لأي منحى
															من مناحي الخدمات بأحمال لا مبرر لها أو القيام
															بإعاقتها أو محاولة الحصول على إمكانية الدخول عليها
															دونما تصريح بذلك أو العمل على الإضرار بالخدمات أو
															ما يرتبط بها من أنظمة أو شبكات.
														</p>
													</div>

													<div className="x-pt-60">
														<h3 className="border-bottom x-pb-10">
															7. تحديد مسؤوليتنا
														</h3>

														<p className="x-m-0 x-pt-25">
															7.1. لا تتحمل منصة أموال المسئولية تجاهكم عن أية
															أضرارٍ غير مباشرة، أو أضرار عارضة، أو خاصة، أو
															جزائية، أو عقابية، أو تبعية، ومن ذلك فقد البيانات
															أو اي اضرار بحسابكم الشخصي في البنك او حسابكم على
															منصة أموال، وذلك، حتى لو كانت منصة أموال قد احيطت
															علمًا باحتمال وقوع هذه الاضرار. ولا تتحمل منصة
															أموال المسئولية عن أية أضرار أو أية مسئولية أو أية
															خسائر تتحملونها وتنشأ من واقع استخدامكم للخدمات أو
															اعتمادكم عليها أو جراء عدم تمكنكم من الدخول على
															الخدمات أو استخدامها أو من واقع أية معاملة جرت أو
															علاقة نشأت بينكم وبين المستخدم، وذلك، حتى لو تم
															إحاطة منصة أموال بإمكانية وقوع تلك الاضرار. كما لا
															تتحمل منصة أموال المسئولية عن أية حالة تأخير أو
															إخفاق في التنفيذ، وكان ذلك يرجع لأسباب خارجة عن
															نطاق التحكم المعقول ل منصة أموال. ولا يجوز اعتبار
															حدود المسئولية المذكورة حدودا تقيد أو تحد من أية
															مسئولية لا تستثنيها القوانين واجبة التطبيق.
														</p>

														<p className="x-m-0 x-pt-25">
															7.2. ويكون من حق منصة أموال الإفصاح لأي مِمَّا في
															حيازته معلومات تخص المستخدم ان استلزم القانون ذلك
															أو إن طـُـلب أو صدر توجيه بذلك من جانب أية جهة
															حكومية رسمية. وستقوم منصة أموال بذلك حسبما قد
															يتقرر ملاءمته من جانبنا أو إن كان ذلك الإفصاح أمرا
															ينصب في مصلحتنا.
														</p>

														<p className="x-m-0 x-pt-25">
															7.3. ولا تتحمل منصة أموال أية مسئولية تجاهكم عما
															قد ينشأ من أضرارٍ غير مباشرة، أو عارضة، أو خاصة،
															أو جزائية، أو عقابية، أو تبعية، حتى لو ورد ل منصة
															أموال ما يفيد باحتمال وقوع تلك الاضرار. كما لا
															تتحمل منصة أموال المسئولية عن أية اضرار أو
															التزامات أو خسائر تتحملونها، وكانت تنشأ من واقع
															استخدامكم أو اعتمادكم على الخدمات أو جراء عدم
															أمكانية دخولكم على الخدمات أو استخدامها أو من واقع
															أية معاملة أو علاقة نشأت بينكم وبين أي مقدمي
															الخدمة من الغير، بل وحتى لو ورد ل منصة أموال ما
															يفيد باحتمال وقوع تلك الاضرار. ولا تتحمل منصة
															أموال أيضا المسئولية عن أي تأخير أو إخفاق في
															التنفيذ، وكان ذلك ينشأ كنتيجة لأسباب خارجة عن نطاق
															تحكم منصة أموال أو حدود سيطرتها المعقولة. ولا
															تعتبر حدود المسئولية المذكورة أمرا من شأنه تقييد
															المسئولية التي لا يجوز استثنائها وفق القوانين
															واجبة التطبيق.
														</p>

														<p className="x-m-0 x-pt-25">
															7.4. لن نكون مسؤولين عن أي خسارة أو ضرر ناتج عن
															فيروس أو أي مواد أخرى ضارة تقنيا قد تصيب أجهزة
															الكمبيوتر أو برامج الكمبيوتر أو البيانات أو المواد
															الأخرى المملوكة لك بسبب استخدامك للمنصة أو لتنزيل
															أي محتوى عليها أو على أي موقع مرتبط بها.
														</p>

														<p className="x-m-0 x-pt-25">
															7.5. نحن لا نتحمل أي مسؤولية عن محتوى المواقع
															المرتبطة ب منصة أموال. لا ينبغي تفسير هذه الروابط
															على أنها تأييد من جانبنا لتلك المواقع. لن نكون
															مسؤولين عن أي خسارة أو ضرر قد ينشأ عن استخدامك
															لها.
														</p>

														<p className="x-m-0 x-pt-25">
															7.6. نحن لا نتحمل أي مسؤولية ونخلي مسؤوليتنا عن أي
															معلومات عن كل مقترض ونطاق المخاطر المتاح للممولين
															المحتملين من خلال منصة أموال أو فيما يتعلق
															بتصنيفات نطاق المخاطر. يجوز لنا من وقت لآخر، ولكن
															لا نقبل أي التزام، تحديث أو تعديل تصنيف نطاق
															المخاطر في أي وقت.
														</p>

														<p className="x-m-0 x-pt-25">
															7.7. ستكون مسؤولا تجاهنا عن أي خسارة أو ضرر نتكبده
															نتيجة لأي خرق لهذه الشروط والأحكام أو أي اتفاقية
															قرض من قبلك، أو أي استخدام احتيالي لموقعنا.
														</p>
													</div>

													<div className="x-pt-60">
														<h3 className="border-bottom x-pb-10">
															8. الإبراء من المسئولية
														</h3>

														<p className="x-m-0 x-pt-25">
															8.1. يتم تقديم الخدمات "كما هي" معروضة و"حسبما هو
															متاح" منها. وتعفى منصة أموال، بل وتبرأ من كافة
															الإقرارات والضمانات الصريحة منها أو الضمنية أو
															القانونية التي لم يرد نصها صراحة في الشروط
															الماثلة. كما لا تتقدم منصة أموال بأي إقرار أو ضمان
															بشأن مصداقية الخدمات، أو دقة مواعيدها، أو جودتها،
															أو استدامتها، أو توافرها هي، أو أية خدمات تطلب من
															خلال استخدام خدمات منصة أموال، كما لا تضمن منصة
															أموال عدم تعرض الخدمات للتعطيل أو خلوها من الخطأ،
															ولا تمنح منصة أموال أيما ضمان بشأن جودة الخدمات
															وملاءمتهم وقدراتهم. ولهذا يكون عليكم الموافقة على
															تحملكم حصرا كامل المسئولية الناشئة عن استخدامكم
															الخدمات، وذلك إلى أقصى حد يسمح به القانون واجب
															التطبيق.
														</p>

														<p className="x-m-0 x-pt-25">
															8.2. وتعتبر المعلومات الواردة في منصة أموال هي
															معلومات معروضة لأغراض عامة فقط. أما بالنسبة
															للمعلومات التي ترد من جانب منصة أموال، فنحن نبذل
															بشأنها جهودنا للعمل على أن تظل المعلومات محدثة
															وصحيحة، إلا أننا لا نتقدم بأية إقرارات أو ضمانات
															من أي نوع، سواء على نحو شفهي أو ضمني، وذلك من حيث
															اكتمال، أو دقة، أو مصداقية، أو ملاءمة، أو توافر
															منصة أموال، أو المعلومات، أو الخدمات الواردة على
															منصة أموال بالنسبة لأي غرض. وبالتالي يكون اعتمادكم
															على تلك المعلومات هو أمر يقع على مسئوليتكم الخاصة
															تماما.
														</p>

														<p className="x-m-0 x-pt-25">
															8.3. من المتفق عليه بأن يتعرض الممولين فى منصة
															أموال إلى مخاطر تعثر المقترضين عن سداد قروضهم.
															وكذلك إن قدرة منصة أموال على تحويل عوائد الاستثمار
															إلى ممول ترتكز بشكل كلي على سداد المقترض للدفعات
															المترتبة عليه إلى منصة أموال. لذلك، فإنك على دراية
															تامة بأن مخاطر الاستثمار بواسطة منصة أموال هي ذات
															المخاطر في كافة أنواع الاستثمارات، مما بعني بأنك
															قد تخسر كل أو جزء من قيمة التمويل.
														</p>

														<p className="x-m-0 x-pt-25">
															8.4. تقوم منصة أموال بتقييم القروض تقييما حثيثاً
															قبل أن يتم تحديد نسبة الفائدة على التمويل لكل قرض.
															وعليه فإن الممول يكون المسؤول الوحيد عن اختيار
															المشروعات التي يرغب بتمويلها وفق المخاطر التي
															يراها مناسبة له، دون أية مسئولية على منصة أموال.
														</p>
													</div>

													<div className="x-pt-60">
														<h3 className="border-bottom x-pb-10">
															9. مغادرة منصة أموال
														</h3>

														<p className="x-m-0 x-pt-25">
															إذا لم تعد ترغب في أن تكون مستخدما مسجلا ب منصة
															أموال، بشرط ألا يكون لديك اتفاقيات قرض نشطة،
															فيمكنك إخبارنا وسننهي هذه الشروط والأحكام بيننا.
														</p>
													</div>

													<div className="x-pt-60">
														<h3 className="border-bottom x-pb-10">
															10. استقلالية الأحكام
														</h3>

														<p className="x-m-0 x-pt-25">
															إذا تقرر عدم صلاحية أي جزء من الشروط والأحكام
															الماثلة أو عدم سريانها أو نفاذها وفق القانون واجب
															التطبيق، فيعتبر حينئذٍ ذلك الجزء بمثابة غير ساري
															أو نافذ في حدود ما هو مقرر عدم صلاحيته أو سريانه
															أو نفاذه وحسب، ودون أن يؤثر ذلك وبأي حال من
															الأحوال على باقي أجزاء الشروط والأحكام.
														</p>
													</div>

													<div className="x-pt-60">
														<h3 className="border-bottom x-pb-10">
															11. التعديلات
														</h3>

														<p className="x-m-0 x-pt-25">
															يكون من حق منصة أموال إجراء أية إضافة إلى الشروط
															والاحكام الماثلة أو التغيير أو التعديل فيها في أي
															حين ودونما إخطار بذلك. ويكون على المستخدم الالتزام
															بأية إضافات أو تغييرات أو تعديلاتٍ حين إدراجها في
															متن الشروط والاحكام الماثلة على منصة أموال
															الإلكترونية ل منصة أموال.{" "}
														</p>
													</div>

													<div className="x-pt-60">
														<h3 className="border-bottom x-pb-10">
															12. مجمل المحرر
														</h3>

														<p className="x-m-0 x-pt-25">
															تمثل الشروط والأحكام الماثلة وكذا سياسة الخصوصية
															السارية لدى منصة أموال، بل وما يطرأ عليهم من تحديث
															من حين لآخر وما يتم نشره بشأنهم على (منصة أموال)
															كل ما تم الاتفاق والتوافق والتفاهم بشأنه فيما
															بيننا، وذلك فيما يتعلق بالخدمات، كما ينسخ أي مما
															سلفه من اتفاقات أخرى من أي نوع.
														</p>
													</div>

													<div className="x-pt-60">
														<h3 className="border-bottom x-pb-10">
															13. القانون الواجب التطبيق
														</h3>

														<p className="x-m-0 x-pt-25">
															13.1. تخضع الحقوق والالتزامات الخاصة بالأطراف وفق
															الشروط والاحكام الماثلة، بل وتفسر وتؤول بما يتوافق
															وقوانين جمهورية مصر العربية.
														</p>

														<p className="x-m-0 x-pt-25">
															13.2. كما أنكم وبموجب هذا المُحرر إذ تخضعون ودون
															قيد أو شرط لمناط الاختصاص الحصري لمحاكم جمهورية
															مصر العربية، وذلك بالنسبة لأية منازعات تنشأ من
															واقع الشروط والاحكام الماثلة أو فيما يتعلق بها،
															كما يتعين عليكم التنازل عن أي حق لكم في اتخاذ أية
															إجراءات قانونية في أية منطقة أو مناط اختصاص آخر،
															في حين يكون من حقنا اتخاذ إجراءات قانونية فيما
															يتعلق بأي موضوع ينشأ بصدد الشروط والاحكام الماثلة
															في أية منطقة تقيمون فيها أو تجرون بها أعمالكم أو
															يكون لكم بها أية أصول.
														</p>
													</div>

													<div className="x-pt-60">
														<h3 className="border-bottom x-pb-10">
															14. اتصل بنا
														</h3>

														<p className="x-m-0 x-pt-25">
															إذا كان لديك أي أسئلة حول هذه الشروط والأحكام، أو
															ترغب في الاتصال بنا لأي سبب من الأسباب، يرجى
															التواصل معنا من خلال منصة أموال أو عبر البريد
															الإلكتروني الآتى info@amwal.com.eg
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);

		return (
			<PageContent
				pageContent={pageContent}
				authenticatedUser={this.getAuthenticatedUser()}
				showPageContent={this.showPageContent()}
				windowLocation={window.location}
			/>
		);
	}
}
