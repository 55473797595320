import React from "react";
import { Link } from "react-router-dom";

import { preferredLanguage } from "./../../../configs//ui-language-config";
import { switchClasses } from "./../../../languages/CssClasses";
import { AdminLoanBasicTexts as texts } from "./../../../languages/AdminLoanBasicTexts";
import { GeneralTexts } from "./../../../languages/GeneralTexts";

// Third-Party Modules
import Joi from "@hapi/joi";
import Icon from "@mdi/react"
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";

// Our Modules
import MessageModal, { toggleMessageModal, getErrorMessage } from "./../../shared/MessageModal";
import FormHandler from "./../../shared/FormHandler";
import TextBox from "./../../shared/TextBox";
import SelectBox from "./../../shared/SelectBox";
import TextareaBox from "./../../shared/TextareaBox";
import CustomButton from "./../../shared/CustomButton";
import PageLoader from "./../../shared/PageLoader";
import ButtonLoader from "./../../shared/ButtonLoader";
import RequiredDataHint from "./../../shared/RequiredDataHint";
import AdminLoanSubMenu from "./AdminLoanSubMenu";
import PageContent from "./../../shared/PageContent";

// Our Services
import documentTitleService from "./../../../services/document-title-service";
import logService from "./../../../services/log-service";
import * as loanService from "./../../../services/loan-service";

// Our Configs
import { getRoutePath } from "./../../../configs/route-config";
import { getCommonCountryList } from "./../../../configs/country-config";
import { getLoanPurposes, getLoanDurations, getPercentagesOfTransactionGrossProfit } from "./../../../configs/loan-config";

export default class AdminLoanBasic extends FormHandler {
    /**
     *
     */
    initialState = {
        ...this.initialStateElements,
        allowedUserType: "admin",

        formInputs: {
            id                                        : "",
            required_amount                           : "",
            currency                                  : "",
            loan_purpose                              : "",
            loan_duration_in_months                   : "",
            transaction_private_description           : "",
            has_previous_experience_in_the_transaction: "",
            percentage_of_the_transaction_gross_profit: "",
        },
        validationErrors : {},
        messageModal     : {},
        showPageLoader   : true,
        showButtonLoader : false,
        loan             : {},
        user             : {},
        borrower         : {},
        company          : {},
        urlParmas        : {},
    };

    /**
     * Clone a separate copy of the "initialState" and assign it to the "state".
     * Note that the spread operator "..." is important to get a separate copy.
     */
    state = { ...this.initialState };

    /**
     *
     */
    validationRules = {
        id                                        : Joi.number().optional().allow(null, "").integer().positive(),
        required_amount                           : Joi.number().required().integer().positive().max(10000000).label("Required Amount"),
        currency                                  : Joi.string().required().label("Currency"),
        loan_purpose                              : Joi.string().required().label("Purpose of Loan"),
        loan_duration_in_months                   : Joi.number().required().integer().positive().label("Duration of Loan"),
        transaction_private_description           : Joi.string().required().label("Description of The Transaction"),
        has_previous_experience_in_the_transaction: Joi.string().required().label("Has Previous Experience in The Transaction?"),
        percentage_of_the_transaction_gross_profit: Joi.number().required().positive().label("Percentage of The Transaction Gross Profit"),
    };

    /**
     *
     */
    componentDidMount() {
        // More info: t.ly/ZVsd
        window.scrollTo(0, 0);

        // Properly set the browser's document title
        documentTitleService.setTitle(GeneralTexts["admin-dashboard"] + " | " + texts["admin-loan-basic"]);

        this.setState({ urlParmas: this.props.match.params });

        this.setAuthenticatedUser();
        this.getLoan();
    }

    /**
     * Get loan data that the admin tries to update
     */
    getLoan = async () => {
        this.setState({ showPageLoader: true });

        const response = await loanService.getItem(this.props.match.params.loanUuid, "?include=borrower.user|company");

        const loan = response.data.data.loan;

        // TESTING
        // console.log("loan: ", loan);

        this.setState({
            formInputs: {
                id                                        : loan.id || "",
                required_amount                           : loan.required_amount || "",
                currency                                  : loan.currency || "",
                loan_purpose                              : loan.loan_purpose || "",
                loan_duration_in_months                   : loan.loan_duration_in_months || "",
                transaction_private_description           : loan.transaction_private_description || "",
                has_previous_experience_in_the_transaction: loan.has_previous_experience_in_the_transaction || "",
                percentage_of_the_transaction_gross_profit: loan.percentage_of_the_transaction_gross_profit || "",
            },
            showPageLoader: false,
            loan          : loan,
            borrower      : loan.borrower,
            user          : loan.borrower.user,
            company       : loan.company,
        });
    }

    /**
     *
     */
    doSubmit = async (e) => {
        // Display a loader icon
        this.setState({ showButtonLoader: true });

        try {
            const response = await loanService.updateBasicData(this.props.match.params.loanUuid, {...this.state.formInputs, borrower_id: this.state.borrower.id});

            this.setState({
                messageModal: {
                    ...toggleMessageModal("success"),
                    body: response.data.meta.message,
                    footerButtons: [
                        {
                            variant: "primary",
                            title: "Back to Loans",
                            onClick: () => this.props.history.push(getRoutePath("adminLoanList")),
                        },
                        {
                            variant: "primary",
                            title: "Close",
                            onClick: () => this.setState({ messageModal: { ...this.initialState.messageModal } }),
                        }
                    ],
                    // onHide: () => this.props.history.push(getRoutePath("adminLoanList")),
                }
            });

        } catch (error) {
            /**
             * If there is an unexpected error like: network down, server down, db down, bug, ..
             *  - Log the error.
             *  - Display a generic and friendly error message.
             *
             * Note that "error.response" determines if the error is an expected error or not.
             */

            const isExpectedError =
                error.response
                && error.response.status >= 400
                && error.response.status < 500;

            // if (!error.response) {
            if (!isExpectedError) {
                // TEST
                // console.log("Logging the error", error);

                // Log the error
                logService.log(error);
            }

            // Display an appropriate error message
            this.showErrorMessage(error);
        }

        // Hide the loader
        this.setState({ showButtonLoader: false });
    };

    /**
     *
     */
    showErrorMessage = (error, props = this.props) => {
        this.setState({
            messageModal: {
                ...toggleMessageModal("error"),
                body: getErrorMessage(error, props).body || "",
                footerButtons: getErrorMessage(error, props).footerButtons || [],
            }
        });
    }

    /**
     *
     */
	render() {
        return (
            <PageContent
                pageContent={this.getPageContent()}
                authenticatedUser={this.getAuthenticatedUser()}
                showPageContent={this.showPageContent()}
                windowLocation={window.location}
            />
        );
	}

    /**
     *
     */
    getPageContent = () => {
        return (
            <div>
                {this.getPageTitle()}

                <div className="x-mt-15">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-3 col-lg-2 x-pr-8 x-pl-8">
                                <AdminLoanSubMenu loan={this.state.loan} />
                            </div>

                            <div className="col-md-9 col-lg-10 x-pr-8 x-pl-8">
                                <div className="shadow-sm x-m-0 x-mt-15 x-mb-70 x-p-20 x-pt-16 x-pb-100 x-mih-500 x-bgc-fff x-bdc-e3e3e3 x-bdrs-4">
                                    <PageLoader show={this.state.showPageLoader} />

                                    <div className={"row" + (this.state.showPageLoader ? " x-d-n" : "")}>
                                        <div className="col">
                                            {this.getFormContent()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.getMessageModal()}
            </div>
        );
    }

    /**
     *
     */
    getPageTitle = () => {
        return (
            <>
                <div className={"x-m-25 x-mb-15 " + switchClasses(["x-mr-15"])}>
                    <div>
                        <Link to={getRoutePath("adminLoanList")} className="x-fz-085rem">
                            <Icon
                                path={preferredLanguage.direction === "rtl" ? mdiChevronRight : mdiChevronLeft}
                                size="1.4em"
                                className="x-pos-r x-b-2"
                            />
                            <span className={switchClasses(["__ff-droidarabickufi"])}>
                                {texts["admin-loan-list"]}
                            </span>
                        </Link>
                    </div>

                    <h5 className={"x-pt-10 head-color "}>{texts["admin-loan-basic"]}</h5>
                </div>
            </>
        );
    }

    /**
     *
     */
    getFormContent = () => {
        return (
            <>
                <form className={"__ff-droidarabickufi " + switchClasses(["x-fz-085rem"])} onSubmit={(e) => e.preventDefault()}>
                    <div className="row">
                        <div className="col-md-3">
                            <TextBox
                                name="borrower_first_name"
                                label={texts["borrower"]}
                                value={(this.state.user.first_name + " " + this.state.user.last_name) || ""}
                                disabled={true}
                            />
                        </div>

                        <div className="col-md-3">
                            <TextBox
                                name="company_official_name"
                                label={texts["company"]}
                                value={(this.state.company && this.state.company.official_name) ? this.state.company.official_name : ""}
                                required={true}
                                disabled={true}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            <TextBox
                                name="required_amount"
                                label={texts["required-amount"]}
                                value={this.state.formInputs.required_amount || ""}
                                onBlur={this.handleBlur}
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                validationError={this.state.validationErrors.required_amount || ""}
                                placeholder="250000"
                                extraCssClasses="x-dr-ltr x-ta-l"
                            />
                        </div>

                        <div className="col-md-3">
                            <SelectBox
                                name="currency"
                                value={this.state.formInputs.currency || ""}
                                label={texts["currency"]}
                                onBlur={this.handleBlur}
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                validationError={this.state.validationErrors.currency || ""}
                                selectBoxOptions={getCommonCountryList().map(country => {
                                    return (
                                        <option
                                            key={Math.random()}
                                            value={country.currencies[0].code}
                                        >
                                            {country.currencies[0].name + " (" + country.currencies[0].code + ")"}
                                        </option>
                                    )
                                })}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            <SelectBox
                                name="loan_purpose"
                                value={this.state.formInputs.loan_purpose || ""}
                                label={texts["purpose"]}
                                onBlur={this.handleBlur}
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                validationError={this.state.validationErrors.loan_purpose || ""}
                                selectBoxOptions={getLoanPurposes().map(purpose => {
                                    return (
                                        <option
                                            key={Math.random()}
                                            value={purpose.value}
                                        >
                                            {purpose.label}
                                        </option>
                                    )
                                })}
                            />
                        </div>

                        <div className="col-md-3">
                            <SelectBox
                                name="loan_duration_in_months"
                                value={this.state.formInputs.loan_duration_in_months || ""}
                                label={texts["duration"]}
                                onBlur={this.handleBlur}
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                validationError={this.state.validationErrors.loan_duration_in_months || ""}
                                selectBoxOptions={getLoanDurations().map(month => {
                                    return (
                                        <option
                                            key={Math.random()}
                                            value={month.value}
                                        >
                                            {month.label}
                                        </option>
                                    )
                                })}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            <SelectBox
                                name="has_previous_experience_in_the_transaction"
                                value={this.state.formInputs.has_previous_experience_in_the_transaction || ""}
                                label={texts["has-previous-experience-in-the-transaction"]}
                                onBlur={this.handleBlur}
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                validationError={this.state.validationErrors.has_previous_experience_in_the_transaction || ""}
                                selectBoxOptions={
                                    [
                                        <option key={Math.random()} value="yes">Yes</option>,
                                        <option key={Math.random()} value="no">No</option>,
                                    ]
                                }
                            />
                        </div>

                        <div className="col-md-3">
                            <SelectBox
                                name="percentage_of_the_transaction_gross_profit"
                                value={this.state.formInputs.percentage_of_the_transaction_gross_profit || ""}
                                label={texts["percentage-of-the-transaction-gross-profit"]}
                                onBlur={this.handleBlur}
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                validationError={this.state.validationErrors.percentage_of_the_transaction_gross_profit || ""}
                                selectBoxOptions={getPercentagesOfTransactionGrossProfit().map(percentage => {
                                    return (
                                        <option
                                            key={Math.random()}
                                            value={percentage.value}
                                        >
                                            {percentage.label}
                                        </option>
                                    )
                                })}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <TextareaBox
                                name="transaction_private_description"
                                value={this.state.formInputs.transaction_private_description || ""}
                                label={texts["description-of-transaction"]}
                                onBlur={this.handleBlur}
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                validationError={this.state.validationErrors.transaction_private_description || ""}
                                placeholder={texts["description-of-transaction-placeholder"]}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            <div className="x-mt-20">
                                <CustomButton
                                    type="button"
                                    label={this.state.showButtonLoader ? <ButtonLoader /> : GeneralTexts["save"]}
                                    disabled={this.validateFormInputs() ? true : false}
                                    onClick={this.handleSubmit}
                                />
                            </div>
                        </div>
                    </div>
                </form>

                <RequiredDataHint className={this.state.showPageLoader ? "x-d-n" : ""} />
            </>
        );
    }

    /**
     *
     */
    getMessageModal = () => {
        return (
            <MessageModal
                show={this.state.messageModal.show || false}
                title={this.state.messageModal.title || null}
                body={this.state.messageModal.body || null}
                alertClassName={this.state.messageModal.alertClassName || null}
                size={this.state.messageModal.size || null}
                centered={this.state.messageModal.centered || false}
                footerButtons={this.state.messageModal.footerButtons || []}
                onHide={this.state.messageModal.onHide || (() => this.setState({ messageModal: { ...this.initialState.messageModal } }))}
            />
        );
    }
}
