// Our Services
import http from "./http-service";

// Our Configs
import { getApiEndpoint } from "./../configs/api-config";

// -----------------------------------------------

export const update = (id, data) => {
    return http.put(
        getApiEndpoint("borrowerUpdate").path + "/" + id,
        data
    );
}

// export const update = (id, data) => {
//     // We use "post" method here instead of "put", because
//     // there is a file being uploaded with the other data
//     return http.post(
//         getApiEndpoint("borrowerUpdate").path + "/" + id,
//         data
//     );
// }
