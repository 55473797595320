// React Modules
import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// Third-Party Modules
// import _isEmpty from "lodash/isEmpty";
// import _toLower from "lodash/toLower";

// Our Modules
// import Home from "./components/Home";
import HomeV2 from "./components/HomeV2";
import SwitchLanguage from "./components/SwitchLanguage";
import PreSignup from "./components/PreSignup";
import Signup from "./components/Signup";
import Login from "./components/Login";
import Logout from "./components/Logout";
import PasswordForgot from "./components/PasswordForgot";
import PasswordReset from "./components/PasswordReset";
import Loans from "./components/Loans";
import LoanDetails from "./components/LoanDetails";
import BorrowOverview from "./components/BorrowOverview";
import InvestOverview from "./components/InvestOverview";
import BorrowTermsAndConditions from "./components/BorrowTermsAndConditions";
import BorrowPrivacyPolicy from "./components/BorrowPrivacyPolicy";
import BorrowFaqs from "./components/BorrowFaqs";
import InvestTermsAndConditions from "./components/InvestTermsAndConditions";
import InvestPrivacyPolicy from "./components/InvestPrivacyPolicy";
import InvestFaqs from "./components/InvestFaqs";
import ContactUs from "./components/ContactUs";
import NotFound from "./components/NotFound";

// import ChangePassword from "./components/dashboard/shared/ChangePassword";
import BorrowerChangePassword from "./components/dashboard/borrower/BorrowerChangePassword";
import LenderChangePassword from "./components/dashboard/lender/LenderChangePassword";
import AdminChangePassword from "./components/dashboard/admin/AdminChangePassword";
// import BasicProfile from "./components/dashboard/shared/BasicProfile";
import BorrowerBasicProfile from "./components/dashboard/borrower/BorrowerBasicProfile";
import LenderBasicProfile from "./components/dashboard/lender/LenderBasicProfile";
import AdminBasicProfile from "./components/dashboard/admin/AdminBasicProfile";

import BorrowerOverview from "./components/dashboard/borrower/BorrowerOverview";
import BorrowerNotifications from "./components/dashboard/borrower/BorrowerNotifications";
import BorrowerLoanList from "./components/dashboard/borrower/BorrowerLoanList";
import BorrowerLoanCreate from "./components/dashboard/borrower/BorrowerLoanCreate";
import BorrowerLoanUpdate from "./components/dashboard/borrower/BorrowerLoanUpdate";
import BorrowerCompanyCreate from "./components/dashboard/borrower/BorrowerCompanyCreate";
import BorrowerCompanyList from "./components/dashboard/borrower/BorrowerCompanyList";
import BorrowerCompanyUpdate from "./components/dashboard/borrower/BorrowerCompanyUpdate";
import BorrowerAdvancedProfile from "./components/dashboard/borrower/BorrowerAdvancedProfile";
import BorrowerDocumentList from "./components/dashboard/borrower/BorrowerDocumentList";
import BorrowerDocumentShow from "./components/dashboard/borrower/BorrowerDocumentShow";
import AdminDocumentShow from "./components/dashboard/admin/AdminDocumentShow";

import LenderOverview from "./components/dashboard/lender/LenderOverview";
import LenderNotifications from "./components/dashboard/lender/LenderNotifications";
import LenderLoanList from "./components/dashboard/lender/LenderLoanList";
import LenderWallet from "./components/dashboard/lender/LenderWallet";
import LenderLoanDetails from "./components/dashboard/lender/LenderLoanDetails";
import LenderAdvancedProfile from "./components/dashboard/lender/LenderAdvancedProfile";

import AdminOverviewGeneral from "./components/dashboard/admin/AdminOverviewGeneral";
import AdminOverviewUsers from "./components/dashboard/admin/AdminOverviewUsers";
import AdminOverviewLoans from "./components/dashboard/admin/AdminOverviewLoans";
import AdminOverviewBorrowers from "./components/dashboard/admin/AdminOverviewBorrowers";
import AdminOverviewLenders from "./components/dashboard/admin/AdminOverviewLenders";
import AdminOverviewCrowdfundingCampaigns from "./components/dashboard/admin/AdminOverviewCrowdfundingCampaigns";
import AdminNotifications from "./components/dashboard/admin/AdminNotifications";
import AdminLoanList from "./components/dashboard/admin/AdminLoanList";
import AdminUserList from "./components/dashboard/admin/AdminUserList";
import AdminLoanBasic from "./components/dashboard/admin/AdminLoanBasic";
import AdminLoanAdvanced from "./components/dashboard/admin/AdminLoanAdvanced";
import AdminLoanCoverImage from "./components/dashboard/admin/AdminLoanCoverImage";
import AdminLoanInvestigationGallery from "./components/dashboard/admin/AdminLoanInvestigationGallery";
import AdminLoanIndicators from "./components/dashboard/admin/AdminLoanIndicators";
import AdminLoanDocuments from "./components/dashboard/admin/AdminLoanDocuments";
import AdminLoanRiskProfiling from "./components/dashboard/admin/AdminLoanRiskProfiling";
import AdminAdvancedProfile from "./components/dashboard/admin/AdminAdvancedProfile";

// Our Services
// import * as authService from "./services/auth-service";
import * as userService from "./services/user-service";
// import * as accessTokenService from "./services/access-token-service";

// Our Configs
import { getRoutePath } from "./configs/route-config";

// Our helpers
// import { getPathOfBasicProfileRoute, getPathOfChangePasswordRoute } from "./helpers/route-helper";

// Our css
import "./App.css";

export default class App extends React.Component {

    initialState = {
        authenticatedUser: null,
    };

    // -----------------------------------------------

    // Clone a separate copy of the "initialState" and assign it to the "state".
    // Note that the spread operator "..." is important to get a separate copy.
    state = { ...this.initialState };

    // -----------------------------------------------

    componentDidMount() {
        this.setAuthenticatedUserState();
    }

    // -----------------------------------------------

    setAuthenticatedUserState = async () => {
        const user = await userService.getCurrentUser();

        this.setState({
            authenticatedUser: user || null,
        });
    }

    // -----------------------------------------------

    render() {
        // const authenticatedUser = this.state.authenticatedUser;

        return (
            <BrowserRouter>
                <Switch>
                    {/* PUBLIC ROUTES */}
                    <Route
                        exact
                        path={getRoutePath("home")}
                        // render={(props) => <Home {...props} authenticatedUser={this.state.authenticatedUser} />}
                        render={(props) => <HomeV2 {...props} authenticatedUser={this.state.authenticatedUser} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("contactUs")}
                        render={(props) => <ContactUs {...props} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("loans")}
                        render={(props) => <Loans {...props} authenticatedUser={this.state.authenticatedUser} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("loanDetails") + "/:loanUuid"}
                        render={(props) => <LoanDetails {...props} authenticatedUser={this.state.authenticatedUser} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("login")}
                        render={(props) => <Login {...props} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("logout")}
                        render={(props) => <Logout {...props} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("preSignup")}
                        render={(props) => <PreSignup {...props} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("signup")}
                        render={(props) => <Signup {...props} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("passwordForgot")}
                        render={(props) => <PasswordForgot {...props} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("passwordReset")}
                        render={(props) => <PasswordReset {...props} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("borrowOverview")}
                        render={(props) => <BorrowOverview {...props} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("borrowTermsAndConditions")}
                        render={(props) => <BorrowTermsAndConditions {...props} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("borrowPrivacyPolicy")}
                        render={(props) => <BorrowPrivacyPolicy {...props} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("borrowFaqs")}
                        render={(props) => <BorrowFaqs {...props} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("investOverview")}
                        render={(props) => <InvestOverview {...props} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("investTermsAndConditions")}
                        render={(props) => <InvestTermsAndConditions {...props} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("investPrivacyPolicy")}
                        render={(props) => <InvestPrivacyPolicy {...props} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("investFaqs")}
                        render={(props) => <InvestFaqs {...props} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("switchLanguage")}
                        render={(props) => <SwitchLanguage {...props} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("notFound")}
                        render={(props) => <NotFound {...props} />}
                    />

                    {/* PROTECTED ROUTES */}
                    {/* ROUTES OF THE BORROWER DASHBOARD */}
                    <Route
                        exact
                        path={getRoutePath("borrowerChangePassword")}
                        render={(props) => <BorrowerChangePassword {...props} authenticatedUser={this.state.authenticatedUser} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("borrowerBasicProfile")}
                        render={(props) => <BorrowerBasicProfile {...props} authenticatedUser={this.state.authenticatedUser} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("borrowerOverview")}
                        render={(props) => <BorrowerOverview {...props} authenticatedUser={this.state.authenticatedUser} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("borrowerNotifications")}
                        render={(props) => <BorrowerNotifications {...props} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("borrowerLoanList")}
                        render={(props) => <BorrowerLoanList {...props} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("borrowerLoanCreate")}
                        render={(props) => <BorrowerLoanCreate {...props} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("borrowerLoanUpdate") + "/:loanUuid"}
                        render={(props) => <BorrowerLoanUpdate {...props} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("borrowerCompanyList")}
                        render={(props) => <BorrowerCompanyList {...props} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("borrowerCompanyCreate")}
                        render={(props) => <BorrowerCompanyCreate {...props} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("borrowerCompanyUpdate") + "/:companyId"}
                        render={(props) => <BorrowerCompanyUpdate {...props} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("borrowerAdvancedProfile")}
                        render={(props) => <BorrowerAdvancedProfile {...props} authenticatedUser={this.state.authenticatedUser} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("borrowerDocumentList")}
                        render={(props) => <BorrowerDocumentList {...props} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("borrowerDocumentShow") + "/:documentUuid"}
                        render={(props) => <BorrowerDocumentShow {...props} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("adminDocumentShow") + "/:documentUuid"}
                        render={(props) => <AdminDocumentShow {...props} />}
                    />
                    {/* ROUTES OF THE LENDER DASHBOARD */}
                    <Route
                        exact
                        path={getRoutePath("lenderChangePassword")}
                        render={(props) => <LenderChangePassword {...props} authenticatedUser={this.state.authenticatedUser} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("lenderBasicProfile")}
                        render={(props) => <LenderBasicProfile {...props} authenticatedUser={this.state.authenticatedUser} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("lenderOverview")}
                        render={(props) => <LenderOverview {...props} authenticatedUser={this.state.authenticatedUser} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("lenderNotifications")}
                        render={(props) => <LenderNotifications {...props} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("lenderWallet")}
                        render={(props) => <LenderWallet {...props} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("lenderLoanList")}
                        render={(props) => <LenderLoanList {...props} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("lenderLoanDetails") + "/:loanUuid"}
                        render={(props) => <LenderLoanDetails {...props} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("lenderAdvancedProfile")}
                        render={(props) => <LenderAdvancedProfile {...props} authenticatedUser={this.state.authenticatedUser} />}
                    />
                    {/* ROUTES OF THE ADMIN DASHBOARD */}
                    <Route
                        exact
                        path={getRoutePath("adminChangePassword")}
                        render={(props) => <AdminChangePassword {...props} authenticatedUser={this.state.authenticatedUser} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("adminBasicProfile")}
                        render={(props) => <AdminBasicProfile {...props} authenticatedUser={this.state.authenticatedUser} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("adminOverviewGeneral")}
                        render={(props) => <AdminOverviewGeneral {...props} authenticatedUser={this.state.authenticatedUser} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("adminOverviewUsers")}
                        render={(props) => <AdminOverviewUsers {...props} authenticatedUser={this.state.authenticatedUser} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("adminOverviewLoans")}
                        render={(props) => <AdminOverviewLoans {...props} authenticatedUser={this.state.authenticatedUser} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("adminOverviewBorrowers")}
                        render={(props) => <AdminOverviewBorrowers {...props} authenticatedUser={this.state.authenticatedUser} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("adminOverviewLenders")}
                        render={(props) => <AdminOverviewLenders {...props} authenticatedUser={this.state.authenticatedUser} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("adminOverviewCrowdfundingCampaigns")}
                        render={(props) => <AdminOverviewCrowdfundingCampaigns {...props} authenticatedUser={this.state.authenticatedUser} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("adminNotifications")}
                        render={(props) => <AdminNotifications {...props} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("adminLoanList")}
                        render={(props) => <AdminLoanList {...props} authenticatedUser={this.state.authenticatedUser} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("adminUserList")}
                        render={(props) => <AdminUserList {...props} authenticatedUser={this.state.authenticatedUser} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("adminLoanBasic") + "/:loanUuid"}
                        render={(props) => <AdminLoanBasic {...props} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("adminLoanAdvanced") + "/:loanUuid"}
                        render={(props) => <AdminLoanAdvanced {...props} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("adminLoanCoverImage") + "/:loanUuid"}
                        render={(props) => <AdminLoanCoverImage {...props} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("adminLoanInvestigationGallery") + "/:loanUuid"}
                        render={(props) => <AdminLoanInvestigationGallery {...props} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("adminLoanIndicators") + "/:loanUuid"}
                        render={(props) => <AdminLoanIndicators {...props} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("adminLoanDocuments") + "/:loanUuid"}
                        render={(props) => <AdminLoanDocuments {...props} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("adminLoanRiskProfiling") + "/:loanUuid"}
                        render={(props) => <AdminLoanRiskProfiling {...props} />}
                    />
                    <Route
                        exact
                        path={getRoutePath("adminAdvancedProfile")}
                        render={(props) => <AdminAdvancedProfile {...props} authenticatedUser={this.state.authenticatedUser} />}
                    />

                    <Redirect to={getRoutePath("notFound")} />
                </Switch>
            </BrowserRouter>
        );
    }
}
