import React from "react";
import "./sass/ButtonLoader.scss";

const ButtonLoader = (props) => {
    return (
        <div className="button-loader-wrapper">
            <div className="button-loader-dot-typing"></div>
        </div>
    );
}

export default ButtonLoader;
