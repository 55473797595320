import React from "react";
import { preferredLanguage } from "./../configs/ui-language-config";
import { GeneralTexts } from "./../languages/GeneralTexts";
import { RouteConfigTexts } from "./RouteConfigTexts";
import { BorrowerFirstLoanApplicationWizardSubMenuTexts as wizardTexts } from "./../languages/BorrowerFirstLoanApplicationWizardSubMenuTexts";

const texts = {
    ar: {
        "borrower-loan-create": RouteConfigTexts["borrower-loan-create"],
        "apply-for-new-loan"  : GeneralTexts["apply-for-new-loan"],
        "borrower-loan-list"  : RouteConfigTexts["borrower-loan-list"],
        "company"             : "الشركة",
        "required-amount"     : "المبلغ المطلوب",
        "currency"            : "العملة",
        "purpose"             : "الغرض من القرض",
        "duration"            : "مدة القرض",
        "has-previous-experience-in-the-transaction": "لديك خبرة سابقة في العملية محل التمويل؟",
        "percentage-of-the-transaction-gross-profit": "نسبة الربح الإجمالي للعملية (%)",
        "description-of-transaction"                : "ما تفاصيل العملية التي تريد تمويلها؟",
        "description-of-transaction-placeholder"    : "اشرح هنا بالتفصيل طبيعة العملية التي تريد الاقتراض لتنفيذها..",
        "step-4-hint": (
            <React.Fragment>
                <p>خطوة <span className="__ff-rubik x-fz-18">4</span> من <span className="__ff-rubik x-fz-18">4</span> &nbsp;|&nbsp; <strong>{wizardTexts["loan-data"]}</strong></p>
                <p>أكمل البيانات المطلوبة التالية ثم اضغط زر <strong>{GeneralTexts["save"]}</strong> لتكون بذلك قد انتهيت من تقديم أول طلب قرض لك على المنصة، أو أضغط زر <strong>{GeneralTexts["previous"]}</strong> لترجع للخطوة السابقة.</p>
            </React.Fragment>
        ),
    },

    en: {
        "borrower-loan-create": RouteConfigTexts["borrower-loan-create"],
        "apply-for-new-loan"  : GeneralTexts["apply-for-new-loan"],
        "borrower-loan-list"  : RouteConfigTexts["borrower-loan-list"],
        "company"             : "Company",
        "required-amount"     : "Required Amount",
        "currency"            : "Currency",
        "purpose"             : "Purpose of Loan",
        "duration"            : "Duration of Loan",
        "has-previous-experience-in-the-transaction": "Has Previous Experience in The Transaction?",
        "percentage-of-the-transaction-gross-profit": "Percentage of The Transaction Gross Profit (%)",
        "description-of-transaction"                : "What are you going to do with the loan?",
        "description-of-transaction-placeholder"    : "Describe here what are you going to do with the loan..",
        "step-4-hint": (
            <React.Fragment>
                <p>Step <span className="__ff-rubik x-fz-18">4</span> of <span className="__ff-rubik x-fz-18">4</span> &nbsp;|&nbsp; <strong>{wizardTexts["loan-data"]}</strong></p>
                <p>Complete the following data and click <strong>{GeneralTexts["save"]}</strong> to complete your first loan application, or click <strong>{GeneralTexts["previous"]}</strong> to go back to the previous step.</p>
            </React.Fragment>
        ),
    }
}

export const BorrowerLoanCreateTexts = texts[preferredLanguage.alpha2code];
