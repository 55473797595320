import React from "react";
import { NavLink } from "react-router-dom";

import { switchClasses } from "./../../../languages/CssClasses";
import { AdminOverviewSubMenuTexts as texts } from "./../../../languages/AdminOverviewSubMenuTexts";

// Our Configs
import { getRoutePath } from "./../../../configs/route-config";

export default class AdminOverviewSubMenu extends React.Component {
    state = {};

    render() {
        return (
            <div className={"list-group shadow-sm x-mt-15 x-mb-30 __ff-droidarabickufi " + switchClasses(["x-fz-08rem"])}>
                <NavLink to={getRoutePath("adminOverviewGeneral")} className="list-group-item list-group-item-action _active">
                    {texts["general"]}
                </NavLink>
                <NavLink to={getRoutePath("adminOverviewLoans")} className="list-group-item list-group-item-action">
                    {texts["loans"]}
                </NavLink>
                <NavLink to={getRoutePath("adminOverviewUsers")} className="list-group-item list-group-item-action">
                    {texts["users"]}
                </NavLink>
                <NavLink to={getRoutePath("adminOverviewBorrowers")} className="list-group-item list-group-item-action">
                    {texts["borrowers"]}
                </NavLink>
                <NavLink to={getRoutePath("adminOverviewLenders")} className="list-group-item list-group-item-action">
                    {texts["investors"]}
                </NavLink>
                {/* <NavLink to={getRoutePath("adminOverviewCrowdfundingCampaigns")} className="list-group-item list-group-item-action">
                    Crowdfunding Campaigns
                </NavLink> */}
            </div>
        );
    }
}
