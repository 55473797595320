// Third-Party modules
import * as Sentry from "@sentry/react";

const init = () => {
    // sentry.io is an error logging and tracking service
    // TODO: Replace this with Amwal's config on sentry.io
    Sentry.init({
        dsn: "https://09c828bd3a7545948b7e1dcfa4fbd3ef@o424045.ingest.sentry.io/5355327"
    });

}

const log = (error) => {
    Sentry.captureException(error);
}

export default {
    init,
    log
}
