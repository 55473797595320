import React from "react";
import { Link } from "react-router-dom";

import { preferredLanguage } from "./../../../configs//ui-language-config";
import { switchClasses } from "./../../../languages/CssClasses";
import { AdminLoanIndicatorsTexts as texts } from "./../../../languages/AdminLoanIndicatorsTexts";
import { GeneralTexts } from "./../../../languages/GeneralTexts";

// Third-Party Modules
// import _isEmpty from "lodash/isEmpty";
import Joi from "@hapi/joi";
import Icon from "@mdi/react"
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";

// Our Modules
import MessageModal, { toggleMessageModal, getErrorMessage } from "./../../shared/MessageModal";
import FormHandler from "./../../shared/FormHandler";
import TextBox from "./../../shared/TextBox";
import SelectBox from "./../../shared/SelectBox";
// import TextareaBox from "./../../shared/TextareaBox";
import CustomButton from "./../../shared/CustomButton";
import PageLoader from "./../../shared/PageLoader";
import ButtonLoader from "./../../shared/ButtonLoader";
import RequiredDataHint from "./../../shared/RequiredDataHint";
import AdminLoanSubMenu from "./AdminLoanSubMenu";
import YearPickerBox from "./../../shared/YearPickerBox";
import PageContent from "./../../shared/PageContent";

// Our Services
import documentTitleService from "./../../../services/document-title-service";
import logService from "./../../../services/log-service";
import * as loanService from "./../../../services/loan-service";

// Our Configs
import { getRoutePath } from "./../../../configs/route-config";
import { getCommonCountryList } from "./../../../configs/country-config";
// import { getLoanPurposes, getLoanStatuses, getLoanDurations, getPercentagesOfTransactionGrossProfit, getPercentagesOfExpectedReturn } from "./../../../configs/loan-config";

export default class AdminLoanAdvanced extends FormHandler {
    /**
     *
     */
    initialState = {
        ...this.initialStateElements,
        allowedUserType: "admin",

        formInputs: {
            currency                                              : "",
            company_capital_size                                  : "",
            company_average_monthly_sales                         : "",
            company_last_year_revenue                             : "",
            company_number_of_employees                           : "",
            company_operating_license_status                      : "",
            company_operating_license_expiry_year                 : "",
            company_head_office_type                              : "",
            company_head_office_leasing_expiry_year               : "",
            company_head_office_ownership_type                    : "",
            company_head_office_ownership_installments_end_in_year: "",
            company_i_score                                       : "",
            company_has_bank_account                              : "",
            company_has_financial_statements                      : "",
            company_has_tax_related_issues                        : "",
            company_submitted_tax_form                            : "",
            company_has_social_insurance_related_issues           : "",
            company_submitted_social_insurance_form               : "",
            company_has_defaulted_loans                           : "",
            company_has_existing_bank_loans                       : "",
        },
        validationErrors: {},
        messageModal    : {},
        showPageLoader  : true,
        showButtonLoader: false,
        loan            : {},
        loanIndicator  : {},
        // user            : {},
        // borrower        : {},
        // company         : {},
        urlParmas       : {},
    };

    /**
     * Clone a separate copy of the "initialState" and assign it to the "state".
     * Note that the spread operator "..." is important to get a separate copy.
     */
    state = { ...this.initialState };

    /**
     *
     */
    validationRules = {
        currency: Joi.string()
        .required()
        .label("Currency"),

        company_capital_size: Joi.number()
            .required()
            .integer()
            .positive()
            .label("Company Capital Size"),

        company_average_monthly_sales: Joi.number()
            .required()
            .integer()
            .positive()
            .label("Company Average Monthly Sales"),

        company_last_year_revenue: Joi.number()
            .required()
            .integer()
            .positive()
            .label("Company Last Year Revenue"),

        company_number_of_employees: Joi.number()
            .required()
            .integer()
            .positive()
            .label("Company Number of Employees"),

        company_operating_license_status: Joi.string()
            .required()
            .label("Company Operating License Status"),

        company_operating_license_expiry_year: Joi.number()
            .required()
            .integer()
            .positive()
            // Can't be before the year 1900
            // .min(1900)
            // Can't be after the current year
            // .max(new Date().getFullYear())
            .label("Company Operating License Expiry Year"),

        company_head_office_type: Joi.string()
            .required()
            .label("Company Head Office Type"),

        company_head_office_leasing_expiry_year: Joi.number()
            .optional()
            .allow("", null) // More info: t.ly/mMgn
            .integer()
            .positive()
            // Can't be before the year 1900
            // .min(1900)
            // Can't be after the current year
            // .max(new Date().getFullYear())
            .label("Company Head Office Leasing Expiry Year"),

        company_head_office_ownership_type: Joi.string()
            // .required()
            .optional()
            .allow("", null) // More info: t.ly/mMgn
            .label("Company Head Office Ownership Type"),

        company_head_office_ownership_installments_end_in_year: Joi.number()
            .optional()
            .allow("", null) // More info: t.ly/mMgn
            .integer()
            .positive()
            .label("Company Head Office Ownership Installments End in Year"),

        company_i_score: Joi.string()
            .optional()
            .allow("", null) // More info: t.ly/mMgn
            .label("Company iScore"),

        company_has_bank_account: Joi.string()
            .required()
            .label("Company Has Bank Account?"),

        company_has_financial_statements: Joi.string()
            .required()
            .label("Company Has Financial Statements?"),

        company_has_tax_related_issues: Joi.string()
            .required()
            .label("Company Has Tax Related Issues?"),

        company_submitted_tax_form: Joi.string()
            .required()
            .label("Company Submitted Tax Form?"),

        company_has_social_insurance_related_issues: Joi.string()
            .required()
            .label("Company Has Social Insurance Related Issues?"),

        company_submitted_social_insurance_form: Joi.string()
            .required()
            .label("Company Submitted Social Insurance Form?"),

        company_has_defaulted_loans: Joi.string()
            .required()
            .label("Company Has Defaulted Loans?"),

        company_has_existing_bank_loans: Joi.string()
            .required()
            .label("Company Has Existing Bank Loans?"),

    };

    /**
     *
     */
    componentDidMount() {
        // More info: t.ly/ZVsd
        window.scrollTo(0, 0);

        // Properly set the browser's document title
        documentTitleService.setTitle(GeneralTexts["admin-dashboard"] + " | " + texts["admin-loan-indicators"]);

        this.setState({ urlParmas: this.props.match.params });

        this.setAuthenticatedUser();
        this.getLoan();
    }

    /**
     * Get loan data that the admin tries to update
     */
    getLoan = async () => {
        this.setState({ showPageLoader: true });

        // const response = await loanService.getItem(this.props.match.params.loanUuid, "?include=borrower.user|company");
        const response = await loanService.getItem(this.props.match.params.loanUuid, "?include=indicator");

        const loan = response.data.data.loan || {};
        const loanIndicator = loan.indicator || {};

        this.setState({
            formInputs: {
                currency                                              : loanIndicator.currency || "",
                company_capital_size                                  : loanIndicator.company_capital_size || "",
                company_average_monthly_sales                         : loanIndicator.company_average_monthly_sales || "",
                company_last_year_revenue                             : loanIndicator.company_last_year_revenue || "",
                company_number_of_employees                           : loanIndicator.company_number_of_employees || "",
                company_operating_license_status                      : loanIndicator.company_operating_license_status || "",
                company_operating_license_expiry_year                 : loanIndicator.company_operating_license_expiry_year || "",
                company_head_office_type                              : loanIndicator.company_head_office_type || "",
                company_head_office_leasing_expiry_year               : loanIndicator.company_head_office_leasing_expiry_year || "",
                company_head_office_ownership_type                    : loanIndicator.company_head_office_ownership_type || "",
                company_head_office_ownership_installments_end_in_year: loanIndicator.company_head_office_ownership_installments_end_in_year || "",
                company_i_score                                       : loanIndicator.company_i_score || "",
                company_has_bank_account                              : loanIndicator.company_has_bank_account || "",
                company_has_financial_statements                      : loanIndicator.company_has_financial_statements || "",
                company_has_tax_related_issues                        : loanIndicator.company_has_tax_related_issues || "",
                company_submitted_tax_form                            : loanIndicator.company_submitted_tax_form || "",
                company_has_social_insurance_related_issues           : loanIndicator.company_has_social_insurance_related_issues || "",
                company_submitted_social_insurance_form               : loanIndicator.company_submitted_social_insurance_form || "",
                company_has_defaulted_loans                           : loanIndicator.company_has_defaulted_loans || "",
                company_has_existing_bank_loans                       : loanIndicator.company_has_existing_bank_loans || "",
            },
            showPageLoader: false,
            loan          : loan,
            loanIndicator : loanIndicator,
            // borrower      : loan.borrower,
            // user          : loan.borrower.user,
            // company       : loan.company,
        });
    }

    /**
     *
     */
    doSubmit = async (e) => {
        // Display a loader icon
        this.setState({ showButtonLoader: true });

        try {
            const response = await loanService.updateLoanIndicatorData(this.state.loanIndicator.uuid, { ...this.state.formInputs, loan_id: this.state.loan.id });

            // This fixes a bug that was occur when trying to update the
            // data of the loan_indicator directory after creating it
            this.getLoan();

            this.setState({
                // loanIndicator: response.data.data.loan_indicator,
                messageModal: {
                    ...toggleMessageModal("success"),
                    body: response.data.meta.message,
                    footerButtons: [
                        {
                            variant: "primary",
                            title: "Back to Loans",
                            onClick: () => this.props.history.push(getRoutePath("adminLoanList")),
                        },
                        {
                            variant: "primary",
                            title: "Close",
                            onClick: () => this.setState({ messageModal: { ...this.initialState.messageModal } }),
                        }
                    ],
                    // onHide: () => this.props.history.push(getRoutePath("adminLoanList")),
                }
            });

        } catch (error) {
            /**
             * If there is an unexpected error like: network down, server down, db down, bug, ..
             *  - Log the error.
             *  - Display a generic and friendly error message.
             *
             * Note that "error.response" determines if the error is an expected error or not.
             */

            const isExpectedError =
                error.response
                && error.response.status >= 400
                && error.response.status < 500;

            // if (!error.response) {
            if (!isExpectedError) {
                // TEST
                // console.log("Logging the error", error);

                // Log the error
                logService.log(error);
            }

            // Display an appropriate error message
            this.showErrorMessage(error);
        }

        // Hide the loader
        this.setState({ showButtonLoader: false });
    };

    /**
     *
     */
    showErrorMessage = (error, props = this.props) => {
        this.setState({
            messageModal: {
                ...toggleMessageModal("error"),
                body: getErrorMessage(error, props).body || "",
                footerButtons: getErrorMessage(error, props).footerButtons || [],
            }
        });
    }

    /**
     *
     */
	render() {
        return (
            <PageContent
                pageContent={this.getPageContent()}
                authenticatedUser={this.getAuthenticatedUser()}
                showPageContent={this.showPageContent()}
                windowLocation={window.location}
            />
        );
	}

    /**
     *
     */
    getPageContent = () => {
        return (
            <div>
                {this.getPageTitle()}

                <div className="x-mt-15">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-3 col-lg-2 x-pr-8 x-pl-8">
                                <AdminLoanSubMenu loan={this.state.loan} />
                            </div>

                            <div className="col-md-9 col-lg-10 x-pr-8 x-pl-8">
                                <div className="shadow-sm x-m-0 x-mt-15 x-mb-70 x-p-20 x-pt-16 x-pb-100 x-mih-500 x-bgc-fff x-bdc-e3e3e3 x-bdrs-4">
                                    <PageLoader show={this.state.showPageLoader} />

                                    <div className={"row" + (this.state.showPageLoader ? " x-d-n" : "")}>
                                        <div className="col">
                                            {this.getFormContent()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.getMessageModal()}
            </div>
        );
    }

    /**
     *
     */
    getPageTitle = () => {
        return (
            <>
                <div className={"x-m-25 x-mb-15 " + switchClasses(["x-mr-15"])}>
                    <div>
                        <Link to={getRoutePath("adminLoanList")} className="x-fz-085rem">
                            <Icon
                                path={preferredLanguage.direction === "rtl" ? mdiChevronRight : mdiChevronLeft}
                                size="1.4em"
                                className="x-pos-r x-b-2"
                            />
                            <span className={switchClasses(["__ff-droidarabickufi"])}>
                                {texts["admin-loan-list"]}
                            </span>
                        </Link>
                    </div>

                    <h5 className={"x-pt-10 head-color "}>{texts["admin-loan-indicators"]}</h5>
                </div>
            </>
        );
    }

    /**
     *
     */
    getFormContent = () => {
        return (
            <>
                <form className={"__ff-droidarabickufi " + switchClasses(["x-fz-085rem"])} onSubmit={(e) => e.preventDefault()}>
                    <div className="row">
                        <div className="col-md-3">
                            <SelectBox
                                name="currency"
                                value={this.state.formInputs.currency || ""}
                                label={texts["currency"]}
                                onBlur={this.handleBlur}
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                validationError={this.state.validationErrors.currency || ""}
                                selectBoxOptions={getCommonCountryList().map(country => {
                                    return (
                                        <option
                                            key={Math.random()}
                                            value={country.currencies[0].code}
                                        >
                                            {country.currencies[0].name + " (" + country.currencies[0].code + ")"}
                                        </option>
                                    )
                                })}
                            />
                        </div>

                        <div className="col-md-3">
                            <SelectBox
                                name="company_i_score"
                                value={this.state.formInputs.company_i_score || ""}
                                label={texts["company-iscore"]}
                                onBlur={this.handleBlur}
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                validationError={this.state.validationErrors.company_i_score || ""}
                                // TODO: Move these values to a separte config file
                                selectBoxOptions={
                                    <React.Fragment>
                                        <option key={Math.random()} value="excellent">Excellent</option>
                                        <option key={Math.random()} value="very-good">Very Good</option>
                                        <option key={Math.random()} value="good">Good</option>
                                        <option key={Math.random()} value="pass">Pass</option>
                                        <option key={Math.random()} value="weak">Weak</option>
                                        <option key={Math.random()} value="very-weak">Very Weak</option>
                                    </React.Fragment>
                                }
                                required={false}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            <TextBox
                                name="company_capital_size"
                                label={texts["company-capital-size"]}
                                value={this.state.formInputs.company_capital_size || ""}
                                onBlur={this.handleBlur}
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                validationError={this.state.validationErrors.company_capital_size || ""}
                                placeholder="3000000"
                                extraCssClasses="x-dr-ltr x-ta-l"
                            />
                        </div>
                        <div className="col-md-3">
                            <TextBox
                                name="company_average_monthly_sales"
                                label={texts["company-average-monthly-sales"]}
                                value={this.state.formInputs.company_average_monthly_sales || ""}
                                onBlur={this.handleBlur}
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                validationError={this.state.validationErrors.company_average_monthly_sales || ""}
                                placeholder="750000"
                                extraCssClasses="x-dr-ltr x-ta-l"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            <TextBox
                                name="company_last_year_revenue"
                                label={texts["company-last-year-revenue"]}
                                value={this.state.formInputs.company_last_year_revenue || ""}
                                onBlur={this.handleBlur}
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                validationError={this.state.validationErrors.company_last_year_revenue || ""}
                                placeholder="5000000"
                                extraCssClasses="x-dr-ltr x-ta-l"
                            />
                        </div>
                        <div className="col-md-3">
                            <TextBox
                                name="company_number_of_employees"
                                label={texts["company-number-of-employees"]}
                                value={this.state.formInputs.company_number_of_employees || ""}
                                onBlur={this.handleBlur}
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                validationError={this.state.validationErrors.company_number_of_employees || ""}
                                placeholder="12"
                                extraCssClasses="x-dr-ltr x-ta-l"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            <SelectBox
                                name="company_operating_license_status"
                                value={this.state.formInputs.company_operating_license_status || ""}
                                label={texts["company-operating-license-status"]}
                                onBlur={this.handleBlur}
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                validationError={this.state.validationErrors.company_operating_license_status || ""}
                                // TODO: Move these values to a separte config file
                                selectBoxOptions={
                                    <React.Fragment>
                                        <option key={Math.random()} value="valid">Valid</option>
                                        <option key={Math.random()} value="not-valid">Not Valid</option>
                                        <option key={Math.random()} value="in-process">In Process</option>
                                    </React.Fragment>
                                }
                            />
                        </div>

                        <div className="col-md-3">
                            <YearPickerBox
                                isClearable={true}
                                label={texts["company-operating-license-expiry-year"]}
                                name="company_operating_license_expiry_year"
                                onBlur={this.handleBlur}
                                onChange={(date) => {this.handleYearPickerChange(date, "company_operating_license_expiry_year") }}
                                onFocus={this.handleFocus}
                                required={true}
                                validationError={this.state.validationErrors.company_operating_license_expiry_year || ""}
                                value={this.state.formInputs.company_operating_license_expiry_year || ""}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            <SelectBox
                                name="company_head_office_type"
                                value={this.state.formInputs.company_head_office_type || ""}
                                label={texts["company-head-office-type"]}
                                onBlur={this.handleBlur}
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                validationError={this.state.validationErrors.company_head_office_type || ""}
                                // TODO: Move these values to a separte config file
                                selectBoxOptions={
                                    <React.Fragment>
                                        <option key={Math.random()} value="leasing">Leasing</option>
                                        <option key={Math.random()} value="ownership">Ownership</option>
                                    </React.Fragment>
                                }
                            />
                        </div>

                        <div className="col-md-3">
                            <YearPickerBox
                                isClearable={true}
                                label={texts["company-head-office-leasing-expiry-year"]}
                                name="company_head_office_leasing_expiry_year"
                                onBlur={this.handleBlur}
                                onChange={(date) => { this.handleYearPickerChange(date, "company_head_office_leasing_expiry_year") }}
                                onFocus={this.handleFocus}
                                required={false}
                                validationError={this.state.validationErrors.company_head_office_leasing_expiry_year || ""}
                                value={this.state.formInputs.company_head_office_leasing_expiry_year || ""}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            <SelectBox
                                name="company_head_office_ownership_type"
                                value={this.state.formInputs.company_head_office_ownership_type || ""}
                                label={texts["company-head-office-ownership-type"]}
                                onBlur={this.handleBlur}
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                required={false}
                                validationError={this.state.validationErrors.company_head_office_ownership_type || ""}
                                // TODO: Move these values to a separte config file
                                selectBoxOptions={
                                    <React.Fragment>
                                        <option key={Math.random()} value="cash">Cash</option>
                                        <option key={Math.random()} value="on-credit">On Credit</option>
                                    </React.Fragment>
                                }
                            />
                        </div>

                        <div className="col-md-3">
                            <YearPickerBox
                                isClearable={true}
                                label={texts["ownership-installments-end-in-year"]}
                                name="company_head_office_ownership_installments_end_in_year"
                                onBlur={this.handleBlur}
                                onChange={(date) => { this.handleYearPickerChange(date, "company_head_office_ownership_installments_end_in_year") }}
                                onFocus={this.handleFocus}
                                required={false}
                                validationError={this.state.validationErrors.company_head_office_ownership_installments_end_in_year || ""}
                                value={this.state.formInputs.company_head_office_ownership_installments_end_in_year || ""}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            <SelectBox
                                name="company_has_bank_account"
                                value={this.state.formInputs.company_has_bank_account || ""}
                                label={texts["company-has-bank-account"]}
                                onBlur={this.handleBlur}
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                validationError={this.state.validationErrors.company_has_bank_account || ""}
                                // TODO: Move these values to a separte config file
                                selectBoxOptions={
                                    <React.Fragment>
                                        <option key={Math.random()} value="yes">Yes</option>
                                        <option key={Math.random()} value="no">No</option>
                                    </React.Fragment>
                                }
                            />
                        </div>

                        <div className="col-md-3">
                            <SelectBox
                                name="company_has_financial_statements"
                                value={this.state.formInputs.company_has_financial_statements || ""}
                                label={texts["company-has-financial-statements"]}
                                onBlur={this.handleBlur}
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                validationError={this.state.validationErrors.company_has_financial_statements || ""}
                                // TODO: Move these values to a separte config file
                                selectBoxOptions={
                                    <React.Fragment>
                                        <option key={Math.random()} value="yes">Yes</option>
                                        <option key={Math.random()} value="no">No</option>
                                    </React.Fragment>
                                }
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            <SelectBox
                                name="company_has_tax_related_issues"
                                value={this.state.formInputs.company_has_tax_related_issues || ""}
                                label={texts["company-has-tax-related-issues"]}
                                onBlur={this.handleBlur}
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                validationError={this.state.validationErrors.company_has_tax_related_issues || ""}
                                // TODO: Move these values to a separte config file
                                selectBoxOptions={
                                    <React.Fragment>
                                        <option key={Math.random()} value="yes">Yes</option>
                                        <option key={Math.random()} value="no">No</option>
                                    </React.Fragment>
                                }
                            />
                        </div>

                        <div className="col-md-3">
                            <SelectBox
                                name="company_submitted_tax_form"
                                value={this.state.formInputs.company_submitted_tax_form || ""}
                                label={texts["company-submitted-tax-form"]}
                                onBlur={this.handleBlur}
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                validationError={this.state.validationErrors.company_submitted_tax_form || ""}
                                // TODO: Move these values to a separte config file
                                selectBoxOptions={
                                    <React.Fragment>
                                        <option key={Math.random()} value="yes">Yes</option>
                                        <option key={Math.random()} value="no">No</option>
                                    </React.Fragment>
                                }
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            <SelectBox
                                name="company_has_social_insurance_related_issues"
                                value={this.state.formInputs.company_has_social_insurance_related_issues || ""}
                                label={texts["company-has-social-insurance-related-issues"]}
                                onBlur={this.handleBlur}
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                validationError={this.state.validationErrors.company_has_social_insurance_related_issues || ""}
                                // TODO: Move these values to a separte config file
                                selectBoxOptions={
                                    <React.Fragment>
                                        <option key={Math.random()} value="yes">Yes</option>
                                        <option key={Math.random()} value="no">No</option>
                                    </React.Fragment>
                                }
                            />
                        </div>

                        <div className="col-md-3">
                            <SelectBox
                                name="company_submitted_social_insurance_form"
                                value={this.state.formInputs.company_submitted_social_insurance_form || ""}
                                label={texts["company-submitted-social-insurance-form"]}
                                onBlur={this.handleBlur}
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                validationError={this.state.validationErrors.company_submitted_social_insurance_form || ""}
                                // TODO: Move these values to a separte config file
                                selectBoxOptions={
                                    <React.Fragment>
                                        <option key={Math.random()} value="yes">Yes</option>
                                        <option key={Math.random()} value="no">No</option>
                                    </React.Fragment>
                                }
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            <SelectBox
                                name="company_has_existing_bank_loans"
                                value={this.state.formInputs.company_has_existing_bank_loans || ""}
                                label={texts["company-has-existing-bank-loans"]}
                                onBlur={this.handleBlur}
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                validationError={this.state.validationErrors.company_has_existing_bank_loans || ""}
                                // TODO: Move these values to a separte config file
                                selectBoxOptions={
                                    <React.Fragment>
                                        <option key={Math.random()} value="yes">Yes</option>
                                        <option key={Math.random()} value="no">No</option>
                                    </React.Fragment>
                                }
                            />
                        </div>

                        <div className="col-md-3">
                            <SelectBox
                                name="company_has_defaulted_loans"
                                value={this.state.formInputs.company_has_defaulted_loans || ""}
                                label={texts["company-has-defaulted-loans"]}
                                onBlur={this.handleBlur}
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                validationError={this.state.validationErrors.company_has_defaulted_loans || ""}
                                // TODO: Move these values to a separte config file
                                selectBoxOptions={
                                    <React.Fragment>
                                        <option key={Math.random()} value="yes">Yes</option>
                                        <option key={Math.random()} value="no">No</option>
                                    </React.Fragment>
                                }
                            />
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-md-3">
                            <div className="x-mt-20">
                                <CustomButton
                                    type="button"
                                    label={this.state.showButtonLoader ? <ButtonLoader /> : GeneralTexts["save"]}
                                    disabled={this.validateFormInputs() ? true : false}
                                    onClick={this.handleSubmit}
                                />
                            </div>
                        </div>
                    </div>
                </form>

                <RequiredDataHint className={this.state.showPageLoader ? "x-d-n" : ""} />
            </>
        );
    }

    /**
     *
     */
    getMessageModal = () => {
        return (
            <MessageModal
                show={this.state.messageModal.show || false}
                title={this.state.messageModal.title || null}
                body={this.state.messageModal.body || null}
                alertClassName={this.state.messageModal.alertClassName || null}
                size={this.state.messageModal.size || null}
                centered={this.state.messageModal.centered || false}
                footerButtons={this.state.messageModal.footerButtons || []}
                onHide={this.state.messageModal.onHide || (() => this.setState({ messageModal: { ...this.initialState.messageModal } }))}
            />
        );
    }
}
