import React from "react";
import { preferredLanguage } from "./../configs/ui-language-config";
import { GeneralTexts } from "./../languages/GeneralTexts";
import { BorrowerFirstLoanApplicationWizardSubMenuTexts as wizardTexts } from "./../languages/BorrowerFirstLoanApplicationWizardSubMenuTexts";
// import { RouteConfigTexts } from "./RouteConfigTexts";

const texts = {
    ar: {
        "my-advanced-profile"         : "بياناتي المتقدمة",
        "country-of-nationality"      : "بلد الجنسية",
        "country-of-residence"        : "بلد الإقامة",
        "city-of-residence"           : "مدينة الإقامة",
        "address-of-residence"        : "عنوان الإقامة - (المدون في بطاقة الهوية)",
        "current-address-of-residence": "عنوان الإقامة الحالي - (اترك الخانة فارغة إن كان هو نفس العنوان الموجود في بطاقة الهوية)",
        "national-id-number"          : "الرقم القومي",
        "passport-number"             : "رقم جواز السفر",
        "birth-date"                  : "تاريخ الميلاد",
        "has-checkbook"               : "لديك دفتر شيكات؟",
        "embezzled-before"            : "هل اتهمت من قبل بالاختلاس؟",
        "has-defaulted-credit-card-debts": "متعثر في سداد مديونية بطاقات ائتمان؟",
        "confirm-rightness-and-accuracy-of-entered-data": "بالضغط على الزر التالي، فإنك تقر بأن جميع البيانات السابقة التي أدخلتها صحيحة ودقيقة.",
        "welcome"                     : "Welcome",
        "first-time-loan-info": (
            <span>بما أن هذه هي المرة الأولى التي تتقدم فيها لطلب قرض على منصة <strong>أموال</strong>، فإن هناك بعض البيانات الإضافية التي سنحتاجها لإعداد حسابك على المنصة كما ينبغي. ولا تقلق، فلن تحتاج لإدخال تلك البيانات الإضافية مرة أخرى في المرات القادمة.</span>
        ),
        "step-1-hint": (
            <React.Fragment>
                <p>خطوة <span className="__ff-rubik x-fz-18">1</span> من <span className="__ff-rubik x-fz-18">4</span> &nbsp;|&nbsp; <strong>{wizardTexts["my-data"]}</strong></p>
                <p>أكمل البيانات المطلوبة التالية ثم اضغط زر <strong>{GeneralTexts["next"]}</strong> لتنتقل للخطوة التالية.</p>
            </React.Fragment>
        ),
    },

    en: {
        "my-advanced-profile"         : "My Advanced Profile",
        "country-of-nationality"      : "Country of Nationality",
        "country-of-residence"        : "Country of Residence",
        "city-of-residence"           : "City of Residence",
        "address-of-residence"        : "Address of Residence - (as in the ID card)",
        "current-address-of-residence": "Current Address of Residence - (leave the text field blank if it's the same address that's in the ID card)",
        "national-id-number"          : "National ID Number",
        "passport-number"             : "Passport Number",
        "birth-date"                  : "Birth Date",
        "has-checkbook"               : "Has Checkbook?",
        "embezzled-before"            : "Accused of Embezzlement Before?",
        "has-defaulted-credit-card-debts": "Has Defaulted Credit Card Debts?",
        "confirm-rightness-and-accuracy-of-entered-data": "By clicking the next button, you confirm that the data you've entered is right and accurate.",
        "welcome"                     : "Welcome",
        "first-time-loan-info": (
            <span>Since this is your first time applying for a loan on <strong>{GeneralTexts["website-name"]}</strong> platform, there are some additional data you need to provide so that we can properly configure your account. Don't worry, we will not ask for this data next time.</span>
        ),
        "step-1-hint": (
            <React.Fragment>
                <p>Step <span className="__ff-rubik x-fz-18">1</span> of <span className="__ff-rubik x-fz-18">4</span> &nbsp;|&nbsp; <strong>{wizardTexts["my-data"]}</strong></p>
                <p>Complete the following data and click <strong>{GeneralTexts["next"]}</strong> to move to the next step.</p>
            </React.Fragment>
        ),
    }
}

export const BorrowerAdvancedProfileTexts = texts[preferredLanguage.alpha2code];
