import { preferredLanguage } from "./../configs/ui-language-config";

const texts = {
    ar: {
        "open-opportunities"     : "الفرص المتاحة",
        "open-opportunities-hint": "فرص الاستثمار المتاحة حاليا على المنصة",
        "no-loan-requests"       : "لا يوجد فرص استثمار مفتوحة حاليا",
    },

    en: {
        "open-opportunities"     : "Open Opportunities",
        "open-opportunities-hint": "Loan requests that are currently open for crowdfunding",
        "no-loan-requests"       : "There is no open opportunities right now",
    }}

export const LoansTexts = texts[preferredLanguage.alpha2code];
