import { preferredLanguage } from "./../configs/ui-language-config";
// import { GeneralTexts } from "./GeneralTexts";
import { RouteConfigTexts } from "./RouteConfigTexts";

const texts = {
    ar: {
        "admin-loan-advanced"                       : RouteConfigTexts["admin-loan-advanced"],
        "admin-loan-list"                           : RouteConfigTexts["admin-loan-list"],
        "current-status-of-the-loan"                : "حالة القرض الحالية",
        "rejection-reason"                          : "سبب رفض القرض",
        "rejection-reason-placeholder"              : "اشرح هنا لماذا تم رفض القرض، وما الذي يمكن أن يفعله المقترض لعلاج أسباب الرفض...",
        "crowdfunding-campaign-starts-on"           : "تاريخ بداية حملة التمويل",
        "crowdfunding-campaign-ends-on"             : "تاريخ نهاية حملة التمويل",
        "transaction-starts-on"                     : "تاريخ بداية العملية المراد تمويلها",
        "transaction-ends-on"                       : "تاريخ نهاية العملية المراد تمويلها",
        "public-description-of-the-transaction"     : "وصف العملية المراد تمويلها (الذي يمكن أن يشاهده أي مستخدم)",
        "public-description-of-the-transaction-hint": "اكتب هنا وصف العملية المراد تمويلها. لاحظ أن هذا الوصف هو الذي سيظهر على صفحات الموقع للمستخدمين العاديين، وليس الوصف الذي كتبه المقترض",
        "expected-return"                           : "العائد المتوقع",
        "borrower-expected-return"                  : "العائد المتوقع للمقترض",
        "investor-expected-return"                  : "العائد المتوقع للمستثمر",

    },

    en: {
        "admin-loan-advanced"                       : RouteConfigTexts["admin-loan-advanced"],
        "admin-loan-list"                           : RouteConfigTexts["admin-loan-list"],
        "current-status-of-the-loan"                : "Current Status of The Loan",
        "rejection-reason"                          : "Rejection Reason",
        "rejection-reason-placeholder"              : "Describe here why the loan request is rejected and, if possible, what should the borrower do to fix the causing issues...",
        "crowdfunding-campaign-starts-on"           : "Crowdfunding Campaign Starts On",
        "crowdfunding-campaign-ends-on"             : "Crowdfunding Campaign Ends On",
        "transaction-starts-on"                     : "Transaction Starts On",
        "transaction-ends-on"                       : "Transaction Ends On",
        "public-description-of-the-transaction"     : "Description of The Transaction (that can be publicly seen)",
        "public-description-of-the-transaction-hint": "This is the transaction description that will be publicly displayed for the other parties like the investors for example...",
        "expected-return"                           : "Expected Return",
        "borrower-expected-return"                  : "Borrower Expected Return",
        "investor-expected-return"                  : "Investor Expected Return",
    }
}

export const AdminLoanAdvancedTexts = texts[preferredLanguage.alpha2code];
