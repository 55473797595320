import { preferredLanguage } from "./../configs/ui-language-config";

const texts = {
    ar: {
        "loan-amount"  : "المبلغ المطلوب",
        "pledged"      : "المُتعهد به",
        // "remaining"    : "المتبقي",
        "remaining"    : "المبلغ المتبقي",
        "loan-duration": "مدة القرض",
        "month"        : "شهر",
        "months"       : "أشهر",
        "loan-purpose" : "غرض القرض",
        "days-left"    : "الأيام المتبقية",
        "days"         : "يوم",
        "full-details" : "التفاصيل الكاملة",
        "committed-lenders" : "المستثمرين",
        "ROI"          : "العائد على الاستثمار",
    },

    en: {
        "loan-amount"  : "Loan Amount",
        "pledged"      : "Pledged",
        "remaining"    : "Remaining",
        "loan-duration": "Loan Duration",
        "month"        : "month",
        "months"       : "months",
        "loan-purpose" : "Loan Purpose",
        "days-left"    : "Days Left",
        "days"         : "days",
        "full-details" : "Full Details",
        "committed-lenders": "Investors",
        "ROI"          : "ROI",
    }
}

export const LoanCardTexts = texts[preferredLanguage.alpha2code];
