import { preferredLanguage } from "./../configs/ui-language-config";

const texts = {
    ar: {
        "login"             : "دخول",
        "email-address"     : "الإيميل",
        "password"          : "كلمة السر",
        "forgot-my-password": "نسيت كلمة السر",
        "create-new-account": "إنشاء حساب جديد",
    },

    en: {
        "login"             : "Login",
        "email-address"     : "Email Address",
        "password"          : "Password",
        "forgot-my-password": "Forgot My Password",
        "create-new-account": "Create New Account",
    }}

export const LoginTexts = texts[preferredLanguage.alpha2code];
