import React from "react";
import { preferredLanguage } from "./../configs/ui-language-config";
import { GeneralTexts } from "./GeneralTexts";
import { RouteConfigTexts } from "./RouteConfigTexts";

const texts = {
    ar: {
        "borrower-loan-list"   : RouteConfigTexts["borrower-loan-list"],
        "apply-for-new-loan"   : GeneralTexts["apply-for-new-loan"],
        "date"                 : "التاريخ",
        "company"              : "الشركة",
        "required"             : "المطلوب",
        "pledged"              : "المُتعهد به",
        "duration"             : "المدة",
        "crowdfunding-campaign": "حملة التمويل",
        "status"               : "الحالة",
        "month"                : "شهر",
        "edit"                 : "تعديل",

        "no-loans-message": (
            <span>
                ليس لديك قروض لعرضها حاليا، لأنك لم تطلب قروض من قبل.<br />
                لطلب قرض جديد، اضغط زر <strong>{GeneralTexts["apply-for-new-loan"]}</strong>.
            </span>
        ),
    },

    en: {
        "borrower-loan-list"   : RouteConfigTexts["borrower-loan-list"],
        "apply-for-new-loan"   : GeneralTexts["apply-for-new-loan"],
        "date"                 : "Date",
        "company"              : "Company",
        "required"             : "Required",
        "pledged"              : "Pledged",
        "duration"             : "Duration",
        "crowdfunding-campaign": "Crowdfunding Campaign",
        "status"               : "Status",
        "month"                : "months",
        "edit"                 : "Edit",

        "no-loans-message": (
            <span>
                You don't have any loans attached to your account yet.<br />
                To apply for a new loan, click the <strong>{GeneralTexts["apply-for-new-loan"]}</strong> button.
            </span>
        ),
    }
}

export const BorrowerLoanListTexts = texts[preferredLanguage.alpha2code];
