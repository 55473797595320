// More info: t.ly/fVo3
export function openLinkInNewTab(url) {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer")
    if (newWindow) newWindow.opener = null
}

// -----------------------------------------------

/**
 * @param array cssClassesToSwitch
 * @param object preferredLanguageCssClassesObject
 */
export function switchCssClasses(cssClassesToSwitch, preferredLanguageCssClassesObject) {
    const classes = cssClassesToSwitch.map(cssClass => preferredLanguageCssClassesObject[cssClass]);

    // Return the css classes of the preferred language as a space separated string
    // and also, add a space at the beginning so it works properly in all situations
    return " " + (classes.join(" "));
}

// -----------------------------------------------

export function handleSelectBoxBooleanValue(value) {
    // if (value === null || value === undefined || value === false || String(value).trim() === "") {
    //     return "";
    // }

    if (value === 0 || value === "0" || value === false) {
        return "0";
    }

    if (value === 1 || value === "1" || value === true) {
        return "1";
    }

    return "";
}