import React from "react";
import { switchClasses } from "../../languages/CssClasses";
// import { Link } from "react-router-dom";

import { getWhatsapp } from "./../../configs/general-config";

import { openLinkInNewTab } from "./../../helpers/common-helper";

import whatsappLogo from "./../../resources/images/whatsapp-logo.png";
import "./sass/WhatsAppIcon.scss";

export default class WhatsAppIcon extends React.Component {
	state = {};

	render() {
		const hideWhatsAppIconCssClass = this.props.hide === true ? " x-d-n" : "";

		return (
			<img
				alt=""
				src={whatsappLogo}
				className={"whatsapp-icon x-cur-p " + switchClasses(["x-l-15"]) + " " + hideWhatsAppIconCssClass}
				onClick={() => {openLinkInNewTab("https://wa.me/" + getWhatsapp("info"))}}
			/>
		);
	}
}
