import React from "react";
import { switchClasses } from "./../../languages/CssClasses";
import { GeneralTexts } from "./../../languages/GeneralTexts";

const RequiredDataHint = (props) => {
    return (
        <div className={"row " + (props.className || "") + " " + (props.positionInPage === "top" ? "x-mt-0" : "x-mt-25")}>
            <div className={"col " + switchClasses(["_x-ta-l"])}>
                <p className={"x-fz-075rem x-c-888 x-mb-10 " + (props.extraCssClasses || "")}>
                    <span className="x-c-red x-fz-12rem">*</span> = {GeneralTexts["required-data"]}
                </p>
            </div>
        </div>
    );
};

export default RequiredDataHint;
