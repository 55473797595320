import React from "react";

// Third-Party Modules
// import _isEmpty from "lodash/isEmpty";

// Our Services
import documentTitleService from "./../../../services/document-title-service";
// import * as userService from "./../../../services/user-service";

// Our Configs
// import { getRoutePath } from "./../../../configs/route-config";
import PageContent from "../../shared/PageContent";
import PageHandler from "../../shared/PageHandler";

export default class BorrowerOverview extends PageHandler {

    initialState = {
        ...this.initialStateElements,
        allowedUserType: "borrower",
    };

    // -----------------------------------------------

    // Clone a separate copy of the "initialState" and assign it to the "state".
    // Note that the spread operator "..." is important to get a separate copy.
    state = { ...this.initialState };

    // -----------------------------------------------

    componentDidMount() {
        // More info: t.ly/ZVsd
        window.scrollTo(0, 0);

        // ----------------------

        // Properly set the browser's document title
        documentTitleService.setTitle("Borrower Dashboard | Overview");

        // ----------------------

        this.setAuthenticatedUser();
    }

    // -----------------------------------------------

	render() {
        const authenticatedUser = this.getAuthenticatedUser();

        const pageContent = (
            <React.Fragment>
                <h5 className="x-ta-c x-pt-50">
                    Borrower Dashboard
                </h5>

                <h5 className="x-ta-c x-pt-20 x-c-crimson">
                    Overview<br /><br />
                    {authenticatedUser?.first_name} {authenticatedUser?.last_name}
                </h5>
            </React.Fragment>
        );

		return (
            <PageContent
                pageContent={pageContent}
                authenticatedUser={this.getAuthenticatedUser()}
                showPageContent={this.showPageContent()}
                windowLocation={window.location}
                hideHeaderNavbar={false}
                hideFooter={false}
                hideWhatsAppIcon={false}
            />
        );
	}
}
