// Third-Party Modules
import _range from "lodash/range";
import { LoanConfigTexts as texts } from "./../languages/LoanConfigTexts";

const loanPurposes = [
    {
        label: "تصنيع",
        value: "تصنيع" // TODO: values should be in english as it will be stored in the db
    },
    {
        label: "استيراد",
        value: "استيراد" // TODO: values should be in english as it will be stored in the db
    },
    {
        label: "تصدير",
        value: "تصدير" // TODO: values should be in english as it will be stored in the db
    },
    {
        label: "شراء بضاعة",
        value: "شراء بضاعة" // TODO: values should be in english as it will be stored in the db
    },
    {
        label: "بيع بضاعة",
        value: "بيع بضاعة" // TODO: values should be in english as it will be stored in the db
    },
    {
        label: "تقديم خدمة",
        value: "تقديم خدمة" // TODO: values should be in english as it will be stored in the db
    },
    {
        label: "غرض آخر",
        value: "غرض آخر" // TODO: values should be in english as it will be stored in the db
    },
];

// -----------------------------------------------

const loanStatuses = [
    {
        label: "Pending",
        value: "pending"
    },
    {
        label: "Under Review",
        value: "under-review"
    },
    {
        label: "Approved",
        value: "approved"
    },
    {
        label: "Rejected",
        value: "rejected"
    },
    {
        label: "Funded",
        value: "funded"
    },
    {
        label: "Closed",
        value: "closed"
    },
];

// -----------------------------------------------

const loanDurations = [...Array(12).keys()].map(val => {
    let numOfMonths = ++val;
    return {
        // More info: t.ly/vyRN
        "label": String(numOfMonths) + (numOfMonths < 10 ? Array(3).fill("\xa0").join("") : Array(2).fill("\xa0").join("")) + (numOfMonths === 1 ? texts["month"] : texts["months"]),
        "value": numOfMonths,
    }
});

// -----------------------------------------------

// const percentagesOfTransactionGrossProfit = [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60].map(val => {
const percentagesOfTransactionGrossProfit = _range(10, 65, 5).map(val => {
    return {
        // "label": val.toFixed(2) + " %",
        "label": val + " %",
        "value": val.toFixed(2),
    }
});

// -----------------------------------------------

// const percentagesOfExpectedReturn = [...Array(31).keys()].map(val => {
const percentagesOfExpectedReturn = _range(0, 31, 1).map(val => {
    return {
        "label": val + " %",
        "value": val.toFixed(2),
    }
});

// -----------------------------------------------

export function getLoanPurposes(option = null) {
    if (option === null) {
        return loanPurposes;
    }
}

// -----------------------------------------------

export function getLoanStatuses(option = null) {
    if (option === null) {
        return loanStatuses;
    }
}

// -----------------------------------------------

export function getLoanDurations(option = null) {
    if (option === null) {
        return loanDurations;
    }
}

// -----------------------------------------------

export function getPercentagesOfTransactionGrossProfit(option = null) {
    if (option === null) {
        return percentagesOfTransactionGrossProfit;
    }
}

// -----------------------------------------------

export function getPercentagesOfExpectedReturn(option = null) {
    if (option === null) {
        return percentagesOfExpectedReturn;
    }
}

// -----------------------------------------------

export function getMinAmountLenderCanLend() {
    // TODO: Do we need to change this number?
    return 50000;
}

// -----------------------------------------------

export function getMaxAmountLenderCanLend(loanRequiredAmount = null) {
    if (loanRequiredAmount) {
        // TODO: Do we need to change this number?

        // 20% of the loan amount
        // return Number(loanRequiredAmount) * 0.20;

        // 100% of the loan amount
        return Number(loanRequiredAmount);
    }

    return getMinAmountLenderCanLend();
}
