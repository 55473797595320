import React from "react";

import { switchClasses } from "./../../../languages/CssClasses";
import { BorrowerAdvancedProfileTexts as texts } from "./../../../languages/BorrowerAdvancedProfileTexts";
import { BorrowerFirstLoanApplicationWizardSubMenuTexts as wizardTexts } from "./../../../languages/BorrowerFirstLoanApplicationWizardSubMenuTexts";
import { GeneralTexts } from "./../../../languages/GeneralTexts";

// Third-Party Modules
import _isEmpty from "lodash/isEmpty";
import Joi from "@hapi/joi";
import queryString from "query-string";

// Our Modules
import MessageModal, {
	toggleMessageModal,
	getErrorMessage,
} from "./../../shared/MessageModal";
import FormHandler from "./../../shared/FormHandler";
import TextBox from "./../../shared/TextBox";
import SelectBox from "./../../shared/SelectBox";
import CustomButton from "./../../shared/CustomButton";
import PageLoader from "./../../shared/PageLoader";
import ButtonLoader from "./../../shared/ButtonLoader";
import BorrowerProfileSubMenu from "./BorrowerProfileSubMenu";
import BorrowerFirstLoanApplicationWizardSubMenu from "./BorrowerFirstLoanApplicationWizardSubMenu";
import RequiredDataHint from "./../../shared/RequiredDataHint";
import PageContent from "./../../shared/PageContent";

// Our Services
import documentTitleService from "./../../../services/document-title-service";
import logService from "./../../../services/log-service";
import * as userService from "./../../../services/user-service";
import * as borrowerService from "./../../../services/borrower-service";

// Our Configs
import { getCommonCountryList } from "./../../../configs/country-config";
import { getRoutePath } from "./../../../configs/route-config";
import { getCityList } from "./../../../configs/country-config";
// import DateTimePickerBox from "./../../shared/DateTimePickerBox";
import { handleSelectBoxBooleanValue } from "./../../../helpers/common-helper";

export default class BorrowerAdvancedProfile extends FormHandler {
	// Get query string as an object
	urlQueryString = queryString.parse(this.props.location.search);

	// -----------------------------------------------

	initialState = {
		...this.initialStateElements,
		allowedUserType: "borrower",

		formInputs: {
			id: "",
			country_of_nationality: "",
			country_of_residence: "",
			city_of_residence: "",
			address_of_residence: "",
			current_address_of_residence: "",
			birth_date: "",
			national_id_number: "",
			passport_number: "",
			has_checkbook: "",
			embezzled_before: "",
			has_defaulted_credit_card_debts: "",
		},
		validationErrors: {},
		messageModal: {},
		showPageLoader: true,
		showButtonLoader: false,
		closeInfoAlert: false,
	};

	// -----------------------------------------------

	// Clone a separate copy of the "initialState" and assign it to the "state".
	// Note that the spread operator "..." is important to get a separate copy.
	state = { ...this.initialState };

	// -----------------------------------------------

	componentDidMount() {
		// More info: t.ly/ZVsd
		window.scrollTo(0, 0);

		// ----------------------

		// Properly set the browser's document title
		documentTitleService.setTitle(
			GeneralTexts["borrower-dashboard"] + " | " + texts["my-advanced-profile"]
		);

		// ----------------------

		this.setAuthenticatedUser();
		this.getBorrower();

		// console.log(this.urlQueryString);
		// console.log("window.location.pathname: ", window.location.pathname);
		// console.log("this.props.location.pathname: ", this.props.location.pathname);
	}

	// -----------------------------------------------

	componentWillUnmount() {
		// This code fixes the warning: "Can't perform a React state update on an unmounted component'
		// More info: t.ly/Kday
		this.setState = (state, callback) => {
			return;
		};
	}

	// -----------------------------------------------

	// Get data of the current borrower
	getBorrower = async () => {
		const currentUser = await userService.getCurrentUser("?include=borrower");

		if (!_isEmpty(currentUser)) {
			const borrower = currentUser.borrower;

			this.setState({
				formInputs: {
					id: borrower.id,
					country_of_nationality: borrower.country_of_nationality,
					country_of_residence: borrower.country_of_residence,
					city_of_residence: borrower.city_of_residence,
					address_of_residence: borrower.address_of_residence,
					current_address_of_residence: borrower.current_address_of_residence,
					birth_date: borrower.birth_date,
					national_id_number: borrower.national_id_number,
					passport_number: borrower.passport_number,
					has_checkbook: borrower.has_checkbook,
					embezzled_before: borrower.embezzled_before,
					has_defaulted_credit_card_debts:
						borrower.has_defaulted_credit_card_debts,
				},

				// formInputs: borrower,
				showPageLoader: false,
			});
		}
	};

	// -----------------------------------------------

	// refreshPage = () => {
	//     this.setState({});
	// }

	// -----------------------------------------------

	doSubmit = async (e) => {
		this.setState({ showButtonLoader: true });

		try {
			// let formData = new FormData();
			// // This is important if a file is being uploaded with the data. More info: t.ly/Kqyj
			// formData.append("_method", "PUT")
			// for (const key in this.state.formInputs) {
			//     // More info aobut hasOwnProperty() method: t.ly/CYOa
			//     if (this.state.formInputs.hasOwnProperty(key)) {
			//         // Note that if the value is different than String or Blob
			//         // it will be automatically converted to String. More info: t.ly/tubu
			//         // These are some possible solutions to this issue: t.ly/yAAz
			//         formData.append(key, this.state.formInputs[key]);
			//     }
			// }
			// const response = await borrowerService.update(this.state.formInputs.id, formData);

			const response = await borrowerService.update(
				this.state.formInputs.id,
				this.state.formInputs
			);

			const borrower = response.data.data.borrower;

			if (this.isFirstTimeLoan()) {
				// const url = `${getRoutePath("borrowerCompanyCreate")}?first_time_loan=1`;
				// this.props.history.push(url);

				await this.handleNextStep();
			}

			this.setState({
				formInputs: {
					id: borrower.id,
					country_of_nationality: borrower.country_of_nationality,
					country_of_residence: borrower.country_of_residence,
					city_of_residence: borrower.city_of_residence,
					address_of_residence: borrower.address_of_residence,
					current_address_of_residence: borrower.current_address_of_residence,
					birth_date: borrower.birth_date,
					national_id_number: borrower.national_id_number,
					passport_number: borrower.passport_number,
					has_checkbook: borrower.has_checkbook,
					embezzled_before: borrower.embezzled_before,
					has_defaulted_credit_card_debts:
						borrower.has_defaulted_credit_card_debts,
				},
				messageModal: {
					...toggleMessageModal("success"),
					body: response.data.meta.message,
					// onHide: (() => {window.location.reload()}),
				},
			});
		} catch (error) {
			/**
			 * If there is an unexpected error like: network down, server down, db down, bug, ..
			 *  - Log the error.
			 *  - Display a generic and friendly error message.
			 *
			 * Note that "error.response" determines if the error is an expected error or not.
			 */

			const isExpectedError =
				error.response &&
				error.response.status >= 400 &&
				error.response.status < 500;

			if (!isExpectedError) {
				logService.log(error);
			}

			this.showErrorMessage(error);
		}

		this.setState({ showButtonLoader: false });
	};

	// -----------------------------------------------

	showErrorMessage = (error, props = this.props) => {
		this.setState({
			messageModal: {
				...toggleMessageModal("error"),
				body: getErrorMessage(error, props).body || "",
				footerButtons: getErrorMessage(error, props).footerButtons || [],
			},
		});
	};

	// -----------------------------------------------

	closeInfoAlert = () => {
		this.setState({ closeInfoAlert: true });
	};

	// -----------------------------------------------

	isFirstTimeLoan = () => {
		return String(this.urlQueryString?.first_time_loan) === "1";
	};

	// -----------------------------------------------

	getLastCreatedCompany = async () => {
		const currentUser = await userService.getCurrentUser(
			"?include=borrower.companies"
		);

		if (!_isEmpty(currentUser)) {
			// Sort ascending according to the "id"
			// currentUser.borrower.companies.sort((a, b) => a.id - b.id);

			// Sort descending according to the "id"
			currentUser.borrower.companies.sort((a, b) => b.id - a.id);

			const lastCreatedCompany = currentUser?.borrower?.companies[0];

			// this.setState({
			//     lastCreatedCompany: lastCreatedCompany || null,
			// });

			return lastCreatedCompany;
		}

		return null;
	};

	// -----------------------------------------------

	handleNextStep = async () => {
		// this.setState({ showButtonLoader: true });

		const lastCreatedCompany = await this.getLastCreatedCompany();

		const borrowerCompanyCreateUrl = `${getRoutePath(
			"borrowerCompanyCreate"
		)}?first_time_loan=1`;
		const borrowerCompanyUpdateUrl = `${getRoutePath(
			"borrowerCompanyUpdate"
		)}/${lastCreatedCompany?.id}?first_time_loan=1`;

		const url = lastCreatedCompany
			? borrowerCompanyUpdateUrl
			: borrowerCompanyCreateUrl;

		this.props.history.push(url);

		// this.setState({ showButtonLoader: false });
	};

	// -----------------------------------------------

	validationRules = {
		country_of_nationality: Joi.string()
			.required()
			.length(2)
			.label("Country of Nationality"),

		country_of_residence: Joi.string()
			.required()
			.length(2)
			.label("Country of Residence"),

		city_of_residence: Joi.string()
			.required()
			.max(50)
			.label("City of Residence"),

		address_of_residence: Joi.string()
			.required()
			.max(255)
			.label("Address of Residence"),

		current_address_of_residence: Joi.string()
			.optional()
			.allow("", null) // More info: t.ly/mMgn
			.max(255)
			.label("Current Address of Residence"),

		birth_date: Joi.date()
			.required()
			.iso() // requires the date value to be in valid ISO 8601 format (i.e. 2020-08-18)
			.label("Birth Date")
			// More info about custom messages: t.ly/gY6z and t.ly/F5n2
			// And this is a full list of the errors: t.ly/LUUy
			.messages({
				// "date.format": `{{#label}} should match this format: 1980-01-31`,
				"date.format": `Example of {{#label}} format: 1980-01-31`,
			}),

		national_id_number: Joi.string()
			.required()
			.max(25)
			.label("National ID Number"),

		passport_number: Joi.string()
			.optional()
			.allow("", null) // More info: t.ly/mMgn
			.max(25)
			.label("Passport Number"),

		// national_id_card_image: Joi.object()
		//     .required()
		//     // .optional().allow("", null) // More info: t.ly/mMgn
		//     // .max(255)
		//     .label("National ID Card Image"),

		// passport_image: Joi.string()
		//     .optional()
		//     .allow("", null) // More info: t.ly/mMgn
		//     .max(255)
		//     .label("Passport Image"),

		has_checkbook: Joi.string()
			.required()
			// .equal(...["0", "1"])
			.label("Has Checkbook?"),

		embezzled_before: Joi.number()
			.required()
			// .equal(...["0", "1"])
			.label("Embezzled Before?"),

		has_defaulted_credit_card_debts: Joi.number()
			.required()
			// .equal(...["0", "1"])
			.label("Has Defaulted Credit Card Debts?"),

		id: Joi.number().integer().positive().optional(),
	};

	// -----------------------------------------------

	render() {
		const pageContent = (
			<React.Fragment>
				<div className="x-m-25 x-mt-50">
					<h5
						className={"head-color " + switchClasses(["__ff-droidarabickufi"])}
					>
						{this.isFirstTimeLoan()
							? wizardTexts["my-data"]
							: texts["my-advanced-profile"]}
					</h5>
				</div>

				<div className="_border _border-light shadow-sm x-m-25 x-mt-0 x-mb-70 x-p-10 x-pb-100 x-mih-500 x-bgc-fff x-bdc-e3e3e3 x-bdrs-4">
					<div className="container-fluid">
						<div className="row x-pt-20">
							<div className="col-md-3">
								{this.isFirstTimeLoan() ? (
									<BorrowerFirstLoanApplicationWizardSubMenu
										activePagePath={this.props.location.pathname}
									/>
								) : (
									<BorrowerProfileSubMenu />
								)}
							</div>
							<div className="col-md-9">
								<PageLoader show={this.state.showPageLoader} />

								<div className={this.state.showPageLoader ? "x-d-n" : ""}>
									<div className={this.isFirstTimeLoan() ? "" : "x-d-n"}>
										<div className={this.state.closeInfoAlert ? "x-d-n" : ""}>
											<div
												className={
													"alert alert-primary alert-dismissible x-lh-28 x-mt-15 __ff-droidarabickufi " +
													switchClasses(["x-fz-082rem"])
												}
												role="alert"
											>
												<div>
													{/*
                                                    <p>أهلا بك يا <strong>{this.state.authenticatedUser?.first_name}</strong></p>
                                                    <p>بما أن هذه هي المرة الأولى التي تتقدم فيها لطلب قرض على منصة <strong>أموال</strong>، فإن هناك بعض البيانات الإضافية التي سنحتاجها لإعداد حسابك على المنصة كما ينبغي. ولا تقلق، فلن تحتاج لإدخال تلك البيانات الإضافية مرة أخرى في المرات القادمة.</p>
                                                    */}
													<p>
														{texts["welcome"]}{" "}
														<strong>
															{this.state.authenticatedUser?.first_name}
														</strong>
													</p>
													<p>{texts["first-time-loan-info"]}</p>
												</div>
												<button
													type="button"
													className="close"
													onClick={this.closeInfoAlert}
												>
													<span>&times;</span>
												</button>
											</div>
										</div>
										<div className={this.state.closeInfoAlert ? "x-d-n" : ""}>
											<div
												className={
													"alert alert-primary alert-dismissible x-lh-28 x-mt-15 __ff-droidarabickufi " +
													switchClasses(["x-fz-082rem"])
												}
												role="alert"
											>
												<div>
													{/*
                                                    <p>خطوة <span className="__ff-rubik x-fz-18">1</span> من <span className="__ff-rubik x-fz-18">4</span> &nbsp;|&nbsp; <strong>{wizardTexts["my-data"]}</strong></p>
                                                    <p>أكمل البيانات المطلوبة التالية ثم اضغط زر <strong>{GeneralTexts["next"]}</strong> لتنتقل للخطوة التالية.</p>
                                                    */}
													{texts["step-1-hint"]}
												</div>
												<button
													type="button"
													className="close"
													onClick={this.closeInfoAlert}
												>
													<span>&times;</span>
												</button>
											</div>
										</div>
									</div>

									<form
										className={
											"__ff-droidarabickufi " + switchClasses(["x-fz-085rem"])
										}
										onSubmit={(e) => e.preventDefault()}
									>
										<div className="row">
											<div className="col-lg-8">
												<div className="row">
													<div className="col-lg-4">
														<SelectBox
															label={texts["country-of-nationality"]}
															name="country_of_nationality"
															onBlur={this.handleBlur}
															onChange={this.handleChange}
															onFocus={this.handleFocus}
															validationError={
																this.state.validationErrors
																	.country_of_nationality || ""
															}
															value={
																this.state.formInputs.country_of_nationality ||
																""
															}
															selectBoxOptions={getCommonCountryList().map(
																(country) => {
																	return (
																		<option
																			key={Math.random()}
																			value={country.alpha2Code}
																		>
																			{country.name +
																				" (" +
																				country.alpha2Code +
																				")"}
																		</option>
																	);
																}
															)}
														/>
													</div>
													<div className="col-lg-4">
														<SelectBox
															label={texts["country-of-residence"]}
															name="country_of_residence"
															onBlur={this.handleBlur}
															onChange={this.handleChange}
															onFocus={this.handleFocus}
															validationError={
																this.state.validationErrors
																	.country_of_residence || ""
															}
															value={
																this.state.formInputs.country_of_residence || ""
															}
															selectBoxOptions={getCommonCountryList().map(
																(country) => {
																	return (
																		<option
																			key={Math.random()}
																			value={country.alpha2Code}
																		>
																			{country.name +
																				" (" +
																				country.alpha2Code +
																				")"}
																		</option>
																	);
																}
															)}
														/>
													</div>
													<div className="col-lg-4">
														<SelectBox
															label={texts["city-of-residence"]}
															name="city_of_residence"
															onBlur={this.handleBlur}
															onChange={this.handleChange}
															onFocus={this.handleFocus}
															validationError={
																this.state.validationErrors.city_of_residence ||
																""
															}
															value={
																this.state.formInputs.city_of_residence || ""
															}
															selectBoxOptions={getCityList(
																this.state.formInputs.country_of_residence
															).map((city) => {
																return (
																	<option key={Math.random()} value={city}>
																		{city}
																	</option>
																);
															})}
														/>
													</div>
												</div>
											</div>
										</div>

										<div className="row">
											<div className="col-lg-8">
												<TextBox
													label={texts["address-of-residence"]}
													name="address_of_residence"
													onBlur={this.handleBlur}
													onChange={this.handleChange}
													onFocus={this.handleFocus}
													validationError={
														this.state.validationErrors.address_of_residence ||
														""
													}
													value={
														this.state.formInputs.address_of_residence || ""
													}
												/>
											</div>
										</div>

										<div className="row">
											<div className="col-lg-8">
												<TextBox
													label={texts["current-address-of-residence"]}
													name="current_address_of_residence"
													onBlur={this.handleBlur}
													onChange={this.handleChange}
													onFocus={this.handleFocus}
													required={false}
													validationError={
														this.state.validationErrors
															.current_address_of_residence || ""
													}
													value={
														this.state.formInputs
															.current_address_of_residence || ""
													}
												/>
											</div>
										</div>

										<div className="row">
											<div className="col-lg-4">
												<TextBox
													extraCssClasses="x-dr-ltr x-ta-l"
													label={texts["national-id-number"]}
													name="national_id_number"
													onBlur={this.handleBlur}
													onChange={this.handleChange}
													onFocus={this.handleFocus}
													validationError={
														this.state.validationErrors.national_id_number || ""
													}
													value={this.state.formInputs.national_id_number || ""}
												/>
											</div>
											<div className="col-lg-4">
												<TextBox
													extraCssClasses="x-dr-ltr x-ta-l"
													label={texts["passport-number"]}
													name="passport_number"
													onBlur={this.handleBlur}
													onChange={this.handleChange}
													onFocus={this.handleFocus}
													required={false}
													validationError={
														this.state.validationErrors.passport_number || ""
													}
													value={this.state.formInputs.passport_number || ""}
												/>
											</div>
										</div>
										<div className="row">
											<div className="col-lg-4">
												{/*
												<DateTimePickerBox
													dateFormat="yyyy-MM-dd"
													endYear={new Date().getFullYear() - 18} // Should be 18+
													isClearable={true}
													label={texts["birth-date"]}
													name="birth_date"
													onBlur={this.handleBlur}
													onChange={(date) => {
														this.handleDateTimePickerChange(date, "birth_date");
													}}
													onFocus={this.handleFocus}
													required={true}
													showCustomHeader={true}
													showTimeInput={false}
													startYear={new Date().getFullYear() - 80} // Works with renderCustomHeader
													validationError={
														this.state.validationErrors.birth_date || ""
													}
													value={this.state.formInputs.birth_date || ""}
												/>
                                                */}
												<TextBox
													extraCssClasses="x-dr-ltr x-ta-l"
													label={texts["birth-date"]}
													name="birth_date"
													onBlur={this.handleBlur}
													onChange={this.handleChange}
													onFocus={this.handleFocus}
													placeholder="1980-01-31"
													required={true}
													validationError={
														this.state.validationErrors.birth_date || ""
													}
													value={this.state.formInputs.birth_date || ""}
												/>
											</div>

											<div className="col-lg-4">
												<SelectBox
													label={texts["has-checkbook"]}
													name="has_checkbook"
													onBlur={this.handleBlur}
													onChange={this.handleChange}
													onFocus={this.handleFocus}
													validationError={
														this.state.validationErrors.has_checkbook || ""
													}
													value={this.state.formInputs.has_checkbook || ""}
													selectBoxOptions={[
														<option key={Math.random()} value="yes">
															Yes
														</option>,
														<option key={Math.random()} value="no">
															No
														</option>,
													]}
												/>
											</div>
										</div>
										<div className="row">
											<div className="col-lg-4">
												<SelectBox
													label={texts["has-defaulted-credit-card-debts"]}
													name="has_defaulted_credit_card_debts"
													onBlur={this.handleBlur}
													onChange={this.handleChange}
													onFocus={this.handleFocus}
													validationError={
														this.state.validationErrors
															.has_defaulted_credit_card_debts || ""
													}
													value={handleSelectBoxBooleanValue(
														this.state.formInputs
															.has_defaulted_credit_card_debts
													)}
													selectBoxOptions={[
														<option key={Math.random()} value="1">
															Yes
														</option>,
														<option key={Math.random()} value="0">
															No
														</option>,
													]}
												/>
											</div>

											<div className="col-lg-4">
												<SelectBox
													label={texts["embezzled-before"]}
													name="embezzled_before"
													onBlur={this.handleBlur}
													onChange={this.handleChange}
													onFocus={this.handleFocus}
													validationError={
														this.state.validationErrors.embezzled_before || ""
													}
													value={handleSelectBoxBooleanValue(
														this.state.formInputs.embezzled_before
													)}
													selectBoxOptions={[
														<option key={Math.random()} value="1">
															Yes
														</option>,
														<option key={Math.random()} value="0">
															No
														</option>,
													]}
												/>
											</div>
										</div>
										<div className="row x-pt-20">
											<div className="col-12 x-c-red">
												-{" "}
												{
													texts[
														"confirm-rightness-and-accuracy-of-entered-data"
													]
												}
											</div>
										</div>
										<div className="row">
											<div className="col-lg-4">
												<div className="x-mt-20">
													<CustomButton
														disabled={this.validateFormInputs() ? true : false}
														label={
															this.state.showButtonLoader ? (
																<ButtonLoader />
															) : this.isFirstTimeLoan() ? (
																GeneralTexts["next"]
															) : (
																GeneralTexts["save"]
															)
														}
														onClick={this.handleSubmit}
														type="button"
													/>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>

						<RequiredDataHint
							className={this.state.showPageLoader ? "x-d-n" : ""}
						/>
					</div>

					<MessageModal
						alertClassName={this.state.messageModal.alertClassName || null}
						body={this.state.messageModal.body || null}
						centered={this.state.messageModal.centered || false}
						footerButtons={this.state.messageModal.footerButtons || []}
						onHide={
							this.state.messageModal.onHide ||
							(() =>
								this.setState({
									messageModal: { ...this.initialState.messageModal },
								}))
						}
						show={this.state.messageModal.show || false}
						size={this.state.messageModal.size || null}
						title={this.state.messageModal.title || null}
					/>
				</div>
			</React.Fragment>
		);

		return (
			<PageContent
				pageContent={pageContent}
				authenticatedUser={this.getAuthenticatedUser()}
				showPageContent={this.showPageContent()}
				windowLocation={window.location}
				urlQueryString={this.urlQueryString || null}
			/>
		);
	}
}
