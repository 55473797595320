// Our Services
import http from "./http-service";

// Our Configs
import { getApiEndpoint } from "./../configs/api-config";

// -----------------------------------------------

export const getList = (urlQueryString = "") => {
    return http.get(getApiEndpoint("loanList").path + urlQueryString);
}

// -----------------------------------------------

export const getApprovedList = (urlQueryString = "") => {
    return http.get(getApiEndpoint("approvedLoanList").path + urlQueryString);
}

// -----------------------------------------------

export const getItem = (uuid, urlQueryString = "") => {
    return http.get(getApiEndpoint("loanDetails").path + "/" + uuid + urlQueryString);
}

// -----------------------------------------------

export const getPublicItem = (uuid, urlQueryString = "") => {
    return http.get(getApiEndpoint("loanPublicDetails").path + "/" + uuid + urlQueryString);
}

// -----------------------------------------------

export const create = (data) => {
    return http.post(
        getApiEndpoint("loanCreate").path,
        data
    );
}

// -----------------------------------------------

export const updateCoverImage = (loanUUID, data) => {
    return http.post(
        getApiEndpoint("loanCoverImageUpdate").path + "/" + loanUUID + "/cover-image",
        data
    );
}

// -----------------------------------------------

export const updateBasicData = (LoanUuid, data) => {
    return http.put(
        getApiEndpoint("loanUpdateBasicData").path + "/" + LoanUuid,
        data
    );
}

// -----------------------------------------------

export const updateAdvancedData = (loanUuid, data) => {
    return http.put(
        getApiEndpoint("loanUpdateAdvabcedData").path + "/" + loanUuid,
        data
    );
}

// -----------------------------------------------

export const updateLoanIndicatorData = (loanIndicatorUuid, data) => {
    return http.put(
        getApiEndpoint("loanIndicatorUpdate").path + "/" + loanIndicatorUuid,
        data
    );
}
