import { preferredLanguage } from "./../configs/ui-language-config";

const texts = {
    ar: {
        "reset-your-password" : "إعادة تعيين كلمة السر",
        "email-address"       : "إيميلك",
        "new-password"        : "كلمة السر الجديدة",
        "if-remember-password": "إذا كنت تعرف كلمة سر حسابك",
        "login-from-here"     : "ادخل من هنا",
    },

    en: {
        "reset-your-password" : "Reset Your Password",
        "email-address"       : "Your Email",
        "new-password"        : "Your New Password",
        "if-remember-password": "If you remember your password,",
        "login-from-here"     : "Login from here",
    }}

export const PasswordResetTexts = texts[preferredLanguage.alpha2code];
