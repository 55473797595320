import { preferredLanguage } from "../configs/ui-language-config";
// import { GeneralTexts } from "./GeneralTexts";
import { RouteConfigTexts } from "./RouteConfigTexts";

const texts = {
    ar: {
        "admin-loan-investigation-gallery": RouteConfigTexts["admin-loan-investigation-gallery"],
        "admin-loan-list"                 : RouteConfigTexts["admin-loan-list"],
        "add-image"                       : "اضف صورة",
        "current-gallery"                 : "الصور والفيديوهات الحالية",
        "choose-image"                    : "Choose image(s)..",
        "now-click-upload"                : "Click (Upload)",
        "upload"                          : "Upload",
        "choose-image-first"              : "اضغط على زر Browse لاختيار صورة أولاً.",
        "add-youtube-video-url"           : "اضف رابط فيديو YouTube",
    },

    en: {
        "admin-loan-investigation-gallery": RouteConfigTexts["admin-loan-investigation-gallery"],
        "admin-loan-list"                 : RouteConfigTexts["admin-loan-list"],
        "add-image"                       : "Add image",
        "current-gallery"                 : "Current images and videos",
        "choose-image"                    : "Choose image(s)..",
        "now-click-upload"                : "Click (Upload)",
        "upload"                          : "Upload",
        "choose-image-first"              : "Click the Browse button to choose an image first.",
        "add-youtube-video-url"           : "Add YouTube video URL",
    }
}

export const AdminLoanInvestigationGalleryTexts = texts[preferredLanguage.alpha2code];
