import React from "react";
import { NavLink } from "react-router-dom";

import { switchClasses } from "./../../languages/CssClasses";

// Our Configs
import { getRoute, getRoutePath } from "./../../configs/route-config";

export default class BorrowSubMenu extends React.Component {
	state = {};

	render() {
		return (
			<div
				className={
					"list-group shadow-sm x-mt-15 x-mb-30 " +
					switchClasses(["x-fz-082rem"])
				}
			>
				{/* <NavLink to={getRoutePath("borrowOverview")} className="list-group-item list-group-item-action _active">{getRoute("borrowOverview").caption}</NavLink> */}
				<NavLink
					to={getRoutePath("borrowFaqs")}
					className="list-group-item list-group-item-action"
				>
					{getRoute("borrowFaqs").caption}
				</NavLink>
				<NavLink
					to={getRoutePath("borrowTermsAndConditions")}
					className="list-group-item list-group-item-action"
				>
					{getRoute("borrowTermsAndConditions").caption}
				</NavLink>
				<NavLink
					to={getRoutePath("borrowPrivacyPolicy")}
					className="list-group-item list-group-item-action"
				>
					{getRoute("borrowPrivacyPolicy").caption}
				</NavLink>
			</div>
		);
	}
}
