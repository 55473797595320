import React from "react";

import { switchClasses } from "./../languages/CssClasses";
import { LoansTexts as texts } from "./../languages/LoansTexts";
import { RouteConfigTexts } from "./../languages/RouteConfigTexts";

// Third-Party Modules
import moment from "moment";

// Our Modules
import PageLoader from "./shared/PageLoader";
import LoanCard from "./shared/LoanCard";
import PageHandler from "./shared/PageHandler";
import PageContent from "./shared/PageContent";

// Our Services
import documentTitleService from "./../services/document-title-service";
import * as loanService from "./../services/loan-service";

export default class Loans extends PageHandler {

    initialState = {
        ...this.initialStateElements,
        isThisPagePublic: true,

        loans: [],
        showPageLoader: true,
    };

    // -----------------------------------------------

    // Clone a separate copy of the "initialState" and assign it to the "state".
    // Note that the spread operator "..." is important to get a separate copy.
    state = { ...this.initialState };

    // -----------------------------------------------

    componentDidMount() {
        // More info: t.ly/ZVsd
        window.scrollTo(0, 0);

        // ----------------------

        // Properly set the browser's document title
        documentTitleService.setTitle(RouteConfigTexts["loans"]);

        // ----------------------

        this.setAuthenticatedUser();
        this.getApprovedLoanList();
    }

    // -----------------------------------------------

    getApprovedLoanList = async () => {
        this.setState({ showPageLoader: true });

        const response = await loanService.getApprovedList("?include=company");
        const loans = response.data.data.loans || [];

        // Sort descending according to the "id"
        // loans.sort((a, b) => b.id - a.id);

        // mement("2020-10-31 16:13:09").format("X") gets the timestamp in seconds from the provided datetime
        loans.sort((a, b) => moment(b.crowdfunding_campaign_starts_at).format("X") - moment(a.crowdfunding_campaign_starts_at).format("X"));

        this.setState({
            loans: loans,
            showPageLoader: false,
        });
    }

    // -----------------------------------------------

	render() {
        const hasApprovedLoans = this.state.loans.length ? true : false;

        const noLoansHint = (
            <p className={"x-c-333 __ff-droidarabickufi x-ta-c x-pt-20 " + switchClasses(["x-fz-085rem"])}>{texts["no-loan-requests"]}</p>
        );

        const showNoLoansHint = !hasApprovedLoans && !this.state.showPageLoader;

        const approvedLoans = (
            this.state.loans.map(loan => {
                return <LoanCard key={Math.random()} loan={loan} authenticatedUser={this.props.authenticatedUser} />
            })
        );

        const pageContent = (
            <section className="open_loan_requests x-pt-70 x-pb-70">
                <div className="container">
                    <header className="x-ta-c __ff-droidarabickufi">
                        <h3 className="x-c-333">{texts["open-opportunities"]}</h3>
                        <p className={"x-pt-5 x-c-333 " + switchClasses(["x-fz-085rem"])}>{texts["open-opportunities-hint"]}</p>
                        <p className="title-border x-bdbc-333"></p>
                    </header>

                    <PageLoader show={this.state.showPageLoader} color="blue" />

                    {showNoLoansHint ? noLoansHint : null}

                    <div className="row x-pt-50">
                        {approvedLoans}
                    </div>
                </div>
            </section>
        );

        // -----------------------------------------------

        // return (
        //     <React.Fragment>
        //         <div className="">
        //             {openLoanRequests}
        //         </div>
        //     </React.Fragment>
        // );

        return (
            <PageContent
                pageContent={pageContent}
                authenticatedUser={this.getAuthenticatedUser()}
                showPageContent={this.showPageContent()}
                windowLocation={window.location}
            />
        );
	}
}
