import React from "react";
import { Modal, Button } from "react-bootstrap";
import Icon from "@mdi/react"
import { mdiCheckUnderline, mdiWindowClose, mdiInformationOutline, mdiAlert } from "@mdi/js";

// import { switchClasses } from "./../../languages/CssClasses";

// Our configs
// import { getRoutePath } from "./../../configs/route-config";

const MessageModal = (props) => {
    return (
        // More info about Modal component: https://react-bootstrap.github.io/components/modal/
        <Modal
            show={props.show || false}
            // Size options: "sm", "md", "lg", "xl". "md" is the default. More info: t.ly/sNNl
            size={props.size || "md"}
            centered={props.centered || false}
            onHide={props.onHide || (() => { })}
            aria-labelledby="modal_title"
        >
            <div className={"x-bdrs-5 x-dr-ltr " + (props.alertClassName || "alert-light")}>
                {/* <div className={(switchClasses(["x-dr-rtl", "x-ta-r"]))}> */}
                    <Modal.Header closeButton className="x-p-10 x-pb-0 x-bdc-t">
                        {props.title ? addHeaderTitle(props) : null}
                    </Modal.Header>

                    <Modal.Body className="x-pb-25">
                        {props.body || null}
                    </Modal.Body>

                    {props.footerButtons ? addFooterWithButtons(props) : null}
                {/* </div> */}
            </div>
        </Modal>
    );
};

export default MessageModal;

// -----------------------------------------------

const addHeaderTitle = (props) => {
    return (
        <Modal.Title id="modal_title" className="x-pt-10">
            {getTitleIcon(props)}
            {" "}
            {props.title || null}
        </Modal.Title>
    );
}

// -----------------------------------------------

const addFooterWithButtons = (props) => {
    if (props.footerButtons.length) {
        return (
            <Modal.Footer className="x-bdc-t x-pt-0">
                {props.footerButtons.map(button => {
                    return (
                        <Button key={Math.random()} variant={button.variant} onClick={button.onClick} className="x-bdrs-2">
                            {button.title}
                        </Button>
                    );
                })}
            </Modal.Footer>
        );
    }
};

// -----------------------------------------------

const getTitleIcon = (props) => {
    // For more info about available Bootstrap alert types,
    // see: https://getbootstrap.com/docs/4.5/components/alerts
    switch (props.alertClassName) {
        case "alert-success":
            return (
                <Icon
                    path={mdiCheckUnderline}
                    size={props.titleIconSize || "1.1em"}
                    className=""
                />
            );

        case "alert-danger":
            return (
                <Icon
                    path={mdiWindowClose}
                    size={props.titleIconSize || "1.1em"}
                    className=""
                />
            );

        case "alert-primary":
        case "alert-secondary":
        case "alert-info":
            return (
                <Icon
                    path={mdiInformationOutline}
                    size={props.titleIconSize || "1.1em"}
                    className=""
                />
            );

        case "alert-warning":
            return (
                <Icon
                    path={mdiAlert}
                    size={props.titleIconSize || "1.1em"}
                    className=""
                />
            );

        default:
            return null;
    }
}

// -----------------------------------------------

export const toggleMessageModal = (messageModalType) => {
    const commonProps = {
        show: true,
        size: null,
        centered: false,
        body: "",
        footerButtons: [],
        onHide: null
    };

    switch (messageModalType) {
        case "success":
            return {
                ...commonProps,
                title: "Success",
                alertClassName: "alert-success",
            }

        case "error":
            return {
                ...commonProps,
                title: "Error",
                alertClassName: "alert-danger",
            }

        case "primary":
        case "info":
            return {
                ...commonProps,
                title: "Info",
                alertClassName: "alert-info",
            }

        case "warning":
            return {
                ...commonProps,
                title: "Warning",
                alertClassName: "alert-warning",
            }

        case "custom":
            return {
                ...commonProps,
                title: "",
                alertClassName: "alert-custom",
            }

        default:
            return {
                ...commonProps,
                title: "",
                alertClassName: "alert-light",
            }
    }
}

// -----------------------------------------------

export const getErrorMessage = (error, props) => {

    // If there is an unexpected error
    if (!error || !error.response) {
        // Display a generic and friendly error message
        return {
            body: "An unexpected error occurred. Please, try again later or contact us.",
            footerButtons: [
                // {
                //     variant: "primary",
                //     title: "Contact Us",
                //     onClick: () => { props.history.push(getRoutePath("contactUs")) }
                // }
            ]
        }
    }

    // ----------------------

    // if (typeof error.response.data.meta === 'undefined') {
    if (!error.response.data.meta) {
        return {
            body: "Something went wrong. Please, try again later or contact us.",
            footerButtons: [
                // {
                //     variant: "primary",
                //     title: "Contact Us",
                //     onClick: () => { props.history.push(getRoutePath("contactUs")) }
                // }
            ]
        }
    }

    // ----------------------

    // If the "errors" element doesn't exist
    if (!error.response.data.meta.errors) {
        // return error.response.data.meta.message || "Something went wrong. Please, try again later or contact us.";

        if (error.response.data.meta.message) {
            return {
                body: error.response.data.meta.message,
            }

        } else {
            return {
                body: "Something went wrong. Please, try again later or contact us.",
                footerButtons: [
                    // {
                    //     variant: "primary",
                    //     title: "Contact Us",
                    //     onClick: () => { props.history.push(getRoutePath("contactUs")) }
                    // }
                ]
            }
        }
    }

    // ----------------------

    // If the "errors" element exists
    if (error.response.data.meta.errors) {
        // TEST
        // console.log("Keys of errors object: ", Object.keys(error.response.data.meta.errors));

        const messages = [];
        // let msgBody = "";

        // First, get the keys of the "errors" object
        Object.keys(error.response.data.meta.errors).forEach(key => {
            // Second, get the messages of every error key
            error.response.data.meta.errors[key].forEach(message => {
                messages.push("- " + message);
                // msgBody += "- " + message;
            })
        });


        return {
            // body: (messages.map(message => `- ${message}`)).join('\n\r'),
            body: messages.join('\n\r'),
            // body: msgBody,
        }
    }

    // ----------------------

    return {};
}
