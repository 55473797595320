import React from "react";
import { Link } from "react-router-dom";

// import { switchClasses } from "../../languages/CssClasses";
import { GeneralTexts } from "../../languages/GeneralTexts";
// import { DocumentUploaderTexts as texts } from "../../languages/DocumentUploaderTexts";

import { getRoutePath } from "../../configs/route-config";
// import { openLinkInNewTab } from "../../helpers/common-helper";
// import placeholderImage from "../../resources/images/placeholder-image.png";
import pdfFileIcon from "../../resources/images/pdf-file-icon.png";
import inactivePdfFileIcon from "../../resources/images/pdf-file-icon-inactive.png";

const DocumentThumbnail = (props) => {
    const documentStoredInDB = props.documentStoredInDB;
    const documentLabel = props.documentLabel;

    const docLabel = (
        <>
            {documentLabel}
            {
                typeof props.required === "undefined" || props.required === true
                    ? <span className="x-c-red"> *</span>
                    : ""
            }
        </>
    );

    const infoHint = (
        <span
            className={(props.infoHintClassName || "link-like x-cur-p") + " " + (props.infoHintFontSize || "x-fz-075rem")  + " " + (props.infoHintExtraClassName || "")}
            onClick={props.onInfoHintClick}
        >
            {props.infoHintTitle || GeneralTexts["info"]}
        </span>
    );

    return (
        <React.Fragment>
            <div className="body-bgc x-bdw-1 x-bds-s x-bdc-eee x-bdrs-5 x-h-a x-ov-h x-d-f x-jc-c x-ai-c x-pt-40 x-pb-5">

                {/* More about openning a link in another tab: t.ly/2wzb */}
                <div className={(documentStoredInDB?.uuid ? "" : " x-d-n")}>
                    <Link
                        to={{ pathname: `${getRoutePath("adminDocumentShow")}/${documentStoredInDB?.uuid}?user_id=${props.userId}` }}
                        target="_blank"
                        // className={(documentStoredInDB?.uuid ? "" : " x-d-n")}
                    >
                        <img
                            src={pdfFileIcon}
                            alt=""
                            className="img-fluid x-w-65"
                        />
                        <div className="x-pt-10 x-fz-075rem">
                            {docLabel}
                        </div>
                    </Link>

                    <div className="x-pt-20">
                        {props.showInfoHint ? infoHint : ""}
                        &nbsp;
                    </div>
                </div>

                <div className={(documentStoredInDB?.uuid ? " x-d-n" : "")}>
                    <img
                        src={inactivePdfFileIcon}
                        alt=""
                        className={"img-fluid x-w-65"}
                    />
                    <div className="x-pt-10 x-fz-075rem">
                        {docLabel}
                    </div>

                    <div className="x-pt-20">
                        {props.showInfoHint ? infoHint : ""}
                        &nbsp;
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

// -----------------------------------------------

export default DocumentThumbnail;
