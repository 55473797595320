// React Modules
import React from "react";
import { Link } from "react-router-dom";

import { switchClasses } from "./../languages/CssClasses";
import { PreSignupTexts as texts } from "./../languages/PreSignupTexts";
import { RouteConfigTexts } from "./../languages/RouteConfigTexts";

// Third-Party Modules
import Joi from "@hapi/joi";

// Our Modules
import MessageModal, { toggleMessageModal, getErrorMessage } from "./shared/MessageModal";
import FormHandler from "./shared/FormHandler";
import TextBox from "./shared/TextBox";
import CustomButton from "./shared/CustomButton";
import ButtonLoader from "./shared/ButtonLoader";
import PageContent from "./shared/PageContent";

// Our Services
import logService from "./../services/log-service";
import * as authService from "./../services/auth-service";
import documentTitleService from "./../services/document-title-service";

// Our Configs
import { getRoutePath } from "./../configs/route-config";

export default class PreSignup extends FormHandler {

    initialState = {
        ...this.initialStateElements,
        isThisPagePublic: true,

        formInputs: {
            email: "",
        },
        validationErrors: {},
        messageModal    : {},
        showButtonLoader: false,
    };

    // -----------------------------------------------

    // Clone a separate copy of the "initialState" and assign it to the "state".
    // Note that the spread operator "..." is important to get a separate copy.
    state = { ...this.initialState };

    // -----------------------------------------------

    componentDidMount() {
        // More info: t.ly/ZVsd
        window.scrollTo(0, 0);

        // ----------------------

        // Properly set the browser's document title
        documentTitleService.setTitle(RouteConfigTexts["pre-signup"]);

        // ----------------------

        this.setAuthenticatedUser();
    }

    // -----------------------------------------------

    doSubmit = async (e) => {
        this.setState({ showButtonLoader: true });

        try {
            const response = await authService.preSignup(this.state.formInputs);

            this.setState({
                messageModal: {
                    ...toggleMessageModal("success"),
                    body: response.data.meta.message || "",
                    onHide: () => this.setState({ ...this.initialState }),
                }
            });
        } catch (error) {
            /**
             * If there is an unexpected error like: network down, server down, db down, bug, ..
             *  - Log the error.
             *  - Display a generic and friendly error message.
             *
             * Note that "error.response" determines if the error is an expected error or not.
             */

            const isExpectedError =
                error.response
                && error.response.status >= 400
                && error.response.status < 500;

            if (!isExpectedError) {
                logService.log(error);
            }

            this.showErrorMessage(error);
        }

        this.setState({ showButtonLoader: false });
    };

    // -----------------------------------------------

    showErrorMessage = (error, props = this.props) => {
        this.setState({
            messageModal: {
                ...toggleMessageModal("error"),
                body: getErrorMessage(error, props).body || "",
                footerButtons: getErrorMessage(error, props).footerButtons || [],
            }
        });
    }

    // -----------------------------------------------

    validationRules = {
        email: Joi.string()
            .required()
            .email({ tlds: false })
            .max(50)
            .label("Email Address"),
    };

    // -----------------------------------------------

    render() {
        const pageContent = (
            <section className="x-pb-100 __ff-droidarabickufi">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-lg"></div>
                        <div className="col-lg">
                            <div className="_border _border-light shadow-sm x-bdc-ddd x-bdrs-4 x-mt-80 x-p-30 x-pt-40 x-pb-50 x-bgc-fff">
                                <h4 className="x-ta-c">{texts["enter-your-email"]}</h4>

                                <form className={"x-mt-40 " + switchClasses(["x-fz-085rem"])} onSubmit={(e) => e.preventDefault()}>
                                    <TextBox
                                        name="email"
                                        label={texts["email-address"]}
                                        value={this.state.formInputs.email || ""}
                                        // autoFocus={true}
                                        onBlur={this.handleBlur}
                                        onChange={this.handleChange}
                                        onFocus={this.handleFocus}
                                        onKeyPress={this.handleKeyPress}
                                        validationError={this.state.validationErrors.email || null}
                                        extraCssClasses="x-dr-ltr x-ta-l"
                                    />

                                    <div className="x-mt-20">
                                        <CustomButton
                                            type="button"
                                            label={this.state.showButtonLoader ? <ButtonLoader /> : "Submit"}
                                            disabled={this.validateFormInputs() ? true : false}
                                            onClick={this.handleSubmit}
                                        />
                                    </div>
                                </form>
                            </div>

                            <p className={"x-p-0 x-pt-15 " + switchClasses(["x-fz-08rem"])}>
                                {texts["already-have-account"]} <Link to={getRoutePath("login")}>{texts["login-from-here"]}</Link>.
                            </p>
                        </div>
                        <div className="col-lg"></div>
                    </div>
                </div>

                <MessageModal
                    show={this.state.messageModal.show || false}
                    title={this.state.messageModal.title || null}
                    body={this.state.messageModal.body || null}
                    alertClassName={this.state.messageModal.alertClassName || null}
                    size={this.state.messageModal.size || null}
                    centered={this.state.messageModal.centered || false}
                    footerButtons={this.state.messageModal.footerButtons || []}
                    onHide={this.state.messageModal.onHide || (() => this.setState({ messageModal: { ...this.initialState.messageModal } }))}
                />
            </section>
        );

        return (
            <PageContent
                pageContent={pageContent}
                authenticatedUser={this.getAuthenticatedUser()}
                showPageContent={this.showPageContent()}
                windowLocation={window.location}
            />
        );
    }
}
