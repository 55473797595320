import { preferredLanguage } from "./../configs/ui-language-config";

const texts = {
    ar: {
        "my-data"           : "بياناتي",
        "company-data"      : "بيانات الشركة",
        "required-documents": "المستندات المطلوبة",
        "loan-data"         : "بيانات القرض المطلوب",
    },

    en: {
        "my-data"           : "My Data",
        "company-data"      : "Company Data",
        "required-documents": "Required Documents",
        "loan-data"         : "Loan Data",
    }
}

export const BorrowerFirstLoanApplicationWizardSubMenuTexts = texts[preferredLanguage.alpha2code];
