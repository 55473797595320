import React from "react";
// import { switchClasses } from "./../../languages/CssClasses";

// Third-Party Modules
// import _isEmpty from "lodash/isEmpty";

import HeaderNavbar from "./../../components/shared/HeaderNavbar";
import Footer from "./../../components/shared/Footer";
import WhatsAppIcon from "./../../components/shared/WhatsAppIcon";
import PageLoader from "./PageLoader";

// import { getRoutePath } from "./../../configs/route-config";

// import * as userService from "./../../services/user-service";
// import * as accessTokenService from "./../../services/access-token-service";

const PageContent = (props) => {
    const pageWrapperCssClasses = (props?.pageWrapperCssClasses || "") + " " + (props?.pageWrapperExtraCssClasses || "");
    const contentWrapperCssClasses = (props?.contentWrapperCssClasses || "") + " " + (props?.pageWrapperExtraCssClasses || "");

    const headerNavbar = (
        <HeaderNavbar
            authenticatedUser={props?.authenticatedUser}
            hide={props?.hideHeaderNavbar}
            urlQueryString={props?.urlQueryString || null}
            windowLocation={props?.windowLocation || null}
        />
    );

    const pageLoader = (
        <PageLoader show={true} color="blue" className="x-pt-150" />
    );

    const pageContent = (
        <div className={contentWrapperCssClasses} style={{ minHeight: "450px" }}>
            {props?.pageContent}
        </div>
    );

    const footer = (
        <Footer hide={props?.hideFooter} />
    );

    const whatsAppIcon = (
        <WhatsAppIcon hide={props?.hideWhatsAppIcon} />
    );

    return (
        <div className={"_bg-pattern " + pageWrapperCssClasses}>
            {headerNavbar}
            {props?.showPageContent ? pageContent : pageLoader}
            {props?.showPageContent ? footer : null}
            {whatsAppIcon}
        </div>
    );
};

export default PageContent;
