import React from "react";
import { Link } from "react-router-dom";

import { preferredLanguage } from "./../../../configs//ui-language-config";
import { switchClasses } from "./../../../languages/CssClasses";
import { BorrowerLoanUpdateTexts as texts } from "./../../../languages/BorrowerLoanUpdateTexts";
import { GeneralTexts } from "./../../../languages/GeneralTexts";

// Third-Party Modules
import _isEmpty from "lodash/isEmpty";
import Joi from "@hapi/joi";
import Icon from "@mdi/react"
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";

// Our Modules
import MessageModal, { toggleMessageModal, getErrorMessage } from "./../../shared/MessageModal";
import FormHandler from "./../../shared/FormHandler";
import TextBox from "./../../shared/TextBox";
import SelectBox from "./../../shared/SelectBox";
import TextareaBox from "./../../shared/TextareaBox";
import CustomButton from "./../../shared/CustomButton";
import PageLoader from "./../../shared/PageLoader";
import ButtonLoader from "./../../shared/ButtonLoader";
import RequiredDataHint from "./../../shared/RequiredDataHint";
import PageContent from "./../../shared/PageContent";

// Our Services
import documentTitleService from "./../../../services/document-title-service";
import logService from "./../../../services/log-service";
import * as userService from "./../../../services/user-service";
import * as loanService from "./../../../services/loan-service";

// Our Configs
import { getRoutePath } from "./../../../configs/route-config";
import { getCommonCountryList } from "./../../../configs/country-config";
import { getLoanPurposes, getLoanDurations, getPercentagesOfTransactionGrossProfit } from "./../../../configs/loan-config";

export default class BorrowerLoanUpdate extends FormHandler {

    initialState = {
        ...this.initialStateElements,
        allowedUserType: "borrower",

        formInputs: {
            // borrower_id                         : "",
            id                                  : "",
            company_id                          : "",
            required_amount                     : "",
            currency                            : "",
            loan_purpose                        : "",
            has_previous_experience_in_the_transaction: "",
            loan_duration_in_months             : "",
            percentage_of_the_transaction_gross_profit: "",
            transaction_private_description               : "",
        },
        validationErrors : {},
        messageModal     : {},
        showPageLoader   : true,
        showButtonLoader : false,
        borrowerCompanies: [],
        urlParmas        : {},
    };

    // -----------------------------------------------

    // Clone a separate copy of the "initialState" and assign it to the "state".
    // Note that the spread operator "..." is important to get a separate copy.
    state = { ...this.initialState };

    // -----------------------------------------------

    validationRules = {
        // borrower_id: Joi.number()
        //     .required()
        //     .integer()
        //     .positive()
        //     .label("Borrower"),

        id: Joi.number()
            .optional()
            .allow(null, "")
            .integer()
            .positive(),

        company_id: Joi.number()
            .required()
            // .optional()
            // .allow(null, "")
            .integer()
            .positive()
            .label("Company"),

        required_amount: Joi.number()
            .required()
            .integer()
            .positive()
            // .min(100001)
            .max(10000000)
            .label("Required Amount"),

        currency: Joi.string()
            .required()
            .label("Currency"),

        loan_purpose: Joi.string()
            .required()
            .label("Purpose of Loan"),

        has_previous_experience_in_the_transaction: Joi.string()
            .required()
            .label("Has Previous Experience in The Transaction?"),

        loan_duration_in_months: Joi.number()
            .required()
            .integer()
            .positive()
            .label("Duration of Loan"),

        percentage_of_the_transaction_gross_profit: Joi.number()
            .required()
            .positive()
            .label("Percentage of The Transaction Gross Profit"),

        transaction_private_description: Joi.string()
            .required()
            .label("Description of The Transaction"),
    };

    // -----------------------------------------------

    componentDidMount() {
        // More info: t.ly/ZVsd
        window.scrollTo(0, 0);

        // ----------------------

        // Properly set the browser's document title
        documentTitleService.setTitle(GeneralTexts["borrower-dashboard"] + " | " + texts["borrower-loan-update"]);

        // ----------------------

        this.setState({ urlParmas: this.props.match.params });

        // ----------------------

        this.setAuthenticatedUser();
        this.getBorrowerCompanies();
        this.getLoan();
    }

    // -----------------------------------------------

    getBorrowerCompanies = async () => {
        this.setState({showPageLoader: true});

        const currentUser = await userService.getCurrentUser("?include=borrower.companies");

        if (!_isEmpty(currentUser)) {
            this.setState({
                borrowerCompanies: currentUser.borrower.companies || [],
                showPageLoader: false,
            });
        }
    }

    // -----------------------------------------------

    // Get loan data that the borrower tries to upsate
    getLoan = async () => {
        this.setState({ showPageLoader: true });

        // TODO: It's better to get the loan with the provided id directly insteas of getting all the borrower's loans
        const currentUser = await userService.getCurrentUser("?include=borrower.loans");

        if (!_isEmpty(currentUser)) {

            // If the borrower doesn't have any loan, redirecto him to the create loan page
            if (_isEmpty(currentUser.borrower.loans)) {
                this.props.history.replace(getRoutePath("borrowerLoanCreate"));
                return;
            }

            // If the borrower has a loan with the provided id in the url
            let loan = currentUser.borrower.loans.find(loan => {
                // return Number(loan.uuid) === Number(this.state.urlParmas.loanUuid);
                return loan.uuid === this.state.urlParmas.loanUuid;
            });

            // If the borrower has a loan with the provided id in the url
            if (_isEmpty(loan)) {
                loan = {};
            }

            this.setState({
                formInputs: {
                    id                                        : loan.id || "",
                    company_id                                : loan.company_id || "",
                    required_amount                           : loan.required_amount || "",
                    currency                                  : loan.currency || "",
                    loan_purpose                              : loan.loan_purpose || "",
                    has_previous_experience_in_the_transaction: loan.has_previous_experience_in_the_transaction || "",
                    loan_duration_in_months                   : loan.loan_duration_in_months || "",
                    percentage_of_the_transaction_gross_profit: loan.percentage_of_the_transaction_gross_profit || "",
                    transaction_private_description                   : loan.transaction_private_description || "",
                },
                showPageLoader: false
            });
        }
    }

    // -----------------------------------------------

    doSubmit = async (e) => {
        // TEST
        // console.log("formInputs", this.state.formInputs);

        // Display a loader icon
        this.setState({ showButtonLoader: true });

        try {
            const response = await loanService.updateBasicData(this.state.urlParmas.loanUuid, this.state.formInputs);

            this.setState({
                messageModal: {
                    ...toggleMessageModal("success"),
                    body: response.data.meta.message,
                    footerButtons: [
                        {
                            variant: "primary",
                            title: "My Loans",
                            onClick: () => this.props.history.push(getRoutePath("borrowerLoanList")),
                        },
                        {
                            variant: "primary",
                            title: "Close",
                            onClick: () => this.setState({ messageModal: { ...this.initialState.messageModal } }),
                        }
                    ],
                    // onHide: () => this.props.history.push(getRoutePath("borrowerLoanList")),
                }
            });

        } catch (error) {
            /**
             * If there is an unexpected error like: network down, server down, db down, bug, ..
             *  - Log the error.
             *  - Display a generic and friendly error message.
             *
             * Note that "error.response" determines if the error is an expected error or not.
             */

            const isExpectedError =
                error.response
                && error.response.status >= 400
                && error.response.status < 500;

            // if (!error.response) {
            if (!isExpectedError) {
                // TEST
                // console.log("Logging the error", error);

                // Log the error
                logService.log(error);
            }

            // Display an appropriate error message
            this.showErrorMessage(error);
        }

        // Hide the loader
        this.setState({ showButtonLoader: false });
    };

    // -----------------------------------------------

    showErrorMessage = (error, props = this.props) => {
        this.setState({
            messageModal: {
                ...toggleMessageModal("error"),
                body: getErrorMessage(error, props).body || "",
                footerButtons: getErrorMessage(error, props).footerButtons || [],
            }
        });
    }

    // -----------------------------------------------

	render() {
		const pageContent = (
            <React.Fragment>
                <div className="x-m-25">
                    <div>
                        <Link to={getRoutePath("borrowerLoanList")} className="x-fz-085rem">
                            <Icon
                                path={preferredLanguage.direction === "rtl" ? mdiChevronRight : mdiChevronLeft}
                                size="1.4em"
                                className="x-pos-r x-b-2"
                            />
                            <span className={switchClasses(["__ff-droidarabickufi"])}>
                                {texts["borrower-loan-list"]}
                            </span>
                        </Link>
                    </div>

                    <h5 className={"x-pt-10 head-color " + switchClasses(["__ff-droidarabickufi"])}>{texts["borrower-loan-update"]}</h5>
                </div>


                <div className="_border _border-light shadow-sm x-m-25 x-mt-0 x-mb-70 x-p-10 x-pb-100 x-mih-500 x-bgc-fff x-bdc-e3e3e3 x-bdrs-4">
                    <div className="container-fluid">
                        <PageLoader show={this.state.showPageLoader} />

                        <div className={"row x-pt-20" + (this.state.showPageLoader ? " x-d-n" : "")}>
                            <div className="col-md-9">
                                <form className={"__ff-droidarabickufi " + switchClasses(["x-fz-085rem"])} onSubmit={(e) => e.preventDefault()}>
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <SelectBox
                                                name="company_id"
                                                value={this.state.formInputs.company_id || ""}
                                                label={texts["company"]}
                                                onBlur={this.handleBlur}
                                                onChange={this.handleChange}
                                                onFocus={this.handleFocus}
                                                validationError={this.state.validationErrors.company_id || ""}
                                                selectBoxOptions={this.state.borrowerCompanies.map(company => {
                                                    return (
                                                        <option
                                                            key={Math.random()}
                                                            value={company.id}
                                                        >
                                                            {company.official_name}
                                                        </option>
                                                    )
                                                })}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-4">
                                            <TextBox
                                                name="required_amount"
                                                label={texts["required-amount"]}
                                                value={this.state.formInputs.required_amount || ""}
                                                onBlur={this.handleBlur}
                                                onChange={this.handleChange}
                                                onFocus={this.handleFocus}
                                                validationError={this.state.validationErrors.required_amount || ""}
                                                placeholder="250000"
                                            />
                                        </div>

                                        <div className="col-lg-4">
                                            <SelectBox
                                                name="currency"
                                                value={this.state.formInputs.currency || ""}
                                                label={texts["currency"]}
                                                onBlur={this.handleBlur}
                                                onChange={this.handleChange}
                                                onFocus={this.handleFocus}
                                                validationError={this.state.validationErrors.currency || ""}
                                                selectBoxOptions={getCommonCountryList().map(country => {
                                                    return (
                                                        <option
                                                            key={Math.random()}
                                                            value={country.currencies[0].code}
                                                        >
                                                            {country.currencies[0].name + " (" + country.currencies[0].code + ")"}
                                                        </option>
                                                    )
                                                })}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-4">
                                            <SelectBox
                                                name="loan_purpose"
                                                value={this.state.formInputs.loan_purpose || ""}
                                                label={texts["purpose"]}
                                                onBlur={this.handleBlur}
                                                onChange={this.handleChange}
                                                onFocus={this.handleFocus}
                                                validationError={this.state.validationErrors.loan_purpose || ""}
                                                selectBoxOptions={getLoanPurposes().map(purpose => {
                                                    return (
                                                        <option
                                                            key={Math.random()}
                                                            value={purpose.value}
                                                        >
                                                            {purpose.label}
                                                        </option>
                                                    )
                                                })}
                                            />
                                        </div>

                                        <div className="col-lg-4">
                                            <SelectBox
                                                name="loan_duration_in_months"
                                                value={this.state.formInputs.loan_duration_in_months || ""}
                                                label={texts["duration"]}
                                                onBlur={this.handleBlur}
                                                onChange={this.handleChange}
                                                onFocus={this.handleFocus}
                                                validationError={this.state.validationErrors.loan_duration_in_months || ""}
                                                selectBoxOptions={getLoanDurations().map(month => {
                                                    return (
                                                        <option
                                                            key={Math.random()}
                                                            value={month.value}
                                                        >
                                                            {month.label}
                                                        </option>
                                                    )
                                                })}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-4">
                                            <SelectBox
                                                name="has_previous_experience_in_the_transaction"
                                                value={this.state.formInputs.has_previous_experience_in_the_transaction || ""}
                                                label={texts["has-previous-experience-in-the-transaction"]}
                                                onBlur={this.handleBlur}
                                                onChange={this.handleChange}
                                                onFocus={this.handleFocus}
                                                validationError={this.state.validationErrors.has_previous_experience_in_the_transaction || ""}
                                                selectBoxOptions={
                                                    [
                                                        <option key={Math.random()} value="yes">Yes</option>,
                                                        <option key={Math.random()} value="no">No</option>,
                                                    ]
                                                }
                                            />
                                        </div>

                                        <div className="col-lg-4">
                                            <SelectBox
                                                name="percentage_of_the_transaction_gross_profit"
                                                value={this.state.formInputs.percentage_of_the_transaction_gross_profit || ""}
                                                label={texts["percentage-of-the-transaction-gross-profit"]}
                                                onBlur={this.handleBlur}
                                                onChange={this.handleChange}
                                                onFocus={this.handleFocus}
                                                validationError={this.state.validationErrors.percentage_of_the_transaction_gross_profit || ""}
                                                selectBoxOptions={getPercentagesOfTransactionGrossProfit().map(percentage => {
                                                    return (
                                                        <option
                                                            key={Math.random()}
                                                            value={percentage.value}
                                                        >
                                                            {percentage.label}
                                                        </option>
                                                    )
                                                })}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-8">
                                            <TextareaBox
                                                name="transaction_private_description"
                                                value={this.state.formInputs.transaction_private_description || ""}
                                                label={texts["description-of-transaction"]}
                                                onBlur={this.handleBlur}
                                                onChange={this.handleChange}
                                                onFocus={this.handleFocus}
                                                validationError={this.state.validationErrors.transaction_private_description || ""}
                                                placeholder={texts["description-of-transaction-placeholder"]}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="x-mt-20">
                                                <CustomButton
                                                    type="button"
                                                    label={this.state.showButtonLoader ? <ButtonLoader /> : GeneralTexts["save"]}
                                                    disabled={this.validateFormInputs() ? true : false}
                                                    onClick={this.handleSubmit}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <RequiredDataHint className={this.state.showPageLoader ? "x-d-n" : ""} />
                    </div>

                    <MessageModal
                        show={this.state.messageModal.show || false}
                        title={this.state.messageModal.title || null}
                        body={this.state.messageModal.body || null}
                        alertClassName={this.state.messageModal.alertClassName || null}
                        size={this.state.messageModal.size || null}
                        centered={this.state.messageModal.centered || false}
                        footerButtons={this.state.messageModal.footerButtons || []}
                        onHide={this.state.messageModal.onHide || (() => this.setState({ messageModal: { ...this.initialState.messageModal } }))}
                    />
                </div>
            </React.Fragment>
        );

        return (
            <PageContent
                pageContent={pageContent}
                authenticatedUser={this.getAuthenticatedUser()}
                showPageContent={this.showPageContent()}
                windowLocation={window.location}
            />
        );
	}
}
