import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import { CustomLightBoxTexts as texts } from "../../languages/CustomLightBoxTexts";
import youtubeLogoUrl from "../../resources/images/youtube-logo.png";

function CustomLightBox (props) {
    // More info: https://fslightbox.com/react

    const links                    = props.links || [];
    const lightBoxUrls             = getUrlsFromLinks(links);
    const isDeletable              = props.isDeletable || false;
    const onDelete                 = props.onDelete || null;
    const numberOfThumbnailsToShow = props.numberOfThumbnailsToShow || links.length;

    const [lightBoxToggler, setLightBoxToggler] = useState(() => false);
    const [lightBoxSource, setLightBoxSource]   = useState(() => lightBoxUrls[0] || "");

    return (
        <>
            {/* {links.map((link, index) => getContentOfMultimediaItem(link))} */}
            <div className="row">
                {getLinksToShowAsThumbnails().map((link, index) => getContentOfMultimediaItem(link))}
            </div>

            <div className="row">
                <div className="col">
                    {getShowMoreButton()}
                </div>
            </div>

            <FsLightbox
                toggler={lightBoxToggler}
                // More info about "sources": t.ly/4ql2
                sources={lightBoxUrls}
                // "source" is used to open the lightbox on a specific slide. More info: t.ly/WMpj
                source={lightBoxSource}
                // More info about "types" and how to avoid CORS issues: t.ly/P6W6
                types={getMultimediaItemTypes(lightBoxUrls)}
            />
        </>
    );

    function getLinksToShowAsThumbnails() {
        if (links.length > numberOfThumbnailsToShow) {
            return links.slice(0, numberOfThumbnailsToShow);
        }

        return links;
    }

    /**
     *
     */
    function getContentOfMultimediaItem(link) {
        if (isYoutubeVideoUrl(link.full_url)) {
            const [youtubeVideoThumbnailUrl, youtubeVideoUrl] = handleMultimediaItemUrl(link.full_url);

            return (
                <div className="col-md-4 x-pt-15 x-pb-15 " key={Math.random()}>
                    <div className="x-p-3 border x-bdrs-2 shadow-sm">
                        <div className="x-h-130 x-ov-h">
                            <img
                                src={youtubeVideoThumbnailUrl}
                                alt=""
                                className="x-mih-130 x-w-100p x-cur-p _img-fluid"
                                onClick={() => openLightBoxOnSlide(youtubeVideoUrl)}
                            />
                            <div
                                className="flex-center x-cur-p x-h-130 x-ta-c x-pos-r"
                                style={{top: "-140px", left: "0"}}
                                onClick={() => openLightBoxOnSlide(youtubeVideoUrl)}
                            >
                                <img src={youtubeLogoUrl} alt="" className="x-w-40 x-h-a x-m-a" />
                            </div>
                        </div>
                    </div>

                    {getDeleteButton(link)}
                </div>
            );
        } else {
            const [imageThumbnailUrl, imageUrl] = handleMultimediaItemUrl(link.full_url);

            return (
                <div className="col-md-4 x-pt-15 x-pb-15 " key={Math.random()}>
                    <div className="x-p-3 border x-bdrs-2 shadow-sm">
                        <div className="x-h-130 x-ov-h">
                            <img
                                src={imageThumbnailUrl}
                                alt=""
                                className="x-mih-130 x-w-100p x-cur-p"
                                onClick={() => openLightBoxOnSlide(imageUrl)}
                            />
                        </div>
                    </div>

                    {getDeleteButton(link)}
                </div>
            );
        }
    }

    /**
     *
     */
    function getUrlsFromLinks(links) {
        let urls = [];

        links.forEach(link => {
            urls.push(link.full_url);
        });

        return urls;
    }

    /**
    *
    */
    function getMultimediaItemTypes(urls) {
        let types = urls.map(url => {
            if (isYoutubeVideoUrl(url)) {
                return "youtube";
            }

            return "image";
        });

        return types;
    }

    /**
     *
     */
    function isYoutubeVideoUrl(url) {
        url = url.toLowerCase();

        // if (url.includes("youtube") && url.includes("v=")) {
        if (url.includes("youtube.com/watch?v=")) {
            return true;
        }

        return false;
    }

    /**
     *
     */
    function handleMultimediaItemUrl(url) {
        if (isYoutubeVideoUrl(url)) {
            const videoId = getYoutubeVideoIdFromUrl(url);

            return [
                // Don't change the order of the following array items
                `https://img.youtube.com/vi/${videoId}/0.jpg`, // Youtube video thumbnail url
                `https://www.youtube.com/watch?v=${videoId}`,  // Youtube video url
            ];
        }

        // Later we may want to handle the thumbnail url of the image. But
        // for now, we just return the same url for both items of the array
        const thumbnailUrl = url;
        return [thumbnailUrl, url];
    }

    /**
     *
     */
    function openLightBoxOnSlide(url) {
        setLightBoxToggler(prevToggler => ! prevToggler);
        // setLightBoxSource(prevUrl => url);
        setLightBoxSource(() => url);
	}

    /**
     *
     */
    function getYoutubeVideoIdFromUrl(url) {
        let videoId = url.split("v=")[1];

        videoId = videoId.split("#")[0];

        videoId = videoId.split("&")[0];

        return videoId;
    }

    function getDeleteButton(link) {
        if (isDeletable) {
            return (
                <div className="x-ta-c">
                    <button
                        className="btn btn-link btn-sm"
                        type="button"
                        onClick={() => onDelete(link)}
                    >
                        Delete
                    </button>
                </div>
            );
        }

        return null;
    }

    function getShowMoreButton() {
        if (links.length > numberOfThumbnailsToShow) {
            return (
                <button
                    className="btn btn-link btn-sm"
                    type="button"
                    onClick={() => openLightBoxOnSlide(lightBoxUrls[numberOfThumbnailsToShow])}
                >
                    {texts["show-more"]}
                </button>
            );
        }

        return null;
    }
};

export default CustomLightBox;
