import React from "react";
import { Link } from "react-router-dom";
import _isEmpty from "lodash/isEmpty"
// import FsLightbox from "fslightbox-react";

import { preferredLanguage } from "../../../configs/ui-language-config";
import { switchClasses } from "../../../languages/CssClasses";
import { AdminLoanInvestigationGalleryTexts as texts } from "../../../languages/AdminLoanInvestigationGalleryTexts";
import { GeneralTexts } from "../../../languages/GeneralTexts";

import Icon from "@mdi/react"
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";

import { getRoutePath } from "../../../configs/route-config";

// Our Modules
import MessageModal, { toggleMessageModal, getErrorMessage } from "../../shared/MessageModal";
import FormHandler from "../../shared/FormHandler";
import PageLoader from "../../shared/PageLoader";
// import RequiredDataHint from "./../../shared/RequiredDataHint";
import AdminLoanSubMenu from "./AdminLoanSubMenu";
import PageContent from "../../shared/PageContent";
import CustomLightBox from "../../shared/CustomLightBox";

// Our Services
import documentTitleService from "../../../services/document-title-service";
import logService from "../../../services/log-service";
import * as loanService from "../../../services/loan-service";
import * as linkService from "../../../services/link-service";

// import youtubeLogoUrl from "./../../../../src/resources/images/youtube-logo.png";

export default class AdminLoanInvestigationGallery extends FormHandler {
    /**
     *
     */
    initialState = {
        ...this.initialStateElements,
        allowedUserType: "admin",

        validationErrors         : {},
        messageModal             : {},
        showPageLoader           : true,
        showButtonLoader         : false,
        loan                     : {},
        // loanLinks                : [],
        investigationGalleryLinks: [],
        selectedFiles            : [],
        youtubeVideoUrlInputValue: "",
    };

    /**
     * Clone a separate copy of the "initialState" and assign it to the "state".
     * Note that the spread operator "..." is important to get a separate copy.
     */
    state = { ...this.initialState };

    /**
     *
     */
    validationRules = {};

    /**
     *
     */
    componentDidMount() {
        // More info: t.ly/ZVsd
        window.scrollTo(0, 0);

        // Properly set the browser's document title
        documentTitleService.setTitle(GeneralTexts["admin-dashboard"] + " | " + texts["admin-loan-investigation-gallery"]);

        this.setAuthenticatedUser();
        this.getLoan();
    }

    /**
     * Get loan data that the admin tries to update
     */
    getLoan = async () => {
        this.setState({ showPageLoader: true });

        // const response = await loanService.getItem(this.props.match.params.loanUuid, "?include=company|indicator|links");
        const response = await loanService.getItem(this.props.match.params.loanUuid, "?include=links");

        const loan = response.data.data.loan;

        // const properLoanLinks = await this.getProperLoanLinks(loan.links);

        this.setState({
            showPageLoader: false,
            loan          : loan,
            // loanLinks     : properLoanLinks,
        });

        this.setInvestigationGalleryLinks(loan.links);

        // console.log("xLoan: ", loan);
    }

    /**
     *
     */
    handleLinkFileChange = (event) => {
        // const { name, value } = event.target;
        //  this.setState({ [name]: value });


        this.setState({selectedFiles: []});

        const input = event.target;

        console.log("link_file: ", input.files);

        if (input.files && input.files[0]) {
            // const reader = new FileReader();

            // reader.onload = (e) => {
            //     const linkFile = e.target.result;

            //     this.setState({ linkFile: linkFile });
            // }

            // reader.readAsDataURL(input.files[0]);

            const selectedFiles = input.files;

            this.setState({selectedFiles: selectedFiles});
        }
    }

    /**
     *
     */
    handleUploadingImage = async (event) => {
        // Prevent default behavior
        event.preventDefault();

        this.setState({ showPageLoader: true });

        const formData = new FormData(event.target);

        // If no file selected
        if (_isEmpty(this.state.selectedFiles)) {
            alert(texts["choose-image-first"]);
            this.setState({ showPageLoader: false });
            return;
        }

        formData.set("category", formData.get("category"));
        formData.set("linkable_id", this.state.loan.id);

        try {
            let selectedFiles = this.state.selectedFiles;

            for (let i = 0; i < selectedFiles.length; i++) {
                formData.set("link_file", selectedFiles[i]);

                const response = await linkService.create(formData);
                const linkDataOfUploadedFile = response.data.data.link;

                this.addAddedLinkToInvestigationGalleryLinks(linkDataOfUploadedFile);
            }

            // Reset the "selectedFiles" state so that the user don't upload the same file again by mistake
            this.setState({selectedFiles: []});

            this.reloadThePage();
        } catch (error) {
            /**
             * If there is an unexpected error like: network down, server down, db down, bug, ..
             *  - Log the error.
             *  - Display a generic and friendly error message.
             *
             * Note that "error.response" determines if the error is an expected error or not.
             */

            const isExpectedError =
                error.response
                && error.response.status >= 400
                && error.response.status < 500;

            if (!isExpectedError) {
                logService.log(error);
            }

            this.showErrorMessage(error);
        }

        this.setState({ showPageLoader: false });
    }

    /**
     *
     */
    handleAddingYoutubeVideoUrl = async (event) => {
        // Prevent default behavior
        event.preventDefault();

        this.setState({ showPageLoader: true });

        const formData = new FormData(event.target);

        // If no youtube video url added
        // if (_isEmpty(formData.get("full_url"))) {
        if (! this.isValidYoutubeVideoUrl(formData.get("full_url"))) {
            alert(texts["add-youtube-video-url"]);
            this.setState({ showPageLoader: false });
            return;
        }

        // Access FormData fields with `formData.get(fieldName)`
        formData.set("full_url", this.getProperYoutubeVideoUrl(formData.get("full_url")));
        formData.set("category", formData.get("category"));
        formData.set("linkable_id", this.state.loan.id);

        try {
            const response = await linkService.create(formData);
            const linkDataOfUploadedFile = response.data.data.link;

            this.addAddedLinkToInvestigationGalleryLinks(linkDataOfUploadedFile);

            // Reset the "youtubeVideoUrlInputValue" state so that the user don't add the same url again by mistake
            this.setState({youtubeVideoUrlInputValue: ""});

            this.reloadThePage();
        } catch (error) {
            /**
             * If there is an unexpected error like: network down, server down, db down, bug, ..
             *  - Log the error.
             *  - Display a generic and friendly error message.
             *
             * Note that "error.response" determines if the error is an expected error or not.
             */

            const isExpectedError =
                error.response
                && error.response.status >= 400
                && error.response.status < 500;

            if (!isExpectedError) {
                logService.log(error);
            }

            this.showErrorMessage(error);
        }

        this.setState({ showPageLoader: false });
    }

    /**
     *
     */
    handleLinkDelete = async (link) => {
        this.setState({ showPageLoader: true });

        try {
            const response = await linkService.deleteLink(link.uuid);

            if (response.data.meta.success) {
                this.removeDeletedLinkFromState(link);
            }

            this.reloadThePage();

            // this.setState({
            //     messageModal: {
            //         ...toggleMessageModal("success"),
            //         body: response.data.meta.message,
            //         onHide: (() => window.location.reload()),
            //     }
            // });
        } catch (error) {
            /**
             * If there is an unexpected error like: network down, server down, db down, bug, ..
             *  - Log the error.
             *  - Display a generic and friendly error message.
             *
             * Note that "error.response" determines if the error is an expected error or not.
             */

            const isExpectedError =
                error.response
                && error.response.status >= 400
                && error.response.status < 500;

            if (!isExpectedError) {
                logService.log(error);
            }

            this.showErrorMessage(error);
        }

        this.setState({ showPageLoader: false });
    }

    /**
     *
     */
    removeDeletedLinkFromState = (link) => {
        const investigationGalleryLinks = this.state.investigationGalleryLinks;

        const newInvestigationGalleryLinks = investigationGalleryLinks.filter((investigationGalleryLink) => {
            return investigationGalleryLink.uuid !== link.uuid;
        });

        this.setState({investigationGalleryLinks: newInvestigationGalleryLinks});
    }

    getProperYoutubeVideoUrl = (url) => {
        let videoId = url.split("v=")[1];

        videoId = videoId.split("#")[0];

        videoId = videoId.split("&")[0];

        return `https://www.youtube.com/watch?v=${videoId}`;
    }

    /**
     *
     */
    isValidYoutubeVideoUrl = (url) => {
        url = url || "";

        url = url.toLowerCase();

        // if (url.includes("youtube") && url.includes("v=")) {
        if (url.includes("youtube.com/watch?v=")) {
            return true;
        }

        return false;
    }

    /**
     *
     */
    reloadThePage = () => {
        window.location.reload();
    }

    /**
     *
     */
    showErrorMessage = (error, props = this.props) => {
        this.setState({
            messageModal: {
                ...toggleMessageModal("error"),
                body: getErrorMessage(error, props).body || "",
                footerButtons: getErrorMessage(error, props).footerButtons || [],
            }
        });
    }

    /**
     *
     */
    render() {
        return (
            <PageContent
                pageContent={this.getPageContent()}
                authenticatedUser={this.getAuthenticatedUser()}
                showPageContent={this.showPageContent()}
                windowLocation={window.location}
            />
        );
    }

    /**
     *
     */
    getPageContent = () => {
        return (
            <div>
                {this.getPageTitle()}

                <div className="x-mt-15">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-3 col-lg-2 x-pr-8 x-pl-8">
                                <AdminLoanSubMenu loan={this.state.loan} />
                            </div>

                            <div className="col-md-9 col-lg-10 x-pr-8 x-pl-8">
                                <div className="shadow-sm x-m-0 x-mt-15 x-mb-70 x-p-20 x-pt-16 x-pb-100 x-mih-500 x-bgc-fff x-bdc-e3e3e3 x-bdrs-4">
                                    {/* <PageLoader show={this.state.showPageLoader} /> */}

                                    <div className={"row"}>
                                        <div className="col-md-4 x-pt-20">
                                            <div>
                                                {this.getFormContentOfImageUploader()}
                                            </div>

                                            <div className="x-pt-60">
                                                {this.getFormContentOfAddingYoutubeVideoUrl()}
                                            </div>
                                        </div>
                                        <div className="col-md-1"></div>
                                        <div className="col-md-7">
                                            <div className="row">
                                                <div className="col x-ta-c x-pb-20">
                                                    <PageLoader show={this.state.showPageLoader} />
                                                </div>
                                            </div>
                                            <div className="x-dr-ltr">
                                                <CustomLightBox
                                                    links={this.getInvestigationGalleryLinks()}
                                                    isDeletable={true}
                                                    onDelete={this.handleLinkDelete}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.getMessageModal()}
            </div>
        );
    }

    /**
     *
     */
    getPageTitle = () => {
        return (
            <>
                <div className={"x-m-25 x-mb-15 " + switchClasses(["x-mr-15"])}>
                    <div>
                        <Link to={getRoutePath("adminLoanList")} className="x-fz-085rem">
                            <Icon
                                path={preferredLanguage.direction === "rtl" ? mdiChevronRight : mdiChevronLeft}
                                size="1.4em"
                                className="x-pos-r x-b-2"
                            />
                            <span className={switchClasses(["__ff-droidarabickufi"])}>
                                {texts["admin-loan-list"]}
                            </span>
                        </Link>
                    </div>

                    <h5 className={"x-pt-10 head-color "}>{texts["admin-loan-investigation-gallery"]}</h5>
                </div>
            </>
        );
    }

    /**
     *
     */
    getInvestigationGalleryLinks = () => {
        return this.state.investigationGalleryLinks || [];
    }

    /**
     *
     */
    setInvestigationGalleryLinks = (links) => {
        let youtubeVideoLinks = [];
        let imageLinks        = [];

        links.forEach(link => {
            if (link.category === "loan-investigation-youtube-video-url") {
                youtubeVideoLinks.push(link);
            } else if (link.category === "loan-investigation-image") {
                imageLinks.push(link);
            }
        });

        // Because we want the the videos to come first, we place them in the beginning of the array.
        const galleryLinks = [...youtubeVideoLinks, ...imageLinks];

        this.setState({
            investigationGalleryLinks: galleryLinks
        });
    }

    /**
     *
     */
    addAddedLinkToInvestigationGalleryLinks = (link) => {
        let links = this.getInvestigationGalleryLinks();

        links.push(link);

        // this.setInvestigationGalleryLinks(links);

        this.setState({
            investigationGalleryLinks: links
        });
    }

    /**
     *
     */
    getFormContentOfImageUploader = () => {
        return (
            <form className={" " + switchClasses(["x-fz-085rem"])} onSubmit={this.handleUploadingImage} encType="multipart/form-data">
                <input type="hidden" name="category" value="loan-investigation-image" />

                {/* TODO: Create a separate component for this input group so you can easily use it anywhere */}
                <div className="form-group ltr_grouped_field_and_button">
                    <label className="">
                        {texts["add-image"]}
                    </label>
                    <div className="input-group x-dr-ltr x-ta-l">
                        <div className="custom-file">
                            <input
                                className={"custom-file-input form-control"}
                                disabled={false}
                                // id="link_file"
                                multiple="multiple"
                                name="link_file"
                                type="file"
                                onChange={this.handleLinkFileChange}
                            />
                            <label className="custom-file-label form-control x-c-999" htmlFor="link_file">
                                {this.getProperCaptionOfLinkFileInput()}
                            </label>
                        </div>
                        <div className="input-group-append">
                            <button type="submit" className="btn btn-primary x-miw-80">{texts["upload"]}</button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }

    getProperCaptionOfLinkFileInput = () => {
        if (this.state.selectedFiles.length > 0) {
            return (<span className="x-c-crimson">{texts["now-click-upload"]}</span>);
        }

        return texts["choose-image"];
    }

    /**
     *
     */
    getFormContentOfAddingYoutubeVideoUrl = () => {
        return (
            <>
                <form className={"x-pb-30 " + switchClasses(["x-fz-085rem"])} onSubmit={this.handleAddingYoutubeVideoUrl}>
                    <input type="hidden" name="category" value="loan-investigation-youtube-video-url" />

                    <div className="form-group ltr_grouped_field_and_button">
                        <label className="">
                            {texts["add-youtube-video-url"]}
                        </label>
                        <div className="input-group x-dr-ltr x-ta-l">
                            <input
                                autoComplete="off"
                                className="form-control x-fz-1rem"
                                name="full_url"
                                placeholder=""
                                type="text"
                                value={this.state.youtubeVideoUrlInputValue}
                                onChange={(e) => this.setState({youtubeVideoUrlInputValue: e.target.value})}
                            />
                            <div className="input-group-append">
                                {/* <button className="btn btn-primary x-miw-80" type="submit" onClick={() => this.setState({youtubeVideoUrlInputValue: undefined})}>Add</button> */}
                                <button className="btn btn-primary x-miw-80" type="submit">Add</button>
                            </div>
                        </div>
                    </div>
                </form>
            </>
        );
    }

    /**
     *
     */
    getMessageModal = () => {
        return (
            <MessageModal
                show={this.state.messageModal.show || false}
                title={this.state.messageModal.title || null}
                body={this.state.messageModal.body || null}
                alertClassName={this.state.messageModal.alertClassName || null}
                size={this.state.messageModal.size || null}
                centered={this.state.messageModal.centered || false}
                footerButtons={this.state.messageModal.footerButtons || []}
                onHide={this.state.messageModal.onHide || (() => this.setState({ messageModal: { ...this.initialState.messageModal } }))}
            />
        );
    }
}
