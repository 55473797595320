import { preferredLanguage } from "./../configs/ui-language-config";
// import { GeneralTexts } from "./GeneralTexts";
import { RouteConfigTexts } from "./RouteConfigTexts";

const texts = {
    ar: {
        "admin-loan-basic"                          : RouteConfigTexts["admin-loan-basic"],
        "admin-loan-list"                           : RouteConfigTexts["admin-loan-list"],
        "borrower"                                  : "اسم المقترض",
        "company"                                   : "اسم الشركة",
        "required-amount"                           : "المبلغ المطلوب",
        "currency"                                  : "العملة",
        "purpose"                                   : "الغرض من القرض",
        "duration"                                  : "مدة القرض",
        "has-previous-experience-in-the-transaction": "لديك خبرة سابقة في العملية محل التمويل؟",
        "percentage-of-the-transaction-gross-profit": "نسبة الربح الإجمالي للعملية (%)",
        "description-of-transaction"                : "ما تفاصيل العملية التي تريد تمويلها؟",
        "description-of-transaction-placeholder"    : "اشرح هنا بالتفصيل طبيعة العملية التي تريد الاقتراض لتنفيذها..",
    },

    en: {
        "admin-loan-basic"                          : RouteConfigTexts["admin-loan-basic"],
        "admin-loan-list"                           : RouteConfigTexts["admin-loan-list"],
        "borrower"                                  : "Borrower",
        "company"                                   : "Company",
        "required-amount"                           : "Required Amount",
        "currency"                                  : "Currency",
        "purpose"                                   : "Purpose of Loan",
        "duration"                                  : "Duration of Loan",
        "has-previous-experience-in-the-transaction": "Has Previous Experience in The Transaction?",
        "percentage-of-the-transaction-gross-profit": "Percentage of The Transaction Gross Profit (%)",
        "description-of-transaction"                : "What are you going to do with the loan?",
        "description-of-transaction-placeholder"    : "Describe here what are you going to do with the loan..",
    }
}

export const AdminLoanBasicTexts = texts[preferredLanguage.alpha2code];
