import React from "react";
// import { Link } from "react-router-dom";

import { switchClasses } from "./../../../languages/CssClasses";
import { LenderLoanListTexts as texts } from "./../../../languages/LenderLoanListTexts";
import { GeneralTexts } from "./../../../languages/GeneralTexts";

// Third-Party Modules
import moment from "moment";
import numeral from "numeral"
import _isEmpty from "lodash/isEmpty";
import _startCase from "lodash/startCase";
import _toLower from "lodash/toLower";

// Our Services
import documentTitleService from "./../../../services/document-title-service";
import * as userService from "./../../../services/user-service";

// Our Modules
import PageLoader from "./../../shared/PageLoader";
// import CustomButton from "./../../shared/CustomButton";
import Pagination2 from "./../../shared/Pagination2";
import PageContent from "./../../shared/PageContent";
import PageHandler from "./../../shared/PageHandler";

// Our Configs
import { getRoutePath } from "./../../../configs/route-config";
import { getPagination } from "./../../../configs/general-config";

// Our Helpers
import * as paginateHelper from "./../../../helpers/paginate-helper";

export default class LenderLoanList extends PageHandler {

    initialState = {
        ...this.initialStateElements,
        allowedUserType: "lender",

        loansICommittedTo: [],
        showPageLoader   : true,
        pagination       : getPagination(),
    };

    // -----------------------------------------------

    // Clone a separate copy of the "initialState" and assign it to the "state".
    // Note that the spread operator "..." is important to get a separate copy.
    state = { ...this.initialState };

    // -----------------------------------------------

    componentDidMount() {
        // More info: t.ly/ZVsd
        window.scrollTo(0, 0);

        // ----------------------

        // Properly set the browser's document title
        documentTitleService.setTitle(GeneralTexts["lender-dashboard"] + " | " + texts["lender-loan-list"]);

        // ----------------------

        this.setAuthenticatedUser();
        this.getLoansICommittedTo();
    }

    // -----------------------------------------------

    getLoansICommittedTo = async () => {
        const currentUser = await userService.getCurrentUser("?include=lender.loans.company");

        console.log("currentUser: ", currentUser);

        if (!_isEmpty(currentUser)) {
            // Sort ascending according to the "id"
            // currentUser.lender.loans.sort((a, b) => a.id - b.id);

            // Sort descending according to the "id"
            currentUser.lender.loans.sort((a, b) => b.id - a.id);

            this.setState({
                loansICommittedTo: currentUser.lender.loans || [],
                showPageLoader: false,
            });
        }
    }

    // -----------------------------------------------

    getLoansOfCurrentPage = () => {
        return paginateHelper.getCurrentPageItems(
            this.state.loansICommittedTo,
            this.state.pagination.currentPage,
            this.state.pagination.numberOfItemsPerPage
        );
    }

    // -----------------------------------------------

    handlePaginationChange = (pageNumber) => {
        this.setState({ pagination: { ...this.state.pagination, currentPage: pageNumber } });
    }

    // -----------------------------------------------

    render() {
        const showHint = (
            <div className={"x-ta-c x-c-crimson x-pt-50 x-lh-34 " + switchClasses(["__ff-droidarabickufi", "x-fz-085rem"])}>
                {texts["no-investments-message"]}
            </div>
        );

        // ----------------------

        const showLoans = (
            <React.Fragment>
                <div className="table-responsive x-pt-30">
                    <table className="table table-lg table-hover x-fz-085rem __ff-droidarabickufi">
                        <thead>
                            <tr className={switchClasses(["x-fz-08rem"])}>
                                {/* <th>Date</th> */}
                                {/* <th>Company</th> */}
                                <th>{texts["required"]}</th>
                                <th>{texts["pledged"]}</th>
                                <th>{texts["my-commitment"]}</th>
                                <th>{texts["duration"]}</th>
                                <th>{texts["crowdfunding-campaign"]}</th>
                                {/* <th>Crowdfunding Campaign End</th> */}
                                <th>{texts["status"]}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.getLoansOfCurrentPage().map(loan => {
                                return (
                                    <tr key={loan.id} className="__ff-rubik">
                                        {/* <td>{moment(loan.created_at).format("YYYY-MM-DD")}</td> */}
                                        {/* <td>{_startCase(_toLower(!_isEmpty(loan.company) ? loan.company.official_name : ""))}</td> */}
                                        <td><span className="x-c-bbb">{loan.currency}</span> {numeral(loan.required_amount).format("0,0") || 0}</td>
                                        <td><span className="x-c-bbb">{loan.currency}</span> {numeral(loan.pledged_amount).format("0,0") || 0}</td>
                                        <td><span className="x-c-bbb">{loan.currency}</span> {numeral(loan.pivot.amount).format("0,0") || 0}</td>
                                        <td>{loan.loan_duration_in_months} <span className="x-c-bbb">{texts["month"]}</span></td>
                                        <td>
                                            {loan.crowdfunding_campaign_starts_at ? moment(loan.crowdfunding_campaign_starts_at).format("YYYY-MM-DD") : ""}
                                            {loan.crowdfunding_campaign_starts_at ? <span className="x-c-bbb">&nbsp;/&nbsp;</span> : ""}
                                            {loan.crowdfunding_campaign_ends_at ? moment(loan.crowdfunding_campaign_ends_at).format("YYYY-MM-DD") : ""}
                                        </td>
                                        {/* <td>{loan.crowdfunding_campaign_ends_at ? moment(loan.crowdfunding_campaign_ends_at).format("YYYY-MM-DD") : ""}</td> */}
                                        <td className="x-maw-200">{_startCase(_toLower(loan.current_status))}</td>
                                        <td className={switchClasses(["x-ta-l"])}>
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-sm shadow-sm x-fz-075rem"
                                                // TODO: Enable this line when the LenderLoanDetails page is ready
                                                // onClick={() => this.props.history.push(getRoutePath("lenderLoanDetails") + "/" + loan.uuid)}
                                                onClick={() => this.props.history.push(getRoutePath("loanDetails") + "/" + loan.uuid)}
                                            >
                                                {texts["details"]}
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>

                <div className="x-pt-30">
                    <Pagination2
                        numberOfTotalItems={this.state.loansICommittedTo.length}
                        numberOfItemsPerPage={this.state.pagination.numberOfItemsPerPage}
                        currentPage={this.state.pagination.currentPage}
                        onPaginationChange={this.handlePaginationChange}
                    />
                </div>
            </React.Fragment>
        );

        // ----------------------

        const pageContent = (
            <React.Fragment>
                <div className="x-m-25 x-mt-50">
                    <h5 className={"head-color " + switchClasses(["__ff-droidarabickufi"])}>{texts["lender-loan-list"]}</h5>
                </div>

                <div className="_border _border-light shadow-sm x-m-25 x-mt-0 x-mb-70 x-p-10 x-pb-100 x-mih-500 x-bgc-fff x-bdc-e3e3e3 x-bdrs-4">
                    <div className="container-fluid">
                        <PageLoader show={this.state.showPageLoader} />

                        <div className={"row x-pt-20" + (this.state.showPageLoader ? " x-d-n" : "")}>
                            <div className="col">
                                {
                                    this.state.loansICommittedTo.length === 0
                                        ? showHint
                                        : showLoans
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );

        return (
            <PageContent
                pageContent={pageContent}
                authenticatedUser={this.getAuthenticatedUser()}
                showPageContent={this.showPageContent()}
                windowLocation={window.location}
            />
        );
    }
}
