// import React from "react";
import { preferredLanguage } from "./../configs/ui-language-config";
// import { GeneralTexts } from "./GeneralTexts";
import { RouteConfigTexts } from "./RouteConfigTexts";

const texts = {
    ar: {
        "admin-loan-list"      : RouteConfigTexts["admin-loan-list"],
        "date"                 : "التاريخ",
        "company"              : "الشركة",
        "required"             : "المطلوب",
        "pledged"              : "المُتعهد به",
        "duration"             : "المدة",
        "crowdfunding-campaign": "حملة التمويل",
        "loan-status"          : "حالة الطلب",
        "month"                : "شهر",
        "edit"                 : "تعديل",
        "no-loans-message"     : "لا يوجد قروض لعرضها بعد",
        "details"              : "التفاصيل",
    },

    en: {
        "admin-loan-list"      : RouteConfigTexts["admin-loan-list"],
        "date"                 : "Date",
        "company"              : "Company",
        "required"             : "Required",
        "pledged"              : "Pledged",
        "duration"             : "Duration",
        "crowdfunding-campaign": "Crowdfunding Campaign",
        "loan-status"          : "Loan Status",
        "month"                : "months",
        "edit"                 : "Edit",
        "no-loans-message"     : "No loans yet",
        "details"              : "Details",
    }
}

export const AdminLoanListTexts = texts[preferredLanguage.alpha2code];
