import React from "react";
// import { Link } from "react-router-dom";

import { switchClasses } from "./../../../languages/CssClasses";
import { BorrowerCompanyListTexts as texts } from "./../../../languages/BorrowerCompanyListTexts";
import { GeneralTexts } from "./../../../languages/GeneralTexts";

// Third-Party Modules
import _isEmpty from "lodash/isEmpty";
import _startCase from "lodash/startCase";
import _toLower from "lodash/toLower";

// Our Services
import documentTitleService from "./../../../services/document-title-service";
import * as userService from "./../../../services/user-service";

// Our Modules
import CustomButton from "./../../shared/CustomButton";
import PageLoader from "./../../shared/PageLoader";
import Pagination2 from "./../../shared/Pagination2";
import PageContent from "./../../shared/PageContent";

// Our Configs
import { getRoutePath } from "./../../../configs/route-config";
import { getPagination } from "./../../../configs/general-config";

// Our Helpers
import * as paginateHelper from "./../../../helpers/paginate-helper";
import PageHandler from "../../shared/PageHandler";

export default class BorrowerCompanyList extends PageHandler {
    initialState = {
        ...this.initialStateElements,
        allowedUserType: "borrower",

        myCompanies   : [],
        showPageLoader: true,
        pagination    : getPagination(),
    };

    // -----------------------------------------------

    // Clone a separate copy of the "initialState" and assign it to the "state".
    // Note that the spread operator "..." is important to get a separate copy.
    state = { ...this.initialState };

    // -----------------------------------------------

    componentDidMount() {
        // More info: t.ly/ZVsd
        window.scrollTo(0, 0);

        // ----------------------

        // Properly set the browser's document title
        documentTitleService.setTitle(GeneralTexts["borrower-dashboard"] + " | " + texts["borrower-company-list"]);

        // ----------------------

        this.setAuthenticatedUser();
        this.getMyCompanies();
    }

    // -----------------------------------------------

    getMyCompanies = async () => {
        const currentUser = await userService.getCurrentUser("?include=borrower.companies");

        if (!_isEmpty(currentUser)) {
            // Sort ascending according to the "id"
            // currentUser.borrower.companies.sort((a, b) => a.id - b.id);

            // Sort descending according to the "id"
            currentUser.borrower.companies.sort((a, b) => b.id - a.id);

            this.setState({
                myCompanies: currentUser.borrower.companies || [],
                showPageLoader: false,
            });
        }
    }

    // -----------------------------------------------

    getCompaniesOfCurrentPage = () => {
        return paginateHelper.getCurrentPageItems(
            this.state.myCompanies,
            this.state.pagination.currentPage,
            this.state.pagination.numberOfItemsPerPage
        );
    }

    // -----------------------------------------------

    handlePaginationChange = (pageNumber) => {
        this.setState({pagination: {...this.state.pagination, currentPage: pageNumber}});
    }

    // -----------------------------------------------

    render() {
        const showHint = (
            <div className={"x-ta-c x-c-crimson x-pt-50 x-lh-34 " + switchClasses(["__ff-droidarabickufi", "x-fz-085rem"])}>
                {texts["no-companies-message"]}
            </div>
        );

        // ----------------------

        const showCompanies = (
            <React.Fragment>
                <div className="table-responsive x-pt-30">
                    <table className="table table-lg table-hover x-fz-085rem __ff-droidarabickufi">
                        <thead>
                            <tr className={switchClasses(["x-fz-08rem"])}>
                                <th>{texts["name"]}</th>
                                <th>{texts["type"]}</th>
                                <th>{texts["sector"]}</th>
                                <th>{texts["founded"]}</th>
                                <th>{texts["city"]}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.getCompaniesOfCurrentPage().map(company => {
                                return (
                                    <tr key={company.id} className="__ff-rubik">
                                        {/* <td><Link to={getRoutePath("borrowerCompanyUpdate") + "/" + company.id}>{company.official_name}</Link></td> */}
                                        <td>{company.official_name}</td>
                                        <td>{_startCase(_toLower(company.company_type))}</td>
                                        <td>{_startCase(_toLower(company.activity_sector))} {company.activity_subsector ? "-" : ""} {_startCase(_toLower(company.activity_subsector))}</td>
                                        <td>{company.founded_in}</td>
                                        <td>{company.city} - {company.country}</td>
                                        <td className={switchClasses(["x-ta-l"])}>
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-sm shadow-sm x-fz-075rem"
                                                onClick={() => this.props.history.push(getRoutePath("borrowerCompanyUpdate") + "/" + company.id)}
                                            >
                                                {texts["edit"]}
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>

                <div className="x-pt-30">
                    <Pagination2
                        numberOfTotalItems={this.state.myCompanies.length}
                        numberOfItemsPerPage={this.state.pagination.numberOfItemsPerPage}
                        currentPage={this.state.pagination.currentPage}
                        onPaginationChange={this.handlePaginationChange}
                    />
                </div>
            </React.Fragment>
        );

        // ----------------------

        const pageContent = (
            <React.Fragment>
                <div className="x-m-25 x-mt-50 clearfix">
                    <h5 className={"head-color " + switchClasses(["__ff-droidarabickufi", "x-fl-r"])}>{texts["borrower-company-list"]}</h5>

                    {/* If the borrower has created a company before, we will hide the "Add New Company" button so that he can't add more companies */}
                    <div className={switchClasses(["x-fl-l"]) + (this.state.myCompanies.length ? " x-d-n" : "")}>
                        <CustomButton
                            type="button"
                            label={texts["add-new-company"]}
                            disabled={false}
                            onClick={() => { this.props.history.push(getRoutePath("borrowerCompanyCreate")) }}
                            extraCssClasses={switchClasses(["__ff-droidarabickufi", "x-fz-085rem"])}
                        />
                    </div>
                </div>

                <div className="_border _border-light shadow-sm x-m-25 x-mt-0 x-mb-70 x-p-10 x-pb-100 x-mih-500 x-bgc-fff x-bdc-e3e3e3 x-bdrs-4">
                    <div className="container-fluid">
                        <PageLoader show={this.state.showPageLoader} />

                        <div className={"row x-pt-20" + (this.state.showPageLoader ? " x-d-n" : "")}>
                            <div className="col">
                                {
                                    this.state.myCompanies.length === 0
                                    ? showHint
                                    : showCompanies
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );

        return (
            <PageContent
                pageContent={pageContent}
                authenticatedUser={this.getAuthenticatedUser()}
                showPageContent={this.showPageContent()}
                windowLocation={window.location}
            />
        );
    }
}
