import { RouteConfigTexts as texts } from "./../languages/RouteConfigTexts";

import _find from "lodash/find";
import _isEmpty from "lodash/isEmpty";
import _toLower from "lodash/toLower";

// import Icon from "@mdi/react"
import { mdiFinance, mdiBellRingOutline, mdiFormatListText, mdiBankOutline, mdiAccount, mdiFileMultipleOutline, mdiLogoutVariant, mdiAccountGroup, mdiWalletOutline } from '@mdi/js';

const routes = [
    //////////////////////////////////////////////////
    // Public Routes
    //////////////////////////////////////////////////
    {
        name: "home",
        path: "/",
        caption: texts["home"],
        requireAuthentication: false,
    },

    {
        name: "switchLanguage",
        path: "/switch-language",
        requireAuthentication: false,
    },

    {
        name: "preSignup",
        path: "/pre-signup",
        caption: texts["pre-signup"],
        requireAuthentication: false,
    },

    {
        name: "signup",
        path: "/signup",
        caption: texts["signup"],
        requireAuthentication: false,
    },

    {
        name: "login",
        path: "/login",
        caption: texts["login"],
        requireAuthentication: false,
    },

    {
        name: "logout",
        path: "/logout",
        caption: texts["logout"],
        icon: {
            path: mdiLogoutVariant,
            size: "1.3em",
            cssClasses: "",
        },
        requireAuthentication: true,
    },

    {
        name: "passwordForgot",
        path: "/password/forgot",
        caption: texts["forgot-password"],
        requireAuthentication: false,
    },

    {
        name: "passwordReset",
        path: "/password/reset",
        caption: texts["reset-password"],
        requireAuthentication: false,
    },

    {
        name: "loans",
        path: "/loans",
        caption: texts["loans"],
        requireAuthentication: false,
    },

    {
        name: "loanDetails",
        path: "/loan",
        caption: texts["loan-details"],
        requireAuthentication: false,
    },

    {
        name: "borrowOverview",
        path: "/borrow/overview",
        caption: texts["borrow-overview"],
        requireAuthentication: false,
    },

    {
        name: "borrowTermsAndConditions",
        path: "/borrow/terms-and-conditions",
        caption: texts["borrow-terms-and-conditions"],
        requireAuthentication: false,
    },

    {
        name: "borrowPrivacyPolicy",
        path: "/borrow/privacy-policy",
        caption: texts["borrow-privacy-policy"],
        requireAuthentication: false,
    },

    {
        name: "borrowFaqs",
        path: "/borrow/faqs",
        caption: texts["borrow-faqs"],
        requireAuthentication: false,
    },

    {
        name: "investOverview",
        path: "/invest/overview",
        caption: texts["invest-overview"],
        requireAuthentication: false,
    },

    {
        name: "investTermsAndConditions",
        path: "/invest/terms-and-conditions",
        caption: texts["invest-terms-and-conditions"],
        requireAuthentication: false,
    },

    {
        name: "investPrivacyPolicy",
        path: "/invest/privacy-policy",
        caption: texts["invest-privacy-policy"],
        requireAuthentication: false,
    },

    {
        name: "investFaqs",
        path: "/invest/faqs",
        caption: texts["invest-faqs"],
        requireAuthentication: false,
    },

    {
        name: "contactUs",
        path: "/contact-us",
        caption: texts["contact-us"],
        requireAuthentication: false,
    },

    {
        name: "notFound",
        path: "/not-found",
        caption: texts["page-not-found"],
        requireAuthentication: false,
    },

    //////////////////////////////////////////////////
    // Routes of Borrower Dashboard
    //////////////////////////////////////////////////
    {
        name: "borrowerOverview",
        path: "/borrower/overview",
        caption: texts["borrower-overview"],
        icon: {
            path: mdiFinance,
            size: "1.3em",
            cssClasses: "",
        },
        requireAuthentication: true,
    },

    {
        name: "borrowerNotifications",
        path: "/borrower/notifications",
        caption: texts["borrower-notifications"],
        icon: {
            path: mdiBellRingOutline,
            size: "1.3em",
            cssClasses: "",
        },
        requireAuthentication: true,
    },

    {
        name: "borrowerLoanList",
        path: "/borrower/loan/list",
        caption: texts["borrower-loan-list"],
        icon: {
            path: mdiFormatListText,
            size: "1.3em",
            cssClasses: "",
        },
        requireAuthentication: true,
    },

    {
        name: "borrowerLoanCreate",
        path: "/borrower/loan/create",
        caption: texts["borrower-loan-create"],
        requireAuthentication: true,
    },

    {
        name: "borrowerLoanUpdate",
        path: "/borrower/loan/update",
        caption: texts["borrower-loan-update"],
        requireAuthentication: true,
    },

    {
        name: "borrowerCompanyList",
        path: "/borrower/company/list",
        caption: texts["borrower-company-list"],
        icon: {
            path: mdiBankOutline,
            size: "1.3em",
            cssClasses: "",
        },
        requireAuthentication: true,
    },

    {
        name: "borrowerCompanyCreate",
        path: "/borrower/company/create",
        caption: texts["borrower-company-create"],
        requireAuthentication: true,
    },

    {
        name: "borrowerCompanyUpdate",
        path: "/borrower/company/update",
        caption: texts["borrower-company-update"],
        requireAuthentication: true,
    },

    {
        name: "borrowerBasicProfile",
        path: "/borrower/profile/basic",
        caption: texts["borrower-profile-basic"],
        icon: {
            path: mdiAccount,
            size: "1.4em",
            cssClasses: "",
        },
        requireAuthentication: true,
    },

    {
        name: "borrowerAdvancedProfile",
        path: "/borrower/profile/advanced",
        caption: texts["borrower-profile-advanced"],
        requireAuthentication: true,
    },

    {
        name: "borrowerDocumentList",
        path: "/borrower/document/list",
        caption: texts["borrower-document-list"],
        icon: {
            path: mdiFileMultipleOutline,
            size: "1.3em",
            cssClasses: "",
        },
        requireAuthentication: true,
    },

    {
        name: "borrowerDocumentShow",
        path: "/borrower/document/show",
        caption: texts["borrower-document-show"],
        requireAuthentication: true,
    },

    {
        name: "adminDocumentShow",
        path: "/admin/document/show",
        caption: texts["admin-document-show"],
        requireAuthentication: true,
    },

    {
        name: "borrowerChangePassword",
        path: "/borrower/change-password",
        caption: texts["borrower-change-password"],
        requireAuthentication: true,
    },

    //////////////////////////////////////////////////
    // Routes of Lender Dashboard
    //////////////////////////////////////////////////
    {
        name: "lenderOverview",
        path: "/lender/overview",
        caption: texts["lender-overview"],
        icon: {
            path: mdiFinance,
            size: "1.3em",
            cssClasses: "",
        },
        requireAuthentication: true,
    },

    {
        name: "lenderNotifications",
        path: "/lender/notifications",
        caption: texts["lender-notifications"],
        icon: {
            path: mdiBellRingOutline,
            size: "1.3em",
            cssClasses: "",
        },
        requireAuthentication: true,
    },

    {
        name: "lenderWallet",
        path: "/lender/wallet",
        caption: texts["lender-wallet"],
        icon: {
            path: mdiWalletOutline,
            size: "1.3em",
            cssClasses: "",
        },
        requireAuthentication: true,
    },

    {
        name: "lenderLoanList",
        path: "/lender/loan/list",
        caption: texts["lender-loan-list"],
        icon: {
            path: mdiFormatListText,
            size: "1.3em",
            cssClasses: "",
        },
        requireAuthentication: true,
    },

    {
        name: "lenderLoanDetails",
        path: "/lender/loan",
        caption: texts["lender-loan-details"],
        requireAuthentication: true,
    },

    {
        name: "lenderBasicProfile",
        path: "/lender/profile/basic",
        caption: texts["lender-profile-basic"],
        icon: {
            path: mdiAccount,
            size: "1.4em",
            cssClasses: "",
        },
        requireAuthentication: true,
    },

    {
        name: "lenderAdvancedProfile",
        path: "/lender/profile/advanced",
        caption: texts["lender-profile-advanced"],
        requireAuthentication: true,
    },

    {
        name: "lenderDocumentList",
        path: "/lender/document/list",
        caption: texts["lender-document-list"],
        requireAuthentication: true,
    },

    {
        name: "lenderChangePassword",
        path: "/lender/change-password",
        caption: texts["lender-change-password"],
        requireAuthentication: true,
    },

    //////////////////////////////////////////////////
    // Routes of Admin Dashboard
    //////////////////////////////////////////////////
    {
        name: "adminOverviewGeneral",
        path: "/admin/overview/general",
        caption: texts["admin-overview-general"],
        icon: {
            path: mdiFinance,
            size: "1.3em",
            cssClasses: "",
        },
        requireAuthentication: true,
    },

    {
        name: "adminOverviewUsers",
        path: "/admin/overview/users",
        caption: texts["admin-overview-users"],
        requireAuthentication: true,
    },

    {
        name: "adminOverviewLoans",
        path: "/admin/overview/loans",
        caption: texts["admin-overview-loans"],
        requireAuthentication: true,
    },

    {
        name: "adminOverviewBorrowers",
        path: "/admin/overview/borrowers",
        caption: texts["admin-overview-borrowers"],
        requireAuthentication: true,
    },

    {
        name: "adminOverviewLenders",
        path: "/admin/overview/lenders",
        caption: texts["admin-overview-lenders"],
        requireAuthentication: true,
    },

    {
        name: "adminOverviewCrowdfundingCampaigns",
        path: "/admin/overview/crowdfunding-campaigns",
        caption: texts["admin-overview-crowdfunding-campaigns"],
        requireAuthentication: true,
    },

    {
        name: "adminNotifications",
        path: "/admin/notifications",
        caption: texts["admin-notifications"],
        icon: {
            path: mdiBellRingOutline,
            size: "1.3em",
            cssClasses: "",
        },
        requireAuthentication: true,
    },

    {
        name: "adminLoanList",
        path: "/admin/loan/list",
        caption: texts["admin-loan-list"],
        icon: {
            path: mdiFormatListText,
            size: "1.3em",
            cssClasses: "",
        },
        requireAuthentication: true,
    },

    {
        name: "adminUserList",
        path: "/admin/user/list",
        caption: texts["admin-user-list"],
        icon: {
            path: mdiAccountGroup,
            size: "1.3em",
            cssClasses: "",
        },
        requireAuthentication: true,
    },

    {
        name: "adminLoanBasic",
        path: "/admin/loan/basic",
        caption: texts["admin-loan-basic"],
        hint: texts["admin-loan-basic-hint"],
        requireAuthentication: true,
    },

    {
        name: "adminLoanAdvanced",
        path: "/admin/loan/advanced",
        caption: texts["admin-loan-advanced"],
        hint: texts["admin-loan-advanced-hint"],
        requireAuthentication: true,
    },

    {
        name: "adminLoanCoverImage",
        path: "/admin/loan/cover-image",
        caption: texts["admin-loan-cover-image"],
        hint: texts["admin-loan-cover-image-hint"],
        requireAuthentication: true,
    },

    {
        name: "adminLoanInvestigationGallery",
        path: "/admin/loan/investigation-gallery",
        caption: texts["admin-loan-investigation-gallery"],
        hint: texts["admin-loan-investigation-gallery-hint"],
        requireAuthentication: true,
    },

    {
        name: "adminLoanIndicators",
        path: "/admin/loan/indicators",
        caption: texts["admin-loan-indicators"],
        hint: texts["admin-loan-indicators-hint"],
        requireAuthentication: true,
    },

    {
        name: "adminLoanDocuments",
        path: "/admin/loan/documents",
        caption: texts["admin-loan-documents"],
        hint: texts["admin-loan-documents-hint"],
        requireAuthentication: true,
    },

    {
        name: "adminLoanRiskProfiling",
        path: "/admin/loan/risk-profiling",
        caption: texts["admin-loan-risk-profiling"],
        hint: texts["admin-loan-risk-profiling-hint"],
        requireAuthentication: true,
    },

    {
        name: "adminBasicProfile",
        path: "/admin/profile/basic",
        caption: texts["admin-profile-basic"],
        icon: {
            path: mdiAccount,
            size: "1.4em",
            cssClasses: "",
        },
        requireAuthentication: true,
    },

    {
        name: "adminAdvancedProfile",
        path: "/admin/profile/advanced",
        caption: texts["admin-profile-advanced"],
        requireAuthentication: true,
    },

    {
        name: "adminChangePassword",
        path: "/admin/change-password",
        caption: texts["admin-change-password"],
        requireAuthentication: true,
    },
];

// -----------------------------------------------

const dashboardMenuRoutes = {
    // Routes that should be appear in the borrower's nav menu
    borrower: [
        // "borrowerOverview",
        // "borrowerNotifications",
        "borrowerLoanList",
        "borrowerCompanyList",
        "borrowerBasicProfile",
        "borrowerDocumentList",
        "logout",
    ],

    // Routes that should be appear in the lender's nav menu
    lender: [
        // "lenderOverview",
        // "lenderNotifications",
        "lenderWallet",
        "lenderLoanList",
        "lenderBasicProfile",
        // "lenderDocumentList",
        "logout",
    ],

    // Routes that should be appear in the admin's nav menu
    admin: [
        "adminOverviewGeneral",
        // "adminNotifications",
        "adminLoanList",
        "adminUserList",
        "adminBasicProfile",
        "logout",
    ],
};

// -----------------------------------------------

export const getRoute = (routeName) => {
    const route = _find(routes, element => element.name === routeName);
    return route || {};
}

// -----------------------------------------------

export const getRoutePath = (routeName) => {
    const route = getRoute(routeName);
    return ! _isEmpty(route) ? route.path : "";
};

// -----------------------------------------------

export const storePreviousRoutePathInLocalStorage = () => {
    let previousRoutePath = window.location.pathname + window.location.search + window.location.hash;
    localStorage.setItem("previousRoutePath", previousRoutePath)
}

// -----------------------------------------------

export const redirectToTheProperRoutePath = (currentUser) => {
    let previousRoutePath = localStorage.getItem("previousRoutePath") || null;

    if (_isEmpty(previousRoutePath)) {
        window.location = getDashboardLandingPage(currentUser);
        return; // "return" here is very important otherwise an error will occur
    }

    localStorage.removeItem("previousRoutePath");
    window.location = previousRoutePath;
}

// -----------------------------------------------

export const getDashboardLandingPage = (currentUser) => {
    switch (_toLower(currentUser.user_type)) {
        case "borrower":
            // TODO: Enable this path when it's done
            // return getRoutePath("borrowerOverview");
            return getRoutePath("borrowerLoanList");

        case "lender":
            // TODO: Enable this path when it's done
            // return getRoutePath("lenderOverview");
            // return getRoutePath("lenderLoanList");
            return getRoutePath("lenderWallet");

        case "admin":
            // return getRoutePath("adminOverviewGeneral");
            return getRoutePath("adminUserList");

        default:
            return getRoutePath("home");
    }
}

// -----------------------------------------------

export const getDashboardMenuRoutes = (userType) => {
    let routeNames = [];

    switch (userType) {
        case "borrower":
            routeNames = dashboardMenuRoutes.borrower;
            break;

        case "lender":
            routeNames = dashboardMenuRoutes.lender;
            break;

        case "admin":
            routeNames = dashboardMenuRoutes.admin;
            break;

        default:
            routeNames = [];
    }

    return routeNames.map(routeName => {
        return getRoute(routeName);
    });
}
