import { preferredLanguage } from "./../configs/ui-language-config";

const texts = {
    ar: {
        "month" : "شهر",
        "months": "شهر",
    },

    en: {
        "month" : "month",
        "months": "months",
    }
}

export const LoanConfigTexts = texts[preferredLanguage.alpha2code];
