// Third-Party Modules
import _isEmpty from "lodash/isEmpty";

const publicCompanyTypes = [
    {
        label: "منشأة فردية",
        value: "منشأة فردية" // TODO: values should be in english as it will be stored in the db
    },
    {
        label: "شركة توصية بسيطة",
        value: "شركة توصية بسيطة" // TODO: values should be in english as it will be stored in the db
    },
    {
        label: "شركة تضامن",
        value: "شركة تضامن" // TODO: values should be in english as it will be stored in the db
    },
    {
        label: "شركة ذات مسئولية محدودة",
        value: "شركة ذات مسئولية محدودة" // TODO: values should be in english as it will be stored in the db
    },
    {
        label: "شركة مساهمة",
        value: "شركة مساهمة" // TODO: values should be in english as it will be stored in the db
    },
];

// -----------------------------------------------

const publicActivitySectors = [
    {
        label: "Industrial",
        value: "industrial",
        subsectors: [
            // {
            //     "label": "Food",
            //     "value": "food",
            // },
            // {
            //     "label": "Clothes",
            //     "value": "clothes",
            // },
            // {
            //     "label": "Leather",
            //     "value": "leather",
            // },
        ],
    },
    {
        label: "Commercial",
        value: "commercial",
        subsectors: [
            // {
            //     "label": "Retails",
            //     "value": "retails",
            // },
            // {
            //     "label": "Food And Beverage",
            //     "value": "food And beverage",
            // },
        ],
    },
    {
        label: "Service",
        value: "service",
        subsectors: [
            // {
            //     "label": "Hotels",
            //     "value": "hotels",
            // },
            // {
            //     "label": "Tourism Agency",
            //     "value": "tourism agency",
            // },
        ],
    },
    {
        label: "Other",
        value: "other",
        subsectors: [
            // {
            //     "label": "Hotels",
            //     "value": "hotels",
            // },
            // {
            //     "label": "Tourism Agency",
            //     "value": "tourism agency",
            // },
        ],
    },
];

// -----------------------------------------------

export function getCompanyTypes(option = null) {
    if (option === null) {
        return publicCompanyTypes;
    }
}

// -----------------------------------------------

export function getActivitySectors(option = null) {
    if (option === null) {
        return publicActivitySectors;
    }
}

// -----------------------------------------------

export function getActivitySubsectors(sector = null) {
    if (_isEmpty(sector)) return [];

    const theSector = publicActivitySectors.find(sec => {
        return sec.label === sector
            || sec.value === sector;
    });

    if (_isEmpty(theSector)) return [];

    return theSector.subsectors;
}

