import { preferredLanguage } from "./../configs/ui-language-config";
// import { GeneralTexts } from "./GeneralTexts";
import { RouteConfigTexts } from "./RouteConfigTexts";

const texts = {
    ar: {
        "admin-loan-cover-image": RouteConfigTexts["admin-loan-cover-image"],
        "admin-loan-list"       : RouteConfigTexts["admin-loan-list"],
        "choose-another-cover-image": "اختر صورة أخرى",
        "current-cover-image": "الصورة الحالية",
    },

    en: {
        "admin-loan-cover-image": RouteConfigTexts["admin-loan-cover-image"],
        "admin-loan-list"       : RouteConfigTexts["admin-loan-list"],
        "choose-another-cover-image": "Choose another image",
        "current-cover-image": "Current image",
    }
}

export const AdminLoanCoverImageTexts = texts[preferredLanguage.alpha2code];
