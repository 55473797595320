import React from "react";
import PaginationSeparator from "./PaginationSeparator";

const Pagination = (props) => {;
	// If the number of pages is only 1, DON'T return the pagination
	if (props.paginatorItems.length === 1) {
		return null;
	}

	const firstPaginatorItem = props.paginatorItems[0];
	const lastPaginationItem = props.paginatorItems[props.paginatorItems.length - 1];

	let paginatorItemsWithoutFirstAndLastItems = [...props.paginatorItems];
	paginatorItemsWithoutFirstAndLastItems.shift();
	paginatorItemsWithoutFirstAndLastItems.pop();

	return (
		<nav>
			<ul className={(props.className || "pagination justify-content-center x-p-0 ") + (" " + (props.extraCssClasses || ""))}>
				{firstPaginatorItem}
				<PaginationSeparator isHidden={paginatorItemsWithoutFirstAndLastItems.length === 0 ? true : false} />

				{paginatorItemsWithoutFirstAndLastItems}

				<PaginationSeparator isHidden={paginatorItemsWithoutFirstAndLastItems.length === 0 ? true : false} />
				{lastPaginationItem}
			</ul>
		</nav>
	);
};

export default Pagination;
