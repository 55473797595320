// import React from "react";
// import { Link } from "react-router-dom";
// import { GeneralTexts } from "./GeneralTexts";
// import { getRoutePath } from "./../configs/route-config";
import { preferredLanguage } from "./../configs/ui-language-config";
import { RouteConfigTexts } from "./RouteConfigTexts";


const texts = {
    ar: {
        "lender-wallet"                             : RouteConfigTexts["lender-wallet"],
        "deposit"                                   : "إيداع",
        "withdraw"                                  : "سحب",
        "start-deposit"                             : "ابدأ الإيداع",
        "start-withdraw"                            : "ابدأ السحب",
        "show-operations-log"                       : "عرض سجل العمليات",
        "current-wallet-balance"                    : "رصيد المحفظة الحالي",
        "add-money-to-wallet"                       : "إيداع نقود في المحفظة",
        "operations-log"                            : "سجل العمليات",
        "wallet-operations-log"                     : "سجل عمليات السحب والإيداع السابقة",
        "withdraw-money-from-wallet-to-bank-account": "سحب نقود من المحفظة إلى حسابي البنكي",
        "insufficient-wallet-balance"               : "رصيد محفظتك لا يكفي٫ فهو أقل من المبلغ الـذي أدخلته.",
        "amount-to-withdraw"                        : "المبلغ المراد سحبه",
        "steps"                                     : "الخطوات",
        "transfer-money-to-our-bank-account"        : "حول٫ إلى حسابنا البنكي الموضح أدناه، المبلغ الذي تريد إضافته إلى محفظتك، مع تحملك لرسوم التحويل إن وجدت.",
        "send-the-transfer-data-to-email"           : "ارسل لنا بيانات التحويل على الإيميل",
        "send-the-email-from-your-registered-email" : "وارفق صورة من إيصال التحويل إن وجد. يجب أن ترسل لنا الإيميل من إيميلك المسجل لدينا، هذا",
        "when-transfer-completed-increase-wallet"   : "بمجرد أن يظهر المبلغ المحول في حسابنا البنكي، فسنقوم على الفور بإضافته إلى رصيد محفظتك، وسيصل إليك إيميل يعلمك بذلك.",
        "our-bank-account"                          : "بيانات حسابنا البنكي",
        "company-name"                              : "اسم الشركة Company Name",
        "bank-name"                                 : "اسم البنك Bank Name",
        "bank-account-number"                       : "رقم الحساب Account Number",
        "branch-name"                               : "اسم الفرع Branch Name",
        "swift-code"                                : "سويفت كود Swift Code",
        "iban"                                      : "آيبان IBAN",
        "no-previous-deposits-or-withdrawals"       : "لا يوجد عمليات سحب أو إيداع سابقة",
        "enter-amount-to-withdraw"                  : "أدخل قيمة المبلغ الذي تريد سحبه.",
        "will-transfer-amount-to-your-bank-account" : "سنقوم بتحويل المبلغ إلى حسابك البنكي المسجل لدينا، وسنتحمل نحن رسوم التحويل، وسيخصم المبلغ من رصيد محفظتك.",
        "you-will-be-informed-with-email"           : "سيصل إليك إيميل بتفاصيل عملية التحويل التي قمنا بها.",
    },

    en: {
        "lender-wallet"                             : RouteConfigTexts["lender-wallet"],
        "deposit"                                   : "Deposit",
        "withdraw"                                  : "Withdraw",
        "start-deposit"                             : "Start Deposit",
        "start-withdraw"                            : "Start Withdrawal",
        "show-operations-log"                       : "Show Operations Log",
        "current-wallet-balance"                    : "Current Wallet Balance",
        "add-money-to-wallet"                       : "Add Money to My Wallet",
        "operations-log"                            : "Operations Log",
        "wallet-operations-log"                     : "Log of Previous Deposits & Withdrawals",
        "withdraw-money-from-wallet-to-bank-account": "Withdraw Money From My Wallet to My Bank Account",
        "insufficient-wallet-balance"               : "Your wallet balance is insufficient. It is less than the amount you entered.",
        "amount-to-withdraw"                        : "Amount to Withdraw",

        "steps"                                     : "Steps",
        "transfer-money-to-our-bank-account"        : "Transfer, to our bank account, the amount you want to add to your wallet. You should bear the transfer fee if exists.",
        "send-the-transfer-data-to-email"           : "Send the information of the transfer to this email",
        "send-the-email-from-your-registered-email" : "and attach the transfer receipt if exists. You must send us the email from the email address that you're using on the website, which is",
        "when-transfer-completed-increase-wallet"   : "Once the transferred amount appears in our bank account, we will add it to your wallet balance and you will receive an email to be informed.",
        "our-bank-account"                          : "Our Bank Account Info",
        "company-name"                              : "Company Name",
        "bank-name"                                 : "Bank Name",
        "bank-account-number"                       : "Account Number",
        "branch-name"                               : "Branch Name",
        "swift-code"                                : "Swift Code",
        "iban"                                      : "IBAN",
        "no-previous-deposits-or-withdrawals"       : "No previous deposits or withdrawals",
        "enter-amount-to-withdraw"                  : "Enter the amount you want to withdraw.",
        "will-transfer-amount-to-your-bank-account" : "We will transfer the amount to your bank account. We will bear the transfer fees. The transferred amount will be deducted from your wallet balance.",
        "you-will-be-informed-with-email"           : "You will receive an email containing the details of the transfer we did.",

    }
}

export const LenderWalletTexts = texts[preferredLanguage.alpha2code];
