import React from "react";
// import ReactApexChart from "react-apexcharts"

import { switchClasses } from "./../../../languages/CssClasses";
import { AdminOverviewGeneralTexts as texts } from "./../../../languages/AdminOverviewGeneralTexts";
import { GeneralTexts } from "./../../../languages/GeneralTexts";

// Our Modules
import StatsCard from "./../../shared/StatsCard";
import AdminOverviewSubMenu from "./AdminOverviewSubMenu";
import PageLoader from "./../../shared/PageLoader";
import PageContent from "./../../shared/PageContent";
import PageHandler from "./../../shared/PageHandler";

// Our Services
import documentTitleService from "./../../../services/document-title-service";

export default class AdminOverviewGeneral extends PageHandler {

    initialState = {
        ...this.initialStateElements,
        allowedUserType: "admin",

        showPageLoader: true,

        chart_1: {
            series: [
                {
                    name: "Loans",
                    data: [15, 30, 40, 45, 7]
                }
            ],
            options: {
                chart: {
                    type: "bar",
                    width: "100%",
                    height: "auto"
                },
                xaxis: {
                    categories: [2017, 2018, 2019, 2020, 2021]
                },
                colors: ['#2179fe', '#E91E63', '#546E7A', '#80b918', '#FF9800'],
            },
        },

        chart_2: {
            series: [45, 300 - 45],
            options: {
                chart: {
                    type: "donut",
                    width: "100%",
                    height: "auto"
                },
                labels: ["Pending Loans", "Other Loans"],
                colors: ['#E91E63', '#546E7A', '#80b918', '#2179fe', '#FF9800'],
            },
        },

        chart_3: {
            series: [45, 300 - 45],
            options: {
                chart: {
                    type: "pie",
                    width: "100%",
                    height: "auto"
                },
                labels: ["Pending Loans", "Other Loans"],
                colors: ['#E91E63', '#546E7A', '#80b918', '#2179fe', '#FF9800'],
            },
        },

        chart_4: {
            series: [45, 300 - 45],
            options: {
                chart: {
                    type: "donut",
                    width: "100%",
                    height: "auto"
                },
                plotOptions: {
                    pie: {
                        startAngle: -90,
                        endAngle: 90,
                        offsetY: 10
                    }
                },
                grid: {
                    padding: {
                        bottom: -80
                    }
                },
                labels: ["Pending Loans", "Other Loans"],
                colors: ['#E91E63', '#546E7A', '#80b918', '#2179fe', '#FF9800'],
            },
        },

        chart_5: {
            series: [(45/300)*100],
            options: {
                chart: {
                    type: 'radialBar',
                    width: "100%",
                    height: "auto"
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            size: '70%',
                        }
                    },
                },
                labels: ['Pending Loans'],
                colors: ['#E91E63', '#546E7A', '#80b918', '#2179fe', '#FF9800'],
            },
        },

        chart_6: {
            series: [{
                name: "Pending Loans",
                data: [10, 41, 5, 50, 10, 40, 10, 50, 5]
            }],
            options: {
                chart: {
                    type: 'line',
                    zoom: {
                        enabled: false
                    },
                    width: "100%",
                    height: "auto"
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth'
                },
                title: {
                    text: 'Pending Loans By Month',
                    align: 'left'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
                }
            },
        },

        chart_7: {
            series: [
                {
                    name: 'Loans',
                    data: [0, 2, 3.5, 0, 13, 1, 4, 1]
                },
                {
                    name: 'Pending Loans',
                    data: [0, 4, 0, 4, 0, 4, 0, 4]
                }
            ],
            options: {
                chart: {
                    type: 'area',
                    width: "100%",
                    height: "auto",
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth',
                    width: 1
                },
                // colors: ['#E91E63', '#546E7A', '#80b918', '#2179fe', '#FF9800'],
                // colors: ["#009efb", "#39c449"],
                grid: { borderColor: "rgba(0,0,0,0.2)", strokeDashArray: 2 },
                legend: { show: true },
                xaxis: {
                    type: 'numeric',
                    categories: [0, 4, 8, 12, 16, 20, 24, 30]
                },
                tooltip: {
                    theme: "dark",
                },
            },
        },

        chart_8: {
            series: [(45/300)*100],
            options: {
                chart: {
                    type: 'radialBar',
                    offsetY: -20,
                    sparkline: {
                        enabled: true
                    },
                    height: "auto",
                    width: "100%"
                },
                plotOptions: {
                    radialBar: {
                        startAngle: -90,
                        endAngle: 90,
                        track: {
                            background: "#e7e7e7",
                            strokeWidth: '97%',
                            margin: 5, // margin is in pixels
                        },
                        dataLabels: {
                            name: {
                                show: false
                            },
                            value: {
                                offsetY: -2,
                                fontSize: '22px'
                            }
                        }
                    }
                },
                grid: {
                    padding: {
                        top: -10
                    }
                },
                labels: ['Average Results'],
                colors: ['#E91E63', '#546E7A', '#80b918', '#2179fe', '#FF9800'],
            },
        },

        usersOverviewChart: {
            options: {
                chart: {
                    type: 'radialBar',
                    offsetY: -20,
                    sparkline: {
                        enabled: true
                    }
                },
                plotOptions: {
                    radialBar: {
                        startAngle: -90,
                        endAngle: 90,
                        track: {
                            background: "#e7e7e7",
                            strokeWidth: '97%',
                            margin: 5, // margin is in pixels
                        },
                        dataLabels: {
                            name: {
                                show: false
                            },
                            value: {
                                offsetY: -2,
                                fontSize: '22px'
                            }
                        }
                    }
                },
                grid: {
                    padding: {
                        top: -10
                    }
                },
                // labels: ['Borrowers'],
                colors: ['#E91E63', '#546E7A', '#80b918', '#2179fe', '#FF9800'],
            },
        }
    };

    // -----------------------------------------------

    // Clone a separate copy of the "initialState" and assign it to the "state".
    // Note that the spread operator "..." is important to get a separate copy.
    state = { ...this.initialState };

    // -----------------------------------------------
    componentDidMount() {
        // More info: t.ly/ZVsd
        window.scrollTo(0, 0);

        // ----------------------

        // Properly set the browser's document title
        documentTitleService.setTitle(GeneralTexts["admin-dashboard"] + " | " + texts["admin-overview-general"]);

        // ----------------------

        this.setAuthenticatedUser();
        this.getStats();
    }

    // -----------------------------------------------

    // Get stats
    getStats = async () => {
        this.setState({ showPageLoader: true });

        // TODO: Fetch and handle data here..

        this.setState({ showPageLoader: false });
    }

    // -----------------------------------------------

    render() {
        const pageContent = (
            <React.Fragment>
                <div>
                    <div className={"x-m-25 x-mb-15 " + switchClasses(["x-mr-15"])}>
                        <h5 className={"x-pt-10 head-color " + switchClasses(["__ff-droidarabickufi"])}>
                            {texts["admin-overview-general"]}
                        </h5>
                    </div>

                    <div className="x-mt-15">
                        <div className="container-fluid x-ov-h">
                            <div className="row">
                                <div className="col-md-3 col-lg-2">
                                    <AdminOverviewSubMenu />
                                </div>

                                <div className={"col-md-9 col-lg-10 x-pb-100 " + switchClasses(["__ff-droidarabickufi"])}>
                                    <PageLoader show={this.state.showPageLoader} />

                                    <div className={(this.state.showPageLoader ? "x-d-n" : "")}>
                                        <h5 className="x-pt-10 head-color clearfix">
                                            {texts["general"]}

                                            <div className={"x-d-ib " + switchClasses(["x-fl-l"])}>
                                                <select className="custom_select">
                                                    <option value="all-time">{texts["all-time"]}</option>
                                                    <option value="today">{texts["today"]}</option>
                                                    <option value="yesterday">{texts["yesterday"]}</option>
                                                    <option value="last-7-days">{texts["last-7-days"]}</option>
                                                    <option value="last-30-days">{texts["last-30-days"]}</option>
                                                </select>
                                            </div>
                                        </h5>

                                        <div className="row">
                                            <div className="col-sm-6 col-md-4 x-pt-15 x-pb-15">
                                                <StatsCard
                                                    title={texts["loans"]}
                                                    hint={texts["loans-of-all-statuses"]}
                                                    value="1000"
                                                    valuePercentage={(1000 / 1000) * 100}
                                                    cssColorClass="x-c-blueviolet"
                                                    cssBackgroundColorClass="x-bgc-blueviolet"
                                                />
                                            </div>

                                            <div className="col-sm-6 col-md-4 x-pt-15 x-pb-15">
                                                <StatsCard
                                                    title={texts["users"]}
                                                    hint={texts["users-of-all-types"]}
                                                    value="5000"
                                                    valuePercentage={(5000 / 5000) * 100}
                                                    cssColorClass="x-c-lightseagreen"
                                                    cssBackgroundColorClass="x-bgc-lightseagreen"
                                                />
                                            </div>

                                            {/* <div className="col-sm-6 col-md-4 x-pt-15 x-pb-15">
                                                <StatsCard
                                                    title={texts["revenue"]}
                                                    hint={texts["amwal-revenue"]}
                                                    value="75000"
                                                    valuePercentage={(75000 / 75000) * 100}
                                                    cssColorClass="text-success"
                                                    cssBackgroundColorClass="bg-success"
                                                />
                                            </div> */}
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-6 col-md-4 x-pt-15 x-pb-15">
                                                <StatsCard
                                                    title={texts["borrowers"]}
                                                    hint={texts["users-of-type-borrower"]}
                                                    value="4500"
                                                    valuePercentage={(4500 / 5000) * 100}
                                                    cssColorClass="x-c-chocolate"
                                                    cssBackgroundColorClass="x-bgc-chocolate"
                                                />
                                            </div>

                                            <div className="col-sm-6 col-md-4 x-pt-15 x-pb-15">
                                                <StatsCard
                                                    title={texts["investors"]}
                                                    hint={texts["users-of-type-investor"]}
                                                    value="490"
                                                    valuePercentage={(490 / 5000) * 100}
                                                    cssColorClass="text-primary"
                                                    cssBackgroundColorClass="bg-primary"
                                                />
                                            </div>

                                            <div className="col-sm-6 col-md-4 x-pt-15 x-pb-15">
                                                <StatsCard
                                                    title={texts["admins"]}
                                                    hint={texts["users-of-type-admin"]}
                                                    value="10"
                                                    valuePercentage={(10 / 5000) * 100}
                                                    cssColorClass="x-c-darkorange"
                                                    cssBackgroundColorClass="x-bgc-darkorange"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <hr className="x-m-25 x-mt-50 x-mb-45" />

                <div className="_border _border-light shadow-sm x-m-25 x-mt-0 x-mb-70 x-p-10 x-pb-100 x-mih-500 x-bgc-fff x-bdc-e3e3e3 x-bdrs-4">
                    <div className="container-fluid x-ov-h">
                        <div className="row x-pt-20">
                            <div className="col-md-4">
                                <ReactApexChart
                                    options={this.state.chart_1.options}
                                    series={this.state.chart_1.series}
                                    type={this.state.chart_1.options.chart.type}
                                    width={this.state.chart_1.options.chart.width}
                                    height={this.state.chart_1.options.chart.height}
                                />
                            </div>
                            <div className="col-md-4">
                                <ReactApexChart
                                    options={this.state.chart_3.options}
                                    series={this.state.chart_3.series}
                                    type={this.state.chart_3.options.chart.type}
                                    width={this.state.chart_3.options.chart.width}
                                    height={this.state.chart_3.options.chart.height}
                                />
                            </div>
                            <div className="col-md-4">
                                <ReactApexChart
                                    options={this.state.chart_4.options}
                                    series={this.state.chart_4.series}
                                    type={this.state.chart_4.options.chart.type}
                                    width={this.state.chart_4.options.chart.width}
                                    height={this.state.chart_4.options.chart.height}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <ReactApexChart
                                    options={this.state.chart_5.options}
                                    series={this.state.chart_5.series}
                                    type={this.state.chart_5.options.chart.type}
                                    width={this.state.chart_5.options.chart.width}
                                    height={this.state.chart_5.options.chart.height}
                                />
                            </div>
                            <div className="col-md-9">
                                <ReactApexChart
                                    options={this.state.chart_6.options}
                                    series={this.state.chart_6.series}
                                    type={this.state.chart_6.options.chart.type}
                                    width={this.state.chart_6.options.chart.width}
                                    height={this.state.chart_6.options.chart.height}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-9">
                                <ReactApexChart
                                    options={this.state.chart_7.options}
                                    series={this.state.chart_7.series}
                                    type={this.state.chart_7.options.chart.type}
                                    width={this.state.chart_7.options.chart.width}
                                    height={this.state.chart_7.options.chart.height}
                                />
                            </div>
                            <div className="col-md-3">
                                <ReactApexChart
                                    options={this.state.chart_8.options}
                                    series={this.state.chart_8.series}
                                    type={this.state.chart_8.options.chart.type}
                                    width={this.state.chart_8.options.chart.width}
                                    height={this.state.chart_8.options.chart.height}
                                />
                            </div>
                        </div>
                    </div>
                </div> */}
            </React.Fragment>
        );

        return (
            <PageContent
                pageContent={pageContent}
                authenticatedUser={this.getAuthenticatedUser()}
                showPageContent={this.showPageContent()}
                windowLocation={window.location}
            />
        );
    }
}
