import axios from "axios";
import * as accessTokenService from "./access-token-service";

// import {toast} from "react-toastify";
// import logService from "./log-service";

/*
axios.interceptors.response.use(null, error => {
    const expectedError =
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500;

    if (!expectedError) {
        logService.log(error);
        toast.error("An unexpected error occurred.");
    }

    return Promise.reject(error);
});
*/


const accessToken = accessTokenService.getAccessToken();

/**
 * Set Authorization token to every axios call.
 * This way you don't need to set the "config" param to every API call.
 * More info: t.ly/E5CQ
 */
if (accessToken) {
    axios.defaults.headers.common = {Authorization: "Bearer " + accessToken};
}

// axios.defaults.headers.common = {...axios.defaults.headers.common, "Content-Type": "multipart/form-data"};

// ----------------------

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete
};
