import React from "react";
import { Link } from "react-router-dom";

import { preferredLanguage } from "./../configs/ui-language-config";
// import { GeneralTexts } from "./GeneralTexts";
// import { RouteConfigTexts } from "./RouteConfigTexts";

import { getRoutePath } from "./../configs/route-config";
import { getEmail, getWhatsapp } from "./../configs/general-config";

const texts = {
    ar: {
        "loan-details"                              : "تفاصيل الفرصة الاستثمارية",
        "required-amount"                           : "المبلغ المطلوب",
        "pledged"                                   : "المبلغ المُتعهد به",
        "remaining"                                 : "المبلغ المتبقى",
        "committed-investors"                       : "المستثمرين المشاركين",
        "transaction-info"                          : "العملية محل التمويل",
        "transaction-type"                          : "نوع العملية",
        "transaction-duration"                      : "مدة العملية",
        "transaction-gross-profit"                  : "نسبة مجمل الربح (%)",
        "borrower-has-experience-in-the-transaction": "المقترض لديه خبرة في العملية؟",
        "transaction-description"                   : "وصف العملية",
        "multimedia"                                : "صور ومقاطع فيديو",
        "ROI"                                       : "العائد على الاستثمار",
        "return-on-investment"                      : "العائد على الاستثمار",


        "company-info"                              : "معلومات عن الشركة",
        "headquarter-city"                          : "مدينة المقر الرئيسي",
        "company-type"                              : "نوع الشركة",
        "activity-sector"                           : "مجال النشاط",
        "founded-in"                                : "تأسست سنة",
        "operating-license-status"                  : "حالة رخصة التشغيل",
        "number-of-employees"                       : "عدد الموظفين",
        "capital-size"                              : "رأس المال",
        "average-monthly-sales"                     : "متوسط المبيعات الشهرية",
        "last-year-revenue"                         : "إيرادات السنة الماضية",
        "has-bank-account"                          : "لديها حساب بنكي؟",
        "has-financial-statements"                  : "لديها قوائم مالية؟",
        "has-tax-related-issues"                    : "لديها مشاكل متعلقة بالضرائب؟",
        "has-social-insurance-related-issues"       : "لديها مشاكل متعلقة بالتأمينات الاجتماعية؟",
        "has-existing-bank-loans"                   : "حاصلة حاليا على  قروض بنكية؟",
        "has-defaulted-loans"                       : "لديها قروض متعثرة؟",
        "loan-risk-profiling"                       : "تصنيف مخاطر القرض",
        "invest-in-this-loan"                       : "استثمر في هذه الفرصة",
        "amount-to-invest"                          : "أدخل مبلغ الاستثمار",
        "i-want-to-invest"                          : "أريد أن أستثمر",
        "invest-now"                                : "استثمر الآن",
        "month"                                     : "شهر",
        "employee"                                  : "موظف",
        "loan-full-details"                         : "التفاصيل الكاملة للقرض",
        "only-admins-can-see-this-section"          : "يظهر هذا الجزء للأدمن فقط",
        "want-to-invest"                            : "اضغط الزر التالي للاستثمار في هذه الفرصة الاستثمارية",
        "how-much-do-you-want-to-invest"            : "كم تريد أن تستثمر في هذه الفرصة؟",
        "your-current-wallet-balance"               : "رصيد محفظتك الحالي هو",
        "wallet-balance-after-investment"           : "رصيد محفظتك بعد الاستثمار سيكون",
        "amount-will-be-deducted-from-wallet-balance": "المبلغ الذي ستستثمره في هذه الفرصة٫ سيخصم من رصيد محفظتك.",
        "insufficient-wallet-balance"               : "رصيد محفظتك لا يكفي، فهو أقل من المبلغ الـذي أدخلته.",
        "notes"                                     : "ملاحظات",
        "min"                                       : "الحد الأدنى",
        "max"                                       : "الحد الأقصى",
        "login"                                     : "تسجيل الدخول",
        "signup"                                    : "إنشاء حساب",
        "execute"                                   : "تنفيذ",
        "hi"                                        : "مرحبا",
        "or"                                        : "أو",
        "top-up-your-wallet-to-invest"              : (
            <span>
                إذا كان رصيد محفظتك أقل من المبلغ الذي تريد استثماره،&nbsp;
                <Link to={getRoutePath("lenderWallet")}>فاضغط هنا لتقوم بإضافة رصيد لمحفظتك أولا</Link>.
            </span>
        ),
        "button-is-enabled-for-lenders-only"        : (
            <span>
                يظهر الزر مفعلا للمستثمرين فقط
                <br />
                لذلك تأكد من تسجيل دخولك كمستثمر أولا
            </span>
        ),
        "further-inquiry-hint": (
            <span>
                إذا كان لديك مزيد من الاستفسارات، لا تترد في التواصل معنا. يمكنك أن ترسل لنا إيميل على <strong>{getEmail("info")}</strong>&nbsp;
                أو رسالة واتساب على رقم <strong>{getWhatsapp("info")}</strong> وسوف نرد عليك في أسرع وقت.
            </span>
        ),
    },

    en: {
        "loan-details"                              : "Investment Opportunity Details",
        "required-amount"                           : "Required Amount",
        "pledged"                                   : "Pledged",
        "remaining"                                 : "Remaining",
        "committed-investors"                       : "Committed Investors",
        "transaction-info"                          : "Transaction Info",
        "transaction-type"                          : "Transaction Type",
        "transaction-duration"                      : "Transaction Duration",
        "transaction-gross-profit"                  : "Transaction Gross Profit (%)",
        "borrower-has-experience-in-the-transaction": "Borrower Has Experience in The Transaction?",
        "transaction-description"                   : "Transaction Description",
        "multimedia"                                : "Multimedia",
        "ROI"                                       : "ROI",
        "return-on-investment"                      : "Return On Investment (ROI)",



        "company-info"                              : "Company Info",
        "headquarter-city"                          : "Headquarter City",
        "company-type"                              : "Company Type",
        "activity-sector"                           : "Activity Sector",
        "founded-in"                                : "Founded in",
        "operating-license-status"                  : "Operating License Status",
        "number-of-employees"                       : "No. of Employees",
        "capital-size"                              : "Capital Size",
        "average-monthly-sales"                     : "Average Monthly Sales",
        "last-year-revenue"                         : "Last Year Revenue",
        "has-bank-account"                          : "Has Bank Account?",
        "has-financial-statements"                  : "Has Financial Statements?",
        "has-tax-related-issues"                    : "Has Tax-Related Issues?",
        "has-social-insurance-related-issues"       : "Has Social Insurance Related Issues?",
        "has-existing-bank-loans"                   : "Has Existing Bank Loans?",
        "has-defaulted-loans"                       : "Has Defaulted Loans?",
        "loan-risk-profiling"                       : "Loan Risk Profiling",
        "invest-in-this-loan"                       : "Invest in This Opportunity",
        "amount-to-invest"                          : "Enter The Amount to Invest",
        "i-want-to-invest"                          : "I want to invest..",
        "invest-now"                                : "Invest Now",
        "month"                                     : "months",
        "employee"                                  : "Employee",
        "loan-full-details"                         : "Loan Full Details",
        "only-admins-can-see-this-section"          : "Only admins can see this section",
        "want-to-invest"                            : "Click the next button to invest in this opportunity",
        "how-much-do-you-want-to-invest"            : "How much do you want to invest in this opportunity?",
        "your-current-wallet-balance"               : "Your current wallet balance is",
        "wallet-balance-after-investment"           : "Your wallet balance after investment will be",
        "amount-will-be-deducted-from-wallet-balance": "The amount you invest in this opportunity will be deducted from your wallet balance.",
        "insufficient-wallet-balance"               : "Your wallet balance is insufficient. It is less than the amount you entered.",
        "notes"                                     : "NOTES",
        "login"                                     : "Login",
        "signup"                                    : "Signup",
        "execute"                                   : "Submit",
        "min"                                       : "Min.",
        "max"                                       : "Max.",
        "or"                                        : "or",
        "hi"                                        : "Hi",
        "top-up-your-wallet-to-invest"              : (
            <span>
                If your wallet balance is insufficient,&nbsp;
                <Link to={getRoutePath("lenderWallet")}>click here to top up your wallet first</Link>.
            </span>
        ),
        "button-is-enabled-for-lenders-only": (
            <span>
                This button is being enabled for investors only
                <br />
                So make sure to login as investor first
            </span>
        ),
        "further-inquiry-hint": (
            <span>
                If you have any further inquiries, don't hesitate to contact us. Send us an email to <strong>{getEmail("info")}</strong>&nbsp;
                or a WhatsApp message to <strong>{getWhatsapp("info")}</strong> and we will get back to you as soon as possible.
            </span>
        ),
    }
}

export const LoanDetailsTexts = texts[preferredLanguage.alpha2code];
