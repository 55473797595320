// React Modules
import React from "react";

// Our Modules
import MessageModal, { toggleMessageModal, getErrorMessage } from "./shared/MessageModal";
import PageLoader from "./shared/PageLoader";
import FormHandler from "./shared/FormHandler";
import PageContent from "./shared/PageContent";

// Our Services
import logService from "./../services/log-service";
import documentTitleService from "./../services/document-title-service";
import * as authService from "./../services/auth-service";

// Our Configs
import { getRoutePath } from "./../configs/route-config";

export default class Logout extends FormHandler {

    initialState = {
        ...this.initialStateElements,
        isThisPagePublic: true,

        messageModal  : {},
        showPageLoader: true,
    };

    // -----------------------------------------------

    // Clone a separate copy of the "initialState" and assign it to the "state".
    // Note that the spread operator "..." is important to get a separate copy.
    state = { ...this.initialState };

    // -----------------------------------------------

    componentDidMount() {
        // More info: t.ly/ZVsd
        window.scrollTo(0, 0);

        // ----------------------

        // Properly set the browser's document title
        documentTitleService.setTitle("Logout");

        // ----------------------

        this.doLogout();
    }

    // -----------------------------------------------

    doLogout = async () => {
        try {
            const response = await authService.logout();
            const loggedOut = response.data?.meta?.success;

            this.setState({showPageLoader: false});

            // Redirect to the homepage with full reload
            window.location = getRoutePath("home");
        } catch (error) {
            /**
             * If there is an unexpected error like: network down, server down, db down, bug, ..
             *  - Log the error.
             *  - Display a generic and friendly error message.
             *
             * Note that "error.response" determines if the error is an expected error or not.
             */

            const isExpectedError =
                error.response
                && error.response.status >= 400
                && error.response.status < 500;

            if (!isExpectedError) {
                logService.log(error);
            }

            // this.showErrorMessage(error);

            // Redirect to the login page with full reload
            // window.location = getRoutePath("login");

            // Redirect to the homepage with full reload
            window.location = getRoutePath("home");
        }
    };

    // -----------------------------------------------

    showErrorMessage = (error, props = this.props) => {
        this.setState({
            messageModal: {
                ...toggleMessageModal("error"),
                body: getErrorMessage(error, props).body || "",
                footerButtons: getErrorMessage(error, props).footerButtons || [],
            }
        });
    }

    // -----------------------------------------------

    render() {
        const pageContent = (
            <section className="x-pb-100">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col x-ta-c">
                            {/* <PageLoader show={this.state.showPageLoader} /> */}

                            {
                                this.state.showPageLoader
                                ? (
                                    <React.Fragment>
                                        <PageLoader className="x-pt-50" />
                                        <p className="x-pt-30 x-c-999 x-dr-ltr">Logging Out...</p>
                                    </React.Fragment>
                                )
                                : ""
                            }
                        </div>
                    </div>
                </div>

                <MessageModal
                    show={this.state.messageModal.show || false}
                    title={this.state.messageModal.title || null}
                    body={this.state.messageModal.body || null}
                    alertClassName={this.state.messageModal.alertClassName || null}
                    size={this.state.messageModal.size || null}
                    centered={this.state.messageModal.centered || false}
                    footerButtons={this.state.messageModal.footerButtons || []}
                    onHide={this.state.messageModal.onHide || (() => this.setState({ messageModal: { ...this.initialState.messageModal } }))}
                />
            </section>
        );

        return (
            <PageContent
                pageContent={pageContent}
                authenticatedUser={this.getAuthenticatedUser()}
                showPageContent={this.showPageContent()}
                windowLocation={window.location}
            />
        );
    }
}
