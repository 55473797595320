import React from "react";
import DatePicker from "react-datepicker";
import _isEmpty from "lodash/isEmpty";
import _range from "lodash/range";


import "react-datepicker/dist/react-datepicker.css";
import "./sass/DateTimePickerBox.scss";

export default function DateTimePickerBox(props) {
    // Possible "props":
    //------------------
    // props.autoComplete
    // props.className
    // props.dateFormat
    // props.disabled
    // props.endYear
    // props.filterDate
    // props.filterTime
    // props.id
    // props.isClearable
    // props.label
    // props.months
    // props.name
    // props.onBlur
    // props.onChange
    // props.onFocus
    // props.required
    // props.showCustomHeader
    // props.showTimeInput
    // props.startYear
    // props.timeInputLabel
    // props.validationError
    // props.value
    
    // -------------------------
    
    // const selectedDate = !_isEmpty(props.value) ? new Date(props.value) : null;

    const dateValue = String(props.value);
    const selectedDate = !_isEmpty(dateValue) ? new Date(dateValue) : null;

    // TEST
    // console.log("selectedDate: ", selectedDate);

    // -------------------------

    const currentYear = (new Date()).getFullYear();
    const startYear   = props.startYear || currentYear; // Works with renderCustomHeader
    const endYear     = props.endYear || currentYear;   // Works with renderCustomHeader
    const incrementYearBy = 1;

    // Get an array of the years stat in 1990 and end in the current year 
    const years = _range(startYear, endYear + 1, incrementYearBy);

    // -------------------------
    
    // Works with renderCustomHeader
    const defaultMonths = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];

    const months = props.months || defaultMonths;

    // -------------------------

    const customHeader = (
        ({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled
        }) => {
            return (
                <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
                    <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                        {"<"}
                    </button>
                    <select
                        value={date.getFullYear()}
                        onChange={({ target: { value } }) => changeYear(value)}
                    >
                        {years.map(year => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                    </select>

                    <select
                        value={months[date.getMonth()]}
                        onChange={({ target: { value } }) =>
                            changeMonth(months.indexOf(value))
                        }
                    >
                        {months.map(month => (
                            <option key={month} value={month}>
                                {month}
                            </option>
                        ))}
                    </select>

                    <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                        {">"}
                    </button>
                </div>
            );
        }
    );

    // -------------------------

    return (
        <div className="form-group x-pt-15">
            <label htmlFor={props.name}>
                {props.label}
                {
                    typeof props.required === "undefined" || props.required === true
                        ? <span className="x-c-red"> *</span>
                        : ""
                }
            </label>

            {/* This is a full list of the DatePicker properties: t.ly/gOgP */}
            <DatePicker
                autoComplete={props.autoComplete || "off"} 
                className={props.className || "form-control x-bdrs-2 x-pt-21 x-pb-21 x-dr-ltr x-ta-l " + (props.extraCssClasses || "")}
                dateFormat={props.dateFormat || "yyyy-MM-dd hh:mm aa"}
                disabled={props.disabled || false}
                // filterDate={props.filterDate || (() => true)}
                // filterTime={props.filterTime || (() => true)}
                id={props.id || props.name}
                isClearable={props.isClearable || false}
                // maxDate={props.maxDate}
                // minDate={props.minDate}
                name={props.name}
                onBlur={props.onBlur || (() => {})}
                onChange={props.onChange || (() => {})}
                onFocus={props.onFocus || (() => {})}
                renderCustomHeader={props.showCustomHeader ? customHeader : undefined}
                selected={selectedDate} // "selected" opens the calendar on the selected date
                showTimeInput={typeof props.showTimeInput === "undefined" ? true : props.showTimeInput}
                timeInputLabel={props.timeInputLabel || "Time:"}
            />

            <small className="form-text text-danger">{props.validationError || null}</small>
        </div>
    );
}