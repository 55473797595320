import { preferredLanguage } from "./../configs/ui-language-config";

const texts = {
    ar: {
        "select": "اختر",
    },

    en: {
        "select": "Select",
    }
}

export const SelectBoxTexts = texts[preferredLanguage.alpha2code];
