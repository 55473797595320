import React from "react";
import { Link } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiInformationOutline } from '@mdi/js';
import { switchClasses } from "../../../languages/CssClasses";
import { BorrowerDocumentListTexts as texts } from "../../../languages/BorrowerDocumentListTexts";
import { GeneralTexts } from "../../../languages/GeneralTexts";
import { BorrowerFirstLoanApplicationWizardSubMenuTexts as wizardTexts } from "./../../../languages/BorrowerFirstLoanApplicationWizardSubMenuTexts";

// Third-Party Modules
// import Joi from "@hapi/joi";
import _isEmpty from "lodash/isEmpty";
import queryString from "query-string";

// Our Modules
import FormHandler from "../../shared/FormHandler";
import CustomButton from "../../shared/CustomButton";
import PageLoader from "../../shared/PageLoader";
import ButtonLoader from "../../shared/ButtonLoader";
import RequiredDataHint from "../../shared/RequiredDataHint";
// import Hint from "../../shared/Hint";
import DocumentUploader  from "../../shared/DocumentUploader";
import PageContent from "./../../shared/PageContent";
import BorrowerFirstLoanApplicationWizardSubMenu from "./BorrowerFirstLoanApplicationWizardSubMenu";
import MessageModal, { toggleMessageModal, getErrorMessage } from "./../../shared/MessageModal";

// Our Services
import documentTitleService from "../../../services/document-title-service";
import logService from "../../../services/log-service";
import * as userService from "../../../services/user-service";
import * as documentService from "../../../services/document-service";

import { getRoutePath } from "./../../../configs/route-config";
import { openLinkInNewTab } from "../../../helpers/common-helper";

import exampleOfFileUserNationalIDCard from "../../../resources/pdf/example-of-file-user-national-id-card.pdf";
import exampleOfFileCompanyCommercialRegister from "../../../resources/pdf/example-of-file-company-commercial-register.pdf";
import exampleOfFileCompanyTaxCard from "../../../resources/pdf/example-of-file-company-tax-card.pdf";
import exampleOfFileUserPassport from "../../../resources/pdf/example-of-file-user-passport.pdf";
import guideOnHowToCreatePDFFileContainingImages from "../../../resources/pdf/how-to-create-a-pdf-file-containing-images.pdf";

// import placeholderImage from "../../../resources/images/placeholder-image.png";

export default class BorrowerDocumentList extends FormHandler {

    // Get query string as an object
    urlQueryString = queryString.parse(this.props.location.search);

    // -----------------------------------------------

    initialState = {
        ...this.initialStateElements,
        allowedUserType: "borrower",

        borrowerDocumentsStoredInDB: {
            user_national_id_card               : null,
            user_passport                       : null,
            company_commercial_register         : null,
            company_tax_card                    : null,
            company_operating_licenses          : null,
            company_vat_registration_certificate: null,
            company_industrial_license          : null,
        },
        buttonLoaders: {
            user_national_id_card               : false,
            user_passport                       : false,
            company_commercial_register         : false,
            company_tax_card                    : false,
            company_operating_licenses          : false,
            company_vat_registration_certificate: false,
            company_industrial_license          : false,
        },
        validationErrors        : {},
        messageModal            : {},
        showPageLoader          : true,
        showButtonLoader        : false,
        showPreviousButtonLoader: false,
        closeInfoAlert          : false,
    };

    // -----------------------------------------------

    // Clone a separate copy of the "initialState" and assign it to the "state".
    // Note that the spread operator "..." is important to get a separate copy.
    state = { ...this.initialState };

    // -----------------------------------------------

    validationRules = {};

    // -----------------------------------------------

    componentDidMount() {
        // More info: t.ly/ZVsd
        window.scrollTo(0, 0);

        // ----------------------

        // Properly set the browser's document title
        documentTitleService.setTitle(GeneralTexts["dashboard"] + " | " + texts["my-uploaded-documents"]);

        // ----------------------

        // TEST
        // console.log("this.props.history", this.props.history.location.pathname);

        this.setAuthenticatedUser();
        this.getBorrowerDocumentList();
    }

    // -----------------------------------------------

    componentWillUnmount() {
        // This code fixes the warning: "Can't perform a React state update on an unmounted component'
        // More info: t.ly/Kday
        this.setState = (state, callback) => {
            return;
        };
    }

    // -----------------------------------------------

    getBorrowerDocumentList = async () => {
        const currentUser = await userService.getCurrentUser("?include=documents");

        if (!_isEmpty(currentUser)) {
            const documents = currentUser.documents;

            // TEST
            // console.log("Borrower Documents: ", documents);

            this.setState({
                borrowerDocumentsStoredInDB: {
                    user_national_id_card               : this.getLastUploadedDocumentByCategory("USER-NATIONAL-ID-CARD", documents),
                    user_passport                       : this.getLastUploadedDocumentByCategory("USER-PASSPORT", documents),
                    company_commercial_register         : this.getLastUploadedDocumentByCategory("COMPANY-COMMERCIAL-REGISTER", documents),
                    company_tax_card                    : this.getLastUploadedDocumentByCategory("COMPANY-TAX-CARD", documents),
                    company_operating_licenses          : this.getLastUploadedDocumentByCategory("COMPANY-OPERATING-LICENSES", documents),
                    company_vat_registration_certificate: this.getLastUploadedDocumentByCategory("COMPANY-VAT-REGISTRATION-CERTIFICATE", documents),
                    company_industrial_license          : this.getLastUploadedDocumentByCategory("COMPANY-INDUSTRIAL-LICENSE", documents),
                },
                showPageLoader: false,
            });

            // TEST
            // console.log("this.state.borrowerDocumentsStoredInDB 1: ", this.state.borrowerDocumentsStoredInDB)
        }
    }

    // -----------------------------------------------

    getLastUploadedDocumentByCategory = (categoryName, documents = []) => {
        if (documents.length === 0) return null;

        // Get the documents by the specified categoryName
        const filteredDocuments = documents.filter(doc => String(doc.category).toLocaleUpperCase() === categoryName.toLocaleUpperCase());

        if (filteredDocuments.length === 0) return null;

        // Sort descending according to the "id"
        // So we get the last uploaded document at the beginnig of the array
        filteredDocuments.sort((a, b) => b.id - a.id);

        return filteredDocuments[0] || null;
    }

    // -----------------------------------------------

    uploadDocument = async (event) => {
        const input = event.currentTarget;

        // this.setState({ showButtonLoader: true }

        const buttonLoaders = { ...this.state.buttonLoaders };
        buttonLoaders[input.name] = true;
        this.setState({ buttonLoaders: buttonLoaders });

        try {
            const formData = this.setFormData(event);
            const response = await documentService.create(formData);
            const storedDoc = response.data.data.document;

            // TEST
            // console.log("storedDoc: ", storedDoc);

            // storedDoc.full_url = await this.getDocumentFullUrl(storedDoc.uuid);

            const storedDocs = {...this.state.borrowerDocumentsStoredInDB};
            storedDocs[input.name] = storedDoc;
            this.setState({borrowerDocumentsStoredInDB: storedDocs});

            const uploadedDocFullUrl = await this.getDocumentFullUrl(storedDoc.uuid);
            this.setState({
                messageModal: {
                    ...toggleMessageModal("success"),
                    body: response.data.meta.message || "",
                    footerButtons: [
                        {
                            variant: "primary",
                            title: GeneralTexts["show-file"],
                            onClick: () => { openLinkInNewTab(uploadedDocFullUrl) }
                        }
                    ],
                }
            });
        } catch (error) {
            /**
             * If there is an unexpected error like: network down, server down, db down, bug, ..
             *  - Log the error.
             *  - Display a generic and friendly error message.
             *
             * Note that "error.response" determines if the error is an expected error or not.
             */

            const isExpectedError =
                error.response
                && error.response.status >= 400
                && error.response.status < 500;

            if (!isExpectedError) {
                logService.log(error);
            }

            this.showErrorMessage(error);
        }

        buttonLoaders[input.name] = false;
        this.setState({ buttonLoaders: buttonLoaders });
    }

    // -----------------------------------------------

    showErrorMessage = (error, props = this.props) => {
        this.setState({
            messageModal: {
                ...toggleMessageModal("error"),
                body: getErrorMessage(error, props).body || "",
                footerButtons: getErrorMessage(error, props).footerButtons || [],
            }
        });
    }

    // -----------------------------------------------

    getDocumentFullUrl = async (documentUuid) => {
        try {
            const response = await documentService.getItem(documentUuid);
            const documentFullUrl = response.data;
            return documentFullUrl || null;
        } catch (error) {
            return null;
        }
    }

    // -----------------------------------------------

    isRequiredDocumentsAlreadyUploaded = () => {
        const isRequiredDocsUploaded =
            this.state.borrowerDocumentsStoredInDB?.user_national_id_card !== null
            && this.state.borrowerDocumentsStoredInDB?.company_commercial_register !== null
            && this.state.borrowerDocumentsStoredInDB?.company_tax_card !== null;
            // && this.state.borrowerDocumentsStoredInDB?.company_operating_licenses !== null;

        return isRequiredDocsUploaded ? true : false;
    }

    // -----------------------------------------------

    handleNextStep = () => {
        this.setState({ showButtonLoader: true });

        if (this.isRequiredDocumentsAlreadyUploaded()) {
            const url = `${getRoutePath("borrowerLoanCreate")}?first_time_loan=1`;
            this.props.history.push(url);
        } else {
            this.setState({
                messageModal: {
                    ...toggleMessageModal("error"),
                    body: "You need to upload all the required files before you can move to the next step.",
                }
            });
        }

        this.setState({ showButtonLoader: false });
    }

    // -----------------------------------------------

    closeInfoAlert = () => {
        this.setState({ closeInfoAlert: true });
    }

    // -----------------------------------------------

    isFirstTimeLoan = () => {
        return String(this.urlQueryString?.first_time_loan) === "1";
    }

    // -----------------------------------------------

    getLastCreatedCompany = async () => {
        const currentUser = await userService.getCurrentUser("?include=borrower.companies");

        if (!_isEmpty(currentUser)) {
            // Sort ascending according to the "id"
            // currentUser.borrower.companies.sort((a, b) => a.id - b.id);

            // Sort descending according to the "id"
            currentUser.borrower.companies.sort((a, b) => b.id - a.id);

            const lastCreatedCompany = currentUser?.borrower?.companies[0];

            return lastCreatedCompany;
        }

        return null;
    }

    // -----------------------------------------------

    goToTheLastCreatedCompany = async () => {
        this.setState({ showPreviousButtonLoader: true });

        const lastCreatedCompany = await this.getLastCreatedCompany();

        if (lastCreatedCompany) {
            const url = `${getRoutePath("borrowerCompanyUpdate")}/${lastCreatedCompany?.id}?first_time_loan=1`
            this.props.history.push(url);
        }

        this.setState({ showPreviousButtonLoader: false });
    }

    // -----------------------------------------------

    showInfoMessage = (messageBody, modalSize = "md") => {
        this.setState({
            messageModal: {
                ...toggleMessageModal("info"),
                body: messageBody,
                size: modalSize
            }
        });
    }

    // -----------------------------------------------

    getHelpInfo = () => {
        return (
            <div className={"__ff-droidarabickufi " + switchClasses(["x-ta-r", "x-dr-rtl", "x-fz-082rem"])}>
                <ul className="x-pr-20 x-pl-20 x-lh-30">
                    <li>{texts["click-the-file-name-or-icon-to-show-it"]}</li>
                    <li>{texts["all-files-should-be-of-type-pdf"]}</li>
                    <li>{texts["to-replace-an-existing-file-upload-a-new-one"]}</li>
                    <li>
                        {/* More about openning a link in another tab: t.ly/2wzb */}
                        <Link to={{ pathname: guideOnHowToCreatePDFFileContainingImages }} target="_blank">
                            {texts["how-to-create-a-pdf-file-containing-images"]}
                        </Link>
                    </li>
                </ul>
            </div>
        );
    }

    // -----------------------------------------------

	render() {
		const pageContent = (
            <React.Fragment>
                <div className="x-m-25 x-mt-50">
                    <h5 className={"head-color " + switchClasses(["__ff-droidarabickufi"])}>
                        {
                            this.isFirstTimeLoan()
                                ? wizardTexts["required-documents"]
                                : texts["my-uploaded-documents"]
                        }
                    </h5>
                </div>

                <div className="_border _border-light shadow-sm x-m-25 x-mt-0 x-mb-70 x-p-10 x-pb-100 x-mih-500 x-bgc-fff x-bdc-e3e3e3 x-bdrs-4">
                    <div className="container-fluid">
                        <div className="row x-pt-20">
                            {
                                this.isFirstTimeLoan()
                                    ?
                                    <div className="col-md-3">
                                        <BorrowerFirstLoanApplicationWizardSubMenu activePagePath={this.props.location.pathname} />
                                    </div>
                                    : ""
                            }

                            <div className={this.isFirstTimeLoan() ? "col-md-9" : "col-12"}>
                                <PageLoader show={this.state.showPageLoader} />

                                <div className={this.isFirstTimeLoan() ? "" : "x-d-n"}>
                                    <div className={this.state.closeInfoAlert ? "x-d-n" : ""}>
                                        <div className={"alert alert-primary alert-dismissible x-lh-28 x-mt-15 __ff-droidarabickufi " + switchClasses(["x-fz-082rem"])} role="alert">
                                            <div>
                                                {texts["step-3-hint"]}
                                            </div>
                                            <button type="button" className="close" onClick={this.closeInfoAlert}>
                                                <span>&times;</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className={(this.state.showPageLoader ? "x-d-n" : "")}>
                                    <div className={"__ff-droidarabickufi x-mb-10 " + (this.state.showPageLoader ? " x-d-n" : "")}>
                                        <Icon
                                            path={mdiInformationOutline}
                                            size="1rem"
                                            className="x-c-999 _x-pos-r _x-b-2"
                                        />
                                        &nbsp;
                                        <Link to={{ pathname: guideOnHowToCreatePDFFileContainingImages }} target="_blank" className="x-fz-075rem">
                                            {texts["help-info-how-to-create-a-pdf-file-containing-images"]}
                                        </Link>
                                        <span className="clearfix"></span>
                                    </div>

                                    <form className={"__ff-droidarabickufi x-pt-20 " + switchClasses(["x-fz-08rem"])} onSubmit={(e) => e.preventDefault()}>
                                        <div className="row x-ta-c">
                                            <div className="col-md-3 x-pb-70">
                                                <DocumentUploader
                                                    documentStoredInDB={this.state.borrowerDocumentsStoredInDB.user_national_id_card}
                                                    documentUploadButtonName="user_national_id_card"
                                                    documentLabel={texts["user-national-id-card-image"]}
                                                    required={true}
                                                    onChange={this.uploadDocument}
                                                    showButtonLoader={this.state.buttonLoaders["user_national_id_card"]}
                                                    onInfoHintClick={() => this.showInfoMessage(this.getHelpInfo())}
                                                    infoHintFontSize="x-fz-085rem"
                                                />
                                                <div className={"__ff-droidarabickufi x-fz-077rem"}>
                                                    {/* More about openning a link in another tab: t.ly/2wzb */}
                                                    <Link to={{ pathname: exampleOfFileUserNationalIDCard }} target="_blank">
                                                        {texts["example-of-the-required-file"]}
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-md-3 x-pb-70">
                                                <DocumentUploader
                                                    documentStoredInDB={this.state.borrowerDocumentsStoredInDB.company_commercial_register}
                                                    documentUploadButtonName="company_commercial_register"
                                                    documentLabel={texts["company-commercial-register-image"]}
                                                    required={true}
                                                    onChange={this.uploadDocument}
                                                    showButtonLoader={this.state.buttonLoaders["company_commercial_register"]}
                                                    onInfoHintClick={() => this.showInfoMessage(this.getHelpInfo())}
                                                    infoHintFontSize="x-fz-085rem"
                                                />
                                                <div className={"__ff-droidarabickufi x-fz-077rem"}>
                                                    {/* More about openning a link in another tab: t.ly/2wzb */}
                                                    <Link to={{ pathname: exampleOfFileCompanyCommercialRegister }} target="_blank">
                                                        {texts["example-of-the-required-file"]}
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-md-3 x-pb-70">
                                                <DocumentUploader
                                                    documentStoredInDB={this.state.borrowerDocumentsStoredInDB.company_tax_card}
                                                    documentUploadButtonName="company_tax_card"
                                                    documentLabel={texts["company-tax-card-image"]}
                                                    required={true}
                                                    onChange={this.uploadDocument}
                                                    showButtonLoader={this.state.buttonLoaders["company_tax_card"]}
                                                    onInfoHintClick={() => this.showInfoMessage(this.getHelpInfo())}
                                                    infoHintFontSize="x-fz-085rem"
                                                />
                                                <div className={"__ff-droidarabickufi x-fz-077rem"}>
                                                    {/* More about openning a link in another tab: t.ly/2wzb */}
                                                    <Link to={{ pathname: exampleOfFileCompanyTaxCard }} target="_blank">
                                                        {texts["example-of-the-required-file"]}
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-md-3 x-pb-70">
                                                <DocumentUploader
                                                    documentStoredInDB={this.state.borrowerDocumentsStoredInDB.company_operating_licenses}
                                                    documentUploadButtonName="company_operating_licenses"
                                                    documentLabel={texts["company-operating-licenses-images"]}
                                                    required={false}
                                                    onChange={this.uploadDocument}
                                                    showButtonLoader={this.state.buttonLoaders["company_operating_licenses"]}
                                                    onInfoHintClick={() => this.showInfoMessage(this.getHelpInfo())}
                                                    infoHintFontSize="x-fz-085rem"
                                                />
                                                {/*
                                                // NOTE:
                                                // Enable this link when you prepare an example file of the company operating licenses
                                                <div className={"__ff-droidarabickufi x-fz-077rem"}>
                                                    <Link to={{ pathname: "" }} target="_blank">
                                                        {texts["example-of-the-required-file"]}
                                                    </Link>
                                                </div>
                                                */}
                                            </div>
                                        </div>

                                        <div className="row x-ta-c">
                                            <div className="col-md-3 x-pb-70">
                                                <DocumentUploader
                                                    documentStoredInDB={this.state.borrowerDocumentsStoredInDB.company_vat_registration_certificate}
                                                    documentUploadButtonName="company_vat_registration_certificate"
                                                    documentLabel={texts["company-vat-registration-certificate-image"]}
                                                    required={false}
                                                    onChange={this.uploadDocument}
                                                    showButtonLoader={this.state.buttonLoaders["company_vat_registration_certificate"]}
                                                    onInfoHintClick={() => this.showInfoMessage(this.getHelpInfo())}
                                                    infoHintFontSize="x-fz-085rem"
                                                />
                                                {/*
                                                // NOTE:
                                                // Enable this link when you prepare an example file of the company VAT registration certificate
                                                <div className={"__ff-droidarabickufi x-fz-077rem"}>
                                                    <Link to={{ pathname: "" }} target="_blank">
                                                        {texts["example-of-the-required-file"]}
                                                    </Link>
                                                </div>
                                                */}
                                            </div>
                                            <div className="col-md-3 x-pb-70">
                                                <DocumentUploader
                                                    documentStoredInDB={this.state.borrowerDocumentsStoredInDB.company_industrial_license}
                                                    documentUploadButtonName="company_industrial_license"
                                                    documentLabel={texts["company-industrial-license-image"]}
                                                    required={false}
                                                    onChange={this.uploadDocument}
                                                    showButtonLoader={this.state.buttonLoaders["company_industrial_license"]}
                                                    onInfoHintClick={() => this.showInfoMessage(this.getHelpInfo())}
                                                    infoHintFontSize="x-fz-085rem"
                                                />
                                                {/*
                                                // NOTE:
                                                // Enable this link when you prepare an example file of the company industrial license
                                                <div className={"__ff-droidarabickufi x-fz-077rem"}>
                                                    <Link to={{ pathname: "" }} target="_blank">
                                                        {texts["example-of-the-required-file"]}
                                                    </Link>
                                                </div>
                                                */}
                                            </div>
                                            <div className="col-md-3 x-pb-70">
                                                <DocumentUploader
                                                    documentStoredInDB={this.state.borrowerDocumentsStoredInDB.user_passport}
                                                    documentUploadButtonName="user_passport"
                                                    documentLabel={texts["user-passport-image"]}
                                                    required={false}
                                                    onChange={this.uploadDocument}
                                                    showButtonLoader={this.state.buttonLoaders["user_passport"]}
                                                    onInfoHintClick={() => this.showInfoMessage(this.getHelpInfo())}
                                                    infoHintFontSize="x-fz-085rem"
                                                />
                                                <div className={"__ff-droidarabickufi x-fz-077rem"}>
                                                    {/* More about openning a link in another tab: t.ly/2wzb */}
                                                    <Link to={{ pathname: exampleOfFileUserPassport }} target="_blank">
                                                        {texts["example-of-the-required-file"]}
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            this.isFirstTimeLoan()
                                            ? (
                                                    <React.Fragment>
                                                        <hr className="x-mt-40 x-mb-30" />
                                                        <div className="row x-ta-c">
                                                            <div className="col">
                                                                <div className="x-mt-20">
                                                                    <CustomButton
                                                                        type="button"
                                                                        label={
                                                                            this.state.showButtonLoader
                                                                                ? <ButtonLoader />
                                                                                : GeneralTexts["next"]
                                                                        }
                                                                        disabled={!this.isRequiredDocumentsAlreadyUploaded()}
                                                                        onClick={this.handleNextStep}
                                                                    />
                                                                    &nbsp;&nbsp;&nbsp;
                                                                    <CustomButton
                                                                        type="button"
                                                                        label={
                                                                            this.state.showPreviousButtonLoader
                                                                                ? <ButtonLoader />
                                                                                : GeneralTexts["previous"]
                                                                        }
                                                                        disabled={false}
                                                                        onClick={this.goToTheLastCreatedCompany}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                                : ""
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>

                        <RequiredDataHint className={this.state.showPageLoader ? "x-d-n" : ""} />
                    </div>

                    <MessageModal
                        show={this.state.messageModal.show || false}
                        title={this.state.messageModal.title || null}
                        body={this.state.messageModal.body || null}
                        alertClassName={this.state.messageModal.alertClassName || null}
                        size={this.state.messageModal.size || null}
                        centered={this.state.messageModal.centered || false}
                        footerButtons={this.state.messageModal.footerButtons || []}
                        onHide={this.state.messageModal.onHide || (() => this.setState({ messageModal: { ...this.initialState.messageModal } }))}
                    />
                </div>
            </React.Fragment>
        );

        return (
            <PageContent
                pageContent={pageContent}
                authenticatedUser={this.getAuthenticatedUser()}
                showPageContent={this.showPageContent()}
                windowLocation={window.location}
                urlQueryString={this.urlQueryString || null}
            />
        );
	}
}
