import React from "react";
// import { Link } from "react-router-dom";

// import _isEmpty from "lodash/isEmpty";

import { switchClasses } from "./../languages/CssClasses";
import { HomeTexts as texts } from "./../languages/HomeTexts";
// import { GeneralTexts } from "./../languages/GeneralTexts";
// import { RouteConfigTexts } from "./../languages/RouteConfigTexts";

// Third-Party Modules
import moment from "moment";
// import Icon from "@mdi/react"
// import { mdiPlay, mdiMenu, mdiClose, mdiLockOpenOutline, mdiAccountOutline, mdiAlphabetLatin, mdiAbjadArabic } from "@mdi/js";

// Our Modules
import PageContent from "./shared/PageContent";
import PageLoader from "./shared/PageLoader";
// import LoanCard from "./shared/LoanCard";
import LoanCardV2 from "./shared/LoanCardV2";
import PageHandler from "./shared/PageHandler";

// Our Services
import documentTitleService from "./../services/document-title-service";
import * as loanService from "./../services/loan-service";
// import * as userService from "./../services/user-service";

// Our Configs
import { getRoutePath } from "../configs/route-config";

// import amwalLogo from "./../resources/images/amwal-logo.png";
// import amwalLogoTransparent from "./../resources/images/amwal-logo-transparent.png";
// import image01 from "./../resources/images/borrower.png";
// import image02 from "./../resources/images/investor.png";
// import image03 from "./../resources/images/icons/002.png";
// import image04 from "./../resources/images/icons/003.png";
// import image05 from "./../resources/images/icons/004.png";
// import image06 from "./../resources/images/icons/005.png";
// import image07 from "./../resources/images/icons/006.png";
// import image08 from "./../resources/images/icons/007.png";
// import image09 from "./../resources/images/icons/008.png";
// import image10 from "./../resources/images/icons/009.png";
// import image11 from "./../resources/images/borrower-v2.png";
// import image12 from "./../resources/images/investor-v2.png";
import image13 from "./../resources/images/investor-v2-vector.png";
import image14 from "./../resources/images/borrower-v2-vector.png";

// Our SCSS
import "./sass/HomePage/home-page-v2.scss";

export default class HomeV2 extends PageHandler {
	initialState = {
		...this.initialStateElements,
		isThisPagePublic: true,

		loans: [],
		showPageLoader: true,
		showMainSidebar: false,
	};

	// -----------------------------------------------

	// Clone a separate copy of the "initialState" and assign it to the "state".
	// Note that the spread operator "..." is important to get a separate copy.
	state = { ...this.initialState };

	// -----------------------------------------------

	componentDidMount() {
		// More info: t.ly/ZVsd
		window.scrollTo(0, 0);

		// ----------------------

		// Properly set the browser's document title
		documentTitleService.setTitle();

		// ----------------------

		this.setAuthenticatedUser();
		this.getApprovedLoanList();
	}

	// -----------------------------------------------

	getApprovedLoanList = async () => {
		this.setState({ showPageLoader: true });

		const response = await loanService.getApprovedList(
			"?include=company&results=9"
		);
		const loans = response.data.data.loans || [];

		// Sort descending according to the "id"
		// loans.sort((a, b) => b.id - a.id);

		// moment("2020-10-31 16:13:09").format("X") gets the timestamp in seconds from the provided datetime
		loans.sort(
			(a, b) =>
				moment(b.crowdfunding_campaign_starts_at).format("X") -
				moment(a.crowdfunding_campaign_starts_at).format("X")
		);

		this.setState({
			loans: loans,
			showPageLoader: false,
		});
	};

	// -----------------------------------------------

	render() {
		window.scrollTo(0, 0);

		const introSection = (
			<div className="x-bgc-fff x-pb-80">
				<section className="intro-wrapper-v2">
					<div className="container-fluid">
						<div className="row x-pt-30">
							<div className="col x-ta-c">
								<h1 className="x-c-333 x-fz-24 x-lh-45">
									{texts["intro-section-content-v2-1"]}
								</h1>
							</div>
						</div>

						<div className="row">
							<div className="col-md-6 x-pt-50">
								<div className="row">
									<div className="col-1"></div>
									<div className="col-10 x-ta-c">
										<div>
											<img
												alt="Investor"
												className="x-m-a img-fluid"
												src={image13}
											/>
										</div>
										<div className="x-pt-25">
											<a
												href={getRoutePath("preSignup")}
												className={
													"btn _btn-success green_bgc x-c-fff x-miw-200 x-fz-15 x-p-15 x-bdrs-4 shadow-sm x-mt-20 " +
													switchClasses(["x-fz-14"])
												}
											>
												{texts["start-investing-now"]}
											</a>
										</div>
									</div>
									<div className="col-1"></div>
								</div>
							</div>
							<div className="col-md-6 x-pt-50">
								<div className="row">
									<div className="col-1"></div>
									<div className="col-10 x-ta-c">
										<div>
											<img
												alt="Borrower"
												className="x-m-a img-fluid"
												src={image14}
											/>
										</div>
										<div className="x-pt-25">
											<a
												href={getRoutePath("preSignup")}
												className={
													"btn _btn-success green_bgc x-c-fff x-miw-200 x-fz-15 x-p-15 x-bdrs-4 shadow-sm x-mt-20 " +
													switchClasses(["x-fz-14"])
												}
											>
												{texts["start-borrowing-now"]}
											</a>
										</div>
									</div>
									<div className="col-1"></div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		);

		// -----------------------------------------------

		const features = (
			<>
				<div
					className="x-ta-c x-pt-30 x-pb-80"
					style={{ backgroundColor: "#fafafa", borderTop: "1px solid #eee" }}
				>
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-4 x-pt-50">
								<div className="">
									<h4
										className="x-fz-20 text-success"
										style={{ _color: "#0b9152" }}
									>
										{/* {texts["only-1000-egp"]} */}
										{texts["short-term-loan"]}
										{/* <span className="x-d-b x-w-50 x-pt-20 x-m-a" style={{ borderBottom: "1px solid #14995d" }}></span> */}
										<span
											className="x-d-b x-w-50 x-pt-20 x-m-a"
											style={{ borderBottom: "1px solid #999" }}
										></span>
									</h4>
									<div className="x-pt-15 x-pr-20 x-pl-20 x-lh-30 x-c-666">
										{/* {texts["start-investing-with-only-1000-egp"]} */}
										{texts["short-term-loan-description"]}
									</div>
								</div>
							</div>
							<div className="col-md-4 x-pt-50">
								<div className="">
									<h4
										className="x-fz-20 text-success"
										style={{ _color: "#0b9152" }}
									>
										{texts["competitive-return-on-investment"]}
										{/* <span className="x-d-b x-w-50 x-pt-20 x-m-a" style={{ borderBottom: "1px solid #14995d" }}></span> */}
										<span
											className="x-d-b x-w-50 x-pt-20 x-m-a"
											style={{ borderBottom: "1px solid #999" }}
										></span>
									</h4>
									<div className="x-pt-15 x-pr-20 x-pl-20 x-lh-30 x-c-666">
										{texts["investors-achieved-competitive-returns"]}
									</div>
								</div>
							</div>
							<div className="col-md-4 x-pt-50">
								<div className="">
									<h4
										className="x-fz-20 text-success"
										style={{ _color: "#0b9152" }}
									>
										{texts["risk-insurance"]}
										{/* <span className="x-d-b x-w-50 x-pt-20 x-m-a" style={{ borderBottom: "1px solid #14995d" }}></span> */}
										<span
											className="x-d-b x-w-50 x-pt-20 x-m-a"
											style={{ borderBottom: "1px solid #999" }}
										></span>
									</h4>
									<div className="x-pt-15 x-pr-20 x-pl-20 x-lh-30 x-c-666">
										{texts["transactions-are-insured"]}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);

		// -----------------------------------------------

		const hasApprovedLoans = this.state.loans?.length ? true : false;

		const noLoansHint = (
			<p className={"x-c-333 x-ta-c x-pt-20 " + switchClasses(["x-fz-085rem"])}>
				{texts["no-loan-requests"]}
			</p>
		);

		const showNoLoansHint = !hasApprovedLoans && !this.state.showPageLoader;

		// const showSeeMoreButton = this.state.loans?.length ? true : false;
		// const seeMoreButton = (
		//     <div className="row x-pt-40">
		//         <div className="col x-ta-c">
		//             <button className="btn btn-dark x-miw-180 x-bdrs-50 shadow-md x-fw-n">
		//                 <Link to={getRoutePath("loans")} className="x-c-fff x-td-n">{texts["see-more"]}</Link>
		//             </button>
		//         </div>
		//     </div>
		// );

		const approvedLoans = this.state.loans.map((loan) => {
			return (
				<LoanCardV2
					key={Math.random()}
					loan={loan}
					authenticatedUser={this.getAuthenticatedUser()}
				/>
			);
		});

		const openLoanRequests = (
			<section className="open_loan_requests green_bgc x-pt-80 x-pb-100">
				<div className="container-fluid">
					<header className="x-ta-c">
						<h3 className="x-c-fff x-fz-24 x-fw-100">
							{texts["open-investment-opportunities"]}
						</h3>
						<p className="title-border x-bdbc-fff x-bdbw-1"></p>
					</header>

					<PageLoader show={this.state.showPageLoader} color="white" />

					{showNoLoansHint ? noLoansHint : null}

					<div className="row x-pt-50">
						<div className="col-md-1"></div>
						<div className="col-md-10">
							<div className="row">{approvedLoans}</div>
						</div>
					</div>

					{/* Enable the seeMoreButton when needed */}
					{/* {showSeeMoreButton ? seeMoreButton : null} */}
				</div>
			</section>
		);

		// -----------------------------------------------

		const pageContent = (
			<>
				<div className="">
					{introSection}
					{features}
					{openLoanRequests}
				</div>
			</>
		);

		return (
			<PageContent
				pageContent={pageContent}
				authenticatedUser={this.getAuthenticatedUser()}
				showPageContent={this.showPageContent()}
				windowLocation={window.location}
				hideHeaderNavbar={false}
				hideFooter={false}
				hideWhatsAppIcon={false}
			/>
		);
	}
}
