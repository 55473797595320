// React Modules
import React from "react";
import { Link } from "react-router-dom";

import { switchClasses } from "./../languages/CssClasses";
import { SignupTexts as texts } from "./../languages/SignupTexts";
import { RouteConfigTexts } from "./../languages/RouteConfigTexts";

// Third-Party Modules
import Joi from "@hapi/joi";
import queryString from "query-string";

// Our Modules
import MessageModal, { toggleMessageModal, getErrorMessage } from "./shared/MessageModal";
import FormHandler from "./shared/FormHandler";
import TextBox from "./shared/TextBox";
import SelectBox from "./shared/SelectBox";
import CustomButton from "./shared/CustomButton";
import ButtonLoader from "./shared/ButtonLoader";
import PageContent from "./shared/PageContent";

// Our Services
import logService from "./../services/log-service";
import * as authService from "./../services/auth-service";
import documentTitleService from "./../services/document-title-service";

// Our Configs
import { getCommonCountryList } from "./../configs/country-config";
import { getUserTypes, getUserGenders } from "./../configs/user-config";
import { getRoutePath } from "./../configs/route-config";

export default class Signup extends FormHandler {

    // Get query string as an object
    urlQueryString = queryString.parse(this.props.location.search);

    // -----------------------------------------------

    initialState = {
        ...this.initialStateElements,
        isThisPagePublic: true,

        formInputs: {
            first_name          : "",
            last_name           : "",
            user_type           : "",
            country_calling_code: "",
            mobile              : "",
            // email               : this.urlQueryString.email || "",
            email               : "",
            password            : "",
            gender              : "",
            accepted_terms      : "",
            time                : this.urlQueryString.time || "",
            check_token         : this.urlQueryString.check_token || "",
        },
        validationErrors      : {},
        messageModal          : {},
        showButtonLoader      : false,
        currentLinkOfUserTerms: "",
    };

    // -----------------------------------------------

    // Clone a separate copy of the "initialState" and assign it to the "state".
    // Note that the spread operator "..." is important to get a separate copy.
    state = { ...this.initialState };

    // -----------------------------------------------

    validationRules = {
        first_name: Joi.string()
            .required()
            .min(2)
            .max(20)
            .label("First Name"),

        last_name: Joi.string()
            .required()
            .min(2)
            .max(20)
            .label("Last Name"),

        country_calling_code: Joi.string()
            .required()
            .max(5)
            .label("Country"),

        mobile: Joi.string()
            .required()
            .min(8)
            .max(11)

            // Accept only digits. The same as: .pattern(new RegExp('/^\d+$/'))
            .regex(/^\d+$/)

            .label("Mobile Number")

            // More info about custom messages: t.ly/gY6z and t.ly/F5n2
            // And this is a full list of the errors: t.ly/LUUy
            .messages({
                "string.pattern.base": `{{#label}} should consist of numbers only.`,
            }),

        email: Joi.string()
            .required()
            .email({ tlds: false })

            // The provided phrase is to prevent an error that occurs when accessing the
            // "signup" page directly without having the necessary query sting in the url
            .equal(this.urlQueryString.email || "The same email address you've confirmed")

            .max(50)
            .label("Email Address"),

        password: Joi.string()
            .required()
            .min(6)
            .label("Password"),

        user_type: Joi.string()
            .required()
            // .equal(...(getUserTypes().map((type) => type.value)))
            // .insensitive()
            .label("User Type"),

        gender: Joi.string()
            .required()
            // .equal(...getUserGenders().map((gender) => gender.value))
            // .insensitive()
            .label("Gender"),

        accepted_terms: Joi.string()
            .required()
            .equal(...["1"])
            .label("Terms"),

        time: Joi.string()
            .optional(),

        check_token: Joi.string()
            .optional(),
    };

    // -----------------------------------------------

    componentDidMount() {
        // More info: t.ly/ZVsd
        window.scrollTo(0, 0);

        // ----------------------

        // Properly set the browser's document title
        documentTitleService.setTitle(RouteConfigTexts["signup"]);

        // ----------------------

        this.setAuthenticatedUser();

        // ----------------------

        // There are some required query strings that must exist in the url of "signup" page
        // and if not, we should show the user a message telling him what's wrong.
        const requiredParamsExist =
            this.urlQueryString.email
            && this.urlQueryString.time
            && this.urlQueryString.check_token;

        if (!requiredParamsExist) {
            this.setState({
                messageModal: {
                    ...toggleMessageModal("error"),
                    body: "You should confirm your email address first. To confirm your email address, click the link we have sent to your email.",
                    onHide: () => this.setState({ ...this.initialState }),
                }
            });
        }
    }

    // -----------------------------------------------

    doSubmit = async (e) => {
        this.setState({ showButtonLoader: true });

        try {
            const response = await authService.signup(this.state.formInputs);

            // TODO: Should we automatically login the user here?

            this.setState({
                messageModal: {
                    ...toggleMessageModal("success"),
                    body: response.data.meta.message,
                    footerButtons: [
                        {
                            variant: "primary",
                            title: "Login",
                            onClick: () => { this.props.history.replace(getRoutePath("login") + "?email=" + this.urlQueryString.email) }
                        }
                    ],
                    // onHide: () => this.setState({ ...this.initialState }),
                    onHide: () => { this.props.history.replace(getRoutePath("login") + "?email=" + this.urlQueryString.email) },
                }
            });

        } catch (error) {
            /**
             * If there is an unexpected error like: network down, server down, db down, bug, ..
             *  - Log the error.
             *  - Display a generic and friendly error message.
             *
             * Note that "error.response" determines if the error is an expected error or not.
             */

            const isExpectedError =
                error.response
                && error.response.status >= 400
                && error.response.status < 500;

            if (!isExpectedError) {
                logService.log(error);
            }

            this.showErrorMessage(error);
        }

        this.setState({ showButtonLoader: false });
    };

    // -----------------------------------------------

    showErrorMessage = (error, props = this.props) => {
        this.setState({
            messageModal: {
                ...toggleMessageModal("error"),
                body: getErrorMessage(error, props).body || "",
                footerButtons: getErrorMessage(error, props).footerButtons || [],
            }
        });
    }

    // -----------------------------------------------

    getLinkOfUserTerms = () => {
        const userType = this.state.formInputs.user_type;

        switch (userType) {
            case "":
            case "borrower":
                return getRoutePath("borrowTermsAndConditions");

            case "lender":
                return getRoutePath("investTermsAndConditions");

            default:
                return getRoutePath("home");
        }
    };

    // -----------------------------------------------

    render() {
        const pageContent = (
            <section className="x-pb-100 __ff-droidarabickufi">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-lg"></div>
                        <div className="col-lg">
                            <div className="_border _border-light shadow-sm x-bdc-ddd x-bdrs-4 x-mt-80 x-p-30 x-pt-40 x-pb-50 x-bgc-fff">
                                <h5 className="x-ta-c">{texts["create-new-account"]}</h5>

                                <form className={"x-mt-40 " + switchClasses(["x-fz-085rem"])} onSubmit={(e) => e.preventDefault()}>
                                    <TextBox
                                        name="first_name"
                                        label={texts["first-name"]}
                                        value={this.state.formInputs.first_name || ""}
                                        // autoFocus={true}
                                        onBlur={this.handleBlur}
                                        onChange={this.handleChange}
                                        onFocus={this.handleFocus}
                                        validationError={this.state.validationErrors.first_name || ""}
                                    />

                                    <TextBox
                                        name="last_name"
                                        label={texts["last-name"]}
                                        value={this.state.formInputs.last_name || ""}
                                        onBlur={this.handleBlur}
                                        onChange={this.handleChange}
                                        onFocus={this.handleFocus}
                                        validationError={this.state.validationErrors.last_name || ""}
                                    />

                                    <div className="row">
                                        <div className="col-6">
                                            <SelectBox
                                                name="country_calling_code"
                                                value={this.state.formInputs.country_calling_code || ""}
                                                label={texts["country"]}
                                                onBlur={this.handleBlur}
                                                onChange={this.handleChange}
                                                onFocus={this.handleFocus}
                                                validationError={this.state.validationErrors.country_calling_code || ""}
                                                selectBoxOptions={getCommonCountryList().map(country => {
                                                    return (
                                                        <option
                                                            key={Math.random()}
                                                            value={country.callingCodes[0]}
                                                        >
                                                            {country.name + " (+" + country.callingCodes[0] + ")"}
                                                        </option>
                                                    )
                                                })}
                                            />
                                        </div>

                                        <div className="col-6">
                                            <TextBox
                                                name="mobile"
                                                label={texts["mobile-number"]}
                                                value={this.state.formInputs.mobile || ""}
                                                onBlur={this.handleBlur}
                                                onChange={this.handleChange}
                                                onFocus={this.handleFocus}
                                                validationError={this.state.validationErrors.mobile || ""}
                                                placeholder="01012345678"
                                                extraCssClasses="x-dr-ltr x-ta-l"
                                            />
                                        </div>
                                    </div>

                                    <TextBox
                                        name="email"
                                        label={texts["email-address"]}
                                        value={this.state.formInputs.email || ""}
                                        onBlur={this.handleBlur}
                                        onChange={this.handleChange}
                                        onFocus={this.handleFocus}
                                        validationError={this.state.validationErrors.email || ""}
                                        extraCssClasses="x-dr-ltr x-ta-l"
                                    />

                                    <TextBox
                                        type="password"
                                        name="password"
                                        label={texts["password"]}
                                        value={this.state.formInputs.password || ""}
                                        onBlur={this.handleBlur}
                                        onChange={this.handleChange}
                                        onFocus={this.handleFocus}
                                        validationError={this.state.validationErrors.password || ""}
                                        extraCssClasses="x-dr-ltr x-ta-l"
                                    />

                                    <div className="row">
                                        <div className="col-6">
                                            <SelectBox
                                                name="user_type"
                                                value={this.state.formInputs.user_type || ""}
                                                label={texts["user-type"]}
                                                onBlur={this.handleBlur}
                                                onChange={this.handleChange}
                                                onFocus={this.handleFocus}
                                                validationError={this.state.validationErrors.user_type || ""}
                                                selectBoxOptions={getUserTypes().map(type => {
                                                    return (
                                                        <option
                                                            key={Math.random()}
                                                            value={type.value}
                                                        >
                                                            {type.label}
                                                        </option>
                                                    )
                                                })}
                                            />
                                        </div>

                                        <div className="col-6">
                                            <SelectBox
                                                name="gender"
                                                value={this.state.formInputs.gender || ""}
                                                label={texts["gender"]}
                                                onBlur={this.handleBlur}
                                                onChange={this.handleChange}
                                                onFocus={this.handleFocus}
                                                validationError={this.state.validationErrors.gender || ""}
                                                selectBoxOptions={getUserGenders().map(gender => {
                                                    return (
                                                        <option
                                                            key={Math.random()}
                                                            value={gender.value}
                                                        >
                                                            {gender.label}
                                                        </option>
                                                    )
                                                })}
                                            />
                                        </div>
                                    </div>

                                    {/* TODO: Move the checkbox element to a separate component */}
                                    <div className="form-group x-mt-15">
                                        <div className="form-check">
                                            <input
                                                type="checkbox"
                                                name="accepted_terms"
                                                value={this.state.formInputs.accepted_terms}
                                                // checked={this.state.formInputs.accepted_terms}
                                                className="form-check-input"
                                                id="accepted_terms"
                                                onChange={() => {
                                                    const newValue = Number(this.state.formInputs.accepted_terms) === 0 ? "1" : "0";
                                                    this.setState({ formInputs: { ...this.state.formInputs, accepted_terms: newValue } })
                                                }}
                                            />
                                            <label className={"form-check-label " + switchClasses(["x-fz-08rem"])} htmlFor="accepted_terms">
                                                {texts["read-and-agree-hint"]}&nbsp;
                                                <Link to={this.getLinkOfUserTerms}>{texts["terms-and-conditions"]}</Link>
                                            </label>
                                        </div>

                                        <small className="form-text text-danger">
                                            {this.state.validationErrors.accepted_terms || ""}
                                        </small>
                                    </div>

                                    <div className="x-mt-40">
                                        <CustomButton
                                            type="button"
                                            label={this.state.showButtonLoader ? <ButtonLoader /> : "Signup"}
                                            disabled={this.validateFormInputs() ? true : false}
                                            onClick={this.handleSubmit}
                                        />
                                    </div>
                                </form>
                            </div>

                            <p className={"x-p-0 x-pt-15 " + switchClasses(["x-fz-08rem"])}>
                                {texts["already-have-account"]} <Link to={getRoutePath("login")}>{texts["login-from-here"]}</Link>.
                            </p>
                        </div>
                        <div className="col-lg"></div>
                    </div>
                </div>

                <MessageModal
                    show={this.state.messageModal.show || false}
                    title={this.state.messageModal.title || null}
                    body={this.state.messageModal.body || null}
                    alertClassName={this.state.messageModal.alertClassName || null}
                    size={this.state.messageModal.size || null}
                    centered={this.state.messageModal.centered || false}
                    footerButtons={this.state.messageModal.footerButtons || []}
                    onHide={this.state.messageModal.onHide || (() => this.setState({ messageModal: { ...this.initialState.messageModal } }))}
                />
            </section>
        );

        return (
            <PageContent
                pageContent={pageContent}
                authenticatedUser={this.getAuthenticatedUser()}
                showPageContent={this.showPageContent()}
                windowLocation={window.location}
            />
        );
    }
}
