import React from "react";

import { switchClasses } from "./../languages/CssClasses";
// import { InvestFaqsTexts as texts } from "./../languages/InvestFaqsTexts";
import { RouteConfigTexts } from "./../languages/RouteConfigTexts";

// Our Modules
import InvestSubMenu from "./shared/InvestSubMenu";
import PageLoader from "./shared/PageLoader";

// Our Services
import documentTitleService from "./../services/document-title-service";
import PageHandler from "./shared/PageHandler";
import PageContent from "./shared/PageContent";

export default class InvestFaqs extends PageHandler {
	initialState = {
		...this.initialStateElements,
		isThisPagePublic: true,
		showPageLoader: false,
	};

	// -----------------------------------------------

	// Clone a separate copy of the "initialState" and assign it to the "state".
	// Note that the spread operator "..." is important to get a separate copy.
	state = { ...this.initialState };

	// -----------------------------------------------

	componentDidMount() {
		// More info: t.ly/ZVsd
		window.scrollTo(0, 0);

		// ----------------------

		// Properly set the browser's document title
		documentTitleService.setTitle(RouteConfigTexts["invest-faqs"]);

		// ----------------------

		this.setAuthenticatedUser();
	}

	// -----------------------------------------------

	render() {
		const pageContent = (
			<React.Fragment>
				<div>
					<div className={"x-m-25 x-mb-15 " + switchClasses(["x-mr-15"])}>
						<h5 className={"x-pt-20 head-color "}>
							{RouteConfigTexts["invest-faqs"]}
						</h5>
					</div>

					<div className="x-mt-15">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-3 col-lg-2 x-pr-8 x-pl-8">
									<InvestSubMenu />
								</div>

								<div className="col-md-9 col-lg-10 x-pr-8 x-pl-8">
									<div className="shadow-sm x-m-0 x-mt-15 x-mb-70 x-p-20 x-pt-16 x-pb-100 x-mih-500 x-bgc-fff x-bdc-e3e3e3 x-bdrs-4">
										<PageLoader show={this.state.showPageLoader} />

										<div
											className={
												"row" + (this.state.showPageLoader ? " x-d-n" : "")
											}
										>
											<div className="col">
												{/* <p className="x-pt-50 x-dr-ltr x-ta-c">Coming soon..</p> */}
												<h1 className="x-pt-30 x-ta-c x-fz-2rem">
													Investing FAQs
												</h1>

												<div className="x-p-50 x-lh-28">
													<div className="">
														<h3 className="x-m-0">What is Amwal?</h3>
														<div className="">
															<p className="x-m-0 x-pt-15">
																Amwal is a Sharia-compliant crowdlending
																platform that makes it easy for you to diversify
																your investment in different vetted transactions
																of SMEs and achieve high ROI.
															</p>
														</div>
													</div>

													<div className="x-pt-60">
														<h3 className="x-m-0">How does Amwal work?</h3>
														<div className="">
															<ol className="x-m-0 x-pt-15">
																<li>Register an investor account.</li>
																<li>Charge your wallet.</li>
																<li>
																	Diversify your fund on different vetted
																	transactions.
																</li>
																<li>
																	Monitor your portfolio by receiving
																	notifications about transaction updates.
																</li>
																<li>
																	Receive your capital + return at the maturity
																	date of each transaction in your portfolio.
																</li>
																<li>Repeat.</li>
															</ol>
														</div>
													</div>

													<div className="x-pt-60">
														<h3 className="x-m-0">
															Do you accept non-Egyptian lenders?
														</h3>
														<div className="">
															<p className="x-m-0 x-pt-15">
																Yes, in case they are living in Egypt and have
																residence certificates.
															</p>
														</div>
													</div>

													<div className="x-pt-60">
														<h3 className="x-m-0">
															Do you accept lenders residing outside Egypt?
														</h3>
														<div className="">
															<p className="x-m-0 x-pt-15">
																Yes, but only Egyptians living outside Egypt;
																unfortunately, we cannot accept any other
																nationalities not resisted in Egypt currently.
															</p>
														</div>
													</div>

													<div className="x-pt-60">
														<h3 className="x-m-0">What fee do you charge?</h3>
														<div className="">
															<p className="x-m-0 x-pt-15">
																3% deducted from your paid-in capital if you hit
																1.8X the corridor on CDs.
															</p>
														</div>
													</div>

													<div className="x-pt-60">
														<h3 className="x-m-0">
															Who will decide on lending?
														</h3>
														<div className="">
															<p className="x-m-0 x-pt-15">
																Lenders are only authorized to decide to lend to
																SMEs.
															</p>
														</div>
													</div>

													<div className="x-pt-60">
														<h3 className="x-m-0">
															What are the potential risks associated with
															investing on Amwal?
														</h3>
														<div className="">
															<p className="x-m-0 x-pt-15">
																As with any investment, there are certain risks
																that investors need to be aware of when
																investing on Amwal.com.eg. These include:
															</p>

															<ul>
																<li className="x-pt-10">
																	Accidental risks, such as theft, fire, or
																	accidents. However, there is insurance
																	coverage available to protect against these
																	risks.
																</li>
																<li className="x-pt-10">
																	Transaction default risk, which refers to
																	delays or non-payment of revenue collection.
																	In the event of systematic risks, borrowers
																	are given a 60-day grace period to settle
																	payment delays. If the borrower takes no
																	action, Amwal will sell the debt and 50% of
																	the profit to the insurance company. The
																	capital and profit will then be liquidated.
																</li>
																<li className="x-pt-10">
																	Unsystematic risks, such as wars, disasters,
																	and epidemics, may result in lenders losing
																	some of their funds invested in the
																	transaction.
																</li>
															</ul>
														</div>
													</div>

													<div className="x-pt-60">
														<h3 className="x-m-0">
															How does Amwal mitigate the risk of transactions?
														</h3>
														<div className="">
															<p className="x-m-0 x-pt-15">
																At Amwal, we have established a cashless model
																for each transaction. We make payments directly
																to the borrower's suppliers and collect revenue
																from the transaction while also providing legal
																collateral that ensures the integrity of the
																transaction. This approach is designed to
																minimize financial risks for all parties
																involved.
															</p>
														</div>
													</div>

													<div className="x-pt-60">
														<h3 className="x-m-0">
															How can I withdraw my investment?
														</h3>
														<div className="">
															<p className="x-m-0 x-pt-15">
																At Amwal, each lender has a dedicated wallet
																that enables them to manage their investments
																easily. Through this wallet, lenders can submit
																a withdrawal request to transfer their funds to
																the bank account registered on their profile.
															</p>
														</div>
													</div>

													<div className="x-pt-60">
														<h3 className="x-m-0">
															What is the minimum required account opening
															amount for AMWAL? Additionally, what are the
															minimum and maximum bid amounts?
														</h3>
														<div className="">
															<p className="x-m-0 x-pt-15">
																The minimum account opening amount and
																participation requirement for AMWAL is EGP
																50,000. There is no upper limit on the maximum
																bid amount lenders offer to lend to companies.
															</p>
														</div>
													</div>

													<div className="x-pt-60">
														<h3 className="x-m-0">
															What are lenders' minimum and maximum percentage
															of participation amounts when lending to each
															company through AMWAL?
														</h3>
														<div className="">
															<p className="x-m-0 x-pt-15">
																Lenders are limited to a maximum of 25%
																participation in lending to a given company,
																based on the lender's wallet on AMWAL. However,
																the minimum amount required for lenders to
																participate in lending to a company is 50,000
																EGP.
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);

		return (
			<PageContent
				pageContent={pageContent}
				authenticatedUser={this.getAuthenticatedUser()}
				showPageContent={this.showPageContent()}
				windowLocation={window.location}
			/>
		);
	}
}
