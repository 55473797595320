import React from "react";

const PaginationItem = (props) => {
	return (
		<li className={(props.className || "page-item ") + (" " + (props.extraCssClasses || "")) + (Number(props.paginator?.current_page) === props.pageNumber ? " active" : "")}>
			<div
				className="page-link x-cur-p"
				onClick={props.onClick || (() => {})}
			>
				{props.pageNumber}
			</div>
		</li>
	);
};

export default PaginationItem;
