import { preferredLanguage } from "./../configs/ui-language-config";
import { GeneralTexts } from "./GeneralTexts";
import { RouteConfigTexts } from "./RouteConfigTexts";

const texts = {
    ar: {
        "admin-overview-general"     : RouteConfigTexts["admin-overview-general"],
        "admin-overview-users"       : RouteConfigTexts["admin-overview-users"],
        "all-time"                   : GeneralTexts["all-time"],
        "today"                      : GeneralTexts["today"],
        "yesterday"                  : GeneralTexts["yesterday"],
        "last-7-days"                : GeneralTexts["last-7-days"],
        "last-30-days"               : GeneralTexts["last-30-days"],
        "investors"                  : "المستثمرين",
        "all-investors"              : "المستثمرين",
        "users-of-type-investor"     : "المستخدمين من نوع المستثمر",
        "invested-in-loans"          : "استثمروا من قبل",
        "invested-in-loans-hint"     : "الذين استثمروا في قرض او أكثر",
        "did-not-invest"             : "لم يستثمروا بعد",
        "did-not-invest-hint"        : "الذين لم يستثمروا بعد",
    },

    en: {
        "admin-overview-general"     : RouteConfigTexts["admin-overview-general"],
        "admin-overview-users"       : RouteConfigTexts["admin-overview-users"],
        "all-time"                   : GeneralTexts["all-time"],
        "today"                      : GeneralTexts["today"],
        "yesterday"                  : GeneralTexts["yesterday"],
        "last-7-days"                : GeneralTexts["last-7-days"],
        "last-30-days"               : GeneralTexts["last-30-days"],
        "investors"                  : "Investors",
        "all-investors"              : "All Investors",
        "users-of-type-investor"     : "Users of Type Investor",
        "invested-in-loans"          : "Invested in Loans",
        "invested-in-loans-hint"     : "Invested in One or More Loans",
        "did-not-invest"             : "Didn't Invest",
        "did-not-invest-hint"        : "Didn't Invest Yet",
    }
}

export const AdminOverviewLendersTexts = texts[preferredLanguage.alpha2code];
