import cryptoMD5 from "crypto-js/md5";
import { getRoutePath, storePreviousRoutePathInLocalStorage } from "../configs/route-config";
import _isEmpty from "lodash/isEmpty";
import moment from "moment";

const accessTokenKey = cryptoMD5("_token");
const accessTokenExpirationDateKey = cryptoMD5("_access_token_expiration_date");

export const setAccessToken = (tokenValue, tokenKey = accessTokenKey) => {
    // Store tha access token in the browser's local storage
    localStorage.setItem(tokenKey, tokenValue);
}

export const getAccessToken = (tokenKey = accessTokenKey) => {
    // Get the access token from the browser's local storage
    return localStorage.getItem(tokenKey) || null;
}

export const removeAccessToken = (tokenKey = accessTokenKey) => {
    // Remove the access token from the browser's local storage
    localStorage.removeItem(tokenKey);
}

export const redirectIfAccessTokenIsMissing = (routePath = getRoutePath("login")) => {
    if (_isEmpty(getAccessToken())) {
        storePreviousRoutePathInLocalStorage();
        window.location = routePath;
    }
}

//------------------------------------------------

export const setAccessTokenExpirationDate = (tokenExpirationDateValue, tokenExpirationDateKey = accessTokenExpirationDateKey) => {
    // Store tha access token expiration date in the browser's local storage
    localStorage.setItem(tokenExpirationDateKey, tokenExpirationDateValue);
}

export const getAccessTokenExpirationDate = (tokenExpirationDateKey = accessTokenExpirationDateKey) => {
    // Get the access token expiration date from the browser's local storage
    return localStorage.getItem(tokenExpirationDateKey) || null;
}

export const removeAccessTokenExpirationDate = (tokenExpirationDateKey = accessTokenExpirationDateKey) => {
    // Remove the access token expiration date from the browser's local storage
    localStorage.removeItem(tokenExpirationDateKey);
}

export const redirectIfAccessTokenExpired = (routePath = getRoutePath("login")) => {
    // If there is no access token expiration date, then the
    // user hasn't logged in before. So, we shouldn't redirect
    // him to the login page because he may not be registered yet
    if (_isEmpty(getAccessTokenExpirationDate())) {
        return;
    }

    const nowDate = moment().utc();
    const expirationDate = moment().utc(getAccessTokenExpirationDate());

    // If the access token is expired, redirect to the login page
    if (nowDate.isAfter(expirationDate)) {
        window.location = routePath;
    }
}

