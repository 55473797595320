import React from "react";
import { preferredLanguage } from "./../configs/ui-language-config";
import { GeneralTexts } from "./../languages/GeneralTexts";
import { RouteConfigTexts } from "./RouteConfigTexts";
import { BorrowerFirstLoanApplicationWizardSubMenuTexts as wizardTexts } from "./../languages/BorrowerFirstLoanApplicationWizardSubMenuTexts";

const texts = {
    ar: {
        "borrower-company-create"   : RouteConfigTexts["borrower-company-create"],
        "borrower-company-list"     : RouteConfigTexts["borrower-company-list"],
        "company-official-name"     : "الاسم الرسمي للشركة",
        "company-type"              : "نوع الشركة",
        "founded-in"                : "سنة التأسيس",
        "activity-sector"           : "مجال النشاط",
        "activity-subsector"        : "المجال الفرعي للنشاط",
        "country"                   : "الدولة",
        "city"                      : "المدينة",
        "address"                   : "العنوان",
        "mobile-number"             : "رقم الموبايل",
        "landline-number"           : "رقم التليفون الأرضي",
        "email-address"             : "البريد الإلكتروني",
        "website"                   : "موقع الإنترنت",
        "business-cycle"            : "تفاصيل دورة النشاط",
        "business-cycle-placeholder": "اشرح هنا ما الذي تفعله شركتك..",
        "step-2-hint": (
            <React.Fragment>
                <p>خطوة <span className="__ff-rubik x-fz-18">2</span> من <span className="__ff-rubik x-fz-18">4</span> &nbsp;|&nbsp; <strong>{wizardTexts["company-data"]}</strong></p>
                <p>أكمل البيانات المطلوبة التالية ثم اضغط زر <strong>{GeneralTexts["next"]}</strong> لتنتقل للخطوة التالية، أو أضغط زر <strong>{GeneralTexts["previous"]}</strong> لترجع للخطوة السابقة.</p>
            </React.Fragment>
        ),
    },

    en: {
        "borrower-company-create"   : RouteConfigTexts["borrower-company-create"],
        "borrower-company-list"     : RouteConfigTexts["borrower-company-list"],
        "company-official-name"     : "Company Official Name",
        "company-type"              : "Company Type",
        "founded-in"                : "Founded in Year",
        "activity-sector"           : "Activity Sector",
        "activity-subsector"        : "Activity Subsector",
        "country"                   : "Country",
        "city"                      : "City",
        "address"                   : "Address",
        "mobile-number"             : "Mobile Number",
        "landline-number"           : "Landline Number",
        "email-address"             : "Email Address",
        "website"                   : "Website",
        "business-cycle"            : "Business Cycle Details",
        "business-cycle-placeholder": "Describe here what your company do..",
        "step-2-hint": (
            <React.Fragment>
                <p>Step <span className="__ff-rubik x-fz-18">2</span> of <span className="__ff-rubik x-fz-18">4</span> &nbsp;|&nbsp; <strong>{wizardTexts["company-data"]}</strong></p>
                <p>Complete the following data and click <strong>{GeneralTexts["next"]}</strong> to move to the next step or click <strong>{GeneralTexts["previous"]}</strong> to go back to the previous step.</p>
            </React.Fragment>
        ),
    }
}

export const BorrowerCompanyCreateTexts = texts[preferredLanguage.alpha2code];
