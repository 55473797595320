import React from "react";

import { switchClasses } from "./../../../languages/CssClasses";
import { LenderAdvancedProfileTexts as texts } from "./../../../languages/LenderAdvancedProfileTexts";
import { GeneralTexts } from "./../../../languages/GeneralTexts";

// Third-Party Modules
import _isEmpty from "lodash/isEmpty";
import Joi from "@hapi/joi";

// Our Modules
import MessageModal, {
	toggleMessageModal,
	getErrorMessage,
} from "./../../shared/MessageModal";
import FormHandler from "./../../shared/FormHandler";
import TextBox from "./../../shared/TextBox";
import SelectBox from "./../../shared/SelectBox";
import CustomButton from "./../../shared/CustomButton";
import PageLoader from "./../../shared/PageLoader";
import ButtonLoader from "./../../shared/ButtonLoader";
import LenderProfileSubMenu from "./LenderProfileSubMenu";
import RequiredDataHint from "./../../shared/RequiredDataHint";
// import DateTimePickerBox from "./../../shared/DateTimePickerBox";
import PageContent from "./../../shared/PageContent";

// Our Services
import documentTitleService from "./../../../services/document-title-service";
import logService from "./../../../services/log-service";
import * as userService from "./../../../services/user-service";
import * as lenderService from "./../../../services/lender-service";

// Our Configs
import { getCommonCountryList } from "./../../../configs/country-config";
import { getCityList } from "./../../../configs/country-config";

export default class LenderAdvancedProfile extends FormHandler {
	initialState = {
		...this.initialStateElements,
		allowedUserType: "lender",

		formInputs: {
			id: "",
			country_of_nationality: "",
			country_of_residence: "",
			city_of_residence: "",
			address_of_residence: "",
			occupation: "",
			job_position: "",
			work_sector: "",
			preferred_sectors_to_invest_in: "",
			preferred_duration_of_investment: "",
			preferred_investment_amount: "",
			national_id_number: "",
			passport_number: "",
			birth_date: "",
			has_bank_account: "",
			invested_before: "",
			are_investments_from_legal_sources: "",
		},
		validationErrors: {},
		messageModal: {},
		showPageLoader: true,
		showButtonLoader: false,
	};

	// -----------------------------------------------

	// Clone a separate copy of the "initialState" and assign it to the "state".
	// Note that the spread operator "..." is important to get a separate copy.
	state = { ...this.initialState };

	// -----------------------------------------------

	componentDidMount() {
		// More info: t.ly/ZVsd
		window.scrollTo(0, 0);

		// ----------------------

		// Properly set the browser's document title
		documentTitleService.setTitle(
			GeneralTexts["lender-dashboard"] + " | " + texts["my-advanced-profile"]
		);

		// ----------------------

		this.setAuthenticatedUser();
		this.getLender();
	}

	// -----------------------------------------------

	// Get data of the current lender
	getLender = async () => {
		const currentUser = await userService.getCurrentUser("?include=lender");

		if (!_isEmpty(currentUser)) {
			const lender = currentUser.lender;

			this.setState({
				formInputs: {
					id: lender.id || "",
					country_of_nationality: lender.country_of_nationality || "",
					country_of_residence: lender.country_of_residence || "",
					city_of_residence: lender.city_of_residence || "",
					address_of_residence: lender.address_of_residence || "",
					occupation: lender.occupation || "",
					job_position: lender.job_position || "",
					work_sector: lender.work_sector || "",
					preferred_sectors_to_invest_in:
						lender.preferred_sectors_to_invest_in || "",
					preferred_duration_of_investment:
						lender.preferred_duration_of_investment || "",
					preferred_investment_amount: lender.preferred_investment_amount || "",
					birth_date: lender.birth_date || "",
					national_id_number: lender.national_id_number || "",
					passport_number: lender.passport_number || "",
					invested_before: lender.invested_before || "",
					has_bank_account: lender.has_bank_account || "",
					are_investments_from_legal_sources:
						lender.are_investments_from_legal_sources || "",
				},
				showPageLoader: false,
			});
		}
	};

	// -----------------------------------------------

	doSubmit = async (e) => {
		this.setState({ showButtonLoader: true });

		try {
			const response = await lenderService.update(
				this.state.formInputs.id,
				this.state.formInputs
			);

			const lender = response.data.data.lender;

			this.setState({
				formInputs: {
					id: lender.id || "",
					country_of_nationality: lender.country_of_nationality || "",
					country_of_residence: lender.country_of_residence || "",
					city_of_residence: lender.city_of_residence || "",
					address_of_residence: lender.address_of_residence || "",
					occupation: lender.occupation || "",
					job_position: lender.job_position || "",
					work_sector: lender.work_sector || "",
					preferred_sectors_to_invest_in:
						lender.preferred_sectors_to_invest_in || "",
					preferred_duration_of_investment:
						lender.preferred_duration_of_investment || "",
					preferred_investment_amount: lender.preferred_investment_amount || "",
					birth_date: lender.birth_date || "",
					national_id_number: lender.national_id_number || "",
					passport_number: lender.passport_number || "",
					invested_before: lender.invested_before || "",
					has_bank_account: lender.has_bank_account || "",
					are_investments_from_legal_sources:
						lender.are_investments_from_legal_sources || "",
				},

				messageModal: {
					...toggleMessageModal("success"),
					body: response.data.meta.message,
					// onHide: (() => {window.location.reload()}),
				},
			});
		} catch (error) {
			/**
			 * If there is an unexpected error like: network down, server down, db down, bug, ..
			 *  - Log the error.
			 *  - Display a generic and friendly error message.
			 *
			 * Note that "error.response" determines if the error is an expected error or not.
			 */

			const isExpectedError =
				error.response &&
				error.response.status >= 400 &&
				error.response.status < 500;

			if (!isExpectedError) {
				logService.log(error);
			}

			this.showErrorMessage(error);
		}

		this.setState({ showButtonLoader: false });
	};

	// -----------------------------------------------

	showErrorMessage = (error, props = this.props) => {
		this.setState({
			messageModal: {
				...toggleMessageModal("error"),
				body: getErrorMessage(error, props).body || "",
				footerButtons: getErrorMessage(error, props).footerButtons || [],
			},
		});
	};

	// -----------------------------------------------

	validationRules = {
		country_of_nationality: Joi.string()
			.required()
			.length(2)
			.label("Country of Nationality"),
		country_of_residence: Joi.string()
			.required()
			.length(2)
			.label("Country of Residence"),
		city_of_residence: Joi.string()
			.required()
			.max(50)
			.label("City of Residence"),
		address_of_residence: Joi.string()
			.required()
			.max(255)
			.label("Address of Residence"),
		occupation: Joi.string().required().max(255).label("Occupation"),
		job_position: Joi.string().required().max(255).label("Job Position"),
		work_sector: Joi.string().required().max(255).label("Work Sector"),
		preferred_sectors_to_invest_in: Joi.string()
			.optional()
			.allow("", null)
			.max(255)
			.label("Preferred Sectors to Invest in"),
		preferred_duration_of_investment: Joi.string()
			.optional()
			.allow("", null)
			.max(255)
			.label("Preferred Duration of Investment"),
		preferred_investment_amount: Joi.number()
			.optional()
			.allow("", null)
			.integer()
			.positive()
			.label("Preferred Amount to Invest"),
		national_id_number: Joi.string()
			.required()
			.max(25)
			.label("National ID Number"),
		passport_number: Joi.string()
			.optional()
			.allow("", null)
			.max(25)
			.label("Passport Number"),
		has_bank_account: Joi.string().required().label("Has Checkbook?"),
		invested_before: Joi.string().required().label("Invested Before?"),
		are_investments_from_legal_sources: Joi.string()
			.required()
			.label("Investments Are From Legal Sources?"),
		id: Joi.number().integer().positive().optional().allow("", null),

		birth_date: Joi.date()
			.required()
			// requires the date value to be in valid ISO 8601 format (i.e. 2020-08-18)
			.iso()
			.label("Birth Date")
			// More info about custom messages: t.ly/gY6z and t.ly/F5n2 and this is the error list: t.ly/LUUy
			.messages({
				// "date.format": `{{#label}} should match this format: 1980-01-31`,
				"date.format": `Example of {{#label}} format: 1980-01-31`,
			}),
	};

	// -----------------------------------------------

	render() {
		const pageContent = (
			<React.Fragment>
				<div className="x-m-25 x-mt-50">
					<h5
						className={"head-color " + switchClasses(["__ff-droidarabickufi"])}
					>
						{texts["my-advanced-profile"]}
					</h5>
				</div>

				<div className="_border _border-light shadow-sm x-m-25 x-mt-0 x-mb-70 x-p-10 x-pb-100 x-mih-500 x-bgc-fff x-bdc-e3e3e3 x-bdrs-4">
					<div className="container-fluid">
						<div className="row x-pt-20">
							<div className="col-md-3">
								<LenderProfileSubMenu />
							</div>
							<div className="col-md-9">
								<PageLoader show={this.state.showPageLoader} />

								<div className={this.state.showPageLoader ? "x-d-n" : ""}>
									<form
										className={
											"__ff-droidarabickufi " + switchClasses(["x-fz-085rem"])
										}
										onSubmit={(e) => e.preventDefault()}
									>
										<div className="row">
											<div className="col-lg-8">
												<div className="row">
													<div className="col-lg-4">
														<SelectBox
															name="country_of_nationality"
															value={
																this.state.formInputs.country_of_nationality ||
																""
															}
															label={texts["country-of-nationality"]}
															onBlur={this.handleBlur}
															onChange={this.handleChange}
															onFocus={this.handleFocus}
															validationError={
																this.state.validationErrors
																	.country_of_nationality || ""
															}
															selectBoxOptions={getCommonCountryList().map(
																(country) => {
																	return (
																		<option
																			key={Math.random()}
																			value={country.alpha2Code}
																		>
																			{country.name +
																				" (" +
																				country.alpha2Code +
																				")"}
																		</option>
																	);
																}
															)}
														/>
													</div>
													<div className="col-lg-4">
														<SelectBox
															name="country_of_residence"
															value={
																this.state.formInputs.country_of_residence || ""
															}
															label={texts["country-of-residence"]}
															onBlur={this.handleBlur}
															onChange={this.handleChange}
															onFocus={this.handleFocus}
															validationError={
																this.state.validationErrors
																	.country_of_residence || ""
															}
															selectBoxOptions={getCommonCountryList().map(
																(country) => {
																	return (
																		<option
																			key={Math.random()}
																			value={country.alpha2Code}
																		>
																			{country.name +
																				" (" +
																				country.alpha2Code +
																				")"}
																		</option>
																	);
																}
															)}
														/>
													</div>
													<div className="col-lg-4">
														<SelectBox
															name="city_of_residence"
															value={
																this.state.formInputs.city_of_residence || ""
															}
															label={texts["city-of-residence"]}
															onBlur={this.handleBlur}
															onChange={this.handleChange}
															onFocus={this.handleFocus}
															validationError={
																this.state.validationErrors.city_of_residence ||
																""
															}
															selectBoxOptions={getCityList(
																this.state.formInputs.country_of_residence
															).map((city) => {
																return (
																	<option key={Math.random()} value={city}>
																		{city}
																	</option>
																);
															})}
														/>
													</div>
												</div>
											</div>
										</div>

										<div className="row">
											<div className="col-lg-8">
												<TextBox
													name="address_of_residence"
													label={texts["address-of-residence"]}
													value={
														this.state.formInputs.address_of_residence || ""
													}
													onBlur={this.handleBlur}
													onChange={this.handleChange}
													onFocus={this.handleFocus}
													validationError={
														this.state.validationErrors.address_of_residence ||
														""
													}
												/>
											</div>
										</div>

										<div className="row">
											<div className="col-lg-8">
												<div className="row">
													<div className="col-lg-4">
														<SelectBox
															name="occupation"
															value={this.state.formInputs.occupation || ""}
															label={texts["occupation"]}
															onBlur={this.handleBlur}
															onChange={this.handleChange}
															onFocus={this.handleFocus}
															validationError={
																this.state.validationErrors.occupation || ""
															}
															selectBoxOptions={
																// TODO: Move these values to a separte config file
																<React.Fragment>
																	<option
																		key={Math.random()}
																		value="business-owner"
																	>
																		Business Owner
																	</option>
																	<option key={Math.random()} value="employee">
																		Employee
																	</option>
																	<option key={Math.random()} value="other">
																		Other
																	</option>
																</React.Fragment>
															}
														/>
													</div>

													<div className="col-lg-4">
														<SelectBox
															name="job_position"
															value={this.state.formInputs.job_position || ""}
															label={texts["job-position"]}
															onBlur={this.handleBlur}
															onChange={this.handleChange}
															onFocus={this.handleFocus}
															validationError={
																this.state.validationErrors.job_position || ""
															}
															selectBoxOptions={
																// TODO: Move these values to a separte config file
																<React.Fragment>
																	<option key={Math.random()} value="junior">
																		Junior
																	</option>
																	<option key={Math.random()} value="senior">
																		Senior
																	</option>
																	<option
																		key={Math.random()}
																		value="team-leader"
																	>
																		Team Leader
																	</option>
																</React.Fragment>
															}
														/>
													</div>

													<div className="col-lg-4">
														<SelectBox
															name="work_sector"
															value={this.state.formInputs.work_sector || ""}
															label={texts["work-sector"]}
															onBlur={this.handleBlur}
															onChange={this.handleChange}
															onFocus={this.handleFocus}
															validationError={
																this.state.validationErrors.work_sector || ""
															}
															selectBoxOptions={
																// TODO: Move these values to a separte config file
																<React.Fragment>
																	<option
																		key={Math.random()}
																		value="public-sector"
																	>
																		Public Sector
																	</option>
																	<option
																		key={Math.random()}
																		value="private-sector"
																	>
																		Private Sector
																	</option>
																</React.Fragment>
															}
														/>
													</div>
												</div>
											</div>
										</div>

										<div className="row">
											<div className="col-lg-4">
												<SelectBox
													name="preferred_sectors_to_invest_in"
													value={
														this.state.formInputs
															.preferred_sectors_to_invest_in || ""
													}
													label={texts["preferred-sectors-to-invest-in"]}
													onBlur={this.handleBlur}
													onChange={this.handleChange}
													onFocus={this.handleFocus}
													required={false}
													validationError={
														this.state.validationErrors
															.preferred_sectors_to_invest_in || ""
													}
													selectBoxOptions={
														// TODO: Move these values to a separte config file
														<React.Fragment>
															<option key={Math.random()} value="manufacturing">
																Manufacturing
															</option>
															<option key={Math.random()} value="commercial">
																Commercial
															</option>
															<option key={Math.random()} value="service">
																Service
															</option>
															<option key={Math.random()} value="any-sector">
																Any Sector
															</option>
														</React.Fragment>
													}
												/>
											</div>

											<div className="col-lg-4">
												<SelectBox
													name="preferred_duration_of_investment"
													value={
														this.state.formInputs
															.preferred_duration_of_investment || ""
													}
													label={texts["preferred-duration-of-investment"]}
													onBlur={this.handleBlur}
													onChange={this.handleChange}
													onFocus={this.handleFocus}
													required={false}
													validationError={
														this.state.validationErrors
															.preferred_duration_of_investment || ""
													}
													selectBoxOptions={
														// TODO: Move these values to a separte config file
														<React.Fragment>
															<option key={Math.random()} value="1-3">
																1-3 months
															</option>
															<option key={Math.random()} value="4-6">
																4-6 months
															</option>
															<option key={Math.random()} value="7-9">
																7-9 months
															</option>
															<option key={Math.random()} value="10-12">
																10-12 months
															</option>
															<option key={Math.random()} value="any-duration">
																Any Duration
															</option>
														</React.Fragment>
													}
												/>
											</div>
										</div>

										<div className="row">
											<div className="col-lg-4">
												<TextBox
													name="preferred_investment_amount"
													label={texts["preferred-amount-to-invest"]}
													value={
														this.state.formInputs.preferred_investment_amount ||
														""
													}
													onBlur={this.handleBlur}
													onChange={this.handleChange}
													onFocus={this.handleFocus}
													required={false}
													validationError={
														this.state.validationErrors
															.preferred_investment_amount || ""
													}
													placeholder="20000"
													extraCssClasses="x-dr-ltr x-ta-l"
												/>
											</div>

											<div className="col-lg-4">
												<SelectBox
													name="are_investments_from_legal_sources"
													value={
														this.state.formInputs
															.are_investments_from_legal_sources || ""
													}
													label={texts["are-investments-from-legal-sources"]}
													onBlur={this.handleBlur}
													onChange={this.handleChange}
													onFocus={this.handleFocus}
													validationError={
														this.state.validationErrors
															.are_investments_from_legal_sources || ""
													}
													selectBoxOptions={[
														<option key={Math.random()} value="yes">
															Yes
														</option>,
														<option key={Math.random()} value="no">
															No
														</option>,
													]}
												/>
											</div>
										</div>

										<div className="row">
											<div className="col-lg-4">
												<TextBox
													name="national_id_number"
													label={texts["national-id-number"]}
													value={this.state.formInputs.national_id_number || ""}
													onBlur={this.handleBlur}
													onChange={this.handleChange}
													onFocus={this.handleFocus}
													validationError={
														this.state.validationErrors.national_id_number || ""
													}
													extraCssClasses="x-dr-ltr x-ta-l"
												/>
											</div>
											<div className="col-lg-4">
												<TextBox
													name="passport_number"
													label={texts["passport-number"]}
													value={this.state.formInputs.passport_number || ""}
													required={false}
													onBlur={this.handleBlur}
													onChange={this.handleChange}
													onFocus={this.handleFocus}
													validationError={
														this.state.validationErrors.passport_number || ""
													}
													extraCssClasses="x-dr-ltr x-ta-l"
												/>
											</div>
										</div>

										<div className="row">
											<div className="col-lg-4">
												{/*
                                                <DateTimePickerBox
                                                    endYear={(new Date()).getFullYear() - 18} // Should be 18+
                                                    dateFormat="yyyy-MM-dd"
                                                    isClearable={true}
                                                    label={texts["birth-date"]}
                                                    name="birth_date"
                                                    onBlur={this.handleBlur}
                                                    onChange={(date) => { this.handleDateTimePickerChange(date, "birth_date") }}
                                                    onFocus={this.handleFocus}
                                                    required={true}
                                                    showCustomHeader={true}
                                                    showTimeInput={false}
                                                    startYear={(new Date()).getFullYear() - 80} // Works with renderCustomHeader
                                                    validationError={this.state.validationErrors.birth_date || ""}
                                                    value={this.state.formInputs.birth_date || ""}
                                                />
                                                */}
												<TextBox
													extraCssClasses="x-dr-ltr x-ta-l"
													label={texts["birth-date"]}
													name="birth_date"
													onBlur={this.handleBlur}
													onChange={this.handleChange}
													onFocus={this.handleFocus}
													placeholder="1980-01-31"
													required={true}
													validationError={
														this.state.validationErrors.birth_date || ""
													}
													value={this.state.formInputs.birth_date || ""}
												/>
											</div>
										</div>

										<div className="row">
											<div className="col-lg-4">
												<SelectBox
													name="invested_before"
													value={this.state.formInputs.invested_before || ""}
													label={texts["invested-before"]}
													onBlur={this.handleBlur}
													onChange={this.handleChange}
													onFocus={this.handleFocus}
													validationError={
														this.state.validationErrors.invested_before || ""
													}
													selectBoxOptions={[
														<option key={Math.random()} value="yes">
															Yes
														</option>,
														<option key={Math.random()} value="no">
															No
														</option>,
													]}
												/>
											</div>

											<div className="col-lg-4">
												<SelectBox
													name="has_bank_account"
													value={this.state.formInputs.has_bank_account || ""}
													label={texts["has-bank-account"]}
													onBlur={this.handleBlur}
													onChange={this.handleChange}
													onFocus={this.handleFocus}
													validationError={
														this.state.validationErrors.has_bank_account || ""
													}
													selectBoxOptions={[
														<option key={Math.random()} value="yes">
															Yes
														</option>,
														<option key={Math.random()} value="no">
															No
														</option>,
													]}
												/>
											</div>
										</div>

										<div className="row">
											<div className="col-lg-4">
												<div className="x-mt-20">
													<CustomButton
														type="button"
														label={
															this.state.showButtonLoader ? (
																<ButtonLoader />
															) : (
																GeneralTexts["save"]
															)
														}
														disabled={this.validateFormInputs() ? true : false}
														onClick={this.handleSubmit}
													/>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>

						<RequiredDataHint
							className={this.state.showPageLoader ? "x-d-n" : ""}
						/>
					</div>

					<MessageModal
						show={this.state.messageModal.show || false}
						title={this.state.messageModal.title || null}
						body={this.state.messageModal.body || null}
						alertClassName={this.state.messageModal.alertClassName || null}
						size={this.state.messageModal.size || null}
						centered={this.state.messageModal.centered || false}
						footerButtons={this.state.messageModal.footerButtons || []}
						onHide={
							this.state.messageModal.onHide ||
							(() =>
								this.setState({
									messageModal: { ...this.initialState.messageModal },
								}))
						}
					/>
				</div>
			</React.Fragment>
		);

		return (
			<PageContent
				pageContent={pageContent}
				authenticatedUser={this.getAuthenticatedUser()}
				showPageContent={this.showPageContent()}
				windowLocation={window.location}
			/>
		);
	}
}
