import React from "react";

import PageHandler from "./../../shared/PageHandler";
import PageContent from "./../../shared/PageContent";

// Our Services
import documentTitleService from "./../../../services/document-title-service";

export default class LenderOverview extends PageHandler {
    initialState = {
        ...this.initialStateElements,
        allowedUserType: "lender",
    };

    // -----------------------------------------------

    // Clone a separate copy of the "initialState" and assign it to the "state".
    // Note that the spread operator "..." is important to get a separate copy.
    state = { ...this.initialState };

    // -----------------------------------------------

    componentDidMount() {
        // More info: t.ly/ZVsd
        window.scrollTo(0, 0);

        // ----------------------

        // Properly set the browser's document title
        documentTitleService.setTitle("Lender Dashboard | Overview");

        // ----------------------

        this.setAuthenticatedUser();
    }

    // -----------------------------------------------

    render() {
        const pageContent = (
            <React.Fragment>
                <h5 className="x-ta-c x-pt-50">
                    Lender Dashboard
                </h5>

                <h5 className="x-ta-c x-pt-20 x-c-crimson">
                    Overview
                    {/* {this.props.authenticatedUser?.first_name} {this.props.authenticatedUser?.last_name} */}
                </h5>
            </React.Fragment>
        );

        return (
            <PageContent
                pageContent={pageContent}
                authenticatedUser={this.getAuthenticatedUser()}
                showPageContent={this.showPageContent()}
                windowLocation={window.location}
            />
        );
    }
}
