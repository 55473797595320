import React from "react";
import { Link } from "react-router-dom";

import { switchClasses } from "./../../languages/CssClasses";
import { LoanCardTexts as texts } from "./../../languages/LoanCardTexts";

// Third-Party Modules
import moment from "moment";
import numeral from "numeral"

// Our Configs
import { getRoutePath } from "./../../configs/route-config";

const LoanCard = (props) => {
    const requiredAmount            = props.loan.required_amount;
    const pledgedAmount             = props.loan.pledged_amount;
    const remainingAmount           = requiredAmount - pledgedAmount;
    const pledgedAmountPercentage   = ((pledgedAmount / requiredAmount) * 100).toFixed(0);
    const remainingAmountPercentage = (((requiredAmount - pledgedAmount) / requiredAmount) * 100).toFixed(0);

    return (
        <div className="col-md-4 x-pb-30">
            <div className="card mb-5 mb-lg-0">
                <div className={"card-body __ff-droidarabickufi " + switchClasses(["x-fz-085rem"])}>
                    <h5 className="text-muted text-center x-pt-15 x-fz-08rem x-fw-n x-m-0 ___ff-rubik">{texts["loan-amount"]}</h5>
                    <h6 className="card-price text-center x-dr-ltr x-c-000 x-pt-5 __ff-rubik">{numeral(requiredAmount).format("0,0") || 0}<span className="period x-c-999">{props.loan.currency}</span></h6>
                    <div className={"progress x-mt-20 x-bdrs-10 " + switchClasses(["x-dr-rtl"])} style={{ height: "0.8rem" }}>
                        <div className="progress-bar" role="progressbar" style={{ width: pledgedAmountPercentage + "%", backgroundColor: "#00CC6F" }} aria-valuenow={pledgedAmountPercentage} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>

                    <ul className="list-unstyled x-p-0 x-mt-40">
                        <li className="clearfix">
                            <span className={"x-d-ib " + switchClasses(["x-fl-r"])}>{texts["pledged"]}</span>
                            <span className={"x-d-ib x-fw-b x-fz-094rem " + switchClasses(["x-fl-l", "x-dr-rtl"])}>
                                <span className="x-fw-n x-fz-08rem text-success">({pledgedAmountPercentage + "%"})</span>
                                &nbsp;{(numeral(pledgedAmount).format("0,0")) || 0}
                                &nbsp;<span className="x-fw-n x-fz-08rem x-c-999">{props.loan.currency}</span>
                            </span>
                        </li>
                        <hr />

                        <li className="clearfix">
                            <span className={"x-d-ib " + switchClasses(["x-fl-r"])}>{texts["remaining"]}</span>
                            <span className={"x-d-ib x-fw-b x-fz-094rem " + switchClasses(["x-fl-l", "x-dr-rtl"])}>
                                <span className="x-fw-n x-fz-08rem text-danger">({remainingAmountPercentage + "%"})</span>
                                &nbsp;{(numeral(remainingAmount).format("0,0")) || 0}
                                &nbsp;<span className="x-fw-n x-fz-08rem x-c-999">{props.loan.currency}</span>
                            </span>
                        </li>
                        <hr />

                        <li className="clearfix">
                            <span className={"x-d-ib " + switchClasses(["x-fl-r"])}>{texts["loan-duration"]}</span>
                            <span className={"x-d-ib x-fw-b x-fz-094rem " + switchClasses(["x-fl-l", "x-dr-rtl"])}>
                                {props.loan.loan_duration_in_months} <span className="x-c-999 x-fw-n x-fz-08rem">{texts["months"]}</span>
                            </span>
                        </li>
                        <hr />

                        <li className="clearfix">
                            <span className={"x-d-ib " + switchClasses(["x-fl-r"])}>{texts["loan-purpose"]}</span>
                            <span className={"x-d-ib x-fw-b " + switchClasses(["x-fl-l", "x-dr-rtl"])}>
                                {props.loan.loan_purpose}
                            </span>
                        </li>
                        <hr />

                        <li className="clearfix">
                            <span className={"x-d-ib " + switchClasses(["x-fl-r"])}>{texts["days-left"]}</span>
                            <span className={"x-d-ib x-fw-b x-fz-094rem " + switchClasses(["x-fl-l", "x-dr-rtl"])}>
                                {(moment(props.loan.crowdfunding_campaign_ends_at)).diff(moment().utc(), "days")}
                                &nbsp;<span className="x-fw-n x-c-999 x-fz-08rem">{texts["days"]}</span>
                            </span>
                        </li>
                        <hr />
                    </ul>

                    <div className="x-pt-5 x-pb-0 x-ta-c">
                        <Link to={getLoanDetailsUrl(props)} className="link-color">
                            {texts["full-details"]}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

// -----------------------------------------------

const getLoanDetailsUrl = (props) => {
    const loan = props.loan;
    return getRoutePath("loanDetails") + "/" + loan.uuid;

    // NOTE: the following is the old way of getting the loan details url. It's disabled
    // because we no longer redirect the user to the loan details page according to his type
    // We, now, redirect the the users of any type to the loan details page.
    // The following code is kept here till we make sure everything works fine.

    // const authenticatedUser = props.authenticatedUser || {};
    // switch (authenticatedUser.user_type) {
    //     case "admin":
    //         return getRoutePath("adminLoanBasic") + "/" + loan.uuid;

    //     case "lender":
    //         return getRoutePath("lenderLoanDetails") + "/" + loan.uuid;

    //     case "borrower":
    //         // Borrower shouldn't be able to see details of a loan that doesn't
    //         // belong to him so we will redirect him to his loan list instead.
    //         return getRoutePath("borrowerLoanList");

    //     default:
    //         return getRoutePath("login");
    // }
}

// -----------------------------------------------

export default LoanCard;
