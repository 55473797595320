import { preferredLanguage } from "./../configs/ui-language-config";
import { GeneralTexts } from "./GeneralTexts";
import { RouteConfigTexts } from "./RouteConfigTexts";

const texts = {
    ar: {
        "admin-overview-general": RouteConfigTexts["admin-overview-general"],
        "admin-overview-users"  : RouteConfigTexts["admin-overview-users"],
        "all-time"              : GeneralTexts["all-time"],
        "today"                 : GeneralTexts["today"],
        "yesterday"             : GeneralTexts["yesterday"],
        "last-7-days"           : GeneralTexts["last-7-days"],
        "last-30-days"          : GeneralTexts["last-30-days"],
        "users"                 : "المستخدمين",
        "all-users"             : "المستخدمين",
        "users-of-all-types"    : "المستخدمين من جميع الأنواع",
        "borrowers"             : "المقترضين",
        "users-of-type-borrower": "المستخدمين من نوع المقترض",
        "investors"             : "المستثمرين",
        "users-of-type-investor": "المستخدمين من نوع المستثمر",
        "admins"                : "المديرين",
        "users-of-type-admin"   : "المستخدمين من نوع الأدمن",
    },

    en: {
        "admin-overview-general": RouteConfigTexts["admin-overview-general"],
        "admin-overview-users"  : RouteConfigTexts["admin-overview-users"],
        "all-time"              : GeneralTexts["all-time"],
        "today"                 : GeneralTexts["today"],
        "yesterday"             : GeneralTexts["yesterday"],
        "last-7-days"           : GeneralTexts["last-7-days"],
        "last-30-days"          : GeneralTexts["last-30-days"],
        "users"                 : "Users",
        "all-users"             : "All Users",
        "users-of-all-types"    : "Users of All Types",
        "borrowers"             : "Borrowers",
        "users-of-type-borrower": "Users of Type Borrower",
        "investors"             : "Investors",
        "users-of-type-investor": "Users of Type Investor",
        "admins"                : "Admins",
        "users-of-type-admin"   : "Users of Type Admin",
    }
}

export const AdminOverviewUsersTexts = texts[preferredLanguage.alpha2code];
