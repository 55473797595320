import { preferredLanguage } from "./../configs/ui-language-config";
import { switchCssClasses } from "./../helpers/common-helper";

const cssClasses = {
    ar: {
        "mr-auto"    : "mr-auto",
        "x-b-3"      : "",
        "x-b-4"      : "x-b-4",
        "x-dr-rtl"   : "x-dr-rtl",
        "x-fl-l"     : "x-fl-l",
        "x-fl-r"     : "x-fl-r",
        "x-fz-075rem": "x-fz-075rem",
        "x-fz-077rem": "x-fz-077rem",
        "x-fz-07rem" : "x-fz-07rem",
        "x-fz-082rem": "x-fz-082rem",
        "x-fz-085rem": "x-fz-085rem",
        "x-fz-08rem" : "x-fz-08rem",
        "x-fz-091rem": "x-fz-091rem",
        "x-fz-097rem": "x-fz-097rem",
        "x-fz-09rem" : "x-fz-09rem",
        "x-fz-14"    : "x-fz-14",
        "x-fz-16"    : "x-fz-16",
        "x-fz-165rem": "x-fz-165rem",
        "x-fz-21"    : "x-fz-21",
        "x-fz-23"    : "x-fz-23",
        "x-fz-26"    : "x-fz-26",
        "x-l-1"      : "x-l-1",
        "x-l-15"     : "x-l-15",
        "x-l-5"      : "x-l-5",
        "x-lh-50"    : "x-lh-50",
        "x-ml-15"    : "x-ml-15",
        "x-mr-10"    : "x-mr-10",
        "x-mr-15"    : "x-mr-15",
        "x-mr-20"    : "x-mr-20",
        "x-mr-5"     : "x-mr-5",
        "x-pl-10"    : "x-pl-10",
        "x-pl-20"    : "x-pl-20",
        "x-pr-10"    : "x-pr-10",
        "x-pr-20"    : "x-pr-20",
        "x-pr-40"    : "x-pr-40",
        "x-pr-5"     : "x-pr-5",
        "x-pr-60"    : "x-pr-60",
        "x-r-140"    : "x-r-140",
        "x-r-50"     : "x-r-50",
        "x-r-95"     : "x-r-95",
        "x-ta-l"     : "x-ta-l",
        "x-ta-r"     : "x-ta-r",
    },

    en: {
        "mr-auto"    : "ml-auto",
        "x-b-3"      : "x-b-3",
        "x-b-4"      : "x-b-1",
        "x-dr-rtl"   : "x-dr-ltr",
        "x-fl-l"     : "x-fl-r",
        "x-fl-r"     : "x-fl-l",
        "x-fz-075rem": "x-fz-08rem",
        "x-fz-077rem": "x-fz-082rem",
        "x-fz-07rem" : "x-fz-075rem",
        "x-fz-082rem": "x-fz-087rem",
        "x-fz-085rem": "x-fz-094rem",
        "x-fz-08rem" : "x-fz-085rem",
        "x-fz-091rem": "x-fz-1rem",
        "x-fz-097rem": "x-fz-11rem",
        "x-fz-09rem" : "x-fz-094rem",
        "x-fz-14"    : "x-fz-15",
        "x-fz-16"    : "x-fz-19",
        "x-fz-165rem": "x-fz-17rem",
        "x-fz-21"    : "x-fz-25",
        "x-fz-23"    : "x-fz-27",
        "x-fz-26"    : "x-fz-36",
        "x-l-1"      : "x-r-1",
        "x-l-15"     : "x-r-15",
        "x-l-5"      : "x-r-5",
        "x-lh-50"    : "x-lh-46",
        "x-ml-15"    : "x-mr-15",
        "x-mr-10"    : "x-ml-10",
        "x-mr-15"    : "x-ml-15",
        "x-mr-20"    : "x-ml-20",
        "x-mr-5"     : "x-ml-5",
        "x-pl-10"    : "x-pr-10",
        "x-pl-20"    : "x-pr-20",
        "x-pr-10"    : "x-pl-10",
        "x-pr-20"    : "x-pl-20",
        "x-pr-40"    : "x-pl-40",
        "x-pr-5"     : "x-pl-5",
        "x-pr-60"    : "x-pl-60",
        "x-r-140"    : "x-l-140",
        "x-r-50"     : "x-l-50",
        "x-r-95"     : "x-l-95",
        "x-ta-l"     : "x-ta-r",
        "x-ta-r"     : "x-ta-l",
    }
}

export function switchClasses(cssClassesToSwitch) {
    return switchCssClasses(cssClassesToSwitch, cssClasses[preferredLanguage.alpha2code]);
}
