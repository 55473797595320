import React from "react";

const TextBox = (props) => {
    // const {type, name, label, value, className, autoFocus, validationError, ...restProps } = props;
    // console.log(restProps);

    return (
        <div className="form-group x-pt-15">
            <label htmlFor={props.name}>
                {props.label}
                {
                    typeof props.required === "undefined" || props.required === true
                        ? <span className="x-c-red"> *</span>
                        : ""
                }
            </label>
            <input
                autoComplete={props.autoComplete || "off"}
                autoFocus={props.autoFocus || false}
                className={props.className || "form-control x-bdrs-2 x-pt-21 x-pb-21 " + (props.extraCssClasses || "")}
                disabled={props.disabled || false}
                id={props.name}
                name={props.name}
                onBlur={props.onBlur || (() => { })}
                onChange={props.onChange || (() => { })}
                onFocus={props.onFocus || (() => { })}
                onKeyPress={props.onKeyPress || (() => { })}
                placeholder={props.placeholder || null}
                type={props.type || "text"}
                value={props.value}
            // {...restProps}
            />
            <small className="form-text text-danger">{props.validationError || null}</small>
        </div>
    );
};

export default TextBox;
