// Our Services
import http from "./http-service";

// Our Configs
import { getApiEndpoint } from "./../configs/api-config";

// -----------------------------------------------

// export const getLenderLoans = (urlQueryString = "") => {
//     return http.get(getApiEndpoint("lenderLoans").path + urlQueryString);
// }

// -----------------------------------------------

// export const getItem = (uuid, urlQueryString = "") => {
//     return http.get(getApiEndpoint("lenderDetails").path + "/" + uuid + urlQueryString);
// }

// -----------------------------------------------

export const getMyDataAsLender = (urlQueryString = "") => {
    return http.get(getApiEndpoint("lenderDetails").path + urlQueryString);
}

// -----------------------------------------------

export const loanCreate = (data) => {
    return http.post(
        getApiEndpoint("lenderLoanCreate").path,
        data
    );
}

// -----------------------------------------------

export const update = (id, data) => {
    return http.put(
        getApiEndpoint("lenderUpdate").path + "/" + id,
        data
    );
}
