import { GeneralTexts } from "../languages/GeneralTexts";

const mobiles = {
    "info": process.env.REACT_APP_COMPANY_INFO_MOBILE_NUMBER,
    "support": process.env.REACT_APP_COMPANY_SUPPORT_MOBILE_NUMBER,
};

// key is like "info", "support", ...
export function getMobile(key) {
    return mobiles[key];
}

// -----------------------------------------------

const whatsapp = {
    "info": process.env.REACT_APP_COMPANY_WHATSAPP_INFO_MOBILE_NUMBER,
    "support": process.env.REACT_APP_COMPANY_WHATSAPP_SUPPORT_MOBILE_NUMBER,
};

// key is like "info", "support", ...
export function getWhatsapp(key) {
    return whatsapp[key];
}

// -----------------------------------------------

const emails = {
    "admin"  : process.env.REACT_APP_COMPANY_ADMIN_EMAIL,
    "info"   : process.env.REACT_APP_COMPANY_INFO_EMAIL,
    "support": process.env.REACT_APP_COMPANY_SUPPORT_EMAIL,
};

// key is like "info", "support", ...
export function getEmail(key) {
    return emails[key];
}

// -----------------------------------------------

const socialMediaLinks = {
    "facebook" : process.env.REACT_APP_COMPANY_FACEBOOK,
    "twitter"  : process.env.REACT_APP_COMPANY_TWITTER,
    "linkedin" : process.env.REACT_APP_COMPANY_LINKEDIN,
    "instagram": process.env.REACT_APP_COMPANY_INSTAGRAM,
    "youtube"  : process.env.REACT_APP_COMPANY_YOUTUBE,
};

// key is like "facebook", "twitter", ...
export function getSocialMediaLink(key) {
    return socialMediaLinks[key];
}

// -----------------------------------------------

// Keep the numbers as strings
const numbersOfResultsPerPage = ["1", "5", "10", "15", "20", "25", "50"];

export function getNumbersOfResultsPerPage(maxNumber = 25) {
    let numbers = numbersOfResultsPerPage.filter(number => Number(number) <= maxNumber);
    return numbers;
}

// -----------------------------------------------

const pagination = {
    "currentPage": 1,
    "numberOfItemsPerPage": 25,
}

export function getPagination() {
    return pagination;
}

// key is like "numberOfItemsPerPage", ...
// export function getPaginationItem(key) {
//     return pagination[key] || null;
// }

export function getPaginator($paginator) {
    return {
        "total"         : $paginator["total"],
        "per_page"      : $paginator["per_page"],
        "current_page"  : $paginator["current_page"],
        "last_page"     : $paginator["last_page"],

        "first_page_url": $paginator["first_page_url"],
        "last_page_url" : $paginator["last_page_url"],
        "next_page_url" : $paginator["next_page_url"],
        "prev_page_url" : $paginator["prev_page_url"],
        "path"          : $paginator["path"],
        "from"          : $paginator["from"],
        "to"            : $paginator["to"]
    };
}

// -----------------------------------------------

const possibleSortOrders = {
    "createdAt": [
        {
            label: GeneralTexts["created-at-descending"],
            value: "created_at,desc"
        },
        {
            label: GeneralTexts["created-at-ascending"],
            value: "created_at,asc"
        },
    ],
    "updatedAt": [
        {
            label: GeneralTexts["updated-at-descending"],
            value: "updated_at,desc"
        },
        {
            label: GeneralTexts["updated-at-ascending"],
            value: "updated_at,asc"
        },
    ],
    "lastLoginAt": [
        {
            label: GeneralTexts["last-login-at-descending"],
            value: "last_login_at,desc"
        },
        {
            label: GeneralTexts["last-login-at-ascending"],
            value: "last_login_at,asc"
        },
    ],
};

export function getSortOrders(sortOrderKeys = ["createdAt", "updatedAt"]) {
    let sortOrders = [];

    sortOrderKeys.forEach(key => {
        sortOrders = sortOrders.concat(possibleSortOrders[key]);
    });

    return sortOrders;
}
