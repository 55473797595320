import React from "react";

const PaginationSeparator = (props) => {
	if (props.isHidden) {
		return null;
	}

	return (
		<li className={(props.className || "page-item ") + (" " + (props.extraCssClasses || ""))}>
			<div className="page-link">
				{"..."}
			</div>
		</li>
	);
};

export default PaginationSeparator;
