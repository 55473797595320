import React from "react";
import { NavLink } from "react-router-dom";

import { switchClasses } from "./../../../languages/CssClasses";
import { LenderProfileSubMenuTexts as texts } from "./../../../languages/LenderProfileSubMenuTexts";

// Our Configs
import { getRoutePath } from "./../../../configs/route-config";

export default class LenderProfileSubMenu extends React.Component {
    state = {};

    render() {
        return (
            <div className={"list-group shadow-sm x-mt-15 x-mb-30 __ff-droidarabickufi " + switchClasses(["x-fz-08rem"])}>
                <NavLink to={getRoutePath("lenderBasicProfile")} className="list-group-item list-group-item-action _active">{texts["my-basic-profile"]}</NavLink>
                <NavLink to={getRoutePath("lenderAdvancedProfile")} className="list-group-item list-group-item-action">{texts["my-advanced-profile"]}</NavLink>
                <NavLink to={getRoutePath("lenderChangePassword")} className="list-group-item list-group-item-action">{texts["change-my-password"]}</NavLink>
            </div>
        );
    }
}
