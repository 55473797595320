import React from "react";
import _range from "lodash/range"

function getNumberOfPages(props) {
	let numberOfPages = 0;

	if (Number(props.paginator?.last_page)) {
		return numberOfPages = Number(props.paginator?.last_page);
	}

	if (Number(props.paginator?.total) && Number(props.paginator?.per_page)) {
		// If the division result is a floating-point number, Math.ceil() method will
		// return the nearest integer that is greater than that floating-point number
		return numberOfPages = Math.ceil(props.paginator?.total / props.paginator?.per_page);
	}

	return numberOfPages;
}

const Pagination2 = (props) => {
	const numberOfPages = Number(getNumberOfPages(props));

	// If number of pages is 1, DON'T return the pagination
	if (numberOfPages === 1) return null;

	// Get an array containing the page numbers starting from page number 1
	const pageNumbers = _range(1, numberOfPages + 1);

	return (
		<nav>
			<ul className="pagination justify-content-center">
				{pageNumbers.map(pageNumber => {
					return (
						<li className={"page-item " + (Number(props.paginator?.current_page) === pageNumber ? " active" : "")} key={Math.random()}>
							<div
								className="page-link x-cur-p"
								onClick={props.onClick || (() => {})}
							>
								{pageNumber}
							</div>
						</li>
					)
				})}
			</ul>
		</nav>
	);
};

export default Pagination2;
