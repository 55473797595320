// React Modules
import React from "react";
import { Link } from "react-router-dom";

import { switchClasses } from "./../languages/CssClasses";
import { LoginTexts as texts } from "./../languages/LoginTexts";
import { RouteConfigTexts } from "./../languages/RouteConfigTexts";

// Third-Party Modules
// import _toLower from "lodash/toLower";
import Joi from "@hapi/joi";
import queryString from "query-string";

// Our Modules
import MessageModal, { toggleMessageModal, getErrorMessage } from "./shared/MessageModal";
import PageContent from "./shared/PageContent";
import FormHandler from "./shared/FormHandler";
import TextBox from "./shared/TextBox";
import CustomButton from "./shared/CustomButton";
import ButtonLoader from "./shared/ButtonLoader";

// Our Services
import logService from "./../services/log-service";
import * as authService from "./../services/auth-service";
// import * as userService from "./../services/user-service";
import documentTitleService from "./../services/document-title-service";

// Our Configs
import { getRoutePath, getDashboardLandingPage, redirectToTheProperRoutePath } from "./../configs/route-config";

export default class Login extends FormHandler {

    // Get query string as a object
    urlQueryString = queryString.parse(this.props.location.search);

    // -----------------------------------------------

    initialState = {
        ...this.initialStateElements,
        isThisPagePublic: true,

        formInputs: {
            email   : this.urlQueryString.email || "",
            password: "",
        },
        validationErrors: {},
        messageModal    : {},
        showButtonLoader: false,
    };

    // -----------------------------------------------

    // Clone a separate copy of the "initialState" and assign it to the "state".
    // Note that the spread operator "..." is important to get a separate copy.
    state = { ...this.initialState };

    // -----------------------------------------------

    componentDidMount() {
        // More info: t.ly/ZVsd
        window.scrollTo(0, 0);

        // ----------------------

        // Properly set the browser's document title
        documentTitleService.setTitle(RouteConfigTexts["login"]);

        // ----------------------

        // await this.setAuthenticatedUser();
        this.redirectToUserDashboardIfAlreadyAuthenticated();
    }

    // -----------------------------------------------

    redirectToUserDashboardIfAlreadyAuthenticated = () => {
        const authenticatedUser = this.getAuthenticatedUser();

        if (authenticatedUser) {
            const userDashboardLandingPage = getDashboardLandingPage(authenticatedUser);
            this.props.history.push(userDashboardLandingPage);
        }
    }

    // -----------------------------------------------

    doSubmit = async (e) => {
        this.setState({ showButtonLoader: true });

        try {
            const response = await authService.login(this.state.formInputs);

            const currentUser = response.data.data.user;

            /**
             * Redirect the user to his dashboard.
             *
             * "window.location" will cause a full reload of the application on the
             * specified page. This is important, otherwise an error will occur.
             *
             * Also, note that "state.from.pathname" will redirect to the page which
             * we came to the login page from.
             */
            // const { state } = this.props.location;
            // window.location = state ? state.from.pathname : getDashboardLandingPage(currentUser);

            // Full reload is important, otherwise an error will occur
            // window.location = getDashboardLandingPage(currentUser);
            redirectToTheProperRoutePath(currentUser);
        } catch (error) {
            /**
             * If there is an unexpected error like: network down, server down, db down, bug, ..
             *  - Log the error.
             *  - Display a generic and friendly error message.
             *
             * Note that "error.response" determines if the error is an expected error or not.
             */

            const isExpectedError =
                error.response
                && error.response.status >= 400
                && error.response.status < 500;

            if (!isExpectedError) {
                logService.log(error);
            }

            console.log("error: ", error)

            this.showErrorMessage(error);
        }

        this.setState({ showButtonLoader: false });
    };

    // -----------------------------------------------

    // getDashboardLandingPage = (currentUser) => {
    //     switch (_toLower(currentUser.user_type)) {
    //         case "borrower":
    //             // TODO: Enable this path when it's done
    //             // return getRoutePath("borrowerOverview");
    //             return getRoutePath("borrowerLoanList");

    //         case "lender":
    //             // TODO: Enable this path when it's done
    //             // return getRoutePath("lenderOverview");
    //             return getRoutePath("lenderLoanList");

    //         case "admin":
    //             // return getRoutePath("adminOverviewGeneral");
    //             return getRoutePath("adminUserList");

    //         default:
    //             return getRoutePath("home");
    //     }
    // }

    // -----------------------------------------------



    // -----------------------------------------------

    showErrorMessage = (error, props = this.props) => {
        this.setState({
            messageModal: {
                ...toggleMessageModal("error"),
                body: getErrorMessage(error, props).body || "",
                footerButtons: getErrorMessage(error, props).footerButtons || [],
            }
        });
    }

    // -----------------------------------------------

    validationRules = {
        email: Joi.string()
            .required()
            .email({ tlds: false })
            .max(50)
            .label("Email Address"),

        password: Joi.string()
            .required()
            .min(6)
            .label("Password"),
    };

    // -----------------------------------------------

    render() {
        // this.redirectToUserDashboardIfAlreadyAuthenticated();

        const pageContent = (
            <section className="x-pb-100 __ff-droidarabickufi">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-lg"></div>
                        <div className="col-lg">
                            <div className="_border _border-light shadow-sm x-bdc-ddd x-bdrs-4 x-mt-80 x-p-30 x-pt-40 x-pb-50 x-bgc-fff">
                                <h4 className="x-ta-c">{texts["login"]}</h4>

                                <form className={"x-mt-40 " + switchClasses(["x-fz-085rem"])} onSubmit={(e) => e.preventDefault()}>
                                    <TextBox
                                        name="email"
                                        label={texts["email-address"]}
                                        value={this.state.formInputs.email || ""}
                                        // autoFocus={this.state.formInputs.email ? false : true}
                                        onBlur={this.handleBlur}
                                        onChange={this.handleChange}
                                        onFocus={this.handleFocus}
                                        validationError={this.state.validationErrors.email || ""}
                                        extraCssClasses="x-dr-ltr x-ta-l"
                                    />

                                    <TextBox
                                        name="password"
                                        type="password"
                                        label={texts["password"]}
                                        value={this.state.formInputs.password || ""}
                                        // autoFocus={this.state.formInputs.email ? true : false}
                                        onBlur={this.handleBlur}
                                        onChange={this.handleChange}
                                        onFocus={this.handleFocus}
                                        onKeyPress={this.handleKeyPress}
                                        validationError={this.state.validationErrors.password || ""}
                                        extraCssClasses="x-dr-ltr x-ta-l"
                                    />

                                    <div className="x-mt-20">
                                        <CustomButton
                                            type="button"
                                            label={this.state.showButtonLoader ? <ButtonLoader /> : "Login"}
                                            disabled={this.validateFormInputs() ? true : false}
                                            onClick={this.handleSubmit}
                                        />
                                    </div>
                                </form>
                            </div>

                            <div className={"row x-p-0 x-pt-15 " + switchClasses(["x-fz-08rem"])}>
                                <div className="col">
                                    <Link to={getRoutePath("passwordForgot")}>{texts["forgot-my-password"]}</Link>
                                </div>
                                <div className={"col " + switchClasses(["x-ta-l"])}>
                                    <Link to={getRoutePath("preSignup")}>{texts["create-new-account"]}</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg"></div>
                    </div>
                </div>

                <MessageModal
                    show={this.state.messageModal.show || false}
                    title={this.state.messageModal.title || null}
                    body={this.state.messageModal.body || null}
                    alertClassName={this.state.messageModal.alertClassName || null}
                    size={this.state.messageModal.size || null}
                    centered={this.state.messageModal.centered || false}
                    footerButtons={this.state.messageModal.footerButtons || []}
                    onHide={this.state.messageModal.onHide || (() => this.setState({ messageModal: { ...this.initialState.messageModal } }))}
                />
            </section>
        );

        return (
            <PageContent
                pageContent={pageContent}
                authenticatedUser={this.getAuthenticatedUser()}
                showPageContent={this.showPageContent()}
                windowLocation={window.location}
            />
        );
    }
}
