import React from "react";

const TextareaBox = (props) => {
    return (
        <div className="form-group x-pt-15">
            <label htmlFor={props.name}>
                {props.label}
                {
                    typeof props.required === "undefined" || props.required === true
                        ? <span className="x-c-red"> *</span> 
                        : ""
                }
            </label>
            <textarea
                autoFocus={props.autoFocus || false}
                className={props.className || "form-control x-bdrs-2 x-pt-10 x-pb-10 x-fz-085rem " + (props.extraCssClasses || "")}
                cols={props.cols || 30}
                id={props.name}
                name={props.name}
                onBlur={props.onBlur || (() => { })}
                onChange={props.onChange || (() => { })}
                onFocus={props.onFocus || (() => { })}
                placeholder={props.placeholder || null}
                rows={props.rows || 5}
                value={props.value}
            >
            </textarea>
            <small className="form-text text-danger">{props.validationError || null}</small>
        </div>
    );
};

export default TextareaBox;
