import React from "react";
import { preferredLanguage } from "../configs/ui-language-config";
import { GeneralTexts } from "./../languages/GeneralTexts";
// import { RouteConfigTexts } from "./RouteConfigTexts";
import { BorrowerFirstLoanApplicationWizardSubMenuTexts as wizardTexts } from "./../languages/BorrowerFirstLoanApplicationWizardSubMenuTexts";

const texts = {
    ar: {
        "my-uploaded-documents"                       : "مستنداتي",
        "user-national-id-card-image"                 : "بطاقة الرقم القومي",
        "company-commercial-register-image"           : "السجل التجاري للشركة",
        "company-tax-card-image"                      : "البطاقة الضريبية للشركة",
        "user-passport-image"                         : "جواز السفر (إن وجد)",
        "company-operating-licenses-images"           : "تراخيص مزاولة النشاط المختلفة (إن وجد)",
        "company-vat-registration-certificate-image"  : "شهادة تسجيل ضريبة القيمة المضافة (إن وجد)",
        "company-industrial-license-image"            : "رخصة النشاط الصناعي (إن وجد)",
        // "click-the-file-name-or-icon-to-show-it"      : "اضغط على اسم أو أيقونة الملف الذي تريد عرضه.",
        "click-the-file-name-or-icon-to-show-it"      : "اضغط على اسم أو أيقونة الملف ليتم عرضه.",
        // "all-files-should-be-of-type-pdf"             : "جميع الملفات المرفوعة يجب أن تكون من نوع PDF.",
        "all-files-should-be-of-type-pdf"             : "الملف المرفوع يجب أن يكون من نوع PDF.",
        // "to-replace-an-existing-file-upload-a-new-one": "لتبديل ملف مرفوع من قبل بنسخة أحدث، اضغط الزر الموجود أسفل الملف، ثم اختر الملف الجديد، وسيتم رفعه واستخدامه تلقائيا بدلا من الملف القديم.",
        "to-replace-an-existing-file-upload-a-new-one": "لتبديل الملف الحالي بنسخة أحدث، اضغط الزر الموجود أسفل الملف، ثم اختر الملف الجديد، وسيتم رفعه واستخدامه تلقائيا بدلا من الملف القديم.",
        // "how-to-create-a-pdf-file-containing-images"  : "اضغط هنا لتشاهد كيف يمكن إنشاء ملف PDF يحتوى على صور.",
        "how-to-create-a-pdf-file-containing-images"  : "اضغط هنا لتشاهد كيف يمكن إنشاء ملف PDF يحتوى على صور.",
        "help-info-how-to-create-a-pdf-file-containing-images"  : "كيف يمكن إنشاء ملف PDF يحتوى على صور؟",
        "example-of-the-required-file"                : "مثال لشكل الملف",
        "step-3-hint": (
            <React.Fragment>
                <p>خطوة <span className="__ff-rubik x-fz-18">3</span> من <span className="__ff-rubik x-fz-18">4</span> &nbsp;|&nbsp; <strong>{wizardTexts["required-documents"]}</strong></p>
                <p>ارفع الصور المطلوبة التالية ثم اضغط زر <strong>{GeneralTexts["next"]}</strong> لتنتقل للخطوة التالية، أو أضغط زر <strong>{GeneralTexts["previous"]}</strong> لترجع للخطوة السابقة.</p>
            </React.Fragment>
        ),
    },

    en: {
        "my-uploaded-documents"                       : "My Documents",
        "user-national-id-card-image"                 : "National ID Card",
        "company-commercial-register-image"           : "Company Commercial Register",
        "company-tax-card-image"                      : "Company Tax Card",
        "user-passport-image"                         : "Passport (if exists)",
        "company-operating-licenses-images"           : "Company Operating Licenses (if exist)",
        "company-vat-registration-certificate-image"  : "Company VAT Registration Certificate (if exists)",
        "company-industrial-license-image"            : "Company Industrial License (if exists)",
        // "click-the-file-name-or-icon-to-show-it"      : "Click the name or the icon of the file you want to show.",
        "click-the-file-name-or-icon-to-show-it"      : "Click the name or the icon of the file to show it.",
        // "all-files-should-be-of-type-pdf"             : "All uploaded files should be PDF files.",
        "all-files-should-be-of-type-pdf"             : "The uploaded file should be a PDF file.",
        // "to-replace-an-existing-file-upload-a-new-one": "To replace an exsisting file with a newer version of it, click the button under the file and choose the new file. It will be uploaded and will be automatically used instead of the old file.",
        "to-replace-an-existing-file-upload-a-new-one": "To replace the current file with a newer version of it, click the button under the file and choose the new file. It will be uploaded and will be automatically used instead of the old file.",
        "how-to-create-a-pdf-file-containing-images"  : "Check this guide to know how to create a PDF file containing images.",
        "help-info-how-to-create-a-pdf-file-containing-images"  : "How to create a PDF file containing images?",
        "example-of-the-required-file"                : "Example of the file",
        "step-3-hint": (
            <React.Fragment>
                <p>Step <span className="__ff-rubik x-fz-18">3</span> of <span className="__ff-rubik x-fz-18">4</span> &nbsp;|&nbsp; <strong>{wizardTexts["required-documents"]}</strong></p>
                <p>Upload the following required documents and click <strong>{GeneralTexts["next"]}</strong> to move to the next step or click <strong>{GeneralTexts["previous"]}</strong> to go back to the previous step.</p>
            </React.Fragment>
        ),
    }
}

export const BorrowerDocumentListTexts = texts[preferredLanguage.alpha2code];
