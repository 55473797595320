import React from "react";
import * as userService from "./../../services/user-service";
import * as accessTokenService from "./../../services/access-token-service";

export default class PageHandler extends React.Component {

    initialStateElements = {
        isThisPagePublic : false,
        authenticatedUser: null,
        allowedUserType  : null,
    };

    setAuthenticatedUser = async () => {
        if (!this.state.isThisPagePublic) {
            accessTokenService.redirectIfAccessTokenIsMissing();
            // accessTokenService.redirectIfAccessTokenExpired();
        }

        const user = await userService.getCurrentUser();

        if (!this.state.isThisPagePublic) {
            // userService.redirectIfCouldNotRetrieveUserData(user);
            userService.redirectToLoginPageIfCouldNotRetrieveUserData(user);
        }

        this.setState({ authenticatedUser: user || null });
    }

    getAuthenticatedUser = () => {
        return this.state.authenticatedUser || (this.props.authenticatedUser || null);
    }

    showPageContent = () => {
        if (this.state.isThisPagePublic === true) return true;

        const user = this.getAuthenticatedUser();
        const userType = String(user?.user_type).toLocaleLowerCase();
        if (user && (userType === this.state.allowedUserType)) return true;

        return false;
    }
}
