import React from "react";
import { Link } from "react-router-dom";

import { preferredLanguage } from "./../../../configs//ui-language-config";
import { switchClasses } from "./../../../languages/CssClasses";
import { AdminLoanDocumentsTexts as texts } from "./../../../languages/AdminLoanDocumentsTexts";
import { GeneralTexts } from "./../../../languages/GeneralTexts";

import Icon from "@mdi/react"
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";

import { getRoutePath } from "./../../../configs/route-config";

// Our Modules
import MessageModal, { toggleMessageModal, getErrorMessage } from "./../../shared/MessageModal";
import FormHandler from "./../../shared/FormHandler";
import PageLoader from "./../../shared/PageLoader";
import RequiredDataHint from "./../../shared/RequiredDataHint";
import AdminLoanSubMenu from "./AdminLoanSubMenu";
import PageContent from "./../../shared/PageContent";

// Our Services
import documentTitleService from "./../../../services/document-title-service";
// import logService from "./../../../services/log-service";
import * as loanService from "./../../../services/loan-service";
import DocumentThumbnail from "../../shared/DocumentThumbnail";

// import defaultLoanCoverImage from "./../../../../src/resources/images/campaign-default-image-800x450.png";

export default class AdminLoanDocuments extends FormHandler {

    initialState = {
        ...this.initialStateElements,
        allowedUserType: "admin",

        validationErrors: {},
        messageModal    : {},
        showPageLoader  : true,
        showButtonLoader: false,
        loan            : {},
        documents: {
            user_national_id_card               : null,
            company_commercial_register         : null,
            user_passport                       : null,
            company_tax_card                    : null,
            company_operating_licenses          : null,
            company_vat_registration_certificate: null,
            company_industrial_license          : null,
        },
    };

    // -----------------------------------------------

    // Clone a separate copy of the "initialState" and assign it to the "state".
    // Note that the spread operator "..." is important to get a separate copy.
    state = { ...this.initialState };

    // -----------------------------------------------

    validationRules = {};

    // -----------------------------------------------

    componentDidMount() {
        // More info: t.ly/ZVsd
        window.scrollTo(0, 0);

        // ----------------------

        // Properly set the browser's document title
        documentTitleService.setTitle(GeneralTexts["admin-dashboard"] + " | " + texts["admin-loan-documents"]);

        // ----------------------

        this.setAuthenticatedUser();
        this.getLoanDocuments();
    }

    // -----------------------------------------------

    componentWillUnmount() {
        // This code fixes the warning: "Can't perform a React state update on an unmounted component'
        // More info: t.ly/Kday
        this.setState = (state, callback) => {
            return;
        };
    }

    // -----------------------------------------------

    getLoanDocuments = async () => {
        this.setState({ showPageLoader: true });

        // const response = await loanService.getItem(this.props.match.params.loanUuid, "?include=borrower.user.documents|company");
        const response = await loanService.getItem(this.props.match.params.loanUuid, "?include=borrower.user.documents");

        const loan = response.data.data.loan;
        const user = loan.borrower.user;
        const loanDocuments = loan.borrower.user.documents;

        this.setState({
            showPageLoader: false,
            loan          : loan,
            user          : user,
            documents     : {
                user_national_id_card               : this.getLastUploadedDocumentByCategory("USER-NATIONAL-ID-CARD", loanDocuments),
                user_passport                       : this.getLastUploadedDocumentByCategory("USER-PASSPORT", loanDocuments),
                company_commercial_register         : this.getLastUploadedDocumentByCategory("COMPANY-COMMERCIAL-REGISTER", loanDocuments),
                company_tax_card                    : this.getLastUploadedDocumentByCategory("COMPANY-TAX-CARD", loanDocuments),
                company_operating_licenses          : this.getLastUploadedDocumentByCategory("COMPANY-OPERATING-LICENSES", loanDocuments),
                company_vat_registration_certificate: this.getLastUploadedDocumentByCategory("COMPANY-VAT-REGISTRATION-CERTIFICATE", loanDocuments),
                company_industrial_license          : this.getLastUploadedDocumentByCategory("COMPANY-INDUSTRIAL-LICENSE", loanDocuments),
            },
        });
    }

    // -----------------------------------------------

    getLastUploadedDocumentByCategory = (categoryName, documents = []) => {
        if (documents.length === 0) return null;

        // Get the documents by the specified categoryName
        const filteredDocuments = documents.filter(doc => String(doc.category).toLocaleUpperCase() === categoryName.toLocaleUpperCase());

        if (filteredDocuments.length === 0) return null;

        // Sort descending according to the "id"
        // So we get the last uploaded document at the beginning of the array
        filteredDocuments.sort((a, b) => b.id - a.id);

        return filteredDocuments[0] || null;
    }

    // -----------------------------------------------

    showInfoMessage = (messageBody, modalSize = "md") => {
        this.setState({
            messageModal: {
                ...toggleMessageModal("info"),
                body: messageBody,
                size: modalSize
            }
        });
    }

    // -----------------------------------------------

    getHelpInfo = () => {
        return (
            <div className={"__ff-droidarabickufi " + switchClasses(["x-ta-r", "x-dr-rtl", "x-fz-082rem"])}>
                <ul className="x-pr-20 x-pl-20 x-lh-30">
                    <li>{texts["click-the-file-name-or-icon-to-show-it"]}</li>
                </ul>
            </div>
        );
    }

    // -----------------------------------------------

    showErrorMessage = (error, props = this.props) => {
        this.setState({
            messageModal: {
                ...toggleMessageModal("error"),
                body: getErrorMessage(error, props).body || "",
                footerButtons: getErrorMessage(error, props).footerButtons || [],
            }
        });
    }

    // -----------------------------------------------

    /**
     *
     */
	render() {
        return (
            <PageContent
                pageContent={this.getPageContent()}
                authenticatedUser={this.getAuthenticatedUser()}
                showPageContent={this.showPageContent()}
                windowLocation={window.location}
            />
        );
	}

    /**
     *
     */
    getPageContent = () => {
        return (
            <div>
                {this.getPageTitle()}

                <div className="x-mt-15">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-3 col-lg-2 x-pr-8 x-pl-8">
                                <AdminLoanSubMenu loan={this.state.loan} />
                            </div>

                            <div className="col-md-9 col-lg-10 x-pr-8 x-pl-8">
                                <div className="shadow-sm x-m-0 x-mt-15 x-mb-70 x-p-20 x-pt-16 x-pb-100 x-mih-500 x-bgc-fff x-bdc-e3e3e3 x-bdrs-4">
                                    <PageLoader show={this.state.showPageLoader} />

                                    <div className={"row" + (this.state.showPageLoader ? " x-d-n" : "")}>
                                        <div className="col">
                                            {this.getContentOfUploadedDocuments()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.getMessageModal()}
            </div>
        );
    }

    /**
     *
     */
    getPageTitle = () => {
        return (
            <>
                <div className={"x-m-25 x-mb-15 " + switchClasses(["x-mr-15"])}>
                    <div>
                        <Link to={getRoutePath("adminLoanList")} className="x-fz-085rem">
                            <Icon
                                path={preferredLanguage.direction === "rtl" ? mdiChevronRight : mdiChevronLeft}
                                size="1.4em"
                                className="x-pos-r x-b-2"
                            />
                            <span className={switchClasses(["__ff-droidarabickufi"])}>
                                {texts["admin-loan-list"]}
                            </span>
                        </Link>
                    </div>

                    <h5 className={"x-pt-10 head-color "}>{texts["admin-loan-documents"]}</h5>
                </div>
            </>
        );
    }

    /**
     *
     */
    getContentOfUploadedDocuments = () => {
        return (
            <>
                <div className="row x-ta-c x-pt-15">
                    <div className="col-md-4 x-pb-30">
                        <DocumentThumbnail
                            documentStoredInDB={this.state.documents.user_national_id_card}
                            documentLabel={texts["user-national-id-card-image"]}
                            required={true}
                            onInfoHintClick={() => this.showInfoMessage(this.getHelpInfo())}
                            infoHintFontSize="x-fz-08rem"
                            userId={this.state.user?.id}
                            showInfoHint={false}
                        />
                    </div>
                    <div className="col-md-4 x-pb-30">
                        <DocumentThumbnail
                            documentStoredInDB={this.state.documents.company_commercial_register}
                            documentLabel={texts["company-commercial-register-image"]}
                            required={true}
                            onInfoHintClick={() => this.showInfoMessage(this.getHelpInfo())}
                            infoHintFontSize="x-fz-08rem"
                            userId={this.state.user?.id}
                            showInfoHint={false}
                        />
                    </div>
                    <div className="col-md-4 x-pb-30">
                        <DocumentThumbnail
                            documentStoredInDB={this.state.documents.company_tax_card}
                            documentLabel={texts["company-tax-card-image"]}
                            required={true}
                            onInfoHintClick={() => this.showInfoMessage(this.getHelpInfo())}
                            infoHintFontSize="x-fz-08rem"
                            userId={this.state.user?.id}
                            showInfoHint={false}
                        />
                    </div>
                    <div className="col-md-4 x-pb-30">
                        <DocumentThumbnail
                            documentStoredInDB={this.state.documents.company_operating_licenses}
                            documentLabel={texts["company-operating-licenses-images"]}
                            required={false}
                            onInfoHintClick={() => this.showInfoMessage(this.getHelpInfo())}
                            infoHintFontSize="x-fz-08rem"
                            userId={this.state.user?.id}
                            showInfoHint={false}
                        />
                    </div>
                    <div className="col-md-4 x-pb-30">
                        <DocumentThumbnail
                            documentStoredInDB={this.state.documents.company_vat_registration_certificate}
                            documentLabel={texts["company-vat-registration-certificate-image"]}
                            required={false}
                            onInfoHintClick={() => this.showInfoMessage(this.getHelpInfo())}
                            infoHintFontSize="x-fz-08rem"
                            userId={this.state.user?.id}
                            showInfoHint={false}
                        />
                    </div>
                    <div className="col-md-4 x-pb-30">
                        <DocumentThumbnail
                            documentStoredInDB={this.state.documents.company_industrial_license}
                            documentLabel={texts["company-industrial-license-image"]}
                            required={false}
                            onInfoHintClick={() => this.showInfoMessage(this.getHelpInfo())}
                            infoHintFontSize="x-fz-08rem"
                            userId={this.state.user?.id}
                            showInfoHint={false}
                        />
                    </div>
                    <div className="col-md-4 x-pb-30">
                        <DocumentThumbnail
                            documentStoredInDB={this.state.documents.user_passport}
                            documentLabel={texts["user-passport-image"]}
                            required={false}
                            onInfoHintClick={() => this.showInfoMessage(this.getHelpInfo())}
                            infoHintFontSize="x-fz-08rem"
                            userId={this.state.user?.id}
                            showInfoHint={false}
                        />
                    </div>
                </div>

                <RequiredDataHint className={this.state.showPageLoader ? "x-d-n" : ""} />
            </>
        );
    }

    /**
     *
     */
    getMessageModal = () => {
        return (
            <MessageModal
                show={this.state.messageModal.show || false}
                title={this.state.messageModal.title || null}
                body={this.state.messageModal.body || null}
                alertClassName={this.state.messageModal.alertClassName || null}
                size={this.state.messageModal.size || null}
                centered={this.state.messageModal.centered || false}
                footerButtons={this.state.messageModal.footerButtons || []}
                onHide={this.state.messageModal.onHide || (() => this.setState({ messageModal: { ...this.initialState.messageModal } }))}
            />
        );
    }
}
