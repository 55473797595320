import React from "react";

import PageHandler from "./shared/PageHandler";
import PageContent from "./shared/PageContent";

// Our Services
import documentTitleService from "./../services/document-title-service";

export default class NotFound extends PageHandler {

    initialState = {
        ...this.initialStateElements,
        isThisPagePublic: true,
    };

    // -----------------------------------------------

    // Clone a separate copy of the "initialState" and assign it to the "state".
    // Note that the spread operator "..." is important to get a separate copy.
    state = { ...this.initialState };

    // -----------------------------------------------

    componentDidMount() {
        // More info: t.ly/ZVsd
        window.scrollTo(0, 0);

        // ----------------------

        // Properly set the browser's document title
        documentTitleService.setTitle("Page Not Found");

        // ----------------------

        this.setAuthenticatedUser();
    }

    // -----------------------------------------------

	render() {
        const pageContent = (
            <h5 className="x-ta-c x-pt-50">404 | Page Not Found</h5>
        );

        return (
            <PageContent
                pageContent={pageContent}
                authenticatedUser={this.getAuthenticatedUser()}
                showPageContent={this.showPageContent()}
                windowLocation={window.location}
            />
        );
	}
}
