import React from "react";
import { preferredLanguage } from "./../configs/ui-language-config";
// import { GeneralTexts } from "./GeneralTexts";
import { RouteConfigTexts } from "./RouteConfigTexts";

const texts = {
    ar: {
        "admin-user-list"      : RouteConfigTexts["admin-user-list"],
        "search-users"         : "بحث في المستخدمين",
        "more-search-filters"  : "معايير بحث إضافية",
        "select"               : "اختر",
        "user-type"            : "نوع المستخدم",
        "account-type"         : "نوع الحساب",
        "country"              : "الدولة",
        "gender"               : "الجنس",
        "birth-date"           : "تاريخ الميلاد",
        "account-state"        : "حالة الحساب",
        "sorting-order"        : "ترتيب النتائج",
        "sort-by"              : "الترتيب حسب",
        "no-results-message"   : "لا يوجد نتائج",
        "edit"                 : "تعديل",
        "details"              : "التفاصيل",
        "name"                 : "الاسم",
        "type"                 : "النوع",
        "mobile"               : "الموبايل",
        "email"                : "الإيميل",
        "signed-up-at"         : "تاريخ التسجيل",
        "company"              : "الشركة",
        "last-login-at"        : "تاريخ آخر دخول",
        "mobile-and-email"     : "الموبايل والإيميل",
        "loans-requested"      : "قروض طلبها",
        "loans-invested-in"    : "قروض استثمر فيها",
        "years-old"            : "سنة",
        "years-ago"            : "سنة",
        "since"                : "منذ",
        "nationality"          : "الجنسية",
        "national-id-number"   : "الرقم القومي",
        "passport-number"      : "رقم جواز السفر",
        "signup-date"          : "تاريخ التسجيل",
        "last-login-date"      : "تاريخ آخر دخول",
        "last-login-ip"        : "IP آخر دخول",
        "address"              : "العنوان",
        "user-data"            : "بيانات المستخدم",
        "borrower-data"        : "بيانات المقترض",
        "lender-data"          : "بيانات المستثمر",
        "admin-data"           : "بيانات الأدمن",
        "company-data"         : "بيانات الشركة",
        "company-name"         : "اسم الشركة",
        "company-type"         : "نوع الشركة",
        "website"              : "موقع الإنترنت",
        "city-and-country"     : "المدينة والدولة",
        "founded-in"           : "سنة التأسيس",
        "activity-sector"      : "مجال النشاط",
        "business-cycle"       : "وصف نشاط الشركة",
        "loans-he-requested"   : "القروض التي طلبها",
        "requested-amount"     : "المبلغ المطلوب",
        "invested-amount"      : "المبلغ المستثمر",
        "loan-status"          : "حالة القرض",
        "loan-purpose"         : "الغرض من القرض",
        "pledged-amount"       : "المبلغ المٌتعهد به",
        "committed-lenders"    : "المستثمرين المشاركين",
        "no-loans-yet"         : "لم يطلب قروض بعد",
        "loans-he-invested-in" : "القروض التي استثمر فيها",
        "no-investments-yet"   : "لم يستثمر في قروض بعد",
        "amount-he-invested"   : "المبلغ الذي استثمره",

        "fields-of-the-user-to-search-in": (
            <>
                ما تكتبه في خانة البحث، سيتم البحث عنه في البيانات التالية:
                <br />
                اسم المستخدم - رقم الموبايل - الإيميل - اسم الشركة.
            </>
        ),
        "search-by-word-or-number-or-part-of-it": "يمكنك البحث بكلمة أو بجزء منها. كما يمكنك البحث برقم أو بجزء منه.",
        "at-least-3-characters-or-numbers-needed-to-start-search": "يبدأ البحث بعد إدخال 3 أحرف أو 3 أرقام على الأقل.",
    },

    en: {
        "admin-user-list"      : RouteConfigTexts["admin-user-list"],
        "search-users"         : "Search Users",
        "more-search-filters"  : "More Search Filters",
        "select"               : "Select",
        "user-type"            : "User Type",
        "account-type"         : "Account Type",
        "country"              : "Country",
        "gender"               : "Gender",
        "birth-date"           : "Birth Date",
        "account-state"        : "Account State",
        "sorting-order"        : "Sorting",
        "sort-by"              : "Sort by",
        "no-results-message"   : "No Results",
        "edit"                 : "Edit",
        "details"              : "Details",
        "name"                 : "Name",
        "type"                 : "Type",
        "mobile"               : "Mobile",
        "email"                : "Email",
        "signed-up-at"         : "Signed-up at",
        "company"              : "Company",
        "last-login-at"        : "Last Login at",
        "mobile-and-email"     : "Mobile & Email",
        "loans-requested"      : "Loans Requested",
        "loans-invested-in"    : "Loans Invested in",
        "years-old"            : "years", // Keep it in small letters
        "years-ago"            : "years", // Keep it in small letters
        "since"                : "since", // Keep it in small letters
        "nationality"          : "Nationality",
        "national-id-number"   : "National ID Number",
        "passport-number"      : "Passport Number",
        "signup-date"          : "Signup Date",
        "last-login-date"      : "Last Login Date",
        "last-login-ip"        : "Last Login IP",
        "address"              : "Address",
        "user-data"            : "User Data",
        "borrower-data"        : "Borrower Data",
        "lender-data"        : "Investor Data",
        "admin-data"        : "Admin Data",
        "company-data"         : "Company Data",
        "company-name"         : "Company Name",
        "company-type"         : "Company Type",
        "website"              : "Website",
        "city-and-country"     : "City & Country",
        "founded-in"           : "Founded in",
        "activity-sector"      : "Activity Sector",
        "business-cycle"       : "Business Cycle Description",
        "loans-he-requested"   : "Loans He Requested",
        "requested-amount"     : "Requested Amount",
        "invested-amount"      : "Invested Amount",
        "loan-status"          : "Loan Status",
        "loan-purpose"         : "Loan Purpose",
        "pledged-amount"       : "Pledged Amount",
        "committed-lenders"    : "Committed Investors",
        "no-loans-yet"         : "No loans yet",
        "loans-he-invested-in" : "Loans He Invested In",
        "no-investments-yet"   : "No investments yet",
        "amount-he-invested"   : "Amount He Invested",

        "fields-of-the-user-to-search-in": (
            <>
                What you type in the search box, will be searched for in these data:
                <br />
                User Name - Mobile - Email - Company Name.
            </>
        ),
        "search-by-word-or-number-or-part-of-it": "You can search by a word or part of it. And you can search by a number or part of it.",
        "at-least-3-characters-or-numbers-needed-to-start-search": "At least 3 characters or digits needed to start the search.",
    }
}

export const AdminUserListTexts = texts[preferredLanguage.alpha2code];
