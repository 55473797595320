import React from "react";
import { NavLink } from "react-router-dom";

import { switchClasses } from "./../../../languages/CssClasses";

// Our Configs
import { getRoute, getRoutePath } from "./../../../configs/route-config";

export default class AdminLoanSubMenu extends React.Component {
    state = {};

    render() {
        return (
            <div className={"list-group shadow-sm x-mt-15 x-mb-30 __ff-droidarabickufi " + switchClasses(["x-fz-08rem"])}>
                <NavLink to={getRoutePath("adminLoanBasic") + "/" + this.props.loan.uuid} className="list-group-item list-group-item-action _active">
                    {getRoute("adminLoanBasic").caption}
                    <p className={"list-group-item-hint x-m-0 x-pt-5 x-c-999 " + switchClasses(["x-fz-07rem"])}>{getRoute("adminLoanBasic").hint}</p>
                </NavLink>

                <NavLink to={getRoutePath("adminLoanAdvanced") + "/" + this.props.loan.uuid} className="list-group-item list-group-item-action">
                    {getRoute("adminLoanAdvanced").caption}
                    <p className={"list-group-item-hint x-m-0 x-pt-5 x-c-999 " + switchClasses(["x-fz-07rem"])}>{getRoute("adminLoanAdvanced").hint}</p>
                </NavLink>

                <NavLink to={getRoutePath("adminLoanCoverImage") + "/" + this.props.loan.uuid} className="list-group-item list-group-item-action">
                    {getRoute("adminLoanCoverImage").caption}
                    <p className={"list-group-item-hint x-m-0 x-pt-5 x-c-999 " + switchClasses(["x-fz-07rem"])}>{getRoute("adminLoanCoverImage").hint}</p>
                </NavLink>

                <NavLink to={getRoutePath("adminLoanInvestigationGallery") + "/" + this.props.loan.uuid} className="list-group-item list-group-item-action">
                    {getRoute("adminLoanInvestigationGallery").caption}
                    <p className={"list-group-item-hint x-m-0 x-pt-5 x-c-999 " + switchClasses(["x-fz-07rem"])}>{getRoute("adminLoanInvestigationGallery").hint}</p>
                </NavLink>

                <NavLink to={getRoutePath("adminLoanIndicators") + "/" + this.props.loan.uuid} className="list-group-item list-group-item-action">
                    {getRoute("adminLoanIndicators").caption}
                    <p className={"list-group-item-hint x-m-0 x-pt-5 x-c-999 " + switchClasses(["x-fz-07rem"])}>{getRoute("adminLoanIndicators").hint}</p>
                </NavLink>

                <NavLink to={getRoutePath("adminLoanDocuments") + "/" + this.props.loan.uuid} className="list-group-item list-group-item-action">
                    {getRoute("adminLoanDocuments").caption}
                    <p className={"list-group-item-hint x-m-0 x-pt-5 x-c-999 " + switchClasses(["x-fz-07rem"])}>{getRoute("adminLoanDocuments").hint}</p>
                </NavLink>

                {/* <NavLink to={getRoutePath("adminLoanRiskProfiling") + "/" + this.props.loan.uuid} className="list-group-item list-group-item-action">
                    {getRoute("adminLoanRiskProfiling").caption}
                    <p className={"list-group-item-hint x-m-0 x-pt-5 x-c-999 " + switchClasses(["x-fz-07rem"])}>{getRoute("adminLoanRiskProfiling").hint}</p>
                </NavLink> */}
            </div>
        );
    }
}
