import _isEmpty from "lodash/isEmpty";
import { getRoutePath, storePreviousRoutePathInLocalStorage } from "../configs/route-config";

// Our Services
import http from "./http-service";
import * as accessTokenService from "./access-token-service";

// Our Configs
import { getApiEndpoint } from "./../configs/api-config";

// -----------------------------------------------

export const getCurrentUser = async (urlQueryString = "") => {
    try {
        // If the access token doesn't exist
        const accessToken = accessTokenService.getAccessToken();
        if (_isEmpty(accessToken)) return null;

        // Get data of the current user
        const response = await http.get(
            getApiEndpoint("me").path + urlQueryString
        );

        const currentUser = response.data?.data?.user || null;

        return !_isEmpty(currentUser) ? currentUser : null;
    } catch (error) {
        return null;
    }
}

// -----------------------------------------------

export const update = (data) => {
    return http.put(
        getApiEndpoint("myProfileUpdate").path,
        data
    );
}

// -----------------------------------------------

export const changePassword = (data) => {
    return http.put(
        getApiEndpoint("passwordChange").path,
        data
    );
}

// -----------------------------------------------

export const redirectToLoginPageIfCouldNotRetrieveUserData = (user) => {
    if (_isEmpty(user)) {
        // localStorage.setItem("previousRoutePath", window.location.pathname)
        storePreviousRoutePathInLocalStorage();
        window.location = getRoutePath("login");
    }
}

// -----------------------------------------------

// export const redirectIfCouldNotRetrieveUserData = (user, routePath = getRoutePath("login")) => {
//     if (_isEmpty(user)) {
//         window.location = routePath;
//     }
// }

// -----------------------------------------------

export const getList = (urlQueryString = "") => {
    return http.get(getApiEndpoint("userList").path + urlQueryString);
}
