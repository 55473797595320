import React from "react";
// import ReactApexChart from "react-apexcharts"

// Our Modules
// import StatsCard from "./../../shared/StatsCard";
import AdminOverviewSubMenu from "./AdminOverviewSubMenu";
import PageLoader from "./../../shared/PageLoader";
import PageContent from "./../../shared/PageContent";
import PageHandler from "./../../shared/PageHandler";

// Our Services
import documentTitleService from "./../../../services/document-title-service";

export default class AdminOverviewCrowdfundingCampaigns extends PageHandler {

    initialState = {
        ...this.initialStateElements,
        allowedUserType: "admin",

        showPageLoader: true,
    };

    // -----------------------------------------------

    // Clone a separate copy of the "initialState" and assign it to the "state".
    // Note that the spread operator "..." is important to get a separate copy.
    state = { ...this.initialState };

    // -----------------------------------------------
    componentDidMount() {
        // More info: t.ly/ZVsd
        window.scrollTo(0, 0);

        // ----------------------

        // Properly set the browser's document title
        documentTitleService.setTitle("Admin Dashboard | Crowdfunding Campaigns Overview");

        // ----------------------

        this.setAuthenticatedUser();
        this.getStats();
    }

    // -----------------------------------------------

    // Get stats
    getStats = async () => {
        this.setState({ showPageLoader: true });

        // TODO: Fetch and handle data here..

        this.setState({ showPageLoader: false });
    }

    // -----------------------------------------------

    render() {
        const pageContent = (
            <React.Fragment>
                <div>
                    <div className="x-m-25 x-ml-15 x-mb-15">
                        <h5 className="x-pt-10 head-color __ff-rubik">
                            Overview
                        </h5>
                    </div>

                    <div className="x-mt-15">
                        <div className="container-fluid x-ov-h">
                            <div className="row">
                                <div className="col-md-3 col-lg-2">
                                    <AdminOverviewSubMenu />
                                </div>

                                <div className="col-md-9 col-lg-10">
                                    <PageLoader show={this.state.showPageLoader} />

                                    <div className={(this.state.showPageLoader ? "x-d-n" : "")}>
                                        <h5 className="x-pt-10 head-color __ff-rubik clearfix">
                                            Crowdfunding Campaigns
                                            <div className="x-d-ib x-fl-r">
                                                <select className="custom_select">
                                                    <option value="all-time">All Time</option>
                                                    <option value="today">Today</option>
                                                    <option value="yesterday">Yesterday</option>
                                                    <option value="last-7-days">Last 7 Days</option>
                                                    <option value="last-30-days">Last 30 days</option>
                                                </select>
                                            </div>
                                        </h5>

                                        {/* <div className="row">
                                            <div className="col-sm-6 col-lg-4 x-pt-15 x-pb-15">
                                                <StatsCard
                                                    title="All Users"
                                                    value="5000"
                                                    hint="Users of All Types"
                                                    valuePercentage={(5000 / 5000) * 100}
                                                    cssColorClass="text-primary"
                                                    cssBackgroundColorClass="bg-primary"
                                                />
                                            </div>

                                            <div className="col-sm-6 col-lg-4 x-pt-15 x-pb-15">
                                                <StatsCard
                                                    title="Borrowers"
                                                    value="4500"
                                                    hint="Users of Type Borrower"
                                                    valuePercentage={(4500 / 5000) * 100}
                                                    cssColorClass="x-c-chocolate"
                                                    cssBackgroundColorClass="x-bgc-chocolate"
                                                />
                                            </div>

                                            <div className="col-sm-6 col-lg-4 x-pt-15 x-pb-15">
                                                <StatsCard
                                                    title="Investors"
                                                    value="490"
                                                    hint="Users of Type Investor"
                                                    valuePercentage={(490 / 5000) * 100}
                                                    cssColorClass="x-c-blueviolet"
                                                    cssBackgroundColorClass="x-bgc-blueviolet"
                                                />
                                            </div>

                                            <div className="col-sm-6 col-lg-4 x-pt-15 x-pb-15">
                                                <StatsCard
                                                    title="Admins"
                                                    value="10"
                                                    hint="Users of Type Admin"
                                                    valuePercentage={(10 / 5000) * 100}
                                                    cssColorClass="text-danger"
                                                    cssBackgroundColorClass="bg-danger"
                                                />
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <hr className="x-m-25 x-mt-50 x-mb-45" /> */}

            </React.Fragment>
        );

        return (
            <PageContent
                pageContent={pageContent}
                authenticatedUser={this.getAuthenticatedUser()}
                showPageContent={this.showPageContent()}
                windowLocation={window.location}
            />
        );
    }
}
