import React from "react";
import { Link } from "react-router-dom";

import { preferredLanguage } from "./../../../configs//ui-language-config";
import { switchClasses } from "./../../../languages/CssClasses";
import { AdminLoanCoverImageTexts as texts } from "./../../../languages/AdminLoanCoverImageTexts";
import { GeneralTexts } from "./../../../languages/GeneralTexts";

import Icon from "@mdi/react"
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";

import { getRoutePath } from "./../../../configs/route-config";

// Our Modules
import MessageModal, { toggleMessageModal, getErrorMessage } from "./../../shared/MessageModal";
import FormHandler from "./../../shared/FormHandler";
import PageLoader from "./../../shared/PageLoader";
// import RequiredDataHint from "./../../shared/RequiredDataHint";
import AdminLoanSubMenu from "./AdminLoanSubMenu";
import PageContent from "./../../shared/PageContent";

// Our Services
import documentTitleService from "./../../../services/document-title-service";
import logService from "./../../../services/log-service";
import * as loanService from "./../../../services/loan-service";

import defaultLoanCoverImage from "./../../../../src/resources/images/campaign-default-image-800x450.png";

export default class AdminLoanCoverImage extends FormHandler {
    /**
     *
     */
    initialState = {
        ...this.initialStateElements,
        allowedUserType: "admin",

        validationErrors: {},
        messageModal    : {},
        showPageLoader  : true,
        showButtonLoader: false,
        loan            : {},
    };

    /**
     * Clone a separate copy of the "initialState" and assign it to the "state".
     * Note that the spread operator "..." is important to get a separate copy.
     */
    state = { ...this.initialState };

    /**
     *
     */
    validationRules = {};

    /**
     *
     */
    componentDidMount() {
        // More info: t.ly/ZVsd
        window.scrollTo(0, 0);

        // Properly set the browser's document title
        documentTitleService.setTitle(GeneralTexts["admin-dashboard"] + " | " + texts["admin-loan-cover-image"]);

        this.setAuthenticatedUser();
        this.getLoan();
    }

    /**
     * Get loan data that the admin tries to update
     */
    getLoan = async () => {
        this.setState({ showPageLoader: true });

        const response = await loanService.getItem(this.props.match.params.loanUuid);

        const loan = response.data.data.loan;

        this.setState({
            showPageLoader: false,
            loan          : loan,
        });
    }

    /**
     *
     */
    uploadDocument = async (event) => {
        this.setState({ showPageLoader: true });

        const input = event.currentTarget;

        const buttonLoaders = { ...this.state.buttonLoaders };
        buttonLoaders[input.name] = true;
        this.setState({ buttonLoaders: buttonLoaders });

        try {
            const formData = this.setFormData(event);
            const response = await loanService.updateCoverImage(this.state.loan.uuid, formData);
            const loan = response.data.data.loan;
            this.setState({ loan: loan });
        } catch (error) {
            /**
             * If there is an unexpected error like: network down, server down, db down, bug, ..
             *  - Log the error.
             *  - Display a generic and friendly error message.
             *
             * Note that "error.response" determines if the error is an expected error or not.
             */

            const isExpectedError =
                error.response
                && error.response.status >= 400
                && error.response.status < 500;

            if (!isExpectedError) {
                logService.log(error);
            }

            this.showErrorMessage(error);
        }

        this.setState({ showPageLoader: false });
    }

    /**
     *
     */
    showErrorMessage = (error, props = this.props) => {
        this.setState({
            messageModal: {
                ...toggleMessageModal("error"),
                body: getErrorMessage(error, props).body || "",
                footerButtons: getErrorMessage(error, props).footerButtons || [],
            }
        });
    }

    /**
     *
     */
    render() {
        return (
            <PageContent
                pageContent={this.getPageContent()}
                authenticatedUser={this.getAuthenticatedUser()}
                showPageContent={this.showPageContent()}
                windowLocation={window.location}
            />
        );
    }

    /**
     *
     */
    getPageContent = () => {
        return (
            <div>
                {this.getPageTitle()}

                <div className="x-mt-15">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-3 col-lg-2 x-pr-8 x-pl-8">
                                <AdminLoanSubMenu loan={this.state.loan} />
                            </div>

                            <div className="col-md-9 col-lg-10 x-pr-8 x-pl-8">
                                <div className="shadow-sm x-m-0 x-mt-15 x-mb-70 x-p-20 x-pt-16 x-pb-100 x-mih-500 x-bgc-fff x-bdc-e3e3e3 x-bdrs-4">
                                    <PageLoader show={this.state.showPageLoader} />

                                    <div className={"row" + (this.state.showPageLoader ? " x-d-n" : "")}>
                                        <div className="col">
                                            {this.getFormContent()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.getMessageModal()}
            </div>
        );
    }

    /**
     *
     */
    getPageTitle = () => {
        return (
            <>
                <div className={"x-m-25 x-mb-15 " + switchClasses(["x-mr-15"])}>
                    <div>
                        <Link to={getRoutePath("adminLoanList")} className="x-fz-085rem">
                            <Icon
                                path={preferredLanguage.direction === "rtl" ? mdiChevronRight : mdiChevronLeft}
                                size="1.4em"
                                className="x-pos-r x-b-2"
                            />
                            <span className={switchClasses(["__ff-droidarabickufi"])}>
                                {texts["admin-loan-list"]}
                            </span>
                        </Link>
                    </div>

                    <h5 className={"x-pt-10 head-color "}>{texts["admin-loan-cover-image"]}</h5>
                </div>
            </>
        );
    }

    /**
     *
     */
    getFormContent = () => {
        return (
            <>
                <form className={"__ff-droidarabickufi " + switchClasses(["x-fz-085rem"])} onSubmit={(e) => e.preventDefault()}>
                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-6 x-ta-c x-pt-10">
                            <p>{texts["current-cover-image"]}</p>
                            <img src={this.state.loan?.cover_image_url || defaultLoanCoverImage} alt="" className="x-m-a img-fluid x-bdw-1 x-bds-s x-bdc-ddd shadow-sm" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-6">
                            <div className="form-group x-pt-70 x-ta-c x-w-250 x-m-a">
                                <p className="x-m-0 x-pb-10">{texts["choose-another-cover-image"]}</p>
                                <input
                                    type="file"
                                    id="cover_image"
                                    name="cover_image"
                                    onChange={this.uploadDocument}
                                    disabled={false}
                                    className={"form-control x-bdrs-2"}
                                />
                            </div>
                        </div>
                    </div>
                </form>

                {/* <RequiredDataHint className={this.state.showPageLoader ? "x-d-n" : ""} /> */}
            </>
        );
    }

    /**
     *
     */
    getMessageModal = () => {
        return (
            <MessageModal
                show={this.state.messageModal.show || false}
                title={this.state.messageModal.title || null}
                body={this.state.messageModal.body || null}
                alertClassName={this.state.messageModal.alertClassName || null}
                size={this.state.messageModal.size || null}
                centered={this.state.messageModal.centered || false}
                footerButtons={this.state.messageModal.footerButtons || []}
                onHide={this.state.messageModal.onHide || (() => this.setState({ messageModal: { ...this.initialState.messageModal } }))}
            />
        );
    }
}
