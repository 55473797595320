import { preferredLanguage } from "./../configs/ui-language-config";
// import { RouteConfigTexts } from "./RouteConfigTexts";

const texts = {
    ar: {
        "change-my-password": "تغيير كلمة السر",
        "current-password"  : "كلمة السر الحالية",
        "new-password"      : "كلمة السر الجديدة",
    },

    en: {
        "change-my-password": "Change My Password",
        "current-password"  : "Current Password",
        "new-password"      : "New Password",
    }
}

export const ChangePasswordTexts = texts[preferredLanguage.alpha2code];
