import React, { useRef } from "react";
import { Link } from "react-router-dom";

// import { switchClasses } from "../../languages/CssClasses";
import { GeneralTexts } from "../../languages/GeneralTexts";
// import { DocumentUploaderTexts as texts } from "../../languages/DocumentUploaderTexts";

import CustomButton from "../shared/CustomButton";
import ButtonLoader from "../shared/ButtonLoader";

import { getRoutePath } from "../../configs/route-config";
// import { openLinkInNewTab } from "../../helpers/common-helper";
// import placeholderImage from "../../resources/images/placeholder-image.png";
import pdfFileIcon from "../../resources/images/pdf-file-icon.png";
import inactivePdfFileIcon from "../../resources/images/pdf-file-icon-inactive.png";

const DocumentUploader = (props) => {
    const inputFileRef             = useRef(null);
    const documentStoredInDB       = props.documentStoredInDB;
    const documentUploadButtonName = props.documentUploadButtonName;
    const documentLabel            = props.documentLabel;

    /* More info: t.ly/SbpA */
    const onBtnClick = () => {
        inputFileRef.current.click();
    }

    // const getDocumentFullUrl = () => {
    //     return documentStoredInDB ? (documentStoredInDB?.storage_service_base_url + documentStoredInDB?.relative_url) : null;
    // }

    const docLabel = (
        <>
            {documentLabel}
            {
                typeof props.required === "undefined" || props.required === true
                    ? <span className="x-c-red"> *</span>
                    : ""
            }
        </>
    );

    const infoHint = (
        <span
            className={(props.infoHintClassName || "link-like x-cur-p") + " " + (props.infoHintFontSize || "x-fz-075rem")  + " " + (props.infoHintExtraClassName || "")}
            onClick={props.onInfoHintClick}
        >
            {props.infoHintTitle || GeneralTexts["info"]}
        </span>
    );

    return (
        <React.Fragment>
            <div className="body-bgc x-bdw-1 x-bds-s x-bdc-eee x-bdrs-5 x-h-a x-ov-h x-d-f x-jc-c x-ai-c x-pt-40 x-pb-5">
                {/*
                <img
                    // src={getDocumentFullUrl() || placeholderImage}
                    src={placeholderImage}
                    alt=""
                    className="x-m-a img-fluid x-cur-p x-h-200 x-w-a"
                    onClick={props.onClick || (() => { openLinkInNewTab(`${getRoutePath("borrowerDocumentShow")}/${documentStoredInDB?.uuid}`) })}
                />
                */}

                {/* More about openning a link in another tab: t.ly/2wzb */}
                <div className={(documentStoredInDB?.uuid ? "" : " x-d-n")}>
                    <Link
                        to={{ pathname: `${getRoutePath("borrowerDocumentShow")}/${documentStoredInDB?.uuid}` }}
                        target="_blank"
                        // className={(documentStoredInDB?.uuid ? "" : " x-d-n")}
                    >
                        <img
                            src={pdfFileIcon}
                            alt=""
                            className="img-fluid x-w-65"
                        />
                        <div className="x-pt-10">
                            {docLabel}
                        </div>
                    </Link>

                    <div className="x-pt-20">
                        {infoHint}
                    </div>
                </div>

                <div className={(documentStoredInDB?.uuid ? " x-d-n" : "")}>
                    <img
                        src={inactivePdfFileIcon}
                        alt=""
                        className={"img-fluid x-w-65"}
                    />
                    <div className="x-pt-10">
                        {docLabel}
                    </div>

                    <div className="x-pt-20">
                        {infoHint}
                    </div>
                </div>
            </div>
            <div className="form-group x-pt-20">
                {/*
                <label htmlFor={documentUploadButtonName}>
                    {documentLabel}
                    {
                        typeof props.required === "undefined" || props.required === true
                            ? <span className="x-c-red"> *</span>
                            : ""
                    }
                </label>
                */}
                <div className="_x-pt-10">
                    <input
                        type="file"
                        id={documentUploadButtonName}
                        ref={inputFileRef} /* More info: t.ly/SbpA */
                        name={documentUploadButtonName}
                        onChange={props.onChange || (() => { })}
                        disabled={props.disabled || false}
                        className={props.className || "x-op-0 x-w-1 " + (props.extraCssClasses || "")}
                    />
                    <CustomButton
                        type="button"
                        label={props.showButtonLoader ? <ButtonLoader /> : GeneralTexts["choose-file"]}
                        disabled={false}
                        onClick={onBtnClick} /* More info: t.ly/SbpA */
                        extraCssClasses=""
                        // disabled={this.validateFormInputs() ? true : false}
                    />
                    {/* <small className="form-text text-danger">{props.validationError || null}</small> */}
                </div>
            </div>
        </React.Fragment>
    );
};

// -----------------------------------------------

export default DocumentUploader;
