import React from "react";
// import _isEmpty from "lodash/isEmpty";
import { switchClasses } from "../../languages/CssClasses";

const CustomButton = (props) => {
    return (
        <button
            className={props.className || "btn btn-primary shadow-sm x-miw-120 x-bdrs-2 x-pt-10 x-pb-10 _x-fz-09rem " + switchClasses(["x-fz-085rem"]) + " " + (props.extraCssClasses || "")}
            disabled={typeof props.disabled === "undefined" ? true : props.disabled}
            onClick={props.onClick || (() => { })}
            type={props.type || "button"}
            // onKeyPress={props.onKeyPress || (() => { })}
            // onKeyDown={props.onKeyDown || (() => { })}
        >
            {props.label || "Submit"}
        </button>
    );
};

export default CustomButton;
