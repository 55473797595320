import React from "react";
import { preferredLanguage } from "./../configs/ui-language-config";
// import { GeneralTexts } from "./GeneralTexts";
import { RouteConfigTexts } from "./RouteConfigTexts";

const texts = {
    ar: {
        "borrower-company-list": RouteConfigTexts["borrower-company-list"],
        "add-new-company"      : "أضف شركة جديدة",
        "name"                 : "الاسم",
        "type"                 : "النوع",
        "sector"               : "القطاع",
        "founded"              : "تأسست",
        "city"                 : "المدينة",
        "edit"                 : "تعديل",

        "no-companies-message": (
            <span>
                لا يوجد شركات مرتبطة بحسابك حاليا<br />
                لإضافة شركة جديدة, اضغط زر <strong>أضف شركة جديدة</strong>
            </span>
        ),
    },

    en: {
        "borrower-company-list": RouteConfigTexts["borrower-company-list"],
        "add-new-company"      : "Add New Company",
        "name"                 : "Name",
        "type"                 : "Type",
        "sector"               : "Sector",
        "founded"              : "Founded",
        "city"                 : "City",
        "edit"                 : "Edit",

        "no-companies-message": (
            <span>
                You don't have any company attached to your account yet.<br />
                To add a company, click the <strong>Add New Company</strong> button
            </span>
        ),
    }
}

export const BorrowerCompanyListTexts = texts[preferredLanguage.alpha2code];
