import { preferredLanguage } from "./../configs/ui-language-config";
// import { GeneralTexts } from "./GeneralTexts";
import { RouteConfigTexts } from "./RouteConfigTexts";

const texts = {
    ar: {
        "admin-loan-indicators"                      : RouteConfigTexts["admin-loan-indicators"],
        "admin-loan-list"                            : RouteConfigTexts["admin-loan-list"],
        "currency"                                   : "العملة",
        "company-iscore"                             : "iScore الشركة",
        "company-capital-size"                       : "حجم رأسمال الشركة",
        "company-average-monthly-sales"              : "متوسط المبيعات الشهرية للشركة",
        "company-last-year-revenue"                  : "إيرادات الشركة آخر سنة",
        "company-number-of-employees"                : "عدد الموظفين في الشركة",
        "company-operating-license-status"           : "حالة رخصة عمل الشركة",
        "company-operating-license-expiry-year"      : "سنة انتهاء رخصة عمل الشركة",
        "company-head-office-type"                   : "نوع مقر للشركة",
        "company-head-office-leasing-expiry-year"    : "سنة انتهاء إيجار مقر الشركة",
        "company-head-office-ownership-type"         : "نوع ملكية مقر الشركة",
        "ownership-installments-end-in-year"         : "سنة انتهاء أقساط ملكية مقر الشركة",
        "company-has-bank-account"                   : "الشركة لديها حساب بنكي؟",
        "company-has-financial-statements"           : "الشركة لديها قوائم مالية؟",
        "company-has-tax-related-issues"             : "الشركة لديها مشاكل ضريبية؟",
        "company-submitted-tax-form"                 : "الشركة قدمت اقراراتها الضريبية؟",
        "company-has-social-insurance-related-issues": "الشركة لديها مشاكل في التأمينات الاجتماعية؟",
        "company-submitted-social-insurance-form"    : "الشركة قدمت أوراق التأمينات الاجتماعية؟",
        "company-has-existing-bank-loans"            : "الشركة لديها قروض بنكية حاليا؟",
        "company-has-defaulted-loans"                : "الشركة متعثرة في سداد قروض لديها؟",
    },

    en: {
        "admin-loan-indicators"                      : RouteConfigTexts["admin-loan-indicators"],
        "admin-loan-list"                            : RouteConfigTexts["admin-loan-list"],
        "currency"                                   : "Currency",
        "company-iscore"                             : "Company iScore",
        "company-capital-size"                       : "Company Capital Size",
        "company-average-monthly-sales"              : "Company Average Monthly Sales",
        "company-last-year-revenue"                  : "Company Last Year Revenue",
        "company-number-of-employees"                : "Company Number of Employees",
        "company-operating-license-status"           : "Company Operating License Status",
        "company-operating-license-expiry-year"      : "Company Operating License Expiry Year",
        "company-head-office-type"                   : "Company Head Office Type",
        "company-head-office-leasing-expiry-year"    : "Company Head Office Leasing Expiry Year",
        "company-head-office-ownership-type"         : "Company Head Office Ownership Type",
        "ownership-installments-end-in-year"         : "Ownership Installments End in Year",
        "company-has-bank-account"                   : "Company Has Bank Account?",
        "company-has-financial-statements"           : "Company Has Financial Statements?",
        "company-has-tax-related-issues"             : "Company Has Tax Related Issues?",
        "company-submitted-tax-form"                 : "Company Submitted Tax Form?",
        "company-has-social-insurance-related-issues": "Company Has Social Insurance Related issues?",
        "company-submitted-social-insurance-form"    : "Company Submitted Social Insurance Form?",
        "company-has-existing-bank-loans"            : "Company Has Existing Bank Loans?",
        "company-has-defaulted-loans"                : "Company Has Defaulted Loans?",
    }
}

export const AdminLoanIndicatorsTexts = texts[preferredLanguage.alpha2code];
