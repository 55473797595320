import { preferredLanguage } from "./../configs/ui-language-config";

const texts = {
    ar: {
        "home"                                 : "الرئيسية",
        "pre-signup"                           : "تسجيل",
        "signup"                               : "تسجيل",
        "login"                                : "دخول",
        "logout"                               : "خروج",
        "forgot-password"                      : "نسيت كلمة السر",
        "reset-password"                       : "إعادة تعيين كلمة السر",
        "loans"                                : "الفرص المتاحة",
        "loan-details"                         : "تفاصيل القرض",
        "borrow-overview"                      : "نظرة عامة حول الاقتراض",
        "borrow-terms-and-conditions"          : "شروط وقواعد الاقتراض",
        "borrow-privacy-policy"                : "سياسة خصوصية المقترض",
        "borrow-faqs"                          : "الأسئلة الشائعة حول الاقتراض",
        "invest-overview"                      : "نظرة عامة حول الاستثمار",
        "invest-terms-and-conditions"          : "شروط وقواعد الاستثمار",
        "invest-privacy-policy"                : "سياسة خصوصية المستثمر",
        "invest-faqs"                          : "الأسئلة الشائعة حول الاستثمار",
        "contact-us"                           : "اتصل بنا",
        "page-not-found"                       : "صفحة غير موجودة",
        "borrower-overview"                    : "نظرة عامة",
        "borrower-notifications"               : "التنبيهات",
        "borrower-loan-list"                   : "قروضي",
        "borrower-loan-create"                 : "طلب قرض جديد",
        "borrower-loan-update"                 : "تعديل بيانات قرض",
        "borrower-company-list"                : "شركاتي",
        "borrower-company-create"              : "إضافة شركة جديدة",
        "borrower-company-update"              : "تعديل بيانات الشركة",
        "borrower-profile-basic"               : "بياناتي",
        "borrower-profile-advanced"            : "بياناتي المتقدمة",
        "borrower-document-list"               : "مستنداتي",
        "borrower-change-password"             : "تغيير كلمة السر",
        "lender-overview"                      : "نظرة عامة",
        "lender-notifications"                 : "التنبيهات",
        "lender-loan-list"                     : "استثماراتي",
        "lender-wallet"                        : "محفظتي",
        "lender-loan-details"                  : "تفاصيل القرض",
        "lender-profile-basic"                 : "بياناتي",
        "lender-profile-advanced"              : "بياناتي المتقدمة",
        "lender-document-list"                 : "مستنداتي",
        "lender-change-password"               : "تغيير كلمة السر",
        "admin-overview-general"               : "نظرة عامة",
        "admin-overview-users"                 : "نظرة على المستخدمين",
        "admin-overview-loans"                 : "نظرة على القروض",
        "admin-overview-borrowers"             : "نظرة على المقترضين",
        "admin-overview-lenders"               : "نظرة على المستثمرين",
        "admin-notifications"                  : "التنبيهات",
        "admin-loan-list"                      : "القروض",
        "admin-loan-basic"                     : "البيانات الأساسية للقرض",
        "admin-loan-basic-hint"                : "البيانات التي أدخلها المقترض",
        "admin-loan-advanced"                  : "البيانات المتقدمة للقرض",
        "admin-loan-advanced-hint"             : "بيانات تملأ بواسطة أدمن",
        "admin-loan-cover-image"               : "الصورة المصاحبة للقرض",
        "admin-loan-cover-image-hint"          : "صورة تضاف بواسطة أدمن",
        "admin-loan-investigation-gallery"     : "Investigation Gallery",
        "admin-loan-investigation-gallery-hint": "صور وفيديوهات تضاف بواسطة أدمن",
        "admin-loan-indicators"                : "مؤشرات القرض",
        "admin-loan-indicators-hint"           : "بيانات تملأ بواسطة أدمن",
        "admin-loan-risk-profiling"            : "تقييم مخاطر القرض",
        "admin-loan-risk-profiling-hint"       : "بيانات تملأ بواسطة أدمن",
        "admin-user-list"                      : "المستخدمين",
        "admin-profile-basic"                  : "بياناتي",
        "admin-profile-advanced"               : "بياناتي المتقدمة",
        "admin-change-password"                : "تغيير كلمة السر",
        "admin-overview-crowdfunding-campaigns": "نظرة على حملات التمويل",
        "admin-loan-documents"                 : "مستندات القرض",
        "admin-loan-documents-hint"            : "المستندات التي رفعها المقترض",
        "borrower-document-show"               : "عرض المستند",
        "admin-document-show"                  : "عرض المستند",
    },

    en: {
        "home"                                 : "Home",
        "pre-signup"                           : "Signup",
        "login"                                : "Login",
        "logout"                               : "Logout",
        "forgot-password"                      : "Forgot Password",
        "reset-password"                       : "Reset Password",
        "loans"                                : "Investment Opportunities",
        "loan-details"                         : "Loan Details",
        "borrow-overview"                      : "Borrowing Overview",
        "borrow-terms-and-conditions"          : "Borrowing Terms & Conditions",
        "borrow-privacy-policy"                : "Borrower Privacy Policy",
        "borrow-faqs"                          : "Borrowing FAQs",
        "invest-overview"                      : "Investing Overview",
        "invest-terms-and-conditions"          : "Investing Terms & Conditions",
        "invest-privacy-policy"                : "Investor Privacy Policy",
        "invest-faqs"                          : "Investing FAQs",
        "contact-us"                           : "Contact Us",
        "page-not-found"                       : "Page Not Found",
        "borrower-overview"                    : "Overview",
        "borrower-notifications"               : "Notifications",
        "borrower-loan-list"                   : "My Loans",
        "borrower-loan-create"                 : "Apply For New Loan",
        "borrower-loan-update"                 : "Edit My Loan",
        "borrower-company-list"                : "My Companies",
        "borrower-company-create"              : "Add New Company",
        "borrower-company-update"              : "Edit My Company",
        "borrower-profile-basic"               : "My Profile",
        "borrower-profile-advanced"            : "My Advanced Profile",
        "borrower-document-list"               : "My Documents",
        "borrower-change-password"             : "Change My Password",
        "lender-overview"                      : "Overview",
        "lender-notifications"                 : "Notifications",
        "lender-loan-list"                     : "My Investments",
        "lender-wallet"                        : "My Wallet",
        "lender-loan-details"                  : "Loan Details",
        "lender-profile-basic"                 : "My Profile",
        "lender-profile-advanced"              : "My Advanced Profile",
        "lender-document-list"                 : "My Documents",
        "lender-change-password"               : "Change My Password",
        "admin-overview-general"               : "Overview",
        "admin-overview-users"                 : "Users Overview",
        "admin-overview-loans"                 : "Loans Overview",
        "admin-overview-borrowers"             : "Borrowers Overview",
        "admin-overview-lenders"               : "Lenders Overview",
        "admin-notifications"                  : "Notifications",
        "admin-loan-list"                      : "Loans",
        "admin-loan-basic"                     : "Loan Basic Data",
        "admin-loan-basic-hint"                : "Data submitted by the borrower",
        "admin-loan-advanced"                  : "Loan Advanced Data",
        "admin-loan-advanced-hint"             : "Data to be filled by an admin",
        "admin-loan-cover-image"               : "Loan Cover Image",
        "admin-loan-cover-image-hint"          : "An image to be uploaded by an admin",
        "admin-loan-investigation-gallery"     : "Investigation Gallery",
        "admin-loan-investigation-gallery-hint": "Images & videos to be provided by an admin",
        "admin-loan-indicators"                : "Loan Indicators",
        "admin-loan-indicators-hint"           : "Data to be filled by an admin",
        "admin-loan-risk-profiling"            : "Loan Risk Profiling",
        "admin-loan-risk-profiling-hint"       : "Data to be filled by an admin",
        "admin-profile-basic"                  : "My Profile",
        "admin-user-list"                      : "Users",
        "admin-profile-advanced"               : "My Advanced Profile",
        "admin-change-password"                : "Change My Password",
        "admin-overview-crowdfunding-campaigns": "Crowdfunding Campaigns Overview",
        "admin-loan-documents"                 : "Loan Documents",
        "admin-loan-documents-hint"            : "Documents uploaded by the borrower",
        "borrower-document-show"               : "Show Document",
        "admin-document-show"                  : "Show Document",
    }
}

export const RouteConfigTexts = texts[preferredLanguage.alpha2code];
