import http from "./http-service";
import { getApiEndpoint } from "./../configs/api-config";

/**
 *
 */
export const create = (data) => {
    return http.post(
        getApiEndpoint("linkCreate").path,
        data
    );
}

/**
 *
 */
export const deleteLink = (uuid) => {
    return http.delete(
        getApiEndpoint("linkDelete").path + "/" + uuid
    );
}

/**
 *
 */
// export const getList = (urlQueryString = "") => {
//     return http.get(getApiEndpoint("loanList").path + urlQueryString);
// }

/**
 *
 */
// export const getItem = (uuid, urlQueryString = "") => {
//     return http.get(getApiEndpoint("loanDetails").path + "/" + uuid + urlQueryString);
// }

/**
 *
 */
// export const updateCoverImage = (loanUUID, data) => {
//     return http.post(
//         getApiEndpoint("loanCoverImageUpdate").path + "/" + loanUUID + "/cover-image",
//         data
//     );
// }

