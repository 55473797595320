import { GeneralTexts } from "../languages/GeneralTexts";

const publicUserTypes = [
    {
        label: GeneralTexts["borrower"],
        value: "borrower"
    },
    {
        label: GeneralTexts["lender"],
        value: "lender"
    }
];

// -----------------------------------------------

const allUserTypes = [
    ...publicUserTypes,
    {
        label: GeneralTexts["admin"],
        value: "admin"
    }
];

// -----------------------------------------------

const standardUserGenders = [
    {
        // label: "Male",
        label: GeneralTexts["male"],
        value: "male"
    },
    {
        label: GeneralTexts["female"],
        value: "female"
    }
];

// -----------------------------------------------

export function getUserTypes(option = null) {
    if (option === 'all') {
        return allUserTypes;
    }

    return publicUserTypes;
}

// -----------------------------------------------

export function getUserGenders(option = null) {
    if (option === 'all') {
        return [
            ...standardUserGenders,
            {
                label: "Other",
                value: "other"
            }
        ];
    }

    return standardUserGenders;
}

// -----------------------------------------------

const bannedUserOptions = [
    {
        label: GeneralTexts["not-banned"],
        value: "0"
    },
    {
        label: GeneralTexts["banned"],
        value: "1"
    }
];

export function getBannedUserOptions() {
    return bannedUserOptions;
}