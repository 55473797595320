import React from "react";
// import { Link } from "react-router-dom";

import { switchClasses } from "../../languages/CssClasses";

import _round from "lodash/round";

// Third-Party Modules
import numeral from "numeral"

// Our Configs
// import { getRoutePath } from "./../../configs/route-config";

const StatsCard = (props) => {
    const title                   = props.title || "";
    const value                   = props.value ? numeral(props.value).format("0,0") : "";
    const hint                    = props.hint || "";
    const valuePercentage         = props.valuePercentage ? _round(props.valuePercentage, 2) : 0;
    const cssColorClass           = props.cssColorClass || "text-primary";
    const cssBackgroundColorClass = props.cssBackgroundColorClass || "bg-primary";

    return (
        <div className={"x-bgc-fff x-bdc-fff x-bdrs-4 x-p-20 x-mih-150 shadow-sm " + switchClasses(["__ff-droidarabickufi"])}>
            <h5>{title}</h5>
            <h4 className={"x-mb-0 x-pt-10 x-dr-ltr __ff-rubik " + switchClasses(["x-ta-l"])}>{value}</h4>
            <p className={"x-m-0 x-pt-7 x-c-999 x-fw-300 " + switchClasses(["x-ta-l", "x-fz-08rem"])}>{hint}</p>
            <p className={cssColorClass + " x-m-0 x-pt-10 x-dr-ltr __ff-rubik"}>{valuePercentage || ""}%</p>
            <div className="progress">
                <div className={cssBackgroundColorClass + " progress-bar"} role="progressbar" aria-valuenow={valuePercentage || 0} aria-valuemin="0" aria-valuemax="100" style={{ width: (valuePercentage || 0) + "%" }}></div>
            </div>
        </div>
    );
};

export default StatsCard;
