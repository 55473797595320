import React from "react";
import { SelectBoxTexts as texts } from "./../../languages/SelectBoxTexts";

const SelectBox = (props) => {
    return (
        <div className="form-group x-pt-15">
            <label htmlFor={props.name}>
                {props.label}
                {
                    typeof props.required === "undefined" || props.required === true
                        ? <span className="x-c-red"> *</span>
                        : ""
                }
            </label>
            <select
                autoFocus={props.autoFocus || false}
                className={props.className || "custom-select x-h-44 x-bdrs-2 " + (props.extraCssClasses || "")}
                id={props.name}
                name={props.name}
                onBlur={props.onBlur || (() => { })}
                onChange={props.onChange || (() => { })}
                onFocus={props.onFocus || (() => { })}
                value={props.value}
            >
                {/* <option disabled={true}>Select..</option> */}
                <option value="">{texts["select"]}..</option>
                {props.selectBoxOptions}
            </select>

            <small className="form-text text-danger">{props.validationError}</small>
        </div>
    );
};

export default SelectBox;
