import React from "react";

import { switchClasses } from "./../languages/CssClasses";
// import { BorrowFaqsTexts as texts } from "./../languages/BorrowFaqsTexts";
import { RouteConfigTexts } from "./../languages/RouteConfigTexts";

// Our Modules
import BorrowSubMenu from "./shared/BorrowSubMenu";
import PageLoader from "./shared/PageLoader";

// Our Services
import documentTitleService from "./../services/document-title-service";
import PageHandler from "./shared/PageHandler";
import PageContent from "./shared/PageContent";

export default class BorrowFaqs extends PageHandler {
	initialState = {
		...this.initialStateElements,
		isThisPagePublic: true,
		showPageLoader: false,
	};

	// -----------------------------------------------

	// Clone a separate copy of the "initialState" and assign it to the "state".
	// Note that the spread operator "..." is important to get a separate copy.
	state = { ...this.initialState };

	// -----------------------------------------------

	componentDidMount() {
		// More info: t.ly/ZVsd
		window.scrollTo(0, 0);

		// ----------------------

		// Properly set the browser's document title
		documentTitleService.setTitle(RouteConfigTexts["borrow-faqs"]);

		// ----------------------

		this.setAuthenticatedUser();
	}

	// -----------------------------------------------

	render() {
		const pageContent = (
			<React.Fragment>
				<div>
					<div className={"x-m-25 x-mb-15 " + switchClasses(["x-mr-15"])}>
						<h5 className={"x-pt-20 head-color "}>
							{RouteConfigTexts["borrow-faqs"]}
						</h5>
					</div>

					<div className="x-mt-15">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-3 col-lg-2 x-pr-8 x-pl-8">
									<BorrowSubMenu />
								</div>

								<div className="col-md-9 col-lg-10 x-pr-8 x-pl-8">
									<div className="shadow-sm x-m-0 x-mt-15 x-mb-70 x-p-20 x-pt-16 x-pb-100 x-mih-500 x-bgc-fff x-bdc-e3e3e3 x-bdrs-4">
										<PageLoader show={this.state.showPageLoader} />

										<div
											className={
												"row" + (this.state.showPageLoader ? " x-d-n" : "")
											}
										>
											<div className="col">
												{/* <p className="x-pt-50 x-dr-ltr x-ta-c">Coming soon..</p> */}
												<h1 className="x-pt-30 x-ta-c x-fz-2rem">
													Borrowing FAQs
												</h1>

												<div className="x-p-50 x-lh-28">
													<div className="">
														<h3 className="x-m-0">Disclaimer</h3>
														<div className="">
															<p className="x-m-0 x-pt-15">
																All of Amwal's financing solutions are
																structured under the Modaraba concept. This
																concept involves lenders participating in each
																transaction with liquidity while the borrower
																contributes management and know-how. This
																enables both parties to share the risks and
																rewards of the transaction, by Islamic finance
																principles. By utilizing the Modaraba concept,
																Amwal can provide flexible and customized
																financing solutions to SMEs while ensuring that
																its financing solutions are compliant with
																Sharia law.
															</p>

															<p className="x-m-0 x-pt-15">
																Amwal's financing portfolio operates on a
																cashless system, whereby the cost of each
																transaction is paid directly by Amwal to the
																borrower's suppliers. In this arrangement, the
																borrower does not receive any cash in their bank
																account and waives the sales in favor of Amwal.
																This financing model is designed to ensure
																compliance with Sharia law while providing
																flexible and customized financing solutions to
																SMEs. By utilizing this cashless system, Amwal
																can mitigate financial risks and ensure the
																success of its financing solutions.
															</p>
														</div>
													</div>

													<div className="x-pt-60">
														<h3 className="x-m-0">What is Amwal?</h3>
														<div className="">
															<p className="x-m-0 x-pt-15">
																Amwal is a Sharia-compliant crowd-lending
																platform that makes it easy for you to finance
																your transaction and business need from personal
																lenders.
															</p>
														</div>
													</div>

													<div className="x-pt-60">
														<h3 className="x-m-0">
															What categories of small businesses meet AMWAL’s
															certain criteria?
														</h3>
														<div className="">
															<ul className="x-m-0 x-pt-15">
																<li>
																	Registered in Egypt for a minimum of two
																	years.
																</li>
																<li>Have a B2B business model.</li>
																<li>
																	Achieve a gross profit margin of at least 20%
																	per business cycle.
																</li>
																<li>
																	Businesses must not have declared bankruptcy.
																</li>
																<li>
																	Have a track record of transactions that
																	require financing.
																</li>
															</ul>
														</div>
													</div>

													<div className="x-pt-60">
														<h3 className="x-m-0">
															What loan products does Amwal offer?
														</h3>
														<div className="">
															<ol className="x-m-0 x-pt-15">
																<li>
																	<p className="x-m-0 h5">
																		Purchasing order finance
																	</p>
																	<p className="x-m-0">Short-Term Finance</p>
																	<p className="x-m-0">Profit Share model</p>
																	<p>
																		<span className="h5 x-c-999">Example:</span>{" "}
																		ABC Corporation is a prominent plastic
																		factory specializing in medical packing that
																		has recently received a purchase order worth
																		EGP 1M from a hospital. The direct cost of
																		the transaction is EGP 800K, resulting in a
																		profit of EGP 200K. With a duration of 60
																		days, Amwal can provide financing for the
																		transaction to enable ABC Corporation to
																		fulfill the purchase order and generate
																		revenue while minimizing financial risks. In
																		exchange for the financing, ABC Corporation
																		forfeits the revenue of the transaction to
																		Amwal, who recovers the paid-in capital of
																		EGP 800K and distributes the profits of EGP
																		200K between the lenders and the borrower.
																	</p>
																</li>
																<li>
																	<p className="x-m-0 h5">
																		Supply Chain Finance
																	</p>
																	<p className="x-m-0">Short-term finance</p>
																	<p className="x-m-0">Buy now Pay later</p>
																	<p>
																		<span className="h5 x-c-999">Example:</span>{" "}
																		ABC Corporation is a well-established
																		distributor for a key supplier, Al Araby,
																		which regularly provides a 10% discount on
																		its regular purchases. ABC Corporation seeks
																		to expand its business by increasing the
																		volume of products purchased from Al Araby.
																		To achieve this objective, Amwal offers a
																		supply chain finance solution for additional
																		purchasing. Through this financing solution,
																		the increased purchasing volume will qualify
																		ABC Corporation for a 20% discount per
																		cycle, with Amwal receiving a portion of the
																		extra 10% discount generated from the
																		additional purchasing. This financing
																		solution enables ABC Corporation to increase
																		its purchasing power and expand its business
																		while mitigating financial risks and paying
																		the main amount that they get.
																	</p>
																</li>
																<li>
																	<p className="x-m-0 h5">E-commerce finance</p>
																	<p className="x-m-0">Short-term finance</p>
																	<p className="x-m-0">Revenue share model</p>
																	<p>
																		<span className="h5 x-c-999">Example:</span>{" "}
																		ABC Corporation is an e-commerce platform
																		that specializes in the online sale of
																		products or a company with an e-commerce arm
																		seeking to increase its sales. To achieve
																		this objective, Amwal can provide financing
																		for social media advertising costs on behalf
																		of the borrower. In exchange, Amwal will
																		receive a percentage of the sales generated
																		from each transaction. These profits will be
																		distributed as dividends to the lenders
																		every month. Through this financing
																		solution, ABC Corporation can expand its
																		customer base, increase its sales, and
																		strengthen its market position while
																		minimizing financial risks. Additionally,
																		Amwal can earn a return on its investment
																		while complying with Sharia law.
																	</p>
																</li>
																<li>
																	<p className="x-m-0 h5">
																		Commercial Real-estate Finance
																	</p>
																	<p className="x-m-0">Long-term finance</p>
																	<p className="x-m-0">Revenue Share Model</p>
																	<p>
																		<span className="h5 x-c-999">Example:</span>{" "}
																		When a new picnic area, merchant, or
																		restaurant is in the process of being
																		established, the owner may require financing
																		to cover establishment expenses such as
																		interior decoration, equipment, and
																		operational costs for the first six months.
																		Amwal can provide the necessary funding to
																		cover these expenses while effectively
																		mitigating financial risks. In exchange,
																		Amwal will receive a percentage of the
																		establishment's revenue for a period of 2-5
																		years, based on different variables. This
																		financing solution enables the establishment
																		to establish itself and generate revenue
																		while providing Amwal with a return on its
																		investment. The return on investment will be
																		distributed to the lenders as dividends, by
																		the terms of the financing agreement.
																	</p>
																</li>
															</ol>
														</div>
													</div>

													<div className="x-pt-60">
														<h3 className="x-m-0">How does lending work?</h3>
														<div className="">
															<ol className="x-m-0 x-pt-15">
																<li>
																	Fulfill borrower applications based on your
																	sector.{" "}
																</li>
																<li>
																	Get the feedback of acceptance or rejection
																	within 4 days after our due diligence.{" "}
																</li>
																<li>Sign the contract.</li>
																<li>Release the finance campaign.</li>
																<li>Paying your suppliers.</li>
																<li>
																	Send updates on the transaction milestone as
																	mentioned in the application and contract.
																</li>
																<li>
																	Amwal receives the revenue from your client on
																	your behalf.
																</li>
																<li>
																	Amwal makes the dividends between you and the
																	lenders at the end of the transaction.
																</li>
															</ol>

															<p className="x-m-0 x-pt-15">
																“If any borrower does not update Amwal with the
																transaction milestone achievement, Amwal will
																apply a fine on the borrower.”
															</p>
														</div>
													</div>

													<div className="x-pt-60">
														<h3 className="x-m-0">
															What is Amwal’s grading system?
														</h3>
														<div className="">
															<p className="x-m-0 x-pt-15">
																Amwal has developed an in-house scoring system
																that is dynamic and considers various factors to
																provide a comprehensive assessment of borrower
																creditworthiness from A “low risk” to D “High
																Risk”.
															</p>

															<p className="x-m-0 x-pt-15">
																Amwal uses machine learning algorithms and data
																analytics to analyze borrower behavior, company
																performance, and market trends to develop its
																dynamic scoring system. Machine learning
																algorithms can analyze large amounts of data to
																identify patterns and make predictions, while
																data analytics can provide insights into market
																trends and customer behavior. Together, these
																technologies can help Amwal build a flexible and
																adaptable AI-based scoring system that can
																provide accurate assessments of borrower
																creditworthiness.
															</p>
														</div>
													</div>

													<div className="x-pt-60">
														<h3 className="x-m-0">
															What is the cost of finance?
														</h3>
														<div className="">
															<p className="x-m-0 x-pt-15">
																Amwal’s finance model is Sharia-compliant which
																means no fixed interest rate, instead, Amwal
																deserves a percentage of your profit or revenue
																based on the product that fulfills your needs.
															</p>

															<p className="x-m-0 x-pt-15">
																In purchase order (PO) financing, lenders are
																entitled to a percentage of your gross profit
																margin (GPM), as outlined below:
															</p>
															<ul>
																<li>
																	Lenders are entitled to 50% of the GPM when
																	they finance 100% of the cost.
																</li>
																<li>
																	Lenders are entitled to 40% of the GPM when
																	they finance 80% of the cost.
																</li>
																<li>
																	The percentage entitlement may vary for other
																	financing amounts.
																</li>
															</ul>

															<p className="x-m-0 x-pt-15">
																<span className="h5">
																	In e-commerce financing,
																</span>{" "}
																lenders are entitled to earn a percentage of
																your revenue, ranging from 2-5% depending on the
																agreed-upon period. At the end of the
																agreed-upon period, lenders will receive their
																paid-in capital.
															</p>

															<p className="x-m-0 x-pt-15">
																<span className="h5">
																	In commercial real estate financing,
																</span>{" "}
																lenders are entitled to receive a percentage of
																the revenue generated over a period of 3-5
																years, typically ranging from 3-8%. Unlike other
																financing models, lenders have no liability to
																pay back the paid-in capital
															</p>
														</div>
													</div>

													<div className="x-pt-60">
														<h3 className="x-m-0">
															What is the period of financing?
														</h3>
														<div className="">
															<ul className="x-m-0 x-pt-15">
																<li>
																	Purchasing order finance- short-term- less
																	than a year.
																</li>
																<li>
																	E-commerce- Short-Term- Less than a year.
																</li>
																<li>
																	Commercial Real-Estate- Long-Term- more than a
																	year.{" "}
																</li>
															</ul>
														</div>
													</div>

													<div className="x-pt-60">
														<h3 className="x-m-0">
															How long will take me to get the approval or
															rejection?
														</h3>
														<div className="">
															<p className="x-m-0 x-pt-15">
																Assuming that all the required documentation is
																complete and meets the necessary criteria, the
																Amwal team will respond within four business
																days. During this time, the borrower's profile,
																business performance, and transaction
																credibility will be measured and evaluated by
																Amwal's experienced professionals
															</p>
														</div>
													</div>

													<div className="x-pt-60">
														<h3 className="x-m-0">
															How long will take to activate the finance after
															the approval?
														</h3>
														<div className="">
															<p className="x-m-0 x-pt-15">
																Once the escrow account has been established to
																receive the funds, the disbursement process
																typically takes approximately 30 business days.
															</p>
														</div>
													</div>

													<div className="x-pt-60">
														<h3 className="x-m-0">
															How do I receive the fund?
														</h3>
														<div className="">
															<p className="x-m-0 x-pt-15">
																As part of the financing arrangement, the
																borrower does not receive any cash from Amwal.
																Instead, Amwal pays the borrower's suppliers on
																their behalf and obtains taxable invoices for
																the transaction. This cashless financing model
																is designed to ensure compliance with Sharia law
																and minimize financial risks for all parties
																involved.
															</p>
														</div>
													</div>

													<div className="x-pt-60">
														<h3 className="x-m-0">
															How do I repay you the financing?
														</h3>
														<div className="">
															<p className="x-m-0 x-pt-15">
																The borrower waives the sale of the transaction
																in favor of Amwal.
															</p>
														</div>
													</div>

													<div className="x-pt-60">
														<h3 className="x-m-0">
															How can I reach out to the AMWAL team if I have
															other questions or concerns?
														</h3>
														<div className="">
															<p className="x-m-0 x-pt-15">
																At Amwal, our top priority is to maintain open
																and effective communication with our partners.
																To this end, our dedicated support team is
																available to address any questions, concerns, or
																issues that may arise during the financing
																process. You can reach our support team by email
																at info@amwal.com.eg or through WhatsApp at
																01227999356. Our team is committed to providing
																timely and effective support to ensure that our
																financing solutions meet the specific needs and
																objectives of our partners.
															</p>
														</div>
													</div>

													<div className="x-pt-60">
														<h3 className="x-m-0">
															Can I pay off the financing early and get a less
															commission?
														</h3>
														<div className="">
															<p className="x-m-0 x-pt-15">
																Amwal is not a conventional financing tool;
																rather, it is a Sharia-based financing tool that
																is specifically designed to meet the unique
																needs of SMEs. The percentage of profitability
																for each transaction is fixed based on the
																transaction's profitability, by the principles
																of Islamic finance. This approach ensures that
																Amwal's financing solutions are fully compliant
																with Sharia law.
															</p>
														</div>
													</div>

													<div className="x-pt-60">
														<h3 className="x-m-0">
															What happened if my customer is late in paying the
															obligation to me?{" "}
														</h3>
														<div className="">
															<p className="x-m-0 x-pt-15">
																At Amwal, we are committed to supporting our
																portfolio against unexpected risks, particularly
																those related to delayed repayment. To this end,
																we offer a 60-day grace period with supportive
																repayment options from the various resources
																available to the borrower. If the grace period
																has passed, and no action has been taken, Amwal
																may sell the debt to an insurance company. If
																this occurs, the borrower's profit share will be
																delayed until the insurance company settles the
																payment.
															</p>
														</div>
													</div>

													<div className="x-pt-60">
														<h3 className="x-m-0">
															What is the action that will be taken in case the
															transaction is lost?
														</h3>
														<div className="">
															<p className="x-m-0 x-pt-15">
																There are several scenarios that may result in
																the loss of a transaction, as outlined below:
															</p>

															<ol className="x-m-0 x-pt-15">
																<li>
																	Quality - If a transaction is lost due to
																	quality issues, the borrower assumes all
																	associated risks, while the lenders are
																	entitled to the capital and return as outlined
																	in the financing contract.
																</li>
																<li>
																	Systematic Risk - This includes risks such as
																	fire, theft, or other risks that are typically
																	covered by insurance. In such cases, the
																	borrower will not be held liable.
																</li>
																<li>
																	Compulsive Risk - This category includes
																	global disasters, centralized decisions, and
																	epidemics. In such cases, the borrower is not
																	held responsible, and the lenders will assume
																	the associated risks along with the borrower.
																</li>
															</ol>
														</div>
													</div>

													<div className="x-pt-60">
														<h3 className="x-m-0">
															How can I withdraw my profit share from the
															transaction?
														</h3>
														<div className="">
															<p className="x-m-0 x-pt-15">
																Each borrower has a unique profile, which
																includes their designated bank account for
																receiving their profit share from each
																transaction. At the maturity date, the borrower
																can submit a withdrawal request, and the funds
																will be transferred to their designated bank
																account. This approach ensures transparency and
																accountability in the financing process, while
																also providing flexibility and convenience to
																our borrowers.
															</p>
														</div>
													</div>

													<div className="x-pt-60">
														<h3 className="x-m-0">
															What fees does AMWAL charge?
														</h3>
														<div className="">
															<p className="x-m-0 x-pt-15">
																5.5% is deducted from the principal amount per
																transaction.
															</p>
														</div>
													</div>

													<div className="x-pt-60">
														<h3 className="x-m-0">
															Can I have multiple Business Finance requests on
															AMWAL’s platform?
														</h3>
														<div className="">
															<p className="x-m-0 x-pt-15">
																Yes, businesses can have more than one active
																finance request at any point in time. Please
																note that any new application will be subject to
																a full credit assessment. Furthermore, the
																business must have made several successful
																repayments on its existing facilities.
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);

		return (
			<PageContent
				pageContent={pageContent}
				authenticatedUser={this.getAuthenticatedUser()}
				showPageContent={this.showPageContent()}
				windowLocation={window.location}
			/>
		);
	}
}
