import React from "react";
// import { Link } from "react-router-dom";

import { switchClasses } from "./../../../languages/CssClasses";
import { LenderWalletTexts as texts } from "./../../../languages/LenderWalletTexts";
import { GeneralTexts } from "./../../../languages/GeneralTexts";

// Third-Party Modules
import Joi from "@hapi/joi";
// import moment from "moment";
import numeral from "numeral"
// import _isEmpty from "lodash/isEmpty";
// import _startCase from "lodash/startCase";
// import _toLower from "lodash/toLower";

// Our Services
import documentTitleService from "./../../../services/document-title-service";
// import * as userService from "./../../../services/user-service";

// Our Modules
import PageLoader from "./../../shared/PageLoader";
import PageContent from "./../../shared/PageContent";
import FormHandler from "./../../shared/FormHandler";
import CustomButton from "../../shared/CustomButton";
import MessageModal, { toggleMessageModal } from "./../../shared/MessageModal";
import ButtonLoader from "./../../shared/ButtonLoader";

// Our Configs
// import { getRoutePath } from "./../../../configs/route-config";

import * as lenderService from "./../../../services/lender-service";
import { getEmail } from "../../../configs/general-config";

export default class LenderWallet extends FormHandler {

    initialState = {
        ...this.initialStateElements,
        allowedUserType: "lender",

        formInputs: {
            amount_to_withdraw: "",
        },
        validationErrors : {},
        lender        : {},
        amountToWithdrawHint: null,
        walletBalanceAfterWithdrawal: null, // null is important as an initial value, DON'T use 0 instead.
        messageModal  : {},
        showPageLoader: true,

        showDepositSection: false,
        showWithdrawSection: false,
        showOperationsLogSection: false,
    };

    // -----------------------------------------------

    // Clone a separate copy of the "initialState" and assign it to the "state".
    // Note that the spread operator "..." is important to get a separate copy.
    state = { ...this.initialState };

    // -----------------------------------------------

    validationRules = {
        amount_to_withdraw: Joi.number()
            .required()
            .integer()
            .positive()
            .min(100) // TODO: Move this number to a config file
            .label("Amount to Withdraw"),
    };

    // -----------------------------------------------

    componentDidMount() {
        // More info: t.ly/ZVsd
        window.scrollTo(0, 0);

        // ----------------------

        // Properly set the browser's document title
        documentTitleService.setTitle(GeneralTexts["lender-dashboard"] + " | " + texts["lender-wallet"]);

        // ----------------------

        this.setAuthenticatedUser();

        this.getCurrentAuthenticatedLender();
    }

    // -----------------------------------------------

    /**
     *
     */
    getCurrentAuthenticatedLender = async () => {
        // If a comma-separated list of attributes is not provided, all the lender attributes will be fetched.
        const response = await lenderService.getMyDataAsLender("?attributes=wallet_balance,wallet_balance_currency");

        const lender = response.data.data.lender || {};

        this.setState({
            lender: lender,
            showPageLoader: false,
        });
    }


    // -----------------------------------------------

    render() {
        const pageContent = (
            <React.Fragment>
                <div className="x-m-25 x-mt-50">
                    <h5 className={"head-color x-ta-c"}>{texts["lender-wallet"]}</h5>
                </div>

                <div className="container-fluid">
                    <div className="row x-mb-100">
                        {/* <div className="col-md-1"></div> */}

                        <div className="col-12">
                            <PageLoader show={this.state.showPageLoader} />

                            <div className={"x-pt-20 " + (this.hideWhenThereIsNoDataToDisplay())}>
                                <div className={"row"}>
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4">
                                        <div className="shadow-md green_bgc x-ta-c x-pt-20 x-pb-20 x-mih-220 x-d-f x-jc-c x-ai-c">
                                            <div className="">
                                                <h1 className="x-m-0 x-pb-15 x-c-fff x-dr-ltr x-fz-23rem">
                                                    {numeral(this.state.lender?.wallet_balance).format("0,0") || 0}&nbsp;
                                                    <span className="x-fz-12rem _x-c-222">{this.state.lender?.wallet_balance_currency}</span>
                                                </h1>
                                                <h6 className="x-m-0 x-fz-09rem">{texts["current-wallet-balance"]}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={"row x-pt-20"}>
                                    <div className="col-md-4 x-pt-30">
                                        <div className="shadow-md x-ta-c x-pt-20 x-pb-20 x-bgc-fff x-mih-220 x-d-f x-jc-c x-ai-c">
                                            <div className="">
                                                <h5 className="x-m-0">{texts["deposit"]}</h5>
                                                <h6 className="x-m-0 x-pt-15 x-fz-085rem x-c-aaa">{texts["add-money-to-wallet"]}</h6>
                                                <CustomButton
                                                    disabled={false}
                                                    extraCssClasses={"x-miw-240 x-mt-40 " +  switchClasses(["x-fz-08rem"]) + " " + (this.state.showDepositSection ? " x-d-n" : "")}
                                                    label={texts["start-deposit"]}
                                                    // onClick={() => {this.showDepositModal(this.getContentOfDepositModal())}}
                                                    onClick={() => {this.showDepositSection()}}
                                                    type="button"
                                                />

                                                <div className="">{this.getContentOfDepositModal()}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4 x-pt-30">
                                        <div className="shadow-md x-ta-c x-pt-20 x-pb-20 x-bgc-fff x-mih-220 x-d-f x-jc-c x-ai-c">
                                            <div className="">
                                                <h5 className="x-m-0">{texts["withdraw"]}</h5>
                                                <h6 className="x-m-0 x-pt-15 x-fz-085rem x-c-aaa">{texts["withdraw-money-from-wallet-to-bank-account"]}</h6>
                                                <CustomButton
                                                    disabled={false}
                                                    extraCssClasses={"x-miw-240 x-mt-40 " +  switchClasses(["x-fz-08rem"]) + " " + (this.state.showWithdrawSection ? " x-d-n" : "")}
                                                    label={texts["start-withdraw"]}
                                                    // onClick={() => {this.showWithdrawModal(this.getContentOfWithdrawModal())}}
                                                    onClick={() => this.showWithdrawSection()}
                                                    type="button"
                                                />

                                                <div className="">{this.getContentOfWithdrawModal()}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4 x-pt-30">
                                        <div className="shadow-md x-ta-c x-pt-20 x-pb-20 x-bgc-fff x-mih-220 x-d-f x-jc-c x-ai-c">
                                            <div className="">
                                                <h5 className="x-m-0">{texts["operations-log"]}</h5>
                                                <h6 className="x-m-0 x-pt-15 x-fz-085rem x-c-aaa">{texts["wallet-operations-log"]}</h6>
                                                <CustomButton
                                                    disabled={false}
                                                    extraCssClasses={"x-miw-240 x-mt-40 " +  switchClasses(["x-fz-08rem"]) + " " + (this.state.showOperationsLogSection ? " x-d-n" : "") }
                                                    label={texts["show-operations-log"]}
                                                    // onClick={() => {this.showOperationsLogModal(this.getContentOfOperationsLogModal())}}
                                                    onClick={() => this.showOperationsLogSection()}
                                                    type="button"
                                                />

                                                <div className="">{this.getContentOfOperationsLogModal()}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <MessageModal
                    show={this.state.messageModal.show || false}
                    title={this.state.messageModal.title || null}
                    body={this.state.messageModal.body || null}
                    alertClassName={this.state.messageModal.alertClassName || null}
                    size={this.state.messageModal.size || null}
                    centered={this.state.messageModal.centered || false}
                    footerButtons={this.state.messageModal.footerButtons || []}
                    onHide={this.state.messageModal.onHide || (() => this.setState({ messageModal: { ...this.initialState.messageModal } }))}
                />
            </React.Fragment>
        );

        return (
            <PageContent
                pageContent={pageContent}
                authenticatedUser={this.getAuthenticatedUser()}
                showPageContent={this.showPageContent()}
                windowLocation={window.location}
            />
        );
    }

    /**
     * Get a CSS class that hides an HTML element.
     */
     hideWhenThereIsNoDataToDisplay = () => {
        // The space before the class name is important
        return this.state.showPageLoader ? " x-d-n" : "";
    }

    /**
     *
     */
    showDepositModal = (content, modalSize = "md") => {
        this.setState({
            messageModal: {
                ...toggleMessageModal("custom"),
                body: content, // Could be HTML content
                size: modalSize
            }
        });
    }

    /**
     *
     */
    showWithdrawModal = (content, modalSize = "md") => {
        this.setState({
            messageModal: {
                ...toggleMessageModal("custom"),
                body: content, // Could be HTML content
                size: modalSize
            }
        });
    }

    /**
     *
     */
    showOperationsLogModal = (content, modalSize = "md") => {
        this.setState({
            messageModal: {
                ...toggleMessageModal("custom"),
                body: content, // Could be HTML content
                size: modalSize
            }
        });
    }

    /**
     * Get the content of the deposit modal.
     */
    getContentOfDepositModal = () => {
        const authenticatedUser = this.getAuthenticatedUser();

        return (
            <div className={"x-pt-50 x-pr-30 x-pl-30 " + (this.state.showDepositSection ? "" : " x-d-n") + " " + switchClasses(["x-dr-rtl", "x-ta-r"])}>
                <h5 className={"ـx-ta-c " + switchClasses([""])}>{texts["steps"]}:</h5>
                <hr />

                <ol className={"x-p-10 x-fz-08rem x-lh-22"}>
                    <li className="">{texts["transfer-money-to-our-bank-account"]}</li>
                    <li className="x-pt-15">
                        {texts["send-the-transfer-data-to-email"]}&nbsp;
                        (<span className="x-c-crimson x-fw-b">{getEmail("admin")}</span>)&nbsp;
                        {texts["send-the-email-from-your-registered-email"]}&nbsp;
                        (<span className="x-c-crimson x-fw-b">{authenticatedUser?.email}</span>).
                    </li>
                    <li className="x-pt-15">{texts["when-transfer-completed-increase-wallet"]}</li>
                </ol>

                <div className={"x-pt-30 x-pb-20 x-fz-08rem " + switchClasses([""])}>
                    <h5 className={""}>{texts["our-bank-account"]}:</h5>
                    <hr />

                    <div className="x-pt-10">
                        <div className="">
                            <span className="x-c-999">{texts["company-name"]}</span>
                            <p className="x-c-crimson x-dr-ltr">AMWAL FINANCIAL ADVISORS</p>
                        </div>

                        <div className="">
                            <span className="x-c-999">{texts["bank-name"]}</span>
                            <p className="x-c-crimson x-dr-ltr">CIB</p>
                        </div>

                        <div className="">
                            <span className="x-c-999">{texts["bank-account-number"]}</span>
                            <p className="x-c-crimson x-dr-ltr">100044894372</p>
                        </div>

                        <div className="">
                            <span className="x-c-999">{texts["branch-name"]}</span>
                            <p className="x-c-crimson x-dr-ltr">Smart Village</p>
                        </div>

                        <div className="">
                            <span className="x-c-999">{texts["swift-code"]}</span>
                            <p className="x-c-crimson x-dr-ltr">CIBEEGCX119</p>
                        </div>

                        <div className="">
                            <span className="x-c-999">{texts["iban"]}</span>
                            <p className="x-c-crimson x-dr-ltr">EG560010011900000100044894372</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Get the content of the withdraw modal.
     */
    getContentOfWithdrawModal = () => {
        const lender = this.state.lender;

        return (
            <div className={"x-pt-50 x-pr-30 x-pl-30 " + (this.state.showWithdrawSection ? "" : " x-d-n") + " " + switchClasses(["x-dr-rtl", "x-ta-r"])}>
                <h5 className={"ـx-ta-c " + switchClasses([""])}>{texts["steps"]}:</h5>
                <hr />

                <ol className={"x-p-10 x-fz-08rem x-lh-22"}>
                    <li className="">{texts["enter-amount-to-withdraw"]}</li>
                    <li className="x-pt-15">{texts["will-transfer-amount-to-your-bank-account"]}</li>
                    <li className="x-pt-15">{texts["you-will-be-informed-with-email"]}</li>
                </ol>

                <form className="x-pt-30" onSubmit={(e) => e.preventDefault()}>
                    <div className="row">
                        {/* <div className="col-md-3"></div> */}
                        {/* <div className="col-md-6"> */}
                        <div className="col-md-1"></div>
                        <div className="col-md-10">
                            {/* TODO: Make a separate component of this input group like TextBox */}
                            {/* Start Input Group */}
                            <div className="form-group _x-pt-15">
                                <label htmlFor={"amount_to_withdraw"} className="x-d-b x-ta-c">
                                    {texts["amount-to-withdraw"]}
                                    {/* <span className="x-c-red"> *</span> */}
                                </label>
                                <div className="input-group mb-3 x-dr-ltr">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">{lender.wallet_balance_currency}</span>
                                    </div>
                                    <input
                                        autoComplete={"off"}
                                        autoFocus={false}
                                        className={"form-control x-bdrs-2 x-pt-21 x-pb-21   x-dr-ltr x-ta-l x-fz-1rem"}
                                        disabled={false}
                                        id={"amount_to_withdraw"}
                                        name={"amount_to_withdraw"}
                                        onBlur={this.handleBlur}
                                        onChange={(e) => {this.handleChange(e); this.validateAmountToWithdraw(e)}}
                                        onFocus={this.handleFocus}
                                        onKeyPress={()=>{}}
                                        placeholder={"5000"}
                                        type="text"
                                        value={this.state.formInputs.amount_to_withdraw || ""}
                                    />
                                </div>
                                <small className="form-text text-danger">{this.state.validationErrors.amount_to_withdraw || null}</small>
                                <small className="form-text text-danger">{this.state.amountToWithdrawHint || null}</small>
                             </div>
                        </div>
                    </div>

                    <div className="row x-pb-30">
                        {/* <div className="col-md-3"></div> */}
                        {/* <div className="col-md-6"> */}
                        <div className="col-md-1"></div>
                        <div className="col-md-10">
                            <div className="x-mt-10 x-ta-c">
                                <CustomButton
                                    type="button"
                                    label={this.state.showButtonLoader ? <ButtonLoader /> : texts["withdraw"]}
                                    disabled={this.validateFormInputs() ? true : false}
                                    onClick={this.handleSubmit}
                                    extraCssClasses={"btn-block " + switchClasses(["x-fz-09rem"])}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    /**
     * Get the content of the operations log modal.
     */
    getContentOfOperationsLogModal = () => {
        return (
            <div className={"x-pt-50 x-pr-30 x-pl-30 " + (this.state.showOperationsLogSection ? "" : " x-d-n") + " " + switchClasses(["x-dr-rtl", "x-ta-r"])}>
                <p className="x-ta-c x-c-crimson x-fz-08rem">{texts["no-previous-deposits-or-withdrawals"]}</p>
            </div>
        );
    }

    /**
     *
    */
    //  validateAmountToWithdraw = (event, value) => {
    //     // const input            = event.currentTarget;
    //     // const investmentAmount = Number(input.value);
    //     const amountToWithdraw = Number(value);
    //     const walletBalance    = Number(this.state.lender?.wallet_balance);

    //     this.setState({walletBalanceAfterWithdrawal: walletBalance - amountToWithdraw});

    //     if (amountToWithdraw > walletBalance) {
    //         this.setState({amountToWithdrawHint: texts["insufficient-wallet-balance"]});
    //     } else {
    //         this.setState({amountToWithdrawHint: null});
    //     }
    // };

    /**
     *
    */
    validateAmountToWithdraw = (event) => {
        const input            = event.currentTarget;
        const amountToWithdraw = Number(input.value);
        const walletBalance    = Number(this.state.lender?.wallet_balance);

        this.setState({walletBalanceAfterWithdrawal: walletBalance - amountToWithdraw});

        if (amountToWithdraw > walletBalance) {
            this.setState({amountToWithdrawHint: texts["insufficient-wallet-balance"]});
        } else {
            this.setState({amountToWithdrawHint: null});
        }
    }

    showDepositSection = () => {
        this.setState({
            showDepositSection: true,
            showWithdrawSection: false,
            showOperationsLogSection: false,
        });
    }

    showWithdrawSection = () => {
        this.setState({
            showWithdrawSection: true,
            showDepositSection: false,
            showOperationsLogSection: false,
        });
    }

    showOperationsLogSection = () => {
        this.setState({
            showOperationsLogSection: true,
            showDepositSection: false,
            showWithdrawSection: false,
        });
    }

}
