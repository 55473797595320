import { preferredLanguage } from "./../configs/ui-language-config";

const texts = {
    ar: {
        "website-name"                   : process.env.REACT_APP_WEBSITE_NAME_AR,
        "dashboard"                      : "لوحة التحكم",
        "borrower-dashboard"             : "لوحة تحكم المقترض",
        "lender-dashboard"               : "لوحة تحكم المستثمر",
        "admin-dashboard"                : "لوحة تحكم الأدمن",
        "apply-for-new-loan"             : "طلب قرض جديد",
        "required-data"                  : "بيانات مطلوبة",
        "all-time"                       : "كل الأوقات",
        "today"                          : "اليوم",
        "yesterday"                      : "أمس",
        "last-7-days"                    : "7 أيام الماضية",
        "last-30-days"                   : "30 يوم الماضية",
        // "click-image-to-enlarge"         : "اضغط على أي صورة لتراها بحجم أكبر",
        "choose-image"                   : "اختر صورة",
        "choose-file"                    : "اختر ملف",
        "save"                           : "حفظ",
        "next"                           : "التالي",
        "previous"                       : "السابق",
        // "help"                           : "مساعدة",
        "help"                           : "help", // Keep it "help" without translation and in small letters
        "help-info"                      : "معلومات مساعدة",
        // "info"                        : "معلومة",
        "info"                           : "info", // Keep it "info" without translation and in small letters
        "show-file"                      : "عرض الملف",
        "signup-now"                     : "سجل الآن",
        "admin"                          : "أدمن",
        "borrower"                       : "مقترض",
        "lender"                         : "مستثمر",
        "male"                           : "ذكر",
        "female"                         : "أنثى",
        "banned"                         : "حساب موقوف",
        "not-banned"                     : "حساب نشط",
        "from"                           : "من",
        "to"                             : "إلى",
        "select-option"                  : "اختر..",
        "created-at-descending"          : "تاريخ الإنشاء (الأحدث أولا)",
        "created-at-ascending"           : "تاريخ الإنشاء (الأقدم أولا)",
        "updated-at-descending"          : "تاريخ آخر تحديث (الأحدث أولا)",
        "updated-at-ascending"           : "تاريخ آخر تحديث (الأقدم أولا)",
        "last-login-at-descending"       : "تاريخ آخر دخول (الأحدث أولا)",
        "last-login-at-ascending"        : "تاريخ آخر دخول (الأقدم أولا)",
        // "first-name-ascending"           : "الاسم الأول (من أ إلي ي)",
        // "first-name-descending"          : "الاسم الأول (من ي إلى أ)",
        "clear-search-filters"           : "إزالة معايير البحث",
        "number-of-results-per-page"     : "عدد النتائج في الصفحة",
        "displaying-results"             : "عرض النتائج",
        "search"                         : "بحث",
        "result"                         : "نتيجة",
    },

    en: {
        "website-name"                   : process.env.REACT_APP_WEBSITE_NAME_EN,
        "dashboard"                      : "Dashboard",
        "borrower-dashboard"             : "Borrower Dashboard",
        "lender-dashboard"               : "Investor Dashboard",
        "admin-dashboard"                : "Admin Dashboard",
        "apply-for-new-loan"             : "Apply For New Loan",
        "required-data"                  : "Required Data",
        "all-time"                       : "All Time",
        "today"                          : "Today",
        "yesterday"                      : "Yesterday",
        "last-7-days"                    : "Last 7 Days",
        "last-30-days"                   : "Last 30 Days",
        // "click-image-to-enlarge"         : "Click any image to enlarge it",
        "choose-image"                   : "Choose Image",
        "choose-file"                    : "Choose File",
        "save"                           : "Save",
        "next"                           : "Next",
        "previous"                       : "Previous",
        "help"                           : "help", // Keep it small letters
        "help-info"                      : "Help Information",
        "info"                           : "info", // Keep it in small letters
        "show-file"                      : "Show File",
        "signup-now"                     : "Signup Now",
        "admin"                          : "Admin",
        "borrower"                       : "Borrower",
        "lender"                         : "Investor",
        "male"                           : "Male",
        "female"                         : "Female",
        "banned"                         : "Banned Account",
        "not-banned"                     : "Wroking Account",
        "from"                           : "From",
        "to"                             : "To",
        "select-option"                  : "Select..",
        "created-at-descending"          : "Create Date (newest first)",
        "created-at-ascending"           : "Create Date (oldest first)",
        "updated-at-descending"          : "Last Update Date (newest first)",
        "updated-at-ascending"           : "Last Update Date (oldest first)",
        "last-login-at-descending"       : "Last Login Date (newest first)",
        "last-login-at-ascending"        : "Last Login Date (oldest first)",
        "clear-search-filters"           : "Clear Search Filters",
        "number-of-results-per-page"     : "Number of Results Per Page",
        "displaying-results"             : "Displaying Results",
        "search"                         : "Search",
        "result"                         : "results", // Keep it in small letters
    }
}

export const GeneralTexts = texts[preferredLanguage.alpha2code];
