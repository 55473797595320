import React from "react";
import Select from "react-select";
import _range from "lodash/range"
// import { Link } from "react-router-dom";
// import Pagination from 'react-bootstrap/Pagination';

// More info about query-string: t.ly/EDZS
import queryString from "query-string";

import { switchClasses } from "./../../../languages/CssClasses";
import { AdminUserListTexts as texts } from "./../../../languages/AdminUserListTexts";
import { GeneralTexts } from "./../../../languages/GeneralTexts";

// Third-Party Modules
import moment from "moment";
// import moment from 'moment-timezone';
import numeral from "numeral"
import _isEmpty from "lodash/isEmpty";
import _startCase from "lodash/startCase";
import _toLower from "lodash/toLower";
import { mdiClose, mdiDotsHorizontal } from '@mdi/js';
import Icon from "@mdi/react"

// Our Services
import documentTitleService from "./../../../services/document-title-service";
import * as userService from "./../../../services/user-service";
import { openLinkInNewTab } from "./../../../helpers/common-helper";

// Our Modules
import PageLoader from "./../../shared/PageLoader";
import Pagination from "./../../shared/Pagination";
import PageContent from "./../../shared/PageContent";
import PageHandler from "./../../shared/PageHandler";
import MessageModal, { toggleMessageModal } from "./../../shared/MessageModal";
import PaginationItem from "../../shared/PaginationItem";

// Our Configs
import { getRoutePath } from "./../../../configs/route-config";
import { getNumbersOfResultsPerPage, getPaginator, getSortOrders } from "./../../../configs/general-config";
import { getCommonCountryList } from "./../../../configs/country-config";
import { getBannedUserOptions, getUserGenders } from "../../../configs/user-config";
import { getCountry } from "../../../configs/country-config";

export default class AdminUserList extends PageHandler {

    // Get the "query string" part as an object. More info: t.ly/EDZS
    urlQueryStringObject = queryString.parse(
        this.props.location.search,
        {arrayFormat: "separator", arrayFormatSeparator: "|"}
    );

    // -----------------------------------------------

    initialState = {
        ...this.initialStateElements,
        allowedUserType: "admin",

        users         : [],
        showPageLoader: true,
        pagination    : null,
        urlQueryString: this.props.location.search,
        messageModal  : {},
        counter  : 0,
    };

    // -----------------------------------------------

    // Clone a separate copy of the "initialState" and assign it to the "state".
    // Note that the spread operator "..." is important to get a separate copy.
    state = { ...this.initialState };

    // -----------------------------------------------

    componentDidMount() {
        // More info: t.ly/ZVsd
        window.scrollTo(0, 0);

        // ----------------------

        // Properly set the browser's document title
        documentTitleService.setTitle(GeneralTexts["admin-dashboard"] + " | " + texts["admin-user-list"]);

        // ----------------------

        this.setAuthenticatedUser();
        this.getUsers();

        // TEST
        // console.log("urlQueryStringObject: ", this.urlQueryStringObject);
        // console.log("this.props.location: ", this.props.location);
        // console.log("window.location: ", window.location);
        // console.log("adminUserList route: ", getRoutePath("adminUserList"));
        // console.log("url: ", this.props.location.pathname + this.props.location.search + this.props.location.hash);
    }

    // -----------------------------------------------

    // getCurrentPageFullUrl = () => {
    //     return this.props.location.pathname + this.props.location.search + this.props.location.hash;
    // }

    // -----------------------------------------------

    filterUsers = async (event) => {
        const input = event.currentTarget;

        if (_isEmpty(this.urlQueryStringObject[input.name]) || input.name === "q") {
            this.urlQueryStringObject[input.name] = [input.value];
        } else {
            if (! Array.isArray(this.urlQueryStringObject[input.name])) {
                this.urlQueryStringObject[input.name] = [this.urlQueryStringObject[input.name]];
            }

            if (this.urlQueryStringObject[input.name].indexOf(input.value) === -1) {
                this.urlQueryStringObject[input.name].push(input.value);
            }
        }

        // ----------------------

        // console.log("this.urlQueryStringObject: ", this.urlQueryStringObject);

        // This fixes a bug that was happens when searching or filtering
        // the results while the "page" parameter is greater than 1
        this.urlQueryStringObject.page = 1;

        // let urlQueryString = queryString.stringify(this.urlQueryStringObject, {arrayFormat: "comma"});
        let urlQueryString = queryString.stringify(this.urlQueryStringObject, {arrayFormat: "separator", arrayFormatSeparator: "|"});

        // decodeURIComponent function decodes special characters.
        // In addition to these characters: , / ? : @ & = + $ #
        // More info: t.ly/3mji and t.ly/X7xQ
        urlQueryString = decodeURIComponent(urlQueryString);

        // console.log("urlQueryString: ", urlQueryString);

        await this.props.history.push(getRoutePath("adminUserList") + "?" + urlQueryString);

        // Start search by "q" textbox value only if its length is greater than 2 characters
        if (input.name === "q" && (String(input.value).length === 0 || String(input.value).length > 2)) {
            this.setState({showPageLoader: true});
            this.getUsers();
        }
    }

    // -----------------------------------------------

    // More info: t.ly/D6dh and t.ly/RWBp and t.ly/YqKn
    handleChangeOfReactSelectComponent = async (selectedOptions, actionMeta) => {
        // console.log("selectedOptions: ", selectedOptions);

        const inputName = actionMeta.name;
        let selectedValues;

        if (Array.isArray(selectedOptions)) {
            selectedValues = selectedOptions.map(option => option.value);
        } else {
            selectedValues = [selectedOptions?.value || ""];
        }

        this.urlQueryStringObject[inputName] = selectedValues;

        // console.log("this.urlQueryStringObject: ", this.urlQueryStringObject);

        // This fixes a bug that was happens when searching or filtering
        // the results while the "page" parameter is greater than 1
        this.urlQueryStringObject.page = 1;

        // let urlQueryString = queryString.stringify(this.urlQueryStringObject, { arrayFormat: "comma" });
        let urlQueryString = queryString.stringify(this.urlQueryStringObject, {arrayFormat: "separator", arrayFormatSeparator: "|"});

        // decodeURIComponent function decodes special characters.
        // In addition to these characters: , / ? : @ & = + $ #
        // More info: t.ly/3mji and t.ly/X7xQ
        urlQueryString = decodeURIComponent(urlQueryString);

        // console.log("urlQueryString: ", urlQueryString);

        this.setState({showPageLoader: true});

        await this.props.history.push(getRoutePath("adminUserList") + "?" + urlQueryString);
        this.getUsers();
    }

    // -----------------------------------------------

    handlePaginatorChange = async (pageNumber) => {
        this.urlQueryStringObject.page = [pageNumber];

        // console.log("this.urlQueryStringObject: ", this.urlQueryStringObject);

        // let urlQueryString = queryString.stringify(this.urlQueryStringObject, { arrayFormat: "comma" });
        let urlQueryString = queryString.stringify(this.urlQueryStringObject, {arrayFormat: "separator", arrayFormatSeparator: "|"});

        // decodeURIComponent function decodes special characters.
        // In addition to these characters: , / ? : @ & = + $ #
        // More inof: t.ly/3mji and t.ly/X7xQ
        urlQueryString = decodeURIComponent(urlQueryString);

        // console.log("urlQueryString: ", urlQueryString);

        this.setState({ showPageLoader: true });

        await this.props.history.push(getRoutePath("adminUserList") + "?" + urlQueryString);
        this.getUsers();
    }

    // -----------------------------------------------;

    getUsers = async () => {
        const urlQueryString = this.props.location.search;
        const response       = await userService.getList(urlQueryString);

        const users     = response.data.data.users;
        const paginator = response.data.meta.paginator;

        // TEST
        // console.log("USERS: ", users);

        this.setState({
            users: users || [],
            pagination: paginator ? getPaginator(paginator) : {},
            showPageLoader: false,
        });
    }

    // -----------------------------------------------

    getDefaultUserTypes = () => {
        let defaultUserTypes = [];
        let typesInQueryString = this.urlQueryStringObject?.type;

        if (_isEmpty(typesInQueryString)) {
            return defaultUserTypes;
        }

        if (!Array.isArray(typesInQueryString)) {
            typesInQueryString = [typesInQueryString];
        }

        for (let i = 0; i < (typesInQueryString).length; i++) {
            defaultUserTypes.push({
                value: typesInQueryString[i],
                label: GeneralTexts[typesInQueryString[i]]
            });
        }

        return defaultUserTypes;
    }

    // -----------------------------------------------

    getDefaultCountries = () => {
        let defaultCountries = [];
        let countriesInQueryString = this.urlQueryStringObject?.country;

        if (_isEmpty(countriesInQueryString)) {
            return defaultCountries;
        }

        const countryList = getCommonCountryList().map(country => {
            return {
                value: country.callingCodes[0],
                // label: country.name + " (+" + country.callingCodes[0] + ")"
                label: country.name
            };
        });

        if (!Array.isArray(countriesInQueryString)) {
            countriesInQueryString = [countriesInQueryString];
        }

        for (let i = 0; i < (countriesInQueryString).length; i++) {
            for (let j = 0; j < countryList.length; j++) {
                if (countriesInQueryString[i] === countryList[j].value) {
                    defaultCountries.push(countryList[j]);
                }
            }
        }

        return defaultCountries;
    }

    // -----------------------------------------------

    getDefaultGenders = () => {
        let defaultGenders = [];
        let genderInQueryString = this.urlQueryStringObject?.gender;

        if (_isEmpty(genderInQueryString)) {
            return defaultGenders;
        }

        const genderList = getUserGenders().map(gender => {
            return {
                value: gender.value,
                label: gender.label
            };
        });

        if (!Array.isArray(genderInQueryString)) {
            genderInQueryString = [genderInQueryString];
        }

        for (let i = 0; i < (genderInQueryString).length; i++) {
            for (let j = 0; j < genderList.length; j++) {
                if (genderInQueryString[i] === genderList[j].value) {
                    defaultGenders.push(genderList[j]);
                }
            }
        }

        return defaultGenders;
    }

    // -----------------------------------------------

    getDefaultAccountStatus = () => {
        let defaultAccountStatuses = [];
        let accountStatusInQueryString = this.urlQueryStringObject?.is_banned;

        if (_isEmpty(accountStatusInQueryString)) {
            return defaultAccountStatuses;
        }

        const accountStatusList = getBannedUserOptions().map(banned => {
            return {
                value: banned.value,
                label: banned.label
            };
        });

        if (!Array.isArray(accountStatusInQueryString)) {
            accountStatusInQueryString = [accountStatusInQueryString];
        }

        for (let i = 0; i < (accountStatusInQueryString).length; i++) {
            for (let j = 0; j < accountStatusList.length; j++) {
                if (accountStatusInQueryString[i] === accountStatusList[j].value) {
                    defaultAccountStatuses.push(accountStatusList[j]);
                }
            }
        }

        return defaultAccountStatuses;
    }

    // -----------------------------------------------

    getDefaultSortOrders = () => {
        let defaultSortOrders = [];
        let sortOrderInQueryString = this.urlQueryStringObject?.sort_by;

        if (_isEmpty(sortOrderInQueryString)) {
            return defaultSortOrders;
        }

        const sortOrderList = getSortOrders(["createdAt", "updatedAt", "lastLoginAt"]).map(sortOrder => {
            return {
                value: sortOrder.value,
                label: sortOrder.label
            };
        });

        if (!Array.isArray(sortOrderInQueryString)) {
            sortOrderInQueryString = [sortOrderInQueryString];
        }

        for (let i = 0; i < (sortOrderInQueryString).length; i++) {
            for (let j = 0; j < sortOrderList.length; j++) {
                // This is a special case for the "sort_by" query string parameter
                // if (`${sortOrderInQueryString[i]},${sortOrderInQueryString[i+1]}` === sortOrderList[j].value) {
                if (sortOrderInQueryString[i] === sortOrderList[j].value) {
                    defaultSortOrders.push(sortOrderList[j]);
                }
            }
        }

        return defaultSortOrders;
    }

    // -----------------------------------------------

    getDefaultNumberOfResultsPerPage = () => {
        const defaultNumberOfResultsPerPage = {value: "15", label: "15"};

        let defaultNumberOfResults = [];
        let numberOfResultsInQueryString = this.urlQueryStringObject?.results;

        if (_isEmpty(numberOfResultsInQueryString)) {
            // return defaultNumberOfResults;
            return [defaultNumberOfResultsPerPage];
        }

        const numberOfResultsList = getNumbersOfResultsPerPage().map(num => {
            return {
                value: num,
                label: num
            };
        });

        if (!Array.isArray(numberOfResultsInQueryString)) {
            numberOfResultsInQueryString = [numberOfResultsInQueryString];
        }

        for (let i = 0; i < (numberOfResultsInQueryString).length; i++) {
            for (let j = 0; j < numberOfResultsList.length; j++) {
                if (String(numberOfResultsInQueryString[i]) === String(numberOfResultsList[j].value)) {
                    defaultNumberOfResults.push(numberOfResultsList[j]);
                }
            }
        }

        return defaultNumberOfResults;
    }

    // -----------------------------------------------

    getPaginatorItems  = () => {
        const lastPage = Number(this.state.pagination?.last_page);
        const pageNumbers = (_range(1, lastPage + 1));

        const paginatorItems = pageNumbers.map(pageNumber => {
            return (
                <PaginationItem
                    key={Math.random()}
                    onClick={() => this.handlePaginatorChange(pageNumber)}
                    pageNumber={pageNumber}
                    paginator={this.state.pagination}
                />
            );
        });

        return paginatorItems;
    }

    // -----------------------------------------------

    clearAllSearchFilters = async () => {
        // this.urlQueryStringObject = {};

        // let urlQueryString = queryString.stringify(this.urlQueryStringObject, { arrayFormat: "comma" });

        // // decodeURIComponent function decodes special characters.
        // // In addition to these characters: , / ? : @ & = + $ #
        // // More info: t.ly/3mji and t.ly/X7xQ
        // urlQueryString = decodeURIComponent(urlQueryString);

        // this.setState({ showPageLoader: true });

        // await this.props.history.push(getRoutePath("adminUserList"));
        // this.getUsers();

        window.location = getRoutePath("adminUserList");
    }

    // -----------------------------------------------

    getSignupDate = (signupDate) => {
        if (_isEmpty(signupDate)) {
            return "...";
        }

        // If the signup year is the current year, then no need to return it
        if (moment(signupDate).format("YYYY") === moment().format("YYYY")) {
            return moment(signupDate).format("DD-MMM hh:mma");
        } else {
            // If the signup year is NOT the current year, then return it
            return moment(signupDate).format("DD-MMM-YYYY hh:mma");
        }
    }

    // -----------------------------------------------

    getLastLoginDate = (lastLoginDate) => {
        if (_isEmpty(lastLoginDate)) {
            return "...";
        }

        // If the year of the last login date is the current year, then no need to return it
        if (moment(lastLoginDate).format("YYYY") === moment().format("YYYY")) {
            return moment(lastLoginDate).format("DD-MMM hh:mma");
        } else {
            // If the year of the last login date is NOT the current year, then return it
            return moment(lastLoginDate).format("DD-MMM-YYYY hh:mma");
        }
    }

    // -----------------------------------------------

    getUserBirthDate = (user) => {
        if (user.user_type === "borrower") {
            if (_isEmpty(user.borrower.birth_date)) {
                return "...";
            }

            return moment(user.borrower.birth_date).format("DD-MMM-YYYY");
        }

        if (user.user_type === "lender") {
            if (_isEmpty(user.lender.birth_date)) {
                return "...";
            }

            return moment(user.lender.birth_date).format("DD-MMM-YYYY");
        }

        return "...";
    }

    // -----------------------------------------------

    getUserAge = (user) => {
        let birthDate = this.getUserBirthDate(user);

        if (_isEmpty(birthDate)) {
            return;
        }

        return moment().diff(birthDate, "years") || 0;
    }

    // -----------------------------------------------

    getUserAgeFormatted = (user) => {
        let age = this.getUserAge(user);

        if (age === 0) {
            return;
        }

        return "(" + age + " " + texts["years-old"] + ")";
    }

    // -----------------------------------------------

    getUserNationality = (user) => {
        if (user.user_type === "borrower") {
            if (_isEmpty(user.borrower.country_of_nationality)) {
                return "...";
            }

            return getCountry(user.borrower.country_of_nationality).name;
        }

        if (user.user_type === "lender") {
            if (_isEmpty(user.lender.country_of_nationality)) {
                return "...";
            }

            return getCountry(user.lender.country_of_nationality).name;
        }

        return getCountry(user.country_calling_code).name;
    }

    // -----------------------------------------------

    getUserNationalIdNumber = (user) => {
        if (user.user_type === "borrower") {
            if (_isEmpty(user.borrower.national_id_number)) {
                return "...";
            }

            return user.borrower.national_id_number;
        }

        if (user.user_type === "lender") {
            if (_isEmpty(user.lender.national_id_number)) {
                return "...";
            }

            return user.lender.national_id_number;
        }

        return "...";
    }

    // -----------------------------------------------

    getUserPassportNumber = (user) => {
        if (user.user_type === "borrower") {
            if (_isEmpty(user.borrower.passport_number)) {
                return "...";
            }

            return user.borrower.passport_number;
        }

        if (user.user_type === "lender") {
            if (_isEmpty(user.lender.passport_number)) {
                return "...";
            }

            return user.lender.passport_number;
        }

        return "...";
    }

    // -----------------------------------------------

    getUserAddress = (user) => {
        let address = "...";

        if (user.user_type === "borrower") {
            if (!_isEmpty(user.borrower.address_of_residence)) {
                address = user.borrower.address_of_residence;
            }

            // if (!_isEmpty(user.borrower.city_of_residence)) {
            //     address += " - " + user.borrower.city_of_residence;
            // }

            // if (!_isEmpty(user.borrower.country_of_residence)) {
            //     address += " - " + user.borrower.country_of_residence;
            // }

            return address;
        }

        if (user.user_type === "lender") {
            if (!_isEmpty(user.lender.address_of_residence)) {
                address = user.lender.address_of_residence;
            }

            // if (!_isEmpty(user.lender.city_of_residence)) {
            //     address += " - " + user.lender.city_of_residence;
            // }

            // if (!_isEmpty(user.lender.country_of_residence)) {
            //     address += " - " + user.lender.country_of_residence;
            // }

            return address;
        }

        return address;
    }

    // -----------------------------------------------

    getUserCityAndCountry = (user) => {
        let address = "";

        if (user.user_type === "borrower") {
            if (!_isEmpty(user.borrower.city_of_residence)) {
                address = user.borrower.city_of_residence;
            }

            if (!_isEmpty(user.borrower.country_of_residence)) {
                address += " - " + user.borrower.country_of_residence;
            }

            return address;
        }

        if (user.user_type === "lender") {
            if (!_isEmpty(user.lender.city_of_residence)) {
                address = user.lender.city_of_residence;
            }

            if (!_isEmpty(user.lender.country_of_residence)) {
                address += " - " + user.lender.country_of_residence;
            }

            return address;
        }

        return address;
    }

    // -----------------------------------------------

    getCompanyAddress = (user) => {
        let address = "...";

        if (!_isEmpty(user.borrower?.companies[0]?.address)) {
            address = user.borrower?.companies[0]?.address;
        }

        return address;
    }

    // -----------------------------------------------

    getCompanyCityAndCountry = (user) => {
        if (_isEmpty(user.borrower)) return;

        let address = "";

        if (!_isEmpty(user.borrower?.companies[0]?.city)) {
            address = user.borrower?.companies[0]?.city;
        }

        if (!_isEmpty(user.borrower?.companies[0]?.country)) {
            address += " - " + user.borrower?.companies[0]?.country;
        }

        return address;
    }

    // -----------------------------------------------

    getCompanyAgeFormatted = (year) => {
        if (!year) return;

        let age = (moment().year() - year) || 0;

        if (age < 0) return;

        // return "(" + texts["since"] + " " + age + " " + texts["years-ago"] + ")";
        return `(${texts["since"]} ${age} ${texts["years-ago"]})`;
    }

    // -----------------------------------------------

    getCompanyMobile = (user) => {
        if (_isEmpty(user.borrower?.companies[0]?.mobile)) return "...";

        return `(+${user.borrower?.companies[0]?.country_calling_code}) ${user.borrower?.companies[0]?.mobile}`;
    }

    // -----------------------------------------------

    getProperTitleForUserData = (user) => {
        if (user.user_type === "borrower") return texts["borrower-data"];

        if (user.user_type === "lender") return texts["lender-data"];

        if (user.user_type === "admin") return texts["admin-data"];

        return texts["user-data"];
    }

    // -----------------------------------------------

    getSearchHelpContent = () => {
        return (
            <div className={switchClasses(["x-ta-r", "x-dr-rtl", "x-fz-082rem"])}>
                <ul className="x-pr-20 x-pl-20 x-lh-30">
                    <li>{texts["fields-of-the-user-to-search-in"]}</li>
                    <li className="x-pt-10">{texts["search-by-word-or-number-or-part-of-it"]}</li>
                    <li className="x-pt-10">{texts["at-least-3-characters-or-numbers-needed-to-start-search"]}</li>
                </ul>
            </div>
        );
    }

    // -----------------------------------------------

    getRequestedLoans = (borrowerCompanies) => {
        let requestedLoans = [];

        for (let i = 0; i < borrowerCompanies.length; i++) {
            for (let j = 0; j < borrowerCompanies[i]?.loans?.length; j++) {
                requestedLoans.push(borrowerCompanies[i].loans[j]);
            }
        }

        return requestedLoans;
    }

    // -----------------------------------------------

    showSearchHelpContent = (content, modalSize = "md") => {
        this.setState({
            messageModal: {
                ...toggleMessageModal("info"),
                body: content, // Could be HTML content
                size: modalSize
            }
        });
    }

    // -----------------------------------------------

    showUserDetailsContent = (content, modalSize = "lg") => {
        this.setState({
            messageModal: {
                ...toggleMessageModal("custom"),
                body: content, // Could be HTML content
                size: modalSize
            },
            counter: 0
        });
    }

    // -----------------------------------------------

    getUserDetailsContent = (user) => {
        let userInfo = this.getUserInfoContent(user);
        let companyInfo = this.getUserCompanyInfoContent(user);
        let borrowerRequestedLoans = this.getBorrowerRequestedLoansContent(user);
        let lenderInvestments = this.getLenderInvestmentsContent(user);

        return (
            <>
                <div>{userInfo}</div>
                <div>{companyInfo}</div>
                <div>{borrowerRequestedLoans}</div>
                <div>{lenderInvestments}</div>
            </>
        );
    }

    // -----------------------------------------------

    getUserInfoContent = (user) => {
        return (
            <div className="_shadow-sm _body-bgc border x-bgc-fff x-p-20 x-bdrs-4">
                <div className={"x-c-000 x-fz-08rem " + switchClasses(["x-ta-r", "x-dr-rtl"])}>
                    <div className="row">
                        <div className="col-12">
                            <div className="x-m-0 x-pt-12 x-pb-12 _border-bottom">
                                <h5 className={"x-m-0 head-color _x-fz-15rem"}>{this.getProperTitleForUserData(user)}</h5>
                            </div>
                        </div>
                    </div>
                    <div className="row x-pt-10">
                        <div className="col-md-4">
                            <div className="x-m-0 x-pt-12 x-pb-12 border-bottom">
                                <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["name"]}</p>
                                <p className="x-m-0">{_startCase(_toLower(user.first_name))} {_startCase(_toLower(user.last_name))}</p>
                            </div>
                            <div className="x-m-0 x-pt-12 x-pb-12 border-bottom">
                                <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["account-type"]}</p>
                                <p className="x-m-0">{_startCase(_toLower(user.user_type))}</p>
                            </div>
                            <div className="x-m-0 x-pt-12 x-pb-12 border-bottom">
                                <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["nationality"]}</p>
                                <p className="x-m-0">{this.getUserNationality(user)}</p>
                            </div>
                            <div className="x-m-0 x-pt-12 x-pb-12 border-bottom">
                                <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["signup-date"]}</p>
                                <p className="x-m-0 x-dr-ltr">{this.getSignupDate(user.created_at)}</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="x-m-0 x-pt-12 x-pb-12 border-bottom">
                                <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["mobile"]}</p>
                                <p className="x-m-0 x-dr-ltr">(+{user.country_calling_code}) {user.mobile}</p>
                            </div>
                            <div className="x-m-0 x-pt-12 x-pb-12 border-bottom">
                                <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["gender"]}</p>
                                <p className="x-m-0">{_startCase(_toLower(user.gender))}</p>
                            </div>
                            <div className="x-m-0 x-pt-12 x-pb-12 border-bottom">
                                <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["national-id-number"]}</p>
                                <p className="x-m-0 x-dr-ltr">{this.getUserNationalIdNumber(user)}</p>
                            </div>
                            <div className="x-m-0 x-pt-12 x-pb-12 border-bottom">
                                <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["last-login-date"]}</p>
                                <p className="x-m-0 x-dr-ltr">{this.getLastLoginDate(user.last_login_at)}</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="x-m-0 x-pt-12 x-pb-12 border-bottom">
                                <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["email"]}</p>
                                <p className="x-m-0 x-dr-ltr">{_toLower(user.email)}</p>
                            </div>
                            <div className="x-m-0 x-pt-12 x-pb-12 border-bottom">
                                <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["birth-date"]}</p>
                                <p className="x-m-0">
                                    <span className="x-d-ib x-dr-ltr">{this.getUserBirthDate(user)}</span>
                                    &nbsp;&nbsp;&nbsp;
                                    <span className="x-c-999">{this.getUserAgeFormatted(user)}</span>
                                </p>
                            </div>
                            <div className="x-m-0 x-pt-12 x-pb-12 border-bottom">
                                <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["passport-number"]}</p>
                                <p className="x-m-0 x-dr-ltr">{this.getUserPassportNumber(user)}</p>
                            </div>
                            <div className="x-m-0 x-pt-12 x-pb-12 border-bottom">
                                <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["last-login-ip"]}</p>
                                <p className="x-m-0 x-dr-ltr">{user.last_login_ip || "..."}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="x-m-0 x-pt-12 x-pb-12 _border-bottom">
                                <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["address"]}</p>
                                <p className="x-m-0">{this.getUserAddress(user)}</p>
                                <p className="x-m-0">{this.getUserCityAndCountry(user)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    // -----------------------------------------------

    getUserCompanyInfoContent = (user) => {
        if (user.user_type !== "borrower") return;

        return (
            <div className="_shadow-sm _body-bgc border x-bgc-fff x-p-20 x-bdrs-4 x-mt-20">
                <div className={"x-c-000 x-fz-08rem " + switchClasses(["x-ta-r", "x-dr-rtl"])}>
                    <div className="row">
                        <div className="col-12">
                            <div className="x-m-0 x-pt-12 x-pb-12 _border-bottom">
                                <h5 className={"x-m-0 head-color _x-fz-15rem"}>{texts["company-data"]}</h5>
                            </div>
                        </div>
                    </div>
                    <div className="row x-pt-10">
                        <div className="col-md-4">
                            <div className="x-m-0 x-pt-12 x-pb-12 border-bottom">
                                <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["company-name"]}</p>
                                <p className="x-m-0">{_startCase(_toLower(user.borrower?.companies[0]?.official_name)) || "..."}</p>
                            </div>
                            <div className="x-m-0 x-pt-12 x-pb-12 border-bottom">
                                <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["activity-sector"]}</p>
                                <p className="x-m-0">{_startCase(_toLower(user.borrower?.companies[0]?.activity_sector)) || "..."}</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="x-m-0 x-pt-12 x-pb-12 border-bottom">
                                <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["company-type"]}</p>
                                <p className="x-m-0">{_startCase(_toLower(user.borrower?.companies[0]?.company_type)) || "..."}</p>
                            </div>
                            <div className="x-m-0 x-pt-12 x-pb-12 border-bottom">
                                <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["website"]}</p>
                                <p className="x-m-0 x-dr-ltr">{_toLower(user.borrower?.companies[0]?.website) || "..."}</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="x-m-0 x-pt-12 x-pb-12 border-bottom">
                                <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["founded-in"]}</p>
                                <p className="x-m-0">
                                    <span className="x-d-ib x-dr-ltr">{user.borrower?.companies[0]?.founded_in || "..."}</span>
                                    &nbsp;&nbsp;&nbsp;
                                    <span className="x-c-999">{this.getCompanyAgeFormatted(user.borrower?.companies[0]?.founded_in)}</span>
                                </p>
                            </div>
                            <div className="x-m-0 x-pt-12 x-pb-12 border-bottom">
                                <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["mobile"]}</p>
                                {/* <p className="x-m-0 x-dr-ltr">(+{user.borrower?.companies[0]?.country_calling_code}) {user.borrower?.companies[0]?.mobile}</p> */}
                                <p className="x-m-0 x-dr-ltr">{this.getCompanyMobile(user)}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="x-m-0 x-pt-12 x-pb-12 border-bottom">
                                <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["address"]}</p>
                                <p className="x-m-0">{this.getCompanyAddress(user)}</p>
                                <p className="x-m-0">{this.getCompanyCityAndCountry(user)}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="x-m-0 x-pt-12 x-pb-12 _border-bottom">
                                <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["business-cycle"]}</p>
                                <p className="x-m-0">{user.borrower?.companies[0]?.business_cycle || "..."}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    // -----------------------------------------------

    getBorrowerRequestedLoansContent = (user) => {
        if (user.user_type !== "borrower") return;

        return (
            <div className="_shadow-sm _body-bgc border x-bgc-fff x-p-20 x-bdrs-4 x-mt-20">
                <div className={"x-c-000 x-fz-08rem " + switchClasses(["x-ta-r", "x-dr-rtl"])}>
                    <div className="row">
                        <div className="col-12">
                            <div className="x-m-0 x-pt-12 x-pb-12 _border-bottom">
                                <h5 className={"x-m-0 head-color _x-fz-15rem"}>{texts["loans-he-requested"]}</h5>
                            </div>
                        </div>
                    </div>
                    <div className="row x-pt-10">
                        <div className="col-12">
                            {this.getNoLoanRequestedYetHint(user.borrower?.companies[0])}

                            <div className="table-responsive">
                                <table className="table table-lg table-hover x-fz-08rem">
                                    <tbody>
                                        {user.borrower?.companies[0]?.loans.map(loan => {
                                            return (
                                                <tr
                                                    key={Math.random()}
                                                    className={"x-cur-p "}
                                                    onClick={() => openLinkInNewTab(getRoutePath("loanDetails") + "/" + loan.uuid)}
                                                >
                                                    <td className="x-va-m">
                                                        {this.setState({ counter: this.state.counter++ })}
                                                        {this.state.counter}
                                                    </td>
                                                    <td>
                                                        <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["requested-amount"]}</p>
                                                        <p className="x-m-0 x-dr-ltr">
                                                            {(numeral(loan?.required_amount).format("0,0")) || 0}
                                                            &nbsp;
                                                            <span className="period x-c-999">{loan?.currency}</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["loan-purpose"]}</p>
                                                        <p className="x-m-0">{loan?.loan_purpose}</p>
                                                    </td>
                                                    <td>
                                                        <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["pledged-amount"]}</p>
                                                        <p className="x-m-0 x-dr-ltr">
                                                            {(numeral(loan?.pledged_amount).format("0,0")) || 0}
                                                            &nbsp;
                                                            <span className="period x-c-999">{loan?.currency}</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["committed-lenders"]}</p>
                                                        <p className="x-m-0 x-dr-ltr">{loan?.number_of_committed_lenders}</p>
                                                    </td>
                                                    <td>
                                                        <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["loan-status"]}</p>
                                                        <p className="x-m-0">{_startCase(_toLower(loan?.current_status))}</p>
                                                    </td>
                                                    <td className={"x-va-m link-like x-cur-p " + switchClasses(["x-ta-l"])}>
                                                        <Icon
                                                            path={mdiDotsHorizontal}
                                                            size="1.2rem"
                                                            className="_x-c-999 _x-pos-r _x-b-2"
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    // -----------------------------------------------

    getLenderInvestmentsContent = (user) => {
        if (user.user_type !== "lender") return;

        return (
            <div className="_shadow-sm _body-bgc border x-bgc-fff x-p-20 x-bdrs-4 x-mt-20">
                <div className={"x-c-000 x-fz-08rem " + switchClasses(["x-ta-r", "x-dr-rtl"])}>
                    <div className="row">
                        <div className="col-12">
                            <div className="x-m-0 x-pt-12 x-pb-12 _border-bottom">
                                <h5 className={"x-m-0 head-color _x-fz-15rem"}>{texts["loans-he-invested-in"]}</h5>
                            </div>
                        </div>
                    </div>
                    <div className="row x-pt-10">
                        <div className="col-12">
                            {this.getNoInvestmentsYetHint(user.lender?.loans)}

                            <div className="table-responsive">
                                <table className="table table-lg table-hover x-fz-08rem">
                                    <tbody>
                                        {user.lender?.loans?.map(loan => {
                                            return (
                                                <tr
                                                    key={Math.random()}
                                                    className={"x-cur-p "}
                                                    onClick={() => openLinkInNewTab(getRoutePath("loanDetails") + "/" + loan.uuid)}
                                                >
                                                    <td className="x-va-m">
                                                        {this.setState({counter: this.state.counter++})}
                                                        {this.state.counter}
                                                    </td>
                                                    <td>
                                                        <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["requested-amount"]}</p>
                                                        <p className="x-m-0 x-dr-ltr">
                                                            {(numeral(loan?.required_amount).format("0,0")) || 0}
                                                            &nbsp;
                                                            <span className="period x-c-999">{loan?.currency}</span>
                                                        </p>
                                                    </td>
                                                    {/* <td>
                                                        <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["loan-purpose"]}</p>
                                                        <p className="x-m-0">{loan?.loan_purpose}</p>
                                                    </td> */}
                                                    <td>
                                                        <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["pledged-amount"]}</p>
                                                        <p className="x-m-0 x-dr-ltr">
                                                            {(numeral(loan?.pledged_amount).format("0,0")) || 0}
                                                            &nbsp;
                                                            <span className="period x-c-999">{loan?.currency}</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["amount-he-invested"]}</p>
                                                        <p className="x-m-0 x-dr-ltr">
                                                            {(numeral(loan?.pivot?.amount).format("0,0")) || 0}
                                                            &nbsp;
                                                            <span className="period x-c-999">{loan?.currency}</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["committed-lenders"]}</p>
                                                        <p className="x-m-0 x-dr-ltr">{loan?.number_of_committed_lenders}</p>
                                                    </td>
                                                    <td>
                                                        <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["loan-status"]}</p>
                                                        <p className="x-m-0">{_startCase(_toLower(loan?.current_status))}</p>
                                                    </td>
                                                    <td className={"x-va-m link-like x-cur-p " + switchClasses(["x-ta-l"])}>
                                                        <Icon
                                                            path={mdiDotsHorizontal}
                                                            size="1.2rem"
                                                            className="_x-c-999 _x-pos-r _x-b-2"
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    // -----------------------------------------------

    getNoLoanRequestedYetHint = (company) => {
        if (_isEmpty(company) || _isEmpty(company?.loans)) {
            return (
                <div className="x-c-crimson">{texts["no-loans-yet"]}</div>
            );
        }

        return;
    }

    // -----------------------------------------------

    getNoInvestmentsYetHint = (loans) => {
        if (_isEmpty(loans)) {
            return (
                <div className="x-c-crimson">{texts["no-investments-yet"]}</div>
            );
        }

        return;
    }

    // -----------------------------------------------

    render() {
        const showHint = (
            <div className={"x-ta-c x-c-crimson x-pt-50 x-lh-34 " + switchClasses(["x-fz-085rem"])}>
                {texts["no-results-message"]}
            </div>
        );

        // ----------------------

        const showUsers = (
            <React.Fragment>
                <div className="">
                    <p className={"x-m-0 x-d-ib " + (_isEmpty(this.urlQueryStringObject) ? " x-v-h" : " ") + switchClasses(["x-fl-r"])}>
                        <Icon
                            path={mdiClose}
                            size="0.9rem"
                            className="x-c-999 _x-pos-r _x-b-2"
                        />
                        &nbsp;
                        <span
                            className={"link-like x-cur-p " + switchClasses(["x-fz-075rem"])}
                            onClick={() => this.clearAllSearchFilters()}
                        >
                            {GeneralTexts["clear-search-filters"]}
                        </span>
                    </p>

                    <p className={"x-m-0 x-d-ib " + switchClasses(["x-fl-l", "x-fz-08rem"])}>
                        <span className="x-fz-09rem">{this.state.pagination?.total}</span>
                        &nbsp;
                        <span className="x-fz-075rem x-c-999">{GeneralTexts["result"]}</span>
                    </p>
                    <div className="clearfix"></div>
                </div>

                <div className="table-responsive x-pt-7">
                    <table className="table table-lg table-hover x-fz-08rem">
                        <tbody>
                            {this.state.users.map(user => {
                                return (
                                    <tr
                                        key={Math.random()}
                                        className={"x-cur-p "}
                                        onClick={() => this.showUserDetailsContent(this.getUserDetailsContent(user))}
                                    >
                                        {/*
                                        <td>
                                            <p className="x-m-0">
                                                {user.first_name.replace(this.urlQueryStringObject?.q, () => <span className="x-bgc-yellow">{this.urlQueryStringObject?.q}</span>)}
                                                &nbsp;
                                                {_startCase(_toLower(user.last_name))}
                                            </p>
                                        </td>
                                        */}

                                        {/*
                                        <td>
                                            <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["name"]}</p>
                                            <p className="x-m-0">{_startCase(_toLower(user.first_name))} {_startCase(_toLower(user.last_name))}</p>
                                            <p className={"x-m-0 x-fz-07rem x-c-999 x-pt-15"}>{texts["account-type"]}</p>
                                            <p className="x-m-0">{_startCase(_toLower(user.user_type))}</p>
                                        </td>
                                        */}
                                        <td>
                                            <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["name"]}</p>
                                            <p className="x-m-0 _link-like _x-cur-p">{_startCase(_toLower(user.first_name))} {_startCase(_toLower(user.last_name))}</p>
                                        </td>
                                        <td>
                                            <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["account-type"]}</p>
                                            <p className="x-m-0">{_startCase(_toLower(user.user_type))}</p>
                                        </td>

                                        {/*
                                        <td>
                                            <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["mobile"]}</p>
                                            <p className="x-m-0 x-dr-ltr">(+{user.country_calling_code}) {user.mobile}</p>
                                            <p className={"x-m-0 x-fz-07rem x-c-999 x-pt-15"}>{texts["email"]}</p>
                                            <p className="x-m-0 x-dr-ltr">{_toLower(user.email)}</p>
                                        </td>
                                        */}
                                        <td>
                                            <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["mobile"]}</p>
                                            <p className="x-m-0 x-dr-ltr">(+{user.country_calling_code}) {user.mobile}</p>
                                        </td>
                                        <td>
                                            <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["email"]}</p>
                                            <p className="x-m-0 x-dr-ltr">{_toLower(user.email)}</p>
                                        </td>

                                        {/*
                                        <td>
                                            {(user.user_type === "borrower") ? (
                                                <>
                                                    <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["company"]}</p>
                                                    {user.borrower?.companies?.map((company) => {
                                                        return (<p key={Math.random()} className="x-m-0">- {_startCase(_toLower(company.official_name))}</p>)
                                                    })}
                                                </>
                                            ) : ""}
                                        </td>
                                        */}

                                        {/*
                                        <td>
                                            {(user.user_type === "lender" || user.user_type === "borrower") ? (
                                                <>
                                                    <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["loans-requested"]}</p>
                                                    <p className="x-m-0">{this.getRequestedLoans(user.borrower?.companies || []).length || 0}</p>
                                                    <p className={"x-m-0 x-fz-07rem x-c-999 x-pt-15"}>{texts["loans-invested-in"]}</p>
                                                    <p className="x-m-0">{user.lender?.loans?.length || 0}</p>
                                                </>
                                            ) : ""}
                                        </td>
                                        */}
                                        <td>
                                            {(user.user_type === "admin") ? "" : ""}

                                            {(user.user_type === "borrower") ? (
                                                <>
                                                    <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["loans-requested"]}</p>
                                                    <p className="x-m-0">{this.getRequestedLoans(user.borrower?.companies).length}</p>
                                                </>
                                            ) : ""}

                                            {(user.user_type === "lender") ? (
                                                <>
                                                    <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["loans-invested-in"]}</p>
                                                    <p className="x-m-0">{user.lender?.loans?.length}</p>
                                                </>
                                            ) : ""}
                                        </td>

                                        <td className={switchClasses(["x-ta-r"])}>
                                            <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["last-login-at"]}</p>
                                            <p className="x-m-0 x-dr-ltr">{this.getLastLoginDate(user.last_login_at)}</p>
                                        </td>

                                        {/*
                                        <td className={switchClasses(["x-ta-l"])}>
                                            <p className={"x-m-0 x-fz-07rem x-c-999"}>{texts["last-login-at"]}</p>
                                            <p className="x-m-0 x-dr-ltr">{this.getLastLoginDate(user.last_login_at)}</p>
                                            <p className={"x-m-0 x-fz-07rem x-c-999 x-pt-15"}>{texts["signed-up-at"]}</p>
                                            <p className="x-m-0 x-dr-ltr">{this.getSignupDate(user.created_at)}</p>
                                        </td>
                                        */}

                                        {/*
                                        <td className={switchClasses(["x-ta-l"])}>
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-sm shadow-sm x-fz-075rem"
                                                onClick={() => this.props.history.push(getRoutePath("") + "/" + user.uuid)}
                                            >
                                                {texts["details"]}
                                            </button>
                                        </td>
                                        */}

                                        <td className={"x-va-m _x-w-50 link-like x-cur-p " + switchClasses(["x-ta-l"])}>
                                            <Icon
                                                path={mdiDotsHorizontal}
                                                size="1.2rem"
                                                className="_x-c-999 _x-pos-r _x-b-2"
                                            />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </React.Fragment>
        );

        // ----------------------

        const showPaginator = (
            <React.Fragment>
                <div className="x-pt-30">
                    <Pagination paginatorItems={this.getPaginatorItems()} />

                    {/*
                    <Pagination>
                        <Pagination.Item
                            key={1}
                            active={1 === this.state.pagination?.current_page}
                            // href={`${this.props.match.url}?page=1`}
                            href={this.getNewUrl(1)}
                            // onClick={this.handlePaginatorChange(1)}
                        >
                            {1}
                        </Pagination.Item>

                        <Pagination.Ellipsis />
                        {this.getPaginatorItems()}
                        <Pagination.Ellipsis />

                        <Pagination.Item
                            key={this.state.pagination?.last_page}
                            active={this.state.pagination?.last_page === this.state.pagination?.current_page}
                            // href={`${this.props.match.url}?page=${this.state.pagination?.last_page}`}
                            href={this.getNewUrl(this.state.pagination?.last_page)}
                            // onClick={this.handlePaginatorChange(this.state.pagination?.last_page)}
                        >
                            {this.state.pagination?.last_page}
                        </Pagination.Item>

                    </Pagination>;
                    */}
                </div>
            </React.Fragment>
        );

        // ----------------------

        const pageContent = (
            <React.Fragment>
                <div>
                    <div className={"x-m-25 x-mb-15 " + switchClasses(["x-mr-15"])}>
                        <h5 className={"x-pt-10 head-color "}>
                            {texts["admin-user-list"]}
                        </h5>
                    </div>

                    <div className="x-mt-15">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-3 col-lg-2 x-pr-8 x-pl-8">
                                    <div className={"list-group _x-bgc-fff shadow-sm x-mt-15 x-mb-30 " + switchClasses(["x-fz-085rem"])}>
                                        <li className={"list-group-item list-group-item-action x-p-10 x-pt-20"}>
                                            <div className="form-group">
                                                <p className="_head-color x-mb-2">
                                                    {GeneralTexts["search"]}
                                                    &nbsp;
                                                    <span
                                                        className={"link-like x-cur-p x-fz-075rem " + switchClasses(["x-fl-l"])}
                                                        onClick={() => this.showSearchHelpContent(this.getSearchHelpContent())}
                                                    >
                                                        {/* {GeneralTexts["info"]} */}
                                                        {GeneralTexts["help"]}
                                                    </span>
                                                    <span className="clearfix"></span>
                                                </p>
                                                <input
                                                    autoComplete="off"
                                                    className="form-control x-bdrs-2 x-fz-08rem x-h-38 _x-c-blue"
                                                    name="q"
                                                    onChange={this.filterUsers}
                                                    placeholder=""
                                                    type="text"
                                                    value={this.urlQueryStringObject?.q || ""}
                                                />
                                            </div>
                                            {/*
                                            <div className="input-group mb-3">
                                                <input
                                                    autoComplete="off"
                                                    className="form-control x-fz-08rem x-h-38"
                                                    name="q"
                                                    onBlur={this.filterUsers}
                                                    placeholder=""
                                                    type="text"
                                                    value={this.urlQueryStringObject?.q}
                                                />
                                                <div className="input-group-append">
                                                    <button className="btn btn-primary" type="button">Go</button>
                                                </div>
                                            </div>
                                            */}
                                        </li>

                                        <li className={"list-group-item list-group-item-action x-p-10 x-pt-20"}>
                                            <p className="_head-color">{texts["more-search-filters"]}</p>

                                            <div className="form-group">
                                                <p className="_head-color x-mb-2 x-c-777 x-fz-075rem">{texts["account-type"]}</p>
                                                <Select
                                                    className="x-fz-075rem"
                                                    closeMenuOnSelect={true}
                                                    defaultValue={this.getDefaultUserTypes()}
                                                    isClearable={true}
                                                    isDisabled={false}
                                                    isMulti={true}
                                                    isSearchable={true}
                                                    name="type"
                                                    onChange={this.handleChangeOfReactSelectComponent}
                                                    options={[
                                                        { value: "borrower", label: GeneralTexts["borrower"] },
                                                        { value: "lender", label: GeneralTexts["lender"] },
                                                        { value: "admin", label: GeneralTexts["admin"] },
                                                    ]}
                                                    placeholder={GeneralTexts["select-option"]}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <p className="_head-color x-mb-2 x-c-777 x-fz-075rem">{texts["country"]}</p>
                                                <Select
                                                    className="x-fz-075rem"
                                                    closeMenuOnSelect={true}
                                                    defaultValue={this.getDefaultCountries()}
                                                    isClearable={true}
                                                    isDisabled={false}
                                                    isMulti={true}
                                                    isSearchable={true}
                                                    name="country"
                                                    onChange={this.handleChangeOfReactSelectComponent}
                                                    options={getCommonCountryList().map(country => {
                                                        return {
                                                            value: country.callingCodes[0],
                                                            label: country.name
                                                        };
                                                    })}
                                                    placeholder={GeneralTexts["select-option"]}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <p className="_head-color x-mb-2 x-c-777 x-fz-075rem">{texts["gender"]}</p>
                                                <Select
                                                    className="x-fz-075rem"
                                                    closeMenuOnSelect={true}
                                                    defaultValue={this.getDefaultGenders()}
                                                    isClearable={true}
                                                    isDisabled={false}
                                                    isMulti={false}
                                                    isSearchable={true}
                                                    name="gender"
                                                    onChange={this.handleChangeOfReactSelectComponent}
                                                    options={getUserGenders().map(gender => {
                                                        return {
                                                            value: gender.value,
                                                            label: gender.label
                                                        };
                                                    })}
                                                    placeholder={GeneralTexts["select-option"]}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <p className="_head-color x-mb-2 x-c-777 x-fz-075rem">{texts["account-state"]}</p>
                                                <Select
                                                    className="x-fz-075rem"
                                                    closeMenuOnSelect={true}
                                                    defaultValue={this.getDefaultAccountStatus()}
                                                    isClearable={true}
                                                    isDisabled={false}
                                                    isMulti={false}
                                                    isSearchable={true}
                                                    name="is_banned"
                                                    onChange={this.handleChangeOfReactSelectComponent}
                                                    options={getBannedUserOptions().map(banned => {
                                                        return {
                                                            value: banned.value,
                                                            label: banned.label
                                                        };
                                                    })}
                                                    placeholder={GeneralTexts["select-option"]}
                                                />
                                            </div>
                                        </li>

                                        <li className={"list-group-item list-group-item-action x-p-10 x-pt-20"}>
                                            <p className="_head-color">{texts["sorting-order"]}</p>

                                            <div className="form-group">
                                                <p className="_head-color x-mb-2 x-c-777 x-fz-075rem">{texts["sort-by"]}</p>
                                                <Select
                                                    className="x-fz-075rem"
                                                    closeMenuOnSelect={true}
                                                    defaultValue={this.getDefaultSortOrders()}
                                                    isClearable={true}
                                                    isDisabled={false}
                                                    isMulti={false}
                                                    isSearchable={true}
                                                    name="sort_by"
                                                    onChange={this.handleChangeOfReactSelectComponent}
                                                    options={getSortOrders(["createdAt", "updatedAt", "lastLoginAt"]).map(sortOrder => {
                                                        return {
                                                            value: sortOrder.value,
                                                            label: sortOrder.label
                                                        };
                                                    })}
                                                    placeholder={GeneralTexts["select-option"]}
                                                />
                                            </div>
                                        </li>

                                        <li className={"list-group-item list-group-item-action x-p-10 x-pt-20"}>
                                            <p className="_head-color">{GeneralTexts["displaying-results"]}</p>

                                            <div className="form-group">
                                                <p className="_head-color x-mb-2 x-c-777 x-fz-075rem">{GeneralTexts["number-of-results-per-page"]}</p>
                                                <Select
                                                    className="x-fz-075rem"
                                                    closeMenuOnSelect={true}
                                                    defaultValue={this.getDefaultNumberOfResultsPerPage()}
                                                    isClearable={true}
                                                    isDisabled={false}
                                                    isMulti={false}
                                                    isSearchable={true}
                                                    name="results"
                                                    onChange={this.handleChangeOfReactSelectComponent}
                                                    options={getNumbersOfResultsPerPage().map(num => {
                                                        return {
                                                            value: num,
                                                            label: num
                                                        };
                                                    })}
                                                    placeholder={GeneralTexts["select-option"]}
                                                />
                                            </div>
                                        </li>
                                    </div>
                                </div>

                                <div className="col-md-9 col-lg-10 x-pr-8 x-pl-8">
                                    <div className="_border _border-light shadow-sm x-m-0 x-mt-15 x-mb-70 x-p-20 x-pt-16 x-pb-100 x-mih-500 x-bgc-fff x-bdc-e3e3e3 x-bdrs-4">
                                        <PageLoader show={this.state.showPageLoader} />

                                        <div className={"row" + (this.state.showPageLoader ? " x-d-n" : "")}>
                                            <div className="col">
                                                {
                                                    this.state.users.length === 0
                                                        ? showHint
                                                        : showUsers
                                                }
                                                {showPaginator}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <MessageModal
                        show={this.state.messageModal.show || false}
                        title={this.state.messageModal.title || null}
                        body={this.state.messageModal.body || null}
                        alertClassName={this.state.messageModal.alertClassName || null}
                        size={this.state.messageModal.size || null}
                        centered={this.state.messageModal.centered || false}
                        footerButtons={this.state.messageModal.footerButtons || []}
                        onHide={this.state.messageModal.onHide || (() => this.setState({ messageModal: { ...this.initialState.messageModal } }))}
                    />
                </div>
            </React.Fragment>
        );

        return (
            <PageContent
                pageContent={pageContent}
                authenticatedUser={this.getAuthenticatedUser()}
                showPageContent={this.showPageContent()}
                windowLocation={window.location}
            />
        );
    }
}
