import { preferredLanguage } from "./../configs/ui-language-config";
// import { GeneralTexts } from "./GeneralTexts";
import { RouteConfigTexts } from "./RouteConfigTexts";

const texts = {
    ar: {
        "admin-loan-documents"                      : RouteConfigTexts["admin-loan-documents"],
        "admin-loan-list"                           : RouteConfigTexts["admin-loan-list"],
        "user-national-id-card-image"               : "بطاقة الرقم القومي",
        "company-commercial-register-image"         : "السجل التجاري للشركة",
        "company-tax-card-image"                    : "البطاقة الضريبية للشركة",
        "user-passport-image"                       : "جواز السفر (إن وجد)",
        "company-operating-licenses-images"         : "تراخيص مزاولة النشاط المختلفة (إن وجد)",
        "company-vat-registration-certificate-image": "شهادة تسجيل ضريبة القيمة المضافة (إن وجد)",
        "company-industrial-license-image"          : "رخصة النشاط الصناعي (إن وجد)",
        "click-the-file-name-or-icon-to-show-it"    : "اضغط على اسم أو أيقونة الملف ليتم عرضه.",
    },

    en: {
        "admin-loan-documents"                      : RouteConfigTexts["admin-loan-documents"],
        "admin-loan-list"                           : RouteConfigTexts["admin-loan-list"],
        "user-national-id-card-image"               : "National ID Card",
        "company-commercial-register-image"         : "Company Commercial Register",
        "company-tax-card-image"                    : "Company Tax Card",
        "user-passport-image"                       : "Passport (if exists)",
        "company-operating-licenses-images"         : "Company Operating Licenses (if exist)",
        "company-vat-registration-certificate-image": "Company VAT Registration Certificate (if exists)",
        "company-industrial-license-image"          : "Company Industrial License (if exists)",
        "click-the-file-name-or-icon-to-show-it"    : "Click the name or the icon of the file to show it.",
    }
}

export const AdminLoanDocumentsTexts = texts[preferredLanguage.alpha2code];
