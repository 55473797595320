import React from "react";
import { Link } from "react-router-dom";

import { switchClasses } from "./../../languages/CssClasses";
import { LoanCardTexts as texts } from "./../../languages/LoanCardTexts";

// Third-Party Modules
// import moment from "moment";
import numeral from "numeral"

// Our Configs
import { getRoutePath } from "./../../configs/route-config";
import defaultLoanCoverImage from "./../../../src/resources/images/campaign-default-image-800x450.png";

const LoanCardV2 = (props) => {
    const requiredAmount            = props.loan.required_amount;
    const pledgedAmount             = props.loan.pledged_amount;
    const remainingAmount           = requiredAmount - pledgedAmount;
    const pledgedAmountPercentage   = ((pledgedAmount / requiredAmount) * 100).toFixed(0);
    const remainingAmountPercentage = (((requiredAmount - pledgedAmount) / requiredAmount) * 100).toFixed(0);
    const numberOfCommittedLenders  = props.loan.number_of_committed_lenders || 0;
    const loanCoverImage            = props.loan.cover_image_url || defaultLoanCoverImage;
    const loanDurationInMonths      = props.loan.loan_duration_in_months || 0;
    const lenderROI                 = props.loan.lender_return || 0;

    return (
        <>
            <div className="col-md-4 x-pt-30">
                <Link to={getLoanDetailsUrl(props)} className="_link-color x-td-n">
                    <div className="x-bdrs-5 x-ov-h x-c-000 shadow-sm">
                        <div className="x-bdbw-1 x-bdbc-eee x-bdbs-s">
                            {/* TODO: Image of the loan campaign should be dynamic */}
                            <img
                                alt=""
                                className="img-fluid x-m-a"
                                src={loanCoverImage}
                                style={{width: "100%"}}
                            />
                        </div>

                        <div className="x-bgc-fff x-pt-20 x-pb-20">
                            <div className="x-pr-15 x-pl-15">
                                <h6 className="text-muted x-ta-c x-fz-078rem x-fw-n x-m-0 ">
                                    {texts["loan-amount"]}
                                </h6>
                                <h5 className="card-price x-ta-c x-dr-ltr x-c-000 x-pt-5 x-fz-2rem">
                                    {numeral(requiredAmount).format("0,0") || 0}
                                    &nbsp;<span className="_period _x-c-999 x-fw-200 x-fz-16rem">{props.loan.currency}</span>
                                </h5>
                                <div className={"progress x-mt-10 x-bdrs-10 " + switchClasses(["x-dr-rtl"])} style={{ height: "0.5rem" }}>
                                    <div className="progress-bar green_bgc" role="progressbar" style={{ width: pledgedAmountPercentage + "%", _backgroundColor: "#00CC6F" }} aria-valuenow={pledgedAmountPercentage} aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>

                            <div className="x-pt-30 x-pb-10">
                                <div className="row no-gutters">
                                    {/*
                                    <div className={"col-4 " + switchClasses(["x-pr-10", "_x-pl-5"])}>
                                        <p className="x-m-0 _x-fw-b x-fz-1rem">
                                            <span className="x-fw-n x-fz-08rem text-success">{pledgedAmountPercentage + "%"}</span><br />
                                            <span className="x-fw-n x-fz-07rem x-c-999">{props.loan.currency}</span>
                                            &nbsp;{(numeral(pledgedAmount).format("0,0")) || 0}
                                        </p>
                                        <p className="x-m-0 x-c-999 x-fz-078rem">
                                            {texts["pledged"]}
                                        </p>
                                    </div>
                                    */}
                                    <div className={"col-4 x-ta-c x-pt-8 x-pb-8 " + switchClasses(["_x-pr-10"])}>
                                        <p className="x-m-0 x-fz-1rem " style={{direction: "ltr"}}>
                                            <span>{(numeral(remainingAmount).format("0,0")) || 0}</span>
                                            &nbsp;
                                            <span className="x-fw-n x-fz-09rem">{props.loan.currency}</span>
                                        </p>
                                        <p className="x-m-0 x-pt-5 x-c-999 x-fz-078rem">
                                            {texts["remaining"]}
                                        </p>
                                    </div>

                                    {/*
                                    <div className={"col-4 " + switchClasses(["x-pr-10"])} style={{ borderRight: "1px solid #ddd", borderLeft: "1px solid #ddd" }}>
                                        <p className="x-m-0 x-fz-1rem">
                                            <span className="x-fw-n x-fz-08rem text-danger">{remainingAmountPercentage + "%"}</span><br />
                                            <span className="x-fw-n x-fz-07rem x-c-999">{props.loan.currency}</span>
                                            &nbsp;{(numeral(remainingAmount).format("0,0")) || 0}
                                        </p>
                                        <p className="x-m-0 x-c-999 x-fz-078rem">
                                            {texts["remaining"]}
                                        </p>
                                    </div>
                                    */}
                                    <div className={"col-4 x-ta-c x-p-0 x-pt-8" + switchClasses(["_x-pr-10"])} style={{ borderRight: "1px solid #ddd", borderLeft: "1px solid #ddd" }}>
                                        <p className="x-m-0 x-fz-1rem">
                                            {loanDurationInMonths || "N/A"}
                                            &nbsp;<span className="x-fw-n x-fz-085rem">{loanDurationInMonths < 1 ? "" : (loanDurationInMonths > 1 ? texts["months"] : texts["month"])}</span>
                                        </p>
                                        <p className="x-m-0 x-pt-5 x-c-999 x-fz-078rem">
                                            {texts["loan-duration"]}
                                        </p>
                                    </div>

                                    {/*
                                    <div className={"col-4 " + switchClasses(["_x-pr-10", "_x-pl-10"])}>
                                        <p className="x-m-0 _x-fw-b x-fz-1rem x-ta-c">
                                            <span className="x-fw-n x-fz-08rem x-c-t">.</span><br />
                                            {(numeral(numberOfCommittedLenders).format("0,0")) || 0}
                                        </p>
                                        <p className="x-m-0 x-c-999 x-fz-078rem x-ta-c">
                                            {texts["committed-lenders"]}
                                        </p>
                                    </div>
                                    */}
                                    <div className={"col-4 x-ta-c x-p-x x-pt-8 " + switchClasses(["_x-pr-10"])}>
                                        <p className="x-m-0 x-fz-1rem " style={{direction: "ltr"}}>
                                            <span>{lenderROI ? (numeral(lenderROI).format("0,0")) : "N/A"}</span>
                                            &nbsp;
                                            <span className="x-fw-n x-fz-085rem">{lenderROI ? "%" : ""}</span>
                                        </p>
                                        <p className={"x-m-0 x-pt-5 x-c-999 " + switchClasses(["x-fz-075rem"])} >
                                            {texts["ROI"]}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </>
    );
};

// -----------------------------------------------

const getLoanDetailsUrl = (props) => {
    const loan = props.loan;
    return getRoutePath("loanDetails") + "/" + loan.uuid;

    // NOTE: the following is the old way of getting the loan details url. It's disabled
    // because we no longer redirect the user to the loan details page according to his type
    // We, now, redirect the the users of any type to the loan details page.
    // The following code is kept here till we make sure everything works fine.

    // const authenticatedUser = props.authenticatedUser || {};
    // switch (authenticatedUser.user_type) {
    //     case "admin":
    //         return getRoutePath("adminLoanBasic") + "/" + loan.uuid;

    //     case "lender":
    //         return getRoutePath("lenderLoanDetails") + "/" + loan.uuid;

    //     case "borrower":
    //         // Borrower shouldn't be able to see details of a loan that doesn't
    //         // belong to him so we will redirect him to his loan list instead.
    //         return getRoutePath("borrowerLoanList");

    //     default:
    //         return getRoutePath("login");
    // }
}

// -----------------------------------------------

export default LoanCardV2;
