import { preferredLanguage } from "./../configs/ui-language-config";
import { GeneralTexts } from "./GeneralTexts";
import { RouteConfigTexts } from "./RouteConfigTexts";

const texts = {
    ar: {
        "admin-overview-general"        : RouteConfigTexts["admin-overview-general"],
        "admin-overview-loans"          : RouteConfigTexts["admin-overview-loans"],
        "all-time"                      : GeneralTexts["all-time"],
        "today"                         : GeneralTexts["today"],
        "yesterday"                     : GeneralTexts["yesterday"],
        "last-7-days"                   : GeneralTexts["last-7-days"],
        "last-30-days"                  : GeneralTexts["last-30-days"],
        "loans"                         : "القروض",
        "all-loans"                     : "جميع القروض",
        "loans-of-all-statuses-hint"    : "القروض من جميع الحالات",
        "pending"                       : "الجديدة",
        "pending-loan-requests-hint"    : "طلبات القروض الجديدة",
        "under-review"                  : "تحت المراجعة",
        "under-review-loans-hint"       : "طلبات القروض تحت المراجع",
        "approved"                      : "المقبولة",
        "approved-loans-hint"           : "طلبات القروض المقبولة",
        "rejected"                      : "المرفوضة",
        "rejected-loans-hint"           : "طلبات القروض المرفوضة",
        "failed"                        : "الفاشلة",
        "failed-loans-hint"             : "القروض الفاشلة",
        "fully-succeeded"               : "الناحجة بالكامل",
        "fully-succeeded-loans-hint"    : "القروض الناجحة بالكامل",
        "partially-succeeded"           : "الناجحة جزئيا",
        "partially-succeeded-loans-hint": "القروض الناجحة جزئيا",
        // "loans-chart"                   : "الشكل البياني للقروض",
    },

    en: {
        "admin-overview-general"        : RouteConfigTexts["admin-overview-general"],
        "admin-overview-loans"          : RouteConfigTexts["admin-overview-loans"],
        "all-time"                      : GeneralTexts["all-time"],
        "today"                         : GeneralTexts["today"],
        "yesterday"                     : GeneralTexts["yesterday"],
        "last-7-days"                   : GeneralTexts["last-7-days"],
        "last-30-days"                  : GeneralTexts["last-30-days"],
        "loans"                         : "Loans",
        "all-loans"                     : "All Loans",
        "loans-of-all-statuses-hint"    : "Loans of All Statuses",
        "pending"                       : "Pending (New)",
        "pending-loan-requests-hint"    : "New Loan Requests",
        "under-review"                  : "Under Review",
        "under-review-loans-hint"       : "Under-Review Loans",
        "approved"                      : "Approved",
        "approved-loans-hint"           : "Approved Loans",
        "rejected"                      : "Rejected",
        "rejected-loans-hint"           : "Rejected Loans",
        "failed"                        : "Failed",
        "failed-loans-hint"             : "Failed Loans",
        "fully-succeeded"               : "Fully Succeeded",
        "fully-succeeded-loans-hint"    : "Fully Succeeded Loans",
        "partially-succeeded"           : "Partially Succeeded",
        "partially-succeeded-loans-hint": "Partially Succeeded Loans",
        // "loans-chart"                   : "Loans Chart",
    }
}

export const AdminOverviewLoansTexts = texts[preferredLanguage.alpha2code];
