import React from "react";
// import { Link } from "react-router-dom";

import { preferredLanguage } from "./../configs/ui-language-config";
// import { GeneralTexts } from "./GeneralTexts";
// import { RouteConfigTexts } from "./RouteConfigTexts";

import { getEmail, getWhatsapp } from "./../configs/general-config";

const texts = {
    ar: {
        "lender-loan-details"                       : "تفاصيل الفرصة الاستثمارية",
        "required-amount"                           : "المبلغ المطلوب",
        "pledged"                                   : "المبلغ المُتعهد به",
        "remaining"                                 : "المبلغ المتبقى",
        "committed-investors"                       : "المستثمرين المشاركين",
        "transaction-info"                          : "العملية محل التمويل",
        "transaction-type"                          : "نوع العملية",
        "transaction-duration"                      : "مدة العملية",
        "transaction-gross-profit"                  : "نسبة مجمل الربح (%)",
        "borrower-has-experience-in-the-transaction": "المقترض لديه خبرة في العملية؟",
        "transaction-description"                   : "وصف العملية",
        "company-info"                              : "معلومات عن الشركة",
        "headquarter-city"                          : "مدينة المقر الرئيسي",
        "company-type"                              : "نوع الشركة",
        "activity-sector"                           : "مجال النشاط",
        "founded-in"                                : "تأسست سنة",
        "operating-license-status"                  : "حالة رخصة التشغيل",
        "number-of-employees"                       : "عدد الموظفين",
        "capital-size"                              : "رأس المال",
        "average-monthly-sales"                     : "متوسط المبيعات الشهرية",
        "last-year-revenue"                         : "إيرادات السنة الماضية",
        "has-bank-account"                          : "لديها حساب بنكي؟",
        "has-financial-statements"                  : "لديها قوائم مالية؟",
        "has-tax-related-issues"                    : "لديها مشاكل متعلقة بالضرائب؟",
        "has-social-insurance-related-issues"       : "لديها مشاكل متعلقة بالتأمينات الاجتماعية؟",
        "has-existing-bank-loans"                   : "حاصلة حاليا على  قروض بنكية؟",
        "has-defaulted-loans"                       : "لديها قروض متعثرة؟",
        "loan-risk-profiling"                       : "تصنيف مخاطر القرض",
        "invest-in-this-loan"                       : "استثمر في هذه الفرصة",
        "amount-to-invest"                          : "مبلغ الاستثمار",
        "month"                                     : "شهر",
        "employee"                                  : "موظف",
        "want-to-invest": (
            <span>
                هل ترغب في الاستثمار في هذه الفرصة؟
                <br />
                اضغط الزر التالي لتدخل قيمة المبلغ الذي تريد استثماره
            </span>
        ),
        "further-inquiry-hint": (
            <span>
                إذا كان لديك مزيد من الاستفسارات، لا تترد في التواصل معنا. يمكنك أن ترسل لنا إيميل على <strong>{getEmail("info")}</strong>&nbsp;
                أو رسالة واتساب على رقم <strong>{getWhatsapp("info")}</strong> وسوف نرد عليك في أسرع وقت.
            </span>
        ),
    },

    en: {
        "lender-loan-details"                       : "Investment Opportunity Details",
        "required-amount"                           : "Required Amount",
        "pledged"                                   : "Pledged",
        "remaining"                                 : "Remaining",
        "committed-investors"                       : "Committed Investors",
        "transaction-info"                          : "Transaction Info",
        "transaction-type"                          : "Transaction Type",
        "transaction-duration"                      : "Transaction Duration",
        "transaction-gross-profit"                  : "Transaction Gross Profit (%)",
        "borrower-has-experience-in-the-transaction": "Borrower Has Experience in The Transaction?",
        "transaction-description"                   : "Transaction Description",
        "company-info"                              : "Company Info",
        "headquarter-city"                          : "Headquarter City",
        "company-type"                              : "Company Type",
        "activity-sector"                           : "Activity Sector",
        "founded-in"                                : "Founded in",
        "operating-license-status"                  : "Operating License Status",
        "number-of-employees"                       : "No. of Employees",
        "capital-size"                              : "Capital Size",
        "average-monthly-sales"                     : "Average Monthly Sales",
        "last-year-revenue"                         : "Last Year Revenue",
        "has-bank-account"                          : "Has Bank Account?",
        "has-financial-statements"                  : "Has Financial Statements?",
        "has-tax-related-issues"                    : "Has Tax-Related Issues?",
        "has-social-insurance-related-issues"       : "Has Social Insurance Related Issues?",
        "has-existing-bank-loans"                   : "Has Existing Bank Loans?",
        "has-defaulted-loans"                       : "Has Defaulted Loans?",
        "loan-risk-profiling"                       : "Loan Risk Profiling",
        "invest-in-this-loan"                       : "Invest in This Opportunity",
        "amount-to-invest"                          : "Amount to Invest",
        "month"                                     : "months",
        "employee"                                  : "Employee",
        "want-to-invest": (
            <span>
                Do you want to invest in this opportunity?
                <br />
                Click the next button to enter the amount you want to invest.
            </span>
        ),
        "further-inquiry-hint": (
            <span>
                If you have any further inquiries, don't hesitate to contact us. Send us an email to <strong>{getEmail("info")}</strong>&nbsp;
                or a WhatsApp message to <strong>{getWhatsapp("info")}</strong> and we will get back to you as soon as possible.
            </span>
        ),
    }
}

export const LenderLoanDetailsTexts = texts[preferredLanguage.alpha2code];
