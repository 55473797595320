import React from "react";
import { Link, NavLink } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";

import {
	preferredLanguage,
	getUILanguage,
} from "./../../configs/ui-language-config";
import { switchClasses } from "./../../languages/CssClasses";
import { HeaderNavbarTexts as texts } from "./../../languages/HeaderNavbarTexts";
import { GeneralTexts } from "./../../languages/GeneralTexts";

// Third-Party Modules
import _isEmpty from "lodash/isEmpty";
import Icon from "@mdi/react";
// import { mdiBellRing } from '@mdi/js';

// Our configs
import {
	getRoute,
	getRoutePath,
	getDashboardMenuRoutes,
} from "./../../configs/route-config";

// import logo from "./../../resources/images/amwal-logo.png";
import amwalLogoTransparent from "./../../resources/images/amwal-logo-transparent.png";
// import maleUser from "./../../resources/images/avatar-male.png";
// import femaleUser from "./../../resources/images/default-user-female.jpg";
import maleUser from "./../../resources/images/default-user-male.jpg";

import "./sass/HeaderNavbar.scss";

export default class HeaderNavbar extends React.Component {
	initialState = {};

	// -----------------------------------------------

	// Clone a separate copy of the "initialState" and assign it to the "state".
	// Note that the spread operator "..." is important to get a separate copy.
	state = { ...this.initialState };

	// -----------------------------------------------

	getAuthenticatedUserName = (
		authenticatedUser = this.props.authenticatedUser
	) => {
		return (
			<React.Fragment>
				<img
					src={maleUser}
					alt=""
					className="x-w-26 x-h-26 x-bdrs-100 x-m-5 x-mt-0 x-mb-0"
				/>
				<span className="_text-danger x-fw-500">
					{authenticatedUser?.first_name}
				</span>
			</React.Fragment>
		);
	};

	// -----------------------------------------------

	showDashboardMenu = () => {
		let dashboardMenuRoutes = getDashboardMenuRoutes(
			this.props.authenticatedUser?.user_type
		);
		let counter = dashboardMenuRoutes.length;

		const normalDropdownLinks = dashboardMenuRoutes.map((route) => {
			counter--;

			return (
				<React.Fragment key={Math.random()}>
					<NavDropdown.Item
						exact
						as={NavLink}
						to={route.path}
						className={"x-p-12 " + switchClasses(["x-fz-085rem"])}
					>
						{typeof route.icon === "undefined" ? (
							""
						) : (
							<Icon
								path={route.icon.path}
								size={route.icon.size || "1.3em"}
								className={
									"x-c-777 " +
									route.icon.cssClasses +
									switchClasses(["x-ml-15"])
								}
							/>
						)}
						{route.caption}
					</NavDropdown.Item>

					<NavDropdown.Divider
						className={"x-m-0 " + (counter > 0 ? null : " x-d-n")}
					/>
				</React.Fragment>
			);
		});

		const dropdownLinksIfFirstTimeLoan = (
			<NavDropdown.Item
				exact
				as={NavLink}
				to={getRoute("logout")?.path}
				className={"x-p-12 " + switchClasses(["x-fz-085rem"])}
			>
				<Icon
					path={getRoute("logout")?.icon.path}
					size={getRoute("logout")?.icon.size || "1.3em"}
					className={
						"x-c-ccc " +
						getRoute("logout")?.icon.cssClasses +
						switchClasses(["x-ml-15"])
					}
				/>
				{getRoute("logout")?.caption}
			</NavDropdown.Item>
		);

		return (
			<React.Fragment>
				{/* {this.showLanguageSwitcher()} */}

				<NavDropdown
					title={this.getAuthenticatedUserName()}
					id="basic-nav-dropdown"
					className={
						"x-bdw-1 x-bdc-ddd x-bds-s x-bdrs-4 " + switchClasses(["_x-mr-15"])
					}
				>
					{this.isFirstTimeLoan()
						? dropdownLinksIfFirstTimeLoan
						: normalDropdownLinks}
				</NavDropdown>

				{this.showLanguageSwitcher()}
			</React.Fragment>
		);
	};

	// -----------------------------------------------

	showSignupAndLoginLinks = () => {
		return (
			<React.Fragment>
				<Nav.Link
					as={NavLink}
					exact
					to={getRoutePath("preSignup")}
					className={
						"x-pt-12 link-like x-td-n " + switchClasses(["x-fz-085rem"])
					}
				>
					{texts["signup"]}
				</Nav.Link>

				<Nav.Link
					as={NavLink}
					exact
					to={getRoutePath("login")}
					className={
						"x-pt-12 link-like x-td-n " + switchClasses(["x-fz-085rem"])
					}
				>
					{texts["login"]}
				</Nav.Link>

				{this.showLanguageSwitcher()}
			</React.Fragment>
		);
	};

	// -----------------------------------------------

	showLanguageSwitcher = () => {
		const langCode = preferredLanguage.alpha2code === "ar" ? "en" : "ar";
		// const langTitle = preferredLanguage.alpha2code === "ar" ? "English" : "عربي";
		const langTitle = preferredLanguage.alpha2code === "ar" ? "" : "";
		const langIcon =
			preferredLanguage.alpha2code === "ar"
				? getUILanguage("en").icon
				: getUILanguage("ar").icon;
		const langToolTip =
			preferredLanguage.alpha2code === "ar"
				? "Switch to English"
				: "تغيير اللغة للعربية";

		return (
			// <Nav.Link as={NavLink} exact to={getRoutePath("switchLanguage") + "?lang=" + langCode} className="x-pt-12 clearfix">
			<Nav.Link
				as={NavLink}
				exact
				to={
					getRoutePath("switchLanguage") +
					`?lang=${langCode}&${this.getRedirectUrl()}`
				}
				className="x-pt-12 clearfix"
			>
				<span className={"x-pos-r " + switchClasses(["x-b-3"])}>
					{langTitle}
				</span>
				<img
					src={langIcon}
					alt={""}
					className={
						"x-w-26 x-bdrs-2 x-m-0 x-bds-s x-bdw-1 x-bdc-ccc " +
						switchClasses(["x-mr-5"])
					}
					title={langToolTip}
				/>
			</Nav.Link>
		);
	};

	// -----------------------------------------------

	isFirstTimeLoan = () => {
		return String(this.props.urlQueryString?.first_time_loan) === "1";
	};

	// -----------------------------------------------

	getRedirectUrl = (windowLocation = this.props.windowLocation) => {
		let url = "";

		if (_isEmpty(windowLocation)) {
			url += `pathname=${getRoutePath("home")}`;
			return url;
		}

		const urlSearchPart =
			windowLocation?.search.charAt(0) === "?"
				? windowLocation?.search.slice(1)
				: windowLocation?.search;
		const urlHashPart =
			windowLocation?.hash.charAt(0) === "#"
				? windowLocation?.hash.slice(1)
				: windowLocation?.hash;

		url += `origin=${encodeURIComponent(windowLocation?.origin)}`;
		url += `&pathname=${encodeURIComponent(windowLocation?.pathname)}`;
		url += `&search=${encodeURIComponent(urlSearchPart)}`;
		url += `&hash=${encodeURIComponent(urlHashPart)}`;
		return url;
	};

	// -----------------------------------------------

	getBrandNameSuffix = () => {
		let apiBaseUrl = process.env.REACT_APP_API_BASE_URL || null;

		switch (apiBaseUrl) {
			case "https://api.amwal.com.eg/api":
				return null;

			case "https://api.amwal.link/api":
				return " " + texts["test"];

			case "http://amwal-backend-v1.local/api":
				return " " + texts["development"];

			case "http://localhost/api":
				return " " + texts["development"];

			default:
				return null;
		}
	};

	// -----------------------------------------------

	render() {
		const hideNavbarCssClass = this.props.hide === true ? " x-d-n" : "";

		return (
			<Navbar
				expand="lg"
				className={
					"navbar-light nav-shadow ff-droidarabickufi x-bgc-fff x-pt-12 x-pb-12 " +
					hideNavbarCssClass
				}
			>
				<Navbar.Brand as={Link} to={getRoutePath("home")} className="x-m-0">
					<img
						alt=""
						src={amwalLogoTransparent}
						width="45"
						height="44"
						className="x-pos-r x-t-0 x-ml-3 x-mr-3"
					/>
					<span className={"x-fw-n x-c-000 " + switchClasses(["x-fz-165rem"])}>
						&nbsp;
						{GeneralTexts["website-name"]}
						<span className="x-c-ddd x-fz-13rem x-c-orange">
							{this.getBrandNameSuffix()}
						</span>
					</span>
				</Navbar.Brand>

				<Navbar.Toggle aria-controls="basic-navbar-nav" />

				<Navbar.Collapse id="basic-navbar-nav" className="">
					<Nav
						className={
							"ff-amazonember " + switchClasses(["mr-auto", "x-fz-091rem"])
						}
					>
						<Nav.Link
							as={NavLink}
							exact
							to={getRoute("home").path}
							className="x-pt-12"
						>
							{texts["home"]}
						</Nav.Link>

						{/* <Nav.Link as={NavLink} exact to={getRoute("borrowOverview").path} className={"x-pt-12 " + switchClasses(["x-fz-085rem"])}>
                            {texts["borrow"]}
                        </Nav.Link> */}
						<Nav.Link
							as={NavLink}
							exact
							to={getRoute("borrowFaqs").path}
							className={"x-pt-12 " + switchClasses(["x-fz-085rem"])}
						>
							{texts["borrow"]}
						</Nav.Link>

						{/* <Nav.Link
							as={NavLink}
							exact
							to={getRoute("investOverview").path}
							className={"x-pt-12 " + switchClasses(["x-fz-085rem"])}
						>
							{texts["invest"]}
						</Nav.Link> */}
						<Nav.Link
							as={NavLink}
							exact
							to={getRoute("investFaqs").path}
							className={"x-pt-12 " + switchClasses(["x-fz-085rem"])}
						>
							{texts["invest"]}
						</Nav.Link>

						{/* TODO: Enable this link when its related page is done */}
						{/*
                        <Nav.Link as={NavLink} exact to={getRoute("contactUs").path} className="x-pt-12">
                            {texts["contact-us"]}
                        </Nav.Link>
                         */}

						{!_isEmpty(this.props.authenticatedUser)
							? this.showDashboardMenu()
							: this.showSignupAndLoginLinks()}

						{/* {this.showLanguageSwitcher()} */}
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		);
	}
}
