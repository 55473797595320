import { preferredLanguage } from "./../configs/ui-language-config";

const texts = {
    ar: {
        "phone"        : "التليفون",
        "email"        : "الإيميل",
        "address"      : "العنوان",
        "address-value": process.env.REACT_APP_COMPANY_ADDRESS_AR,
    },

    en: {
        "phone"        : "Phone",
        "email"        : "Email",
        "address"      : "Address",
        "address-value": process.env.REACT_APP_COMPANY_ADDRESS_EN,
    }
}

export const FooterTexts = texts[preferredLanguage.alpha2code];
