import React from "react";
import { Link } from "react-router-dom";
// import FsLightbox from "fslightbox-react";
// import LightBox from "./shared/LightBox"


import { switchClasses } from "../languages/CssClasses";
import { LoanDetailsTexts as texts } from "../languages/LoanDetailsTexts";

// Third-Party Modules
import Joi from "@hapi/joi";
import numeral from "numeral"
import _startCase from "lodash/startCase"
import _toLower from "lodash/toLower"
import _toUpper from "lodash/toUpper"
import _isEmpty from "lodash/isEmpty"

// Our Modules
import MessageModal, { toggleMessageModal, getErrorMessage } from "./shared/MessageModal";
import FormHandler from "./shared/FormHandler";
import PageLoader from "./shared/PageLoader";
import ButtonLoader from "./shared/ButtonLoader";
import CustomButton from "./shared/CustomButton";
// import TextBox from "./shared/TextBox";
// import SelectBox from "./shared/SelectBox";
import PageContent from "./shared/PageContent";
import CustomLightBox from "./shared/CustomLightBox";

// Our Services
import documentTitleService from "../services/document-title-service";
import logService from "../services/log-service";
import * as loanService from "../services/loan-service";
import * as lenderService from "../services/lender-service";

// Our Configs
import { getRoutePath, storePreviousRoutePathInLocalStorage } from "../configs/route-config";
import { getMaxAmountLenderCanLend, getMinAmountLenderCanLend } from "../configs/loan-config";
// import { getCommonCountryList } from "./../configs/country-config";

import defaultLoanCoverImage from "./../../src/resources/images/campaign-default-image-800x450.png";

// import youtubeLogoUrl from "./../../src/resources/images/youtube-logo.png";

export default class LoanDetails extends FormHandler {

    initialState = {
        ...this.initialStateElements,
        isThisPagePublic: true,

        formInputs: {
            amount  : "",
        },
        validationErrors : {},
        messageModal     : {},
        showPageLoader   : true,
        showButtonLoader : false,
        loan             : {},
        loanIndicator    : {},
        loanRiskProfiling: {},
        company          : {},
        lender           : {},
        links            : [],
        investigationGalleryLinks: [],
        walletBalanceAfterInvestment: null, // null is important as an initial value, DON'T use 0 instead.
        investmentAmountHint: null,
        showInvestForm   : false,
    };

    // Clone a separate copy of the "initialState" and assign it to the "state".
    // Note that the spread operator "..." is important to get a separate copy.
    state = { ...this.initialState };

    validationRules = {
        // Note that we override this rules in the getLoan function
        // so we make the max() rule equals the required_amount
        amount: Joi.number()
            .required()
            .integer()
            .positive()
            .min(getMinAmountLenderCanLend())
            .max(getMaxAmountLenderCanLend()) // Note that this value is being replaced in the getLoan() method below
            .label("Amount to Invest"),
    };

    /**
     *
     */
    componentDidMount() {
        // More info: t.ly/ZVsd
        window.scrollTo(0, 0);

        // Properly set the browser's document title
        documentTitleService.setTitle(texts["loan-details"]);

        this.setAuthenticatedUser();
        this.getLoan();
    }

    render() {
        return (
            <PageContent
                pageContent={this.getPageContent()}
                authenticatedUser={this.getAuthenticatedUser()}
                showPageContent={this.showPageContent()}
                windowLocation={window.location}
            />
        );
    }

    /**
     *
     */
    getLoan = async () => {
        this.setState({ showPageLoader: true });

        const response = await loanService.getPublicItem(this.props.match.params.loanUuid, "?include=company|indicator|links");

        const loan = response.data.data.loan || {};

        // console.log("xLOAN: ", loan);

        this.setState({
            formInputs              : {},
            showPageLoader          : false,
            loan                    : loan,
            loanIndicator           : loan.indicator || {},
            loanRiskProfiling       : loan.riskProfiling || {},
            company                 : loan.company || {},
            links                   : loan.links || [],
        });

        this.setInvestigationGalleryLinks(loan.links);

        this.validationRules = {
            ...this.validationRules,
            amount: Joi.number()
                .required()
                .integer()
                .positive()
                .min(getMinAmountLenderCanLend())
                .max(getMaxAmountLenderCanLend(loan.required_amount))
                .label("Amount to Invest"),
        };
    }

    /**
     *
     */
    getCurrentAuthenticatedLender = async () => {
        // If a comma-separated list of attributes is not provided, all the lender attributes will be fetched.
        const response = await lenderService.getMyDataAsLender("?attributes=wallet_balance,wallet_balance_currency");

        const lender = response.data.data.lender || {};

        this.setState({lender: lender});
    }

    /**
     *
     */
    doSubmit = async (e) => {
        this.setState({ showButtonLoader: true });

        try {
            const response = await lenderService.loanCreate({ ...this.state.formInputs, loan_uuid: this.state.loan.uuid });

            this.setState({
                messageModal: {
                    ...toggleMessageModal("success"),
                    body: response.data.meta.message,
                    footerButtons: [
                        {
                            variant: "primary",
                            title: "Loans",
                            onClick: () => this.props.history.push(getRoutePath("lenderLoanList")),
                        },
                        // {
                        //     variant: "primary",
                        //     title: "Close",
                        //     onClick: () => this.setState({ messageModal: { ...this.initialState.messageModal } }),
                        // }
                    ],
                    // onHide: () => this.props.history.push(getRoutePath("lenderLoanList")),
                }
            });
        } catch (error) {
            /**
             * If there is an unexpected error like: network down, server down, db down, bug, ..
             *  - Log the error.
             *  - Display a generic and friendly error message.
             *
             * Note that "error.response" determines if the error is an expected error or not.
             */

            const isExpectedError =
                error.response
                && error.response.status >= 400
                && error.response.status < 500;

            if (!isExpectedError) {
                logService.log(error);
            }

            this.showErrorMessage(error);
        }

        this.setState({ showButtonLoader: false });
    };

    /**
     *
     */
    showErrorMessage = (error, props = this.props) => {
        this.setState({
            messageModal: {
                ...toggleMessageModal("error"),
                body: getErrorMessage(error, props).body || "",
                footerButtons: getErrorMessage(error, props).footerButtons || [],
            }
        });
    }

    /**
     *
     */
    handleShowInvestForm = () => {
        if (this.state.authenticatedUser?.user_type === "lender") {
            this.getCurrentAuthenticatedLender();

            this.setState({showInvestForm: true});
        }
    }

    /**
     * Get the whole content of the page
     */
    getPageContent = () => {
        return (
            <React.Fragment>
                <div className="x-m-10 x-mt-50">
                    <h4 className={"x-ta-c head-color"}>{texts["loan-details"]}</h4>
                </div>

                {this.getLoanCoverImage()}

                <div className="container-fluid">
                    <div className="row x-mb-100">
                        <div className="col-md-2 col-lg-3"></div>
                        <div className={"col-md-8 col-lg-6 " + switchClasses(["x-fz-085rem"])}>
                            {this.getContentOfLoanSummarySection()}
                            {this.getContentOfLoanSection()}
                            {this.getContentOfInvestigationGallerySection()}
                            {this.getContentOfCompanySection()}
                            {this.getContentOfLoanRiskProfilingSection()}
                            {this.getContentOfInvestNowSection()}
                            {this.getContentOfLoanDetailsAdminButtonSection()}
                            {this.getContentOfFurtherInquiryHintSection()}
                        </div>
                    </div>

                    <MessageModal
                        show={this.state.messageModal.show || false}
                        title={this.state.messageModal.title || null}
                        body={this.state.messageModal.body || null}
                        alertClassName={this.state.messageModal.alertClassName || null}
                        size={this.state.messageModal.size || null}
                        centered={this.state.messageModal.centered || false}
                        footerButtons={this.state.messageModal.footerButtons || []}
                        onHide={this.state.messageModal.onHide || (() => this.setState({ messageModal: { ...this.initialState.messageModal } }))}
                    />
                </div>
            </React.Fragment>
        );
    }

    /**
     * Get the cover image of the loan.
     */
    getLoanCoverImage = () => {
        const loan               = this.state.loan;
        const showLoanCoverImage = loan.cover_image_url ? true : false;
        const loanCoverImage     = loan.cover_image_url || defaultLoanCoverImage;

        if (showLoanCoverImage === false) {
            return null;
        }

        return (
            <div className={"container-fluid"}>
                <div className={"row x-pt-10 " + (this.hideWhenThereIsNoDataToDisplay())}>
                    <div className="col-md-2 col-lg-3"></div>
                    <div className={"col-md-8 col-lg-6 x-ta-c"}>
                        <img src={loanCoverImage} alt="" className="x-m-a img-fluid x-bdw-1 x-bds-s x-bdc-ddd shadow-sm" />
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Get the content of the loan summary section.
     */
    getContentOfLoanSummarySection = () => {
        const loan                      = this.state.loan;
        const remainingAmount           = loan.required_amount - loan.pledged_amount;
        const pledgedAmountPercentage   = ((loan.pledged_amount / loan.required_amount) * 100).toFixed(0);
        const remainingAmountPercentage = (((loan.required_amount - loan.pledged_amount) / loan.required_amount) * 100).toFixed(0);

        return (
            <div className="x-p-30 x-mt-30 x-bgc-fff shadow-md x-mih-200">
                <PageLoader show={this.state.showPageLoader} />

                <ul className={"list-unstyled x-pr-0 x-pl-0 " + (this.hideWhenThereIsNoDataToDisplay())}>
                    <li className={switchClasses(["__ff-droidarabickufi"])}>
                        <div className="x-pt-10 x-pb-15">
                            <p className={"text-center x-fw-n x-m-0 x-c-999 " + switchClasses(["x-fz-085rem"])}>{texts["required-amount"]}</p>
                            <h3 className="x-ta-c x-fz-25rem x-m-0 x-dr-ltr">
                                {numeral(loan.required_amount).format("0,0") || 0}<span className="x-fz-1rem">{loan.currency}</span>
                            </h3>
                        </div>
                        <hr />

                        <div className="row x-pt-10">
                            <div className={"col " + switchClasses(["x-ta-r"])}>
                                <p className={"x-fw-n x-m-0 x-c-999 " + switchClasses(["x-fz-085rem"])}>{texts["pledged"]}</p>
                                <p className="x-fz-1rem x-fw-n x-m-0">{pledgedAmountPercentage}%</p>
                                <h3 className="x-fz-15rem x-m-0 x-dr-ltr text-success">
                                    {numeral(loan.pledged_amount).format("0,0") || 0}<span className="x-fw-n x-fz-08rem x-c-999">{loan.currency}</span>
                                </h3>
                            </div>
                            <div className={"col " + switchClasses(["x-ta-l"])}>
                                <p className={"x-fw-n x-m-0 x-c-999 " + switchClasses(["x-fz-085rem"])}>{texts["remaining"]}</p>
                                <p className="x-fz-1rem x-fw-n x-m-0">{remainingAmountPercentage}%</p>
                                <h3 className="x-fz-15rem x-m-0 x-dr-ltr text-danger">
                                    {numeral(remainingAmount).format("0,0") || 0}<span className="x-fw-n x-fz-08rem x-c-999">{loan.currency}</span>
                                </h3>
                            </div>
                        </div>
                        <div className="x-pt-5 x-pb-20">
                            <div className="progress x-mt-5 x-bdrs-10" style={{ height: "1rem" }}>
                                <div
                                    aria-valuemax="100"
                                    aria-valuemin="0"
                                    aria-valuenow={pledgedAmountPercentage}
                                    className="progress-bar green_bgc"
                                    role="progressbar"
                                    style={{ width: pledgedAmountPercentage + "%", "backgroundColor": "#00CC6F" }}>
                                </div>
                            </div>
                        </div>
                        <hr />

                        {/*
                        <div className="x-pt-15 x-ta-c">
                            <p className={"x-fw-n x-m-0 x-c-999 " + switchClasses(["x-fz-085rem"])}>{texts["committed-investors"]}</p>
                            <h3 className="x-fz-17rem x-m-0 x-dr-ltr x-pt-10">{loan.number_of_committed_lenders || 0}</h3>
                        </div>
                        */}

                        <div className="x-pt-15 x-ta-c">
                            <p className={"x-fw-n x-m-0 x-c-999 " + switchClasses(["x-fz-085rem"])}>{texts["return-on-investment"]}</p>
                            <h3 className="x-fz-17rem x-m-0 x-dr-ltr x-pt-10">{numeral(loan.lender_return).format("0,0") + " %" || "N/A"}</h3>
                        </div>
                    </li>
                </ul>
            </div>
        );
    }

    /**
     * Get the content of the loan details section.
     */
    getContentOfLoanSection = () => {
        const loan = this.state.loan;

        return (
            <div className="x-mt-60">
                <h4 className={"head-color x-ta-c"}>{texts["transaction-info"]}</h4>

                <div className="x-p-30 x-mt-15 x-bgc-fff shadow-md x-mih-200">
                    <PageLoader show={this.state.showPageLoader} />

                    <ul className={"list-unstyled x-pr-0 x-pl-0 " + (this.hideWhenThereIsNoDataToDisplay())}>
                        <li className="clearfix">
                            <span className="x-c-999">{texts["transaction-type"]}</span>
                            <span className={"_x-fw-b " + switchClasses(["x-fl-l"])}>{loan.loan_purpose}</span>
                        </li>
                        <hr />
                        <li className="clearfix">
                            <span className="x-c-999">{texts["transaction-duration"]}</span>
                            <span className={"_x-fw-b" + switchClasses(["x-fl-l"])}>
                                {loan.loan_duration_in_months}<span className="x-fw-n x-c-999"> {texts["month"]}</span>
                            </span>
                        </li>
                        <hr />
                        <li className="clearfix">
                            <span className="x-c-999">{texts["transaction-gross-profit"]}</span>
                            <span className={"_x-fw-b x-dr-ltr " + switchClasses(["x-fl-l"])}>
                                {loan.percentage_of_the_transaction_gross_profit}<span className="x-c-999 x-fw-n"> %</span>
                            </span>
                        </li>
                        <hr />
                        <li className="clearfix">
                            <span className="x-c-999">{texts["borrower-has-experience-in-the-transaction"]}</span>
                            <span className={"_x-fw-b " + switchClasses(["x-fl-l"])}>{_startCase(_toLower(loan.has_previous_experience_in_the_transaction))}</span>
                        </li>
                        <hr />
                        <li className="clearfix">
                            <p className="x-c-999 x-mb-10">{texts["transaction-description"]}</p>
                            <div className="x-lh-26 x-whs-pw">
                                {loan.transaction_public_description}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }

    /**
     * Get the content of the multimedia section.
     */
    getContentOfInvestigationGallerySection = () => {
        if (this.getInvestigationGalleryLinks().length === 0) {
            return null;
        }

        return (
            <div className="x-mt-60">
                <h4 className={"head-color x-ta-c"}>{texts["multimedia"]}</h4>

                <div className="x-p-30 x-mt-15 x-bgc-fff shadow-md x-mih-200">
                    <PageLoader show={this.state.showPageLoader} />

                    <div className="x-dr-ltr">
                        <CustomLightBox
                            links={this.getInvestigationGalleryLinks()}
                            numberOfThumbnailsToShow={9}
                        />
                    </div>
                </div>
            </div>
        );
    }

    // /**
    //  *
    //  */
    // getInvestigationGalleryLinks = (numberOfLinks = 9) => {
    //     let galleryLinks = this.state.investigationGalleryLinks || [];

    //     // Get only the first "numberOfLinks" links.
    //     galleryLinks.splice(numberOfLinks);

    //     return galleryLinks;
    // }

    /**
     *
     */
    getInvestigationGalleryLinks = () => {
        return this.state.investigationGalleryLinks || [];
    }

    /**
     *
     */
    setInvestigationGalleryLinks = (links) => {
        let youtubeVideoLinks = [];
        let imageLinks        = [];

        links.forEach(link => {
            if (link.category === "loan-investigation-youtube-video-url") {
                youtubeVideoLinks.push(link);
            } else if (link.category === "loan-investigation-image") {
                imageLinks.push(link);
            }
        });

        // Because we want the the videos to come first, we place them in the beginning of the array.
        const galleryLinks = [...youtubeVideoLinks, ...imageLinks];

        this.setState({
            investigationGalleryLinks: galleryLinks
        });
    }

    /**
     * Get the content of the company details section.
     */
    getContentOfCompanySection = () => {
        const company       = this.state.company;
        const loanIndicator = this.state.loanIndicator;

        return (
            <div className={"x-mt-60" + (_isEmpty(company) ? " x-d-n" : "")}>
                <h4 className={"head-color x-ta-c"}>{texts["company-info"]}</h4>

                <div className="x-p-30 x-mt-15 x-bgc-fff shadow-md x-mih-200">
                    <PageLoader show={this.state.showPageLoader} />

                    <ul className={"list-unstyled x-pr-0 x-pl-0 " + (this.hideWhenThereIsNoDataToDisplay())}>
                        <li className="clearfix">
                            <span className="x-c-999">{texts["headquarter-city"]}</span>
                            {/* <span className="x-fl-r _x-fw-b">
                                {_startCase(_toLower(company.city))}, {_startCase((getCountry(company.country)).name)}
                            </span> */}
                            <span className={"_x-fw-b " + switchClasses(["x-fl-l"])}>
                                {_startCase(_toLower(company.city))}, {_toUpper(company.country)}
                            </span>
                        </li>
                        <hr />
                        <li className="clearfix">
                            <span className="x-c-999">{texts["company-type"]}</span>
                            <span className={"_x-fw-b " + switchClasses(["x-fl-l"])}>{company.company_type}</span>
                        </li>
                        <hr />
                        <li className="clearfix">
                            <span className="x-c-999">{texts["activity-sector"]}</span>
                            <span className={"_x-fw-b " + switchClasses(["x-fl-l"])}>
                                {_startCase(_toLower(company.activity_sector))}
                                &nbsp;
                                {company.activity_subsector ? "- " + _startCase(_toLower(company.activity_subsector)) : ""}
                            </span>
                        </li>
                        <hr />
                        <li className="clearfix">
                            <span className="x-c-999">{texts["founded-in"]}</span>
                            <span className={"_x-fw-b x-dr-ltr " + switchClasses(["x-fl-l"])}>{company.founded_in}</span>
                        </li>
                        <hr />
                        <li className="clearfix">
                            <span className="x-c-999">{texts["operating-license-status"]}</span>
                            <span className={"_x-fw-b " + switchClasses(["x-fl-l"])}>
                                {_startCase(_toLower(loanIndicator.company_operating_license_status)) || <span className="x-fw-n x-c-999">---</span>}
                            </span>
                        </li>
                        <hr />
                        <li className="clearfix">
                            <span className="x-c-999">{texts["number-of-employees"]}</span>
                            <span className={"_x-fw-b " + switchClasses(["x-fl-l"])}>
                                {loanIndicator.company_number_of_employees || ""}
                                &nbsp;
                                <span className="x-fw-n x-c-999">
                                    {
                                        loanIndicator.company_number_of_employees
                                            ? texts["employee"]
                                            : "---"
                                    }
                                </span>
                            </span>
                        </li>
                        <hr />
                        <li className="clearfix">
                            <span className="x-c-999">{texts["capital-size"]}</span>
                            <span className={"_x-fw-b " + switchClasses(["x-fl-l"])}>
                                {
                                    loanIndicator.company_capital_size
                                        ? numeral(loanIndicator.company_capital_size).format("0,0")
                                        : ""
                                }
                                &nbsp;
                                <span className="x-fw-n x-c-999">
                                    {
                                        loanIndicator.company_capital_size
                                            ? loanIndicator.currency
                                            : "---"
                                    }
                                </span>
                            </span>
                        </li>
                        <hr />
                        <li className="clearfix">
                            <span className="x-c-999">{texts["average-monthly-sales"]}</span>
                            <span className={"_x-fw-b " + switchClasses(["x-fl-l"])}>
                                {
                                    loanIndicator.company_average_monthly_sales
                                        ? numeral(loanIndicator.company_average_monthly_sales).format("0,0")
                                        : ""
                                }
                                &nbsp;
                                <span className="x-fw-n x-c-999">
                                    {
                                        loanIndicator.company_average_monthly_sales
                                            ? loanIndicator.currency
                                            : "---"
                                    }
                                </span>
                            </span>
                        </li>
                        <hr />
                        <li className="clearfix">
                            <span className="x-c-999">{texts["last-year-revenue"]}</span>
                            <span className={"_x-fw-b " + switchClasses(["x-fl-l"])}>
                                {
                                    loanIndicator.company_last_year_revenue
                                        ? numeral(loanIndicator.company_last_year_revenue).format("0,0")
                                        : ""
                                }
                                &nbsp;
                                <span className="x-fw-n x-c-999">
                                    {
                                        loanIndicator.company_last_year_revenue
                                            ? loanIndicator.currency
                                            : "---"
                                    }
                                </span>
                            </span>
                        </li>
                        <hr />
                        <li className="clearfix">
                            <span className="x-c-999">{texts["has-bank-account"]}</span>
                            <span className={"_x-fw-b " + switchClasses(["x-fl-l"])}>
                                {_startCase(_toLower(loanIndicator.company_has_bank_account)) || <span className="x-fw-n x-c-999">---</span>}
                            </span>
                        </li>
                        <hr />
                        <li className="clearfix">
                            <span className="x-c-999">{texts["has-financial-statements"]}</span>
                            <span className={"_x-fw-b " + switchClasses(["x-fl-l"])}>
                                {_startCase(_toLower(loanIndicator.company_has_financial_statements)) || <span className="x-fw-n x-c-999">---</span>}
                            </span>
                        </li>
                        <hr />
                        <li className="clearfix">
                            <span className="x-c-999">{texts["has-tax-related-issues"]}</span>
                            <span className={"_x-fw-b " + switchClasses(["x-fl-l"])}>
                                {_startCase(_toLower(loanIndicator.company_has_tax_related_issues)) || <span className="x-fw-n x-c-999">---</span>}
                            </span>
                        </li>
                        <hr />
                        <li className="clearfix">
                            <span className="x-c-999">{texts["has-social-insurance-related-issues"]}</span>
                            <span className={"_x-fw-b " + switchClasses(["x-fl-l"])}>
                                {_startCase(_toLower(loanIndicator.company_has_social_insurance_related_issues)) || <span className="x-fw-n x-c-999">---</span>}
                            </span>
                        </li>
                        <hr />
                        <li className="clearfix">
                            <span className="x-c-999">{texts["has-existing-bank-loans"]}</span>
                            <span className={"_x-fw-b " + switchClasses(["x-fl-l"])}>
                                {_startCase(_toLower(loanIndicator.company_has_existing_bank_loans)) || <span className="x-fw-n x-c-999">---</span>}
                            </span>
                        </li>
                        <hr />
                        <li className="clearfix">
                            <span className="x-c-999">{texts["has-defaulted-loans"]}</span>
                            <span className={"_x-fw-b " + switchClasses(["x-fl-l"])}>
                                {_startCase(_toLower(loanIndicator.company_has_defaulted_loans)) || <span className="x-fw-n x-c-999">---</span>}
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }

    /**
     * Get the content of the loan risk profiling section.
     */
    getContentOfLoanRiskProfilingSection = () => {
        if (_isEmpty(this.state.loanRiskProfiling)) {
            return null;
        }

        return (
            <div className="x-mt-60 x-d-n">
                <h4 className={"head-color x-ta-c"}>{texts["loan-risk-profiling"]}</h4>

                <div className="x-p-30 x-mt-15 x-bgc-fff shadow-md" style={{ minHeight: "200px" }}>
                    <PageLoader show={this.state.showPageLoader} />

                    <ul className={"list-unstyled x-pr-0 x-pl-0 " + (this.hideWhenThereIsNoDataToDisplay())}>
                        <li className="clearfix">
                            <span className="x-c-999">xxxx xxxx</span>
                            <span className={"_x-fw-b " + switchClasses(["x-fl-l"])}>xxxxx</span>
                        </li>
                        <hr />
                        <li className="clearfix">
                            <span className="x-c-999">xxxx xxxx</span>
                            <span className={"_x-fw-b " + switchClasses(["x-fl-l"])}>xxxxx</span>
                        </li>
                        <hr />
                        <li className="clearfix">
                            <span className="x-c-999">xxxx xxxx</span>
                            <span className={"_x-fw-b " + switchClasses(["x-fl-l"])}>xxxxx</span>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }

    /**
     * Get the content of the the loan details admin button section.
     */
    getContentOfLoanDetailsAdminButtonSection = () => {
        const loan = this.state.loan;
        const authenticatedUser = this.state.authenticatedUser;

        if (authenticatedUser?.user_type !== "admin") {
            return null;
        }

        return (
            <div className={"x-mt-60"}>
                <div className="x-p-30 x-mt-15 x-bgc-fff shadow-md">
                    <PageLoader show={this.state.showPageLoader} />

                    <ul className={"list-unstyled x-pr-0 x-pl-0 " + (this.hideWhenThereIsNoDataToDisplay())}>
                        <li className="x-ta-c">
                            <div className={"x-ta-c x-c-crimson x-lh-30 " + switchClasses(["x-fz-085rem"])}>
                                {texts["only-admins-can-see-this-section"]}
                            </div>

                            <div className={"x-pt-20"}>
                                <CustomButton
                                    type="button"
                                    label={texts["loan-full-details"]}
                                    onClick={() => this.props.history.push(getRoutePath("adminLoanBasic") + "/" + loan.uuid)}
                                    disabled={(authenticatedUser?.user_type !== "admin") ? true : false}
                                    extraCssClasses={switchClasses(["x-fz-09rem"])}
                                />
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }

    /**
     * Get the content of the further inquiry hint section.
     */
    getContentOfFurtherInquiryHintSection = () => {
        return (
            <div className="x-mt-50">
                <div className={"x-c-555 x-lh-28 x-ta-c " + switchClasses(["x-fz-085rem"])}>
                    {texts["further-inquiry-hint"]}
                </div>
            </div>
        );
    }

    /**
     * Get a CSS class that hides an HTML element.
     */
    hideWhenThereIsNoDataToDisplay = () => {
        // The space before the class name is important
        return this.state.showPageLoader ? " x-d-n" : ""
    }

    /**
     *
     */
    getContentOfInvestNowSection = () => {
        return (
            <div className="x-mt-60">
                <div className="x-p-30 x-mt-15 x-bgc-fff shadow-md">
                    <PageLoader show={this.state.showPageLoader} />

                    <ul className={"list-unstyled x-pr-0 x-pl-0 " + (this.hideWhenThereIsNoDataToDisplay())}>
                        <li className="x-ta-c">
                            {this.getContentOfInvestNowButton()}
                            {this.getContentOfInvestNowForm()}
                        </li>
                    </ul>
                </div>
            </div>
        );
    }

    /**
     *
     */
    getContentOfInvestNowButton = () => {
        const authenticatedUser = this.state.authenticatedUser;

        return (
            <div className={"row x-pt-30" + (this.state.showInvestForm ? " x-d-n" : "")}>
                <div className="col-md-3"></div>
                    <div className="col-md-6">
                        <CustomButton
                            type="button"
                            label={texts["invest-in-this-loan"]}
                            onClick={this.handleShowInvestForm}
                            disabled={(authenticatedUser?.user_type !== "lender") ? true : false}
                            extraCssClasses={"btn-block " + switchClasses(["x-fz-09rem"])}
                        />

                    <p className="x-c-999 x-pt-10"><small>{texts["button-is-enabled-for-lenders-only"]}</small></p>

                    <div className="x-pt-20 x-fz-08rem">
                        <Link to={getRoutePath("login")} onClick={() => {storePreviousRoutePathInLocalStorage()}}>{texts["login"]}</Link>
                        &nbsp;&nbsp;&nbsp;{texts["or"]}&nbsp;&nbsp;&nbsp;
                        <Link to={getRoutePath("preSignup")}>{texts["signup"]}</Link>
                    </div>
                </div>
            </div>
        );
    }

    /**
     *
     */
    getContentOfInvestNowForm = () => {
        const loan                = this.state.loan;
        const lender              = this.state.lender;
        const authenticatedUser   = this.state.authenticatedUser;
        // const minInvestmentAmount = getMinAmountLenderCanLend();
        // const maxInvestmentAmount = getMaxAmountLenderCanLend(loan.required_amount);

        return (
            <div className={"x-pt-20 _x-pb-10 " + ((authenticatedUser?.user_type === "lender" && this.state.showInvestForm) ? "" : " x-d-n")}>

                <h5>{texts["hi"]} <span className="x-c-crimson">{authenticatedUser?.first_name}</span></h5>
                <h5 className="x-pt-10">{texts["how-much-do-you-want-to-invest"]}</h5>

                {/*
                <p className="x-c-aaa x-fz-075rem x-m-0 x-pt-20">
                    ( {texts["min"]}: <span className="x-c-333">{minInvestmentAmount}</span> {loan.currency}
                    &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;
                    {texts["max"]}: <span className="x-c-333">{maxInvestmentAmount}</span> {loan.currency} )
                </p>
                */}

                <form className="x-mt-40 x-pt-40 x-pb-40 x-bgc-fafafa border-ececec" onSubmit={(e) => e.preventDefault()}>
                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-6">
                            {/* TODO: Make a separate component of this input group like TextBox */}
                            {/* Start Input Group */}
                            <div className="form-group _x-pt-15">
                                <label htmlFor={"amount"}>
                                    {texts["i-want-to-invest"]}
                                    {/* <span className="x-c-red"> *</span> */}
                                </label>
                                <div className="input-group mb-3 x-dr-ltr">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">{loan.currency}</span>
                                    </div>
                                    <input
                                        autoComplete={"off"}
                                        autoFocus={false}
                                        className={"form-control x-bdrs-2 x-pt-21 x-pb-21   x-dr-ltr x-ta-l x-fz-1rem"}
                                        disabled={false}
                                        id={"amount"}
                                        name={"amount"}
                                        onBlur={this.handleBlur}
                                        onChange={(e) => {this.handleChange(e); this.calculateWalletBalanceAfterInvestment(e)}}
                                        onFocus={this.handleFocus}
                                        onKeyPress={(() => {})}
                                        placeholder={getMinAmountLenderCanLend()}
                                        type="text"
                                        value={this.state.formInputs.amount || ""}
                                    />
                                </div>
                                <small className="form-text text-danger">{this.state.validationErrors.amount || null}</small>
                                <small className="form-text text-danger">{this.state.investmentAmountHint || null}</small>
                             </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-6">
                            <div className="x-mt-10 x-ta-c">
                                <CustomButton
                                    type="button"
                                    label={this.state.showButtonLoader ? <ButtonLoader /> : texts["execute"]}
                                    disabled={this.validateFormInputs() ? true : false}
                                    onClick={this.handleSubmit}
                                    extraCssClasses={"btn-block " + switchClasses(["x-fz-09rem"])}
                                />
                            </div>
                        </div>
                    </div>
                </form>

                <div className="x-pt-35 x-fz-08rem x-ta-c x-c-aaa">
                    <p>
                        {texts["your-current-wallet-balance"]}&nbsp;
                        <span className="x-c-crimson x-fw-b x-fz-09rem">{numeral(lender?.wallet_balance).format("0,0") || 0} {lender?.wallet_balance_currency || ""}</span>
                    </p>

                    <p>{texts["top-up-your-wallet-to-invest"]}</p>
                </div>

                {/*
                <ul className={"x-list-d x-lisp-i x-mt-50 x-p-20 x-c-777 x-fz-085rem x-bgc-fafafa x-bdw-1 x-bds-s x-bdc-eee" + switchClasses(["x-ta-r"])}>
                    <li className={"x-list-n x-fz-1rem"}><strong>{texts["notes"]}:</strong></li>

                    <li className="x-m-0 x-pt-10 x-fz-08rem">
                        {texts["your-current-wallet-balance"]}:&nbsp;
                        <span className="x-c-crimson x-fw-b x-fz-09rem">{numeral(lender?.wallet_balance).format("0,0") || 0} {lender?.wallet_balance_currency || ""}</span>.&nbsp;
                    </li>


                    <li className="x-m-0 x-pt-10 x-fz-08rem">
                        {texts["wallet-balance-after-investment"]}:&nbsp;
                        <span className="x-c-crimson x-fw-b x-fz-09rem">
                            {
                                this.state.walletBalanceAfterInvestment === null
                                    ? numeral(lender?.wallet_balance).format("0,0")
                                    : numeral(this.state.walletBalanceAfterInvestment).format("0,0")
                            }
                            &nbsp;{lender?.wallet_balance_currency || ""}
                        </span>.
                    </li>


                    <li className="x-m-0 x-pt-10 x-fz-08rem">
                        {texts["amount-will-be-deducted-from-wallet-balance"]}
                    </li>

                    <li className="x-m-0 x-pt-10 x-fz-08rem">
                        {texts["top-up-your-wallet-to-invest"]}
                    </li>
                </ul>
                */}
            </div>
        );
    }

    /**
     *
    */
    calculateWalletBalanceAfterInvestment = (event) => {
        const input            = event.currentTarget;
        const investmentAmount = Number(input.value);
        const walletBalance    = Number(this.state.lender?.wallet_balance);

        this.setState({walletBalanceAfterInvestment: walletBalance - investmentAmount});

        if (investmentAmount > walletBalance) {
            this.setState({investmentAmountHint: texts["insufficient-wallet-balance"]});
        } else {
            this.setState({investmentAmountHint: null});
        }
    }
}
