import React from "react";
import { Link } from "react-router-dom";

// Third-Party Modules
import Joi from "@hapi/joi";
import Icon from "@mdi/react"
import { mdiChevronLeft } from "@mdi/js";

// Our Modules
import MessageModal, { toggleMessageModal, getErrorMessage } from "./../../shared/MessageModal";
import FormHandler from "./../../shared/FormHandler";
import TextBox from "./../../shared/TextBox";
// import SelectBox from "./../../shared/SelectBox";
// import TextareaBox from "./../../shared/TextareaBox";
// import CustomButton from "./../../shared/CustomButton";
import PageLoader from "./../../shared/PageLoader";
// import ButtonLoader from "./../../shared/ButtonLoader";
import RequiredDataHint from "./../../shared/RequiredDataHint";
import AdminLoanSubMenu from "./AdminLoanSubMenu";
import PageContent from "./../../shared/PageContent";

// Our Services
import documentTitleService from "./../../../services/document-title-service";
// import logService from "./../../../services/log-service";
import * as loanService from "./../../../services/loan-service";

// Our Configs
import { getRoutePath } from "./../../../configs/route-config";
// import { getCommonCountryList } from "./../../../configs/country-config";
// import { getLoanPurposes, getLoanDurations, getPercentagesOfTransactionGrossProfit } from "./../../../configs/loan-config";

export default class AdminLoanRiskProfiling extends FormHandler {

    initialState = {
        ...this.initialStateElements,
        allowedUserType: "admin",

        formInputs       : {
            id: "",
            //...
        },
        validationErrors : {},
        messageModal     : {},
        showPageLoader   : true,
        showButtonLoader : false,
        loan             : {},
        // user             : {},
        // borrower         : {},
        // company          : {},
        urlParmas        : {},
    };

    // -----------------------------------------------

    // Clone a separate copy of the "initialState" and assign it to the "state".
    // Note that the spread operator "..." is important to get a separate copy.
    state = { ...this.initialState };

    // -----------------------------------------------

    validationRules = {
        id: Joi.number().optional().allow(null, "").integer().positive(),
        //...
    };

    // -----------------------------------------------

    componentDidMount() {
        // More info: t.ly/ZVsd
        window.scrollTo(0, 0);

        // ----------------------

        // Properly set the browser's document title
        documentTitleService.setTitle("Admin Dashboard | Loan Risk Profiling");

        // ----------------------

        this.setState({ urlParmas: this.props.match.params });

        // ----------------------

        this.setAuthenticatedUser();
        this.getLoan();
    }

    // -----------------------------------------------

    // Get loan data that the admin tries to update
    getLoan = async () => {
        this.setState({ showPageLoader: true });

        // const response = await loanService.getItem(this.props.match.params.loanUuid, "?include=borrower.user|company");
        const response = await loanService.getItem(this.props.match.params.loanUuid);

        const loan = response.data.data.loan;

        // TEST
        console.log("Loan Data: ", loan);

        this.setState({
            formInputs: {
                id: loan.id || "",
                //...
            },
            showPageLoader: false,
            loan          : loan,
            // borrower      : loan.borrower,
            // user          : loan.borrower.user,
            // company       : loan.company,
        });
    }

    // -----------------------------------------------

    doSubmit = async (e) => {
        //...
    };

    // -----------------------------------------------

    showErrorMessage = (error, props = this.props) => {
        this.setState({
            messageModal: {
                ...toggleMessageModal("error"),
                body: getErrorMessage(error, props).body || "",
                footerButtons: getErrorMessage(error, props).footerButtons || [],
            }
        });
    }

    // -----------------------------------------------

	render() {
		const pageContent = (
            <React.Fragment>
                <div className="x-m-25">
                    <div>
                        <Link to={getRoutePath("adminLoanList")} className="x-fz-092rem">
                            <Icon
                                path={mdiChevronLeft}
                                size="1.4em"
                                className="x-pos-r x-b-2"
                            />
                            Loans
                        </Link>
                    </div>

                    <h5 className="x-pt-10 head-color __ff-rubik">Loan Risk Profiling</h5>
                </div>

                <div className="_border _border-light shadow-sm x-m-25 x-mt-0 x-mb-70 x-p-10 x-pb-100 x-mih-500 x-bgc-fff x-bdc-e3e3e3 x-bdrs-4">
                    <div className="container-fluid">
                        <div className="row x-pt-20">
                            <div className="col-md-3">
                                <AdminLoanSubMenu loan={this.state.loan} />
                            </div>
                            <div className="col-md-9">
                                <PageLoader show={this.state.showPageLoader} />

                                <div className={(this.state.showPageLoader ? "x-d-n" : "")}>
                                    <form className="" onSubmit={(e) => e.preventDefault()}>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <TextBox
                                                    name="xxx"
                                                    label="xxxx xxxx"
                                                    value={this.state.formInputs.xxx || ""}
                                                    onBlur={this.handleBlur}
                                                    onChange={this.handleChange}
                                                    onFocus={this.handleFocus}
                                                    validationError={this.state.validationErrors.xxx || ""}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <RequiredDataHint className={this.state.showPageLoader ? "x-d-n" : ""} />
                    </div>

                    <MessageModal
                        show={this.state.messageModal.show || false}
                        title={this.state.messageModal.title || null}
                        body={this.state.messageModal.body || null}
                        alertClassName={this.state.messageModal.alertClassName || null}
                        size={this.state.messageModal.size || null}
                        centered={this.state.messageModal.centered || false}
                        footerButtons={this.state.messageModal.footerButtons || []}
                        onHide={this.state.messageModal.onHide || (() => this.setState({ messageModal: { ...this.initialState.messageModal } }))}
                    />
                </div>
            </React.Fragment>
        );

        return (
            <PageContent
                pageContent={pageContent}
                authenticatedUser={this.getAuthenticatedUser()}
                showPageContent={this.showPageContent()}
                windowLocation={window.location}
            />
        );
	}
}
