import { preferredLanguage } from "../configs/ui-language-config";
// import { GeneralTexts } from "./GeneralTexts";
// import { RouteConfigTexts } from "./RouteConfigTexts";

const texts = {
    ar: {
        "show-more": "عرض المزيد",
    },

    en: {
        "show-more": "Show More",
    }
}

export const CustomLightBoxTexts = texts[preferredLanguage.alpha2code];
